import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import EnhacedTable from '../../../../../lib/designSystem/Table';
import { getBankrruptcyPosts } from '../../../../../lib/api/dashboard';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CustomButton from '../../../../../lib/designSystem/Button';

const LastBaknrruptcyPosts = () => {
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeBusiness, setActiveBusiness] = useState<any>(null);

  const fetchRelations = async () => {
    setLoading(true);
    const relations = await getBankrruptcyPosts(dispatch);
    setInvoices(relations || []);
    console.log(loading);
    setLoading(false);
  };

  useEffect(() => {
    fetchRelations();
  }, []);

  if (loading) return <CenteredCircularProgress relative />;

  const actions = [
    {
      action: (id: any) => {
        const activeBusiness = invoices.find(
          (invoice: any) => invoice.id === id,
        );
        setActiveBusiness(activeBusiness);
      },
      actionLabel: 'Ver publicaciones',
    },
  ];

  return (
    <div>
      <EnhacedTable
        actions={actions}
        dataHeaders={[
          { key: 'businessName', label: 'Cuenta' },
          { key: 'businessIdentifier', label: 'Rut' },
          { key: 'postsCount', label: 'Publicaciones' },
        ]}
        data={invoices}
        rowsPerPageDefault={100}
      />

      {activeBusiness && (
        <Dialog
          open={true}
          onClose={() => setActiveBusiness(null)}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>
            Publicaciones de {activeBusiness.businessName}.
          </DialogTitle>
          <DialogContent>
            <EnhacedTable
              dataHeaders={[
                { key: 'role', label: 'Rol' },
                { key: 'process', label: 'Procedimiento ' },
                { key: 'watcher', label: 'Liquidador' },
                { key: 'name', label: 'Publicación' },
                { key: 'court', label: 'Tribunal' },
                { key: 'date', label: 'Publicación', type: 'date' },
              ]}
              data={activeBusiness ? activeBusiness.posts : []}
              rowsPerPageDefault={5}
            />
          </DialogContent>
          <DialogActions>
            <CustomButton
              variant="contained"
              color="primary"
              onClick={() => setActiveBusiness(null)}
            >
              Cerrar
            </CustomButton>
          </DialogActions>
        </Dialog>
      )}
      {loading && <CenteredCircularProgress relative />}
    </div>
  );
};

export default LastBaknrruptcyPosts;
