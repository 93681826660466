import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { formatDate, formatMoney } from '../../../../../../lib/functions/utils';

//
export const getImageDimensionsFromBase64 = (
  base64: string,
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.src = base64;
  });
};

export const generateDocumentReportPDF = async (
  document: any,
  userName: string,
  imageBase64: string,
) => {
  const doc = new jsPDF();

  // Add the title section
  const addTitle = async () => {
    // Add the image
    const imgX = 10; // X position for the image
    const imgY = 5; // Y position for the image
    const imgHeight = 15; // Height of the image

    const { width: naturalWidth, height: naturalHeight } =
      await getImageDimensionsFromBase64(imageBase64);
    const aspectRatio = naturalWidth / naturalHeight;
    const imgWidth = imgHeight * aspectRatio;

    // Load and add the image
    doc.addImage(imageBase64 as any, 'PNG', imgX, imgY, imgWidth, imgHeight);

    // Add the title text
    doc.setFontSize(16);
    doc.text('Detalle Documento', 100, 15, { align: 'center' });

    // Add the date and user
    doc.setFontSize(10);
    doc.text(`Fecha: ${new Date().toLocaleString()}`, 160, 10);
    doc.text(`Usuario: ${userName}`, 160, 15);
  };

  // Add the summary section
  const addSummary = () => {
    doc.setFontSize(10);

    // Column positions
    const col1X = 10;
    const col2X = 110;
    const defaultX = 35; // Standard separation between key and value
    const initialY = 35;

    // Column 1
    doc.setFont('helvetica', 'bold');
    doc.text('Rut cliente', col1X, initialY);
    doc.setFont('helvetica', 'normal');
    doc.text(`: ${document.businessIdentifier}`, col1X + defaultX, initialY);

    doc.setFont('helvetica', 'bold');
    doc.text('Rut deudor', col1X, initialY + 5);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${document.stakeholderIdentifier}`,
      col1X + defaultX,
      initialY + 5,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Nro. operación', col1X, initialY + 10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${String(document.requestId)}`,
      col1X + defaultX,
      initialY + 10,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Tipo documento', col1X, initialY + 15);
    doc.setFont('helvetica', 'normal');
    doc.text(`: ${document.type}`, col1X + defaultX, initialY + 15);

    // Column 2
    doc.setFont('helvetica', 'bold');
    doc.text('Nombre cliente', col2X, initialY);
    doc.setFont('helvetica', 'normal');
    const shortName =
      document.businessName.length > 25
        ? document.businessName.substring(0, 25) + '...'
        : document.businessName;
    doc.text(`: ${shortName}`, col2X + defaultX, initialY);

    doc.setFont('helvetica', 'bold');
    doc.text('Nombre deudor', col2X, initialY + 5);
    doc.setFont('helvetica', 'normal');
    const shortStakeholderName =
      document.stakeholderName.length > 25
        ? document.stakeholderName.substring(0, 25) + '...'
        : document.stakeholderName;
    doc.text(`: ${shortStakeholderName}`, col2X + defaultX, initialY + 5);

    doc.setFont('helvetica', 'bold');
    doc.text('Folio', col2X, initialY + 10);
    doc.setFont('helvetica', 'normal');
    doc.text(`: ${document.folio}`, col2X + defaultX, initialY + 10);

    doc.setFont('helvetica', 'bold');
    doc.text('Estado', col2X, initialY + 15);
    doc.setFont('helvetica', 'normal');
    doc.text(`: ${document.status}`, col2X + defaultX, initialY + 15);

    // Column1

    // Move down by 10 and add a "Resumen operación" title
    const newInitialY = initialY + 25;
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('Resumen operación', col1X, newInitialY);

    doc.setFontSize(10);

    doc.setFont('helvetica', 'bold');
    doc.text('Fecha operación', col1X, newInitialY + 10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${formatDate(document.operationDate)}`,
      col1X + defaultX,
      newInitialY + 10,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Tasa negocio', col1X, newInitialY + 15);
    doc.setFont('helvetica', 'normal');
    doc.text(`: ${document.rate}%`, col1X + defaultX, newInitialY + 15);

    doc.setFont('helvetica', 'bold');
    doc.text('Monto documento', col1X, newInitialY + 20);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${formatMoney(document.amount)}`,
      col1X + defaultX,
      newInitialY + 20,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Costo interés', col1X, newInitialY + 25);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${formatMoney(document.interestCost)}`,
      col1X + defaultX,
      newInitialY + 25,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Fecha pago', col1X, newInitialY + 30);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${formatDate(document.paymentDate)}`,
      col1X + defaultX,
      newInitialY + 30,
    );

    // column2

    doc.setFont('helvetica', 'bold');
    doc.text('Anticipo', col2X, newInitialY + 10);
    doc.setFont('helvetica', 'normal');
    doc.text(`: ${document.advance}%`, col2X + defaultX, newInitialY + 10);

    doc.setFont('helvetica', 'bold');
    doc.text('Tasa mora', col2X, newInitialY + 15);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${String(document.arrearRate)}%`,
      col2X + defaultX,
      newInitialY + 15,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Monto neto', col2X, newInitialY + 20);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${formatMoney(document.amountNet)}`,
      col2X + defaultX,
      newInitialY + 20,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Días', col2X, newInitialY + 25);
    doc.setFont('helvetica', 'normal');
    doc.text(`: ${document.daysToCount}`, col2X + defaultX, newInitialY + 25);

    const newInitialY2 = newInitialY + 45;
    doc.setFontSize(12);

    // Titulo Recaudaciones
    doc.setFont('helvetica', 'bold');
    doc.text('Recaudaciones', col1X, newInitialY2);

    doc.setFontSize(10);
    // column 1
    doc.setFont('helvetica', 'bold');
    doc.text('Total cancelado', col1X, newInitialY2 + 10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${formatMoney(document.amountConciliated)}`,
      col1X + defaultX,
      newInitialY2 + 10,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Mora cancelada', col1X, newInitialY2 + 15);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${formatMoney(document.arrearConciliation)}`,
      col1X + defaultX,
      newInitialY2 + 15,
    );

    // Column2
    doc.setFont('helvetica', 'bold');
    doc.text('Capital cancelado', col2X, newInitialY2 + 10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${formatMoney(document.documentConciliation)}`,
      col2X + defaultX,
      newInitialY2 + 10,
    );

    // Column 3

    const newInitialY3 = 150 + document.conciliationLog.length * 10;

    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('Estado actual', col1X, newInitialY3);
    doc.setFontSize(10);

    autoTable(doc, {
      startY: newInitialY3 + 5,
      head: [['Saldo', 'Monto']],
      body: [
        ['Saldo mora', formatMoney(document.arrearAmount)],
        ['Saldo capital', formatMoney(document.documentRemainingAmountNet)],
        ['Saldo total', formatMoney(document.totalRemainingNet)],
      ],
      theme: 'grid',
      headStyles: {
        fillColor: '#efefef',
        textColor: '#1b1b1b',
        fontStyle: 'bold',
        halign: 'left',
        valign: 'middle',
        lineColor: [44, 62, 80],
        fontSize: 9,
      },
      bodyStyles: {
        textColor: [50, 50, 50],
        fontSize: 8,
      },
      styles: {
        font: 'helvetica',
        lineColor: [44, 62, 80],
        lineWidth: 0.1,
      },
      columnStyles: {
        0: { cellWidth: 50 }, // Ancho de la primera columna
        1: { cellWidth: 50 }, // Ancho de la segunda columna
      },
    });
  };

  const addDocumentsTable = () => {
    doc.setFontSize(10);
    autoTable(doc, {
      startY: 130,
      head: [
        ['Tipo', 'Fecha', 'Cancelado', 'Cancelado Documento', 'Cancelado Mora'],
      ],
      body: document.conciliationLog.map((doc: any) => [
        String(doc.typeEsp),
        formatDate(doc.date),
        formatMoney(
          doc.conciliationArrearAmount + doc.conciliationDocumentAmount,
        ),
        formatMoney(doc.conciliationDocumentAmount),
        formatMoney(doc.conciliationArrearAmount),
      ]),
      theme: 'grid',
      headStyles: {
        fillColor: '#efefef', // or '#2980b9' in hex format
        textColor: '#1b1b1b',
        fontStyle: 'bold',
        halign: 'left', // horizontal alignment
        valign: 'middle', // vertical alignment
        lineColor: [44, 62, 80],
        fontSize: 9,
      },
      bodyStyles: {
        textColor: [50, 50, 50],
        fontSize: 8,
      },
      styles: {
        font: 'helvetica', // choose the font
        lineColor: [44, 62, 80],
        lineWidth: 0.1,
      },
    });
    doc.setFontSize(10);
  };

  // Add the applications table section

  await addTitle();
  addSummary();
  addDocumentsTable();
  // Save the PDF
  doc.save(`REPORTE-${document.businessName}-${document.folio}.pdf`);
};
