import React from 'react';
import CollectionsIndicators from './components/Indicators';

const CollectionsHome = () => {
  return (
    <div>
      <CollectionsIndicators />
    </div>
  );
};

export default CollectionsHome;
