import { Collapse, Divider, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { formatDate } from '../../../../../../lib/functions/utils';
import { ExpandMore } from '../documentsPreview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getRequestEvaluations } from '../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { buildRequestId } from '../utils';
import { LightTooltip } from '../../../../../../lib/designSystem/WhiteTooltip';
import { RemoveRedEye } from '@mui/icons-material';

const EvaluationInformation = (props: any) => {
  const { evaluation } = props;

  console.log('evaluation', evaluation);

  return (
    <div style={{ padding: 20 }}>
      {evaluation.revisionStory && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <Typography variant="body1">ID</Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography variant="body1">Nodo decisión</Typography>
            </Grid>

            {evaluation.engineType !== 'Árbol' ? (
              <Grid item xs={3}>
                <Typography variant="body1">Decisión</Typography>
              </Grid>
            ) : (
              <Grid item xs={2} />
            )}

            <Grid item xs={12} md={evaluation.engineType !== 'Árbol' ? 3 : 4}>
              <Typography variant="body1">Resultado</Typography>
            </Grid>
          </Grid>
          <Divider />
          {evaluation.revisionStory.map((node: any, index: number) => (
            <ObjectRevisionStoryContent
              key={index}
              node={node}
              index={index}
              engineType={evaluation.engineType}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ObjectRevisionStoryContent = (props: any) => {
  const { node, index, engineType } = props;

  const coloredText = (text: string) => {
    switch (text) {
      case 'Aprobado':
        return (
          <span style={{ color: 'green', fontWeight: 'bold' }}>{text}</span>
        );
      case 'Rechazado':
        return <span style={{ color: 'red', fontWeight: 'bold' }}>{text}</span>;
      default:
        return (
          <span style={{ color: 'orange', fontWeight: 'bold' }}>{text}</span>
        );
    }
  };

  return (
    <div style={{ padding: '10px 0px' }}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Typography variant="body1">{index + 1}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography fontWeight="bold" variant="body1">
            {node.node}
          </Typography>
        </Grid>

        {engineType !== 'Árbol' ? (
          <Grid item xs={3}>
            <Typography variant="body1">{coloredText(node.comitee)}</Typography>
          </Grid>
        ) : (
          <Grid item xs={2} />
        )}
        <Grid item xs={12} md={engineType !== 'Árbol' ? 3 : 4}>
          {node.subDecisionEngineRevisionStory ? (
            <LightTooltip
              title={
                <div style={{ padding: '10px', minWidth: '700px' }}>
                  <Typography fontWeight="bold">
                    {coloredText(node.comitee)}
                  </Typography>
                  <EvaluationInformation
                    evaluation={{
                      engineType: 'Árbol',
                      revisionStory: node.subDecisionEngineRevisionStory,
                    }}
                  />
                </div>
              }
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'blue',
                  cursor: 'pointer',
                }}
              >
                <RemoveRedEye style={{ marginRight: '5px' }} />
                <Typography fontWeight="bold">SubEvaluación</Typography>
              </div>
            </LightTooltip>
          ) : (
            <Typography>{node.resultText}</Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const ObjectRevisionStoryContainer = (props: any) => {
  const { story } = props;
  const [expandedStory, setExpandedStory] = useState(false);
  const handleExpandClickStory = () => {
    setExpandedStory(!expandedStory);
  };
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 10,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Typography fontWeight="bold" variant="h6">
          {formatDate(new Date(story.timestamp), 'large')} |{' '}
          {story.reviewerName} | {story.status}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography fontWeight="bold" variant="body1">
            Ver detalles
          </Typography>
          <ExpandMore
            expand={expandedStory}
            onClick={handleExpandClickStory}
            aria-expanded={expandedStory}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </div>
      </div>
      <Collapse in={expandedStory} timeout="auto" unmountOnExit>
        <EvaluationInformation evaluation={story} />
      </Collapse>
    </div>
  );
};

const ObjectReviewed = (props: any) => {
  const { object } = props;
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 10,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <div style={{ display: 'flex' }}>
          <Typography fontWeight="bold" variant="h6">
            {object.identifier} | {object.evaluation}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography fontWeight="bold" variant="body1">
            Ver revisiones
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </div>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div style={{ padding: '20px 0px 20px 20px' }}>
          <Typography variant="body1">
            Estas son todas las revisiones registradas:
          </Typography>
          {object.evaluations.map((story: any, index: number) => (
            <ObjectRevisionStoryContainer key={index} story={story} />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

const RiskReviews = (props: any) => {
  const { selectedRequest, requestType } = props;
  const [loading, setLoading] = useState(false);
  const [objectsReviewed, setObjectsReviewed] = useState<any[]>([]);

  const dispatch = useDispatch();

  const getObjectReviewed = async () => {
    setLoading(true);
    try {
      const response = await getRequestEvaluations(dispatch, {
        requestId: buildRequestId(selectedRequest, requestType, 'quote'),
        requestType,
      });
      console.log('response', response);
      setObjectsReviewed(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getObjectReviewed();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      {objectsReviewed.length === 0 && (
        <Typography variant="body1">No hay revisiones registradas</Typography>
      )}
      {objectsReviewed.map((object: any, index: number) => (
        <ObjectReviewed key={index} object={object} />
      ))}
    </div>
  );
};

export default RiskReviews;
