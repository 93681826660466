import React from 'react';
import GeneralCustomAlert from './components/GeneralCustomAlert';

const GeneralComponents = () => {
  return (
    <div>
      <GeneralCustomAlert />
    </div>
  );
};

export default GeneralComponents;
