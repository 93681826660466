import React from 'react';
import { Typography, Divider } from '@mui/material';
import {
  formatMoney,
  formatDate,
} from '../../../../../../../../../../../../../lib/functions/utils';

function calculateIRR(
  cuota: number,
  numCuotas: number,
  montoPrestamo: number,
): number {
  const maxIterations = 1000; // Máximo de iteraciones
  const tolerance = 1e-9; // Tolerancia para convergencia
  let tasa = 0.01; // Suposición inicial para la tasa de interés (1%)

  for (let i = 0; i < maxIterations; i++) {
    let f = 0; // Función f(tasa)
    let df = 0; // Derivada f'(tasa)

    for (let n = 1; n <= numCuotas; n++) {
      f += cuota / Math.pow(1 + tasa, n);
      df += (-n * cuota) / Math.pow(1 + tasa, n + 1);
    }

    f -= montoPrestamo;

    // Actualización de la tasa usando Newton-Raphson
    const nuevaTasa = tasa - f / df;

    if (Math.abs(nuevaTasa - tasa) < tolerance) {
      tasa = nuevaTasa; // Convergencia encontrada
      break;
    }

    tasa = nuevaTasa;
  }

  const finalRate = ((1 + tasa) ** 12 - 1) * 100;

  return finalRate;
}

const Summary = (props: any) => {
  const { creditDetails, amortizationSchedule } = props;
  if (!creditDetails) return null;

  if (!amortizationSchedule.length) return null;
  const firstPayment = new Date(amortizationSchedule[0].paymentDate);
  const lastPayment = new Date(
    amortizationSchedule[amortizationSchedule.length - 1].paymentDate,
  );
  const totalPayments = amortizationSchedule.length;
  const paymentDay = firstPayment.getDate() + 1;
  const monthlyInterestRate = parseFloat(creditDetails.rate);
  const annualInterestRate =
    parseFloat(creditDetails.anualRate) || parseFloat(creditDetails.rate) * 12;
  const operationalExpenses = creditDetails.operationCost || 0;
  const otherExpenses = creditDetails.otherExpenses || 0;
  const totalLoanAmount =
    creditDetails.amount + operationalExpenses + otherExpenses;
  const totalCost = amortizationSchedule.reduce(
    (acc: number, curr: any) => acc + curr.amount,
    0,
  );

  // Calculamos la TIR mensual
  console.log('amortizationSchedule[0].amount', amortizationSchedule[0].amount);
  const cae = calculateIRR(
    amortizationSchedule[0].amount,
    totalPayments,
    creditDetails.amount,
  );

  // Convertimos a tasa anual efectiva (CAE)

  const div = (label: string, value: string) => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant="body1">{label}</Typography>
      <Typography variant="body1">{value}</Typography>
    </div>
  );
  return (
    <div>
      {div('Primer pago', formatDate(firstPayment, 'dd-mm-yyyy'))}
      {div('Último pago', formatDate(lastPayment, 'dd-mm-yyyy'))}
      {div('Total de pagos', totalPayments)}
      {div('Día de pago', String(paymentDay))}
      {div('Tasa de interés mensual', `${monthlyInterestRate.toFixed(2)}%`)}
      {div('Tasa de interés anual', `${annualInterestRate.toFixed(2)}%`)}
      {div('CAE', `${cae.toFixed(2)}%`)}
      <Divider />
      {div('Gastos operacionales', formatMoney(operationalExpenses))}
      {div('Seguros', formatMoney(otherExpenses))}
      {div('Monto total del crédito', formatMoney(totalLoanAmount))}
      <Divider />
      {div('Costo del crédito', formatMoney(totalCost))}
    </div>
  );
};

export default Summary;
