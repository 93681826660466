import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../lib/designSystem/HeaderWithActions';
import { useDispatch, useSelector } from 'react-redux';
import CustomAlert from '../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import {
  formatDate,
  validatePermissions,
} from '../../../../../lib/functions/utils';
import { getAllFactoringReSelling } from '../../../../../lib/api/reSell/factoring';
import ResellDetails from './components/ResellDetails';
import { AuthState } from '../../../../../services/redux/authSlice';
import MultiResellMain from './components/NewMultiResell';
import MultiPaymentFundResell from './components/MultiPayment';
import RegisterSelector from './components/RegisterSelector';
import TableWithPagination from '../../../../../lib/designSystem/TableWithPagination';
import DocumentSearcher from '../../../../../lib/common/DocumentSearcher';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'id', label: 'Id' },
  { key: 'folio', label: 'Folio' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'stakeholderName', label: 'Deudor' },
  { key: 'fundName', label: 'Fondo' },
  { key: 'fundId', label: 'Rut fondo' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'dueDate', label: 'Fecha vencimiento', type: 'date' },
  { key: 'status', label: 'Estado', type: 'status' },
];

const FactoringResells: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [failure, setFailure] = useState('');
  const [resells, setResells] = useState<any>([]);
  const [openNewResell, setOpenNewResell] = useState(false);
  const [openMultiSales, setOpenMultiSales] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedResellIds, setSelectedResellIds] = useState<string[]>([]);
  const [openMultiPayment, setOpenMultiPayment] = useState(false);
  const [payments, setPayments] = useState<any[]>([]);
  const [openRegisterSelector, setOpenRegisterSelector] = useState(false);
  const [businessIdentifier, setBusinessIdentifier] = useState('');
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const [folio, setFolio] = useState('');
  const [fundId, setFundId] = useState('');
  const [status, setStatus] = useState('ACTIVE');
  const statusOptions = [
    { label: 'Completada', value: 'COMPLETED' },
    { label: 'Activa', value: 'ACTIVE' },
  ];
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (status) query += `status=${status}&`;
    if (businessIdentifier)
      query += `businessIdentifier=${businessIdentifier}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (folio) query += `folio=${folio}&`;
    if (fundId) query += `fundId=${fundId}&`;
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };
  const newResell = {
    rate: '',
    operationCostFixed: 0,
    operationCostVariable: 0,
    rateCost: 0,
    operationCostRateVariable: '',
    startDate: formatDate(new Date()),
    endDate: formatDate(new Date()),
    documentId: null,
    observation: '',
  };
  const [resell, setResell] = useState(newResell);
  const fetchResellings = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    try {
      const query = generateQuery({ page, pageSize });
      const response = await getAllFactoringReSelling(dispatch, query);
      setResells(response.data);
      setPagination(response.pagination);
    } catch (error) {
      alert('Error al cargar los pagos');
    }
    setLoading(false);
  };

  const handleDetails = async (id: string) => {
    const resellSearched = resells.find((resell: any) => resell.id === id);
    if (!resellSearched) return;
    setResell(resellSearched);
    setOpenNewResell(true);
    setEdit(true);
  };

  const actions: any = [
    {
      actionLabel: 'Ver',
      action: handleDetails,
    },
  ];

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => setOpenRegisterSelector(true),
      name: 'Registrar',
      color: 'primary',
      disabled:
        !validatePermissions(user, 'financing_fundSale_new') &&
        !validatePermissions(user, 'financing_fundSale_new_payment'),
    },
  ];

  const cantSelectRow = (row: any) => {
    return row.status === 'Completada';
  };

  useEffect(() => {
    fetchResellings({ page: 0, pageSize: PAGE_SIZE });
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás todas las ventas a fondos."
        variant="body1"
        buttons={[
          {
            name: 'Filtro',
            action: () => {},
            color: 'primary',
            customButton: (
              <DocumentSearcher
                searchData={fetchResellings}
                setData={setResells}
                setLoading={setLoading}
                businessIdentifier={businessIdentifier}
                setBusinessIdentifier={setBusinessIdentifier}
                folio={folio}
                setFolio={setFolio}
                fundId={fundId}
                setFundId={setFundId}
                pagination={pagination}
                stakeholderIdentifier={stakeholderIdentifier}
                setStakeholderIdentifier={setStakeholderIdentifier}
                status={status}
                setStatus={setStatus}
                statusOptions={statusOptions}
              />
            ),
          },
          ...buttonsData,
        ]}
      />
      <TableWithPagination
        selected={selectedResellIds}
        setSelected={setSelectedResellIds}
        cantSelectRow={cantSelectRow}
        dataHeaders={headers}
        data={resells}
        setData={setResells}
        rowsPerPageDefault={50}
        actions={actions}
        showActions
        exportToFile="xlsx"
        searchData={fetchResellings}
        pagination={pagination}
        setPagination={setPagination}
        lastElement
      />
      {openNewResell && (
        <ResellDetails
          resell={resell}
          setResell={setResell}
          open={openNewResell}
          setOpen={setOpenNewResell}
          setSuccess={setSuccess}
          setFailure={setFailure}
          setUp={fetchResellings}
          edit={edit}
          setEdit={setEdit}
        />
      )}
      {openMultiSales && (
        <MultiResellMain
          open={openMultiSales}
          setOpen={setOpenMultiSales}
          setSuccess={setSuccess}
          setFailure={setFailure}
          setUp={fetchResellings}
          pagination={pagination}
        />
      )}
      {openMultiPayment && (
        <MultiPaymentFundResell
          open={openMultiPayment}
          setOpen={setOpenMultiPayment}
          setSuccess={setSuccess}
          setFailure={setFailure}
          setUp={fetchResellings}
          payments={payments}
          setPayments={setPayments}
          setSelected={setSelectedResellIds}
          pagination={pagination}
        />
      )}
      {openRegisterSelector && (
        <RegisterSelector
          open={openRegisterSelector}
          setOpen={setOpenRegisterSelector}
          setOpenMultiPayment={setOpenMultiPayment}
          setOpenMultiCreateResell={setOpenMultiSales}
          resells={resells}
          selectedResellIds={selectedResellIds}
          setPayments={setPayments}
        />
      )}

      <CustomAlert
        open={success.length}
        title={success}
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={failure.length}
        title={failure}
        onClose={() => {
          setFailure('');
        }}
        setOpen={setFailure}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default FactoringResells;
