import React from 'react';
import Drawer from '@mui/material/Drawer';
import { Box } from '@mui/material';
import Notifications from '../../pages/Notifications';

// Add these props to your Notifications component
interface NotificationsProps {
  open: boolean;
  // eslint-disable-next-line
  setOpen: (open: boolean) => void;
}

const NotificationsDrawer: React.FC<NotificationsProps> = ({
  open,
  setOpen,
}) => {
  // Existing logic remains the same...

  // Replace the return statement with a Drawer component
  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <Box sx={{ width: 350, backgroundColor: '#F8FAFC', height: '100%' }}>
        {' '}
        {/* Adjust width as needed */}
        <Notifications drawer setOpen={setOpen} />
      </Box>
    </Drawer>
  );
};

export default NotificationsDrawer;
