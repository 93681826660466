import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import { getBusinessTgrProceedings } from '../../../../../lib/api';
import { useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';

const TGRProceedings: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tgrData, setTgrData] = useState({
    businessProceedingFolios: [],
    businessProceedings: [],
  });
  const [allFolios, setAllFolios] = useState<any>([]);
  const [filteredFolios, setFilteredFolios] = useState<any>([]);
  const [selectProceeding, setSelectedProceeding] = useState<any>(null);

  const { id } = useParams<{ id: string }>();

  const fetchRelations = async () => {
    if (!id) return;
    const tgrData = await getBusinessTgrProceedings(dispatch, id);
    console.log(tgrData);
    setAllFolios(tgrData.businessProceedingFolios);
    setFilteredFolios(tgrData.businessProceedingFolios);
    setTgrData(tgrData || []);
    setLoading(false);
  };
  useEffect(() => {
    fetchRelations();
  }, []);
  if (loading) return <CenteredCircularProgress />;

  const handleClickRowProceeding = (id: any) => {
    const foundAgreement: any = tgrData.businessProceedings.find(
      (agreement: any) => agreement.id === id,
    );
    if (!foundAgreement) return;

    if (selectProceeding && selectProceeding.id === id) {
      setSelectedProceeding(null);
      return setFilteredFolios(allFolios);
    }
    const newCuotas = allFolios.filter(
      (cuota: any) => cuota.tgrProceedingId === id,
    );
    console.log(newCuotas);
    setFilteredFolios(newCuotas);
    setSelectedProceeding(foundAgreement);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Expedientes vigentes</Typography>
        <EnhancedTable
          finalSumRow
          exportToFile="xlsx"
          tableSize="small"
          dataHeaders={[
            { key: 'proceedingId', label: 'Expediente' },
            { key: 'year', label: 'Año' },
            { key: 'commune', label: 'Comuna' },
            { key: 'status', label: 'Etapa' },
            { key: 'amount', label: 'Monto liquidado', type: 'money' },
            { key: 'active', label: 'Activo', type: 'boolean-active-null' },
          ]}
          data={tgrData.businessProceedings}
          handleClickRow={handleClickRowProceeding}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Folios</Typography>
        <EnhancedTable
          exportToFile="xlsx"
          finalSumRow
          tableSize="small"
          dataHeaders={[
            { key: 'folio', label: 'Folio' },
            { key: 'proceedingId', label: 'Expediente' },
            { key: 'amount', label: 'Monto', type: 'money' },
            { key: 'dueDate', label: 'Fecha de vencimiento', type: 'date' },
          ]}
          data={filteredFolios}
          lastElement
        />
      </Grid>
    </Grid>
  );
};

export default TGRProceedings;
