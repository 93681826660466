import React, { useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../../../lib/designSystem/Button';
import DialogTitle from '../../../../../../../../../../../../../../../lib/designSystem/DialogTitle';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import { deleteCreditLine } from '../../../../../../../../../../../../../../../lib/api/creditRequest';

const CreditDeleteModal = (props: any) => {
  const {
    open,
    setOpen,
    requestId,
    setUp,
    setSuccessOpen,
    setFailureOpen,
    onClose,
  } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [agreeTerms, setAgreeTerms] = useState(false);

  const handleDeleteCreditLine = async () => {
    setLoading(true);
    try {
      await deleteCreditLine(dispatch, requestId);
      await setUp();
      setSuccessOpen('La solicitud de crédito ha sido eliminada exitosamente.');
      onClose();
      setOpen(false);
    } catch (error) {
      console.log(error);
      setFailureOpen(
        'Ha ocurrido un error al intentar eliminar la solicitud de crédito.',
      );
    }
    setLoading(false);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle setOpen={setOpen}>
        Eliminar solicitud de crédito #{requestId}
      </DialogTitle>
      {loading ? (
        <DialogContent style={{ minHeight: 200 }}>
          <CenteredCircularProgress type="dialog" />
        </DialogContent>
      ) : (
        <div>
          <DialogContent>
            <Typography>
              Al borrar una solicitud de crédito se borrarán todas sus
              dependencias:
            </Typography>
            <div style={{ padding: 8 }}>
              {[
                'Las aprobaciones de esta solicitud',
                'Pagos realizados debido a esta solicitud',
                'Conciliaciones hechas al activar esta solicitud',
              ].map((text, index) => (
                <Typography key={index} variant="body1">
                  - {text}
                </Typography>
              ))}
            </div>
            <div style={{ padding: '8px 0px 0px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setAgreeTerms(!agreeTerms)}
                    value={agreeTerms}
                    checked={agreeTerms}
                  />
                }
                label={
                  <Typography variant="body2">
                    Estoy consciente de que al eliminar esta solicitud de
                    crédito no podré recuperarla
                  </Typography>
                }
              />
            </div>
          </DialogContent>
          <DialogActions>
            <CustomButton
              onClick={() => setOpen(false)}
              variant="outlined"
              color="secondary"
            >
              Cancelar
            </CustomButton>
            <CustomButton
              onClick={() => handleDeleteCreditLine()}
              variant="contained"
              color="error"
              disabled={!agreeTerms}
            >
              Eliminar
            </CustomButton>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

export default CreditDeleteModal;
