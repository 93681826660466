import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../../lib/designSystem/Button';
import { showAlert } from '../../../../../../../../../lib/functions/utils';
import { useDispatch } from 'react-redux';
import { deleteUploadedBalance } from '../../../../../../../../../lib/api/finnacialStatements';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';

const DeleteFSDialog = (props: any) => {
  const { id, deleteId, confirmDeleteDialog, setConfirmDeleteDialog, setUp } =
    props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    if (!id) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    if (!deleteId) {
      alert('No se ha encontrado el id del balance');
      return;
    }
    try {
      setLoading(true);
      const response = await deleteUploadedBalance(dispatch, id, {
        financialStatementId: deleteId,
      });
      console.log(response);
      setConfirmDeleteDialog(false);
      await setUp();
      showAlert(dispatch, 'success', 'Se ha borrado tu balance con éxito');
    } catch (error) {
      console.log(error);
      showAlert(dispatch, 'error', 'Ha ocurrido un error');
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={confirmDeleteDialog}
      onClose={() => setConfirmDeleteDialog(false)}
    >
      <DialogTitle>Borrar Balance</DialogTitle>
      {loading ? (
        <CenteredCircularProgress type="dialog" />
      ) : (
        <DialogContent>
          <Typography>¿Estás seguro que deseas borrar este balance?</Typography>
        </DialogContent>
      )}
      <DialogActions>
        <CustomButton
          color="secondary"
          onClick={() => setConfirmDeleteDialog(false)}
        >
          Cancelar
        </CustomButton>
        <CustomButton color="error" onClick={() => handleDelete()}>
          Borrar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFSDialog;
