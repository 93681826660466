import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { cellFormat } from '../../../../../../../../lib/designSystem/Table/cellFormat';
import ExportDialog from '../../Recieved/components/create/components/Simulator/components/ExportDialog';
import { calculateAmortization } from '../../Recieved/components/create/components/Simulator/helpers/calculateAmotization';

const statusSteps: any = {
  sent: {
    Aceptada:
      'Para que está solicitud sea activada, se debe realizar la nómina y luego su giro correspondiente.',
    Activa: 'La solicitud está activa',
  },
  received: {
    Aceptada:
      'Para que está solicitud sea activada, se debe realizar la nómina y luego su giro correspondiente.',
    Activa: 'La solicitud está activa',
  },
};

const CreditRequestStatus = (props: any) => {
  const { openRequest, audienceType } = props;
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [amortizationSchedule, setAmortizationSchedule] = useState<any>(
    openRequest.documents,
  );

  const handleExportSimulation = () => {
    setOpenExportDialog(true);
  };

  useEffect(() => {
    calculateAmortization({
      creditDetails: openRequest,
      setAmortizationSchedule,
      workingDayActive: openRequest.workingDayActive,
    });
  }, [openRequest]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" fontWeight="bold">
          Resumen
        </Typography>
        <Typography
          onClick={() => handleExportSimulation()}
          variant="caption"
          color="primary"
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          Exportar
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '6px 0px',
        }}
      >
        <Typography variant="body1">
          <b>Cliente</b>
        </Typography>
        <Typography variant="body1">{openRequest.businessName}</Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '6px 0px',
        }}
      >
        <Typography variant="body1">
          <b>Estado</b>
        </Typography>
        <Typography variant="body1">
          {cellFormat(openRequest.status, 'status')}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 0px',
        }}
      >
        <Typography variant="body1">
          <b>Vencimiento</b>
        </Typography>
        <Typography variant="body1">
          {cellFormat(openRequest.paymentDate, 'date')}
        </Typography>
      </div>
      {statusSteps[audienceType][openRequest.status] && (
        <div
          style={{
            padding: '10px 0px',
          }}
        >
          <div>
            <Typography variant="body1">
              <b>Pasos a seguir</b>
            </Typography>

            <Typography variant="body1">
              {statusSteps[audienceType][openRequest.status]}
            </Typography>
          </div>
        </div>
      )}
      {openExportDialog && (
        <ExportDialog
          open={openExportDialog}
          setOpen={setOpenExportDialog}
          creditDetails={openRequest}
          amortizationSchedule={amortizationSchedule}
        />
      )}
    </div>
  );
};

export default CreditRequestStatus;
