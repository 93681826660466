import React, { useEffect, useState } from 'react';
import {
  getIntegrationStatus,
  getPublicationStatus,
} from '../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import { Snackbar, Typography } from '@mui/material';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import NewEnrollment from './components/NewEnrollment';
import PaymentNotifications from './components/PaymentNotifications';
import {
  validatePermissions,
  showAlert,
} from '../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../services/redux/authSlice';
import PublicationForm from '../../../../../../pages/Debt/components/InvoicePublication/components/PublicationForm';
import moment from 'moment';
import DocumentManagements from './components/DocumentManagements';
import { getDocumentsForCollection } from '../../../../../../lib/api/collections';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import DocumentSearcher from '../../../../../../lib/common/DocumentSearcher';
import TableWithPagination from '../../../../../../lib/designSystem/TableWithPagination';
import Unenrollment from './components/Unenrollment';
import DocumentsIndicators from './components/DocumentsIndicators';

const transformDteType = (document: string) => {
  switch (document) {
    case 'electronic-invoice':
      return '33';
    case 'electronic-invoice-exempt':
      return '34';
    default:
      return '';
  }
};

const transformDocType = (document: string) => {
  switch (document) {
    case 'electronic-invoice':
      return 'Factura';
    default:
      return '';
  }
};

/* This set the id for the Cobranza Online integration */
const COBRANZA_ONLINE_ID = 1;

const CollectionDocuments = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [reload, setReload] = useState(0);
  const [customAlert, setCustomAlert] = useState({
    open: false,
    severity: 'error',
    message: '',
  });
  const [openModalAutoCollection, setOpenModalAutoCollection] = useState({
    open: false,
    id: 0,
    stakeholderIdentifier: '',
    paymentDate: '',
  });
  const [openModalUnenrollment, setOpenModalUnenrollment] = useState({
    open: false,
    folio: '',
    collectionFlowExecutions: [],
  });
  const [openPaymentNotifications, setOpenPaymentNotifications] = useState<{
    invoiceId: any;
    open: boolean;
  }>({
    invoiceId: 0,
    open: false,
  });
  const [documentToPublish, setDocumentToPublish] = useState<any>();
  const [integrationStatus, setIntegrationStatus] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [businessIdentifier, setBusinessIdentifier] = useState('');
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const [documentType, setDocumentType] = useState('all');
  const documentTypes = [
    { label: 'Todos', value: 'all' },
    { label: 'Factura', value: 'invoice' },
    { label: 'Solicitud de factoring', value: 'factoring' },
    { label: 'Solicitud de crédito', value: 'credit' },
  ];
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });
  const [executiveId, setExecutiveId] = useState('all');
  const [executiveOptions, setExecutiveOptions] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [canHaveSubordinates, setCanHaveSubordinates] = useState(false);
  const [totalWithNoManagements, setTotalWithNoManagements] = useState(0);
  const [openIndicators, setOpenIndicators] = useState(false);

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (endDate) query += `endDate=${endDate}&`;
    if (businessIdentifier)
      query += `businessIdentifier=${businessIdentifier}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (documentType) query += `documentType=${documentType}&`;
    if (executiveId && executiveId !== 'all')
      query += `executiveId=${executiveId}&`;
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const handleManagement = async (id: string) => {
    setSelectedDocumentId(id);
    setOpen(true);
  };

  const canShowOpenDetails = (id: number) => {
    const request = originalData.find((x: any) => x.id === id);
    if (!request) return alert('Ha ocurrido un error');

    return true;
  };

  const canShowAutomaticEnroll = (id: number) => {
    if (!validatePermissions(user, 'collections_create')) {
      return false;
    }
    const element = originalData.find((x: any) => x.id === id);
    if (!element) return alert('Ha ocurrido un error');
    if (element.collectionFlowExecutions.length > 0) return false;
    if (!element.paid) return true;

    return false;
  };

  const canShowAutomaticUnEnroll = (id: number) => {
    if (!validatePermissions(user, 'collections_create')) {
      return false;
    }
    const element = originalData.find((x: any) => x.id === id);
    if (!element) return alert('Ha ocurrido un error');
    if (element.collectionFlowExecutions.length > 0) return true;
    if (element.paid) return false;
    return false;
  };

  const handleAutomaticUnEnroll = async (id: number) => {
    const document = originalData.find((x: any) => x.id === id);
    if (!document) return showAlert(dispatch, 'error', 'Ha ocurrido un error');
    const folio = document.folio;
    const collectionFlowExecutions = document.collectionFlowExecutions;
    if (collectionFlowExecutions.length == 0) {
      return showAlert(
        dispatch,
        'error',
        'Este documento no está enrolado en un flujo de cobranza',
      );
    }
    setOpenModalUnenrollment({
      open: true,
      folio: folio,
      collectionFlowExecutions: collectionFlowExecutions,
    });
  };

  const handlePublish = async (id: number) => {
    const document = originalData.find((x: any) => x.id === id);
    const published = await getPublicationStatus(
      dispatch,
      document.folio,
      document.companyIdentifier,
      document.stakeholderIdentifier,
    );
    if (published.published) {
      setCustomAlert({
        open: true,
        severity: 'error',
        message: 'Este documento ya está publicado en Cobranza Online',
      });
      return;
    }
    if (!document) return alert('Ha ocurrido un error');
    console.log(document);
    const contact = document.contacts.find(
      (contact: any) => contact.invoicing === true,
    );
    setDocumentToPublish({
      stakeholderIdentifier: document.stakeholderIdentifier,
      businessIdentifier: document.companyIdentifier,
      debtorLegalName: document.stakeholderName,
      folio: document.folio,
      amount: document.amount,
      issueDate: moment(document.issuedDate),
      dueDate: moment(document.paymentDate),
      docType: transformDocType(document.documentType),
      invoiceCode: transformDteType(document.documentType),
      contactName: contact?.name.split(' ').slice(0, -1).join(' ') || '',
      contactEmail: contact?.email || '',
      contactPhone: contact?.phone || '',
      contactLastName: contact?.name.split(' ').slice(-1).join(' ') || '',
    });
    setOpenPublishModal(true);
  };

  const getCobranzaOnlineStatus = async () => {
    try {
      const response = await getIntegrationStatus(dispatch, COBRANZA_ONLINE_ID);
      setIntegrationStatus(response.status);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenAutoCollectionModal = (id: number) => {
    const document = originalData.find((x: any) => x.id === id);
    if (!document) return alert('Ha ocurrido un error');

    setOpenModalAutoCollection({
      open: true,
      id: id,
      stakeholderIdentifier: document.stakeholderIdentifier,
      paymentDate: document.paymentDate,
    });
  };

  const handleOpenIndicators = () => {
    setOpenIndicators(true);
  };

  const actions = [
    {
      action: handleManagement,
      actionLabel: 'Gestiones',
      canShow: canShowOpenDetails,
    },
    {
      action: handleOpenAutoCollectionModal,
      actionLabel: 'Enrolar a flujo de cobranza',
      canShow: canShowAutomaticEnroll,
    },
    {
      action: handleAutomaticUnEnroll,
      actionLabel: 'Desenrolar de flujo de cobranza',
      canShow: canShowAutomaticUnEnroll,
    },
    {
      action: (id: number) =>
        setOpenPaymentNotifications({ invoiceId: id, open: true }),
      actionLabel: 'Notificaciones de pago',
      canShow: canShowOpenDetails,
      notifications: true,
    },
    {
      action: handlePublish,
      actionLabel: 'Publicar en cobranza online',
      canShow: () => integrationStatus,
    },
  ];

  const fetchInvoices = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    try {
      const query = generateQuery({ page, pageSize });
      const response = await getDocumentsForCollection(dispatch, query);
      setDataTable(response.data);
      setOriginalData(response.data);
      setTotalWithNoManagements(response.totalWithNoManagements);
      setPagination(response.pagination);
      setExecutiveOptions(response.executiveOptions);
      await getCobranzaOnlineStatus();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const setUpHeaders = () => {
    const headers: { key: string; label: string; type: any }[] = [
      { key: 'invoiceType', label: 'Tipo', type: 'invoice-type' },
      ...(canHaveSubordinates
        ? [{ key: 'collectorName', label: 'Ejecutivo', type: '' }]
        : []),
      { key: 'folio', label: 'Folio', type: '' },
      { key: 'businessName', label: 'Emisor', type: '' },
      { key: 'stakeholderName', label: 'Receptor', type: '' },
      { key: 'paymentDate', label: 'Vencimiento', type: 'date' },
      { key: 'amount', label: 'Monto', type: 'money' },
      {
        key: 'lastCollectionManagementState',
        label: 'Estado gestión',
        type: '',
      },
      {
        key: 'autoCollectionStatus',
        label: 'Flujo',
        type: 'auto-collection-status',
      },
      {
        key: 'documentRemainingAmountNet',
        label: 'Pendiente',
        type: 'invoice-remaining-amount',
      },
    ];
    setHeaders(headers);
  };

  useEffect(() => {
    const boss = validatePermissions(user, 'have_subordinates');
    const admin = validatePermissions(user, 'edit_subordinates');
    setCanHaveSubordinates(boss || admin);
  }, [user]);

  useEffect(() => {
    fetchInvoices({ page: 0, pageSize: PAGE_SIZE });
  }, [reload]);

  useEffect(() => {
    setUpHeaders();
  }, [reload, canHaveSubordinates]);

  return (
    <>
      {loading && <CenteredCircularProgress type="layout" />}
      {!loading && (
        <div>
          <Snackbar
            open={snackbar.open}
            onClose={() => setSnackbar({ message: '', open: false })}
            message={snackbar.message}
          />
          <PaymentNotifications
            invoiceId={openPaymentNotifications.invoiceId}
            open={openPaymentNotifications.open}
            setOpen={setOpenPaymentNotifications}
            fetchInvoices={fetchInvoices}
          />
          {openModalAutoCollection.open && (
            <NewEnrollment
              setModal={setOpenModalAutoCollection}
              id={openModalAutoCollection.id}
              paymentDate={openModalAutoCollection.paymentDate}
              stakeholderIdentifier={
                openModalAutoCollection.stakeholderIdentifier
              }
              open={openModalAutoCollection.open}
              invoiceData={originalData.find(
                (x: any) => x.id === openModalAutoCollection.id,
              )}
              setReload={setReload}
              reload={reload}
            />
          )}
          {openModalUnenrollment.open && (
            <Unenrollment
              setModal={setOpenModalUnenrollment}
              open={openModalUnenrollment.open}
              folio={openModalUnenrollment.folio}
              collectionFlowExecutions={
                openModalUnenrollment.collectionFlowExecutions
              }
              setReload={setReload}
              reload={reload}
            />
          )}
          <HeaderWithActions
            variant="body1"
            customTitle={
              <Typography variant="body1">
                Documentos pendientes de cobro{' '}
                <strong>
                  ({totalWithNoManagements}/{pagination.total} sin gestionar)
                </strong>
                .
              </Typography>
            }
            buttons={[
              {
                name: 'Filtro',
                action: () => {},
                color: 'primary',
                customButton: (
                  <DocumentSearcher
                    documentTypes={documentTypes}
                    searchData={fetchInvoices}
                    setData={setDataTable}
                    setLoading={setLoading}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    businessIdentifier={businessIdentifier}
                    setBusinessIdentifier={setBusinessIdentifier}
                    stakeholderIdentifier={stakeholderIdentifier}
                    setStakeholderIdentifier={setStakeholderIdentifier}
                    documentType={documentType}
                    setDocumentType={setDocumentType}
                    executiveId={canHaveSubordinates ? executiveId : ''}
                    setExecutiveId={canHaveSubordinates ? setExecutiveId : ''}
                    executiveOptions={
                      canHaveSubordinates ? executiveOptions : undefined
                    }
                    pagination={pagination}
                  />
                ),
              },
              {
                name: 'Ver indicadores',
                action: () => handleOpenIndicators(),
                color: 'primary',
                hidden: !canHaveSubordinates,
              },
            ]}
          />
          <TableWithPagination
            dataHeaders={headers}
            data={dataTable}
            showActions
            rowsPerPageDefault={50}
            actions={actions}
            exportToFile="xlsx"
            setData={setDataTable}
            searchData={fetchInvoices}
            pagination={pagination}
            setPagination={setPagination}
            lastElement
          />
          {open && (
            <DocumentManagements
              documentId={selectedDocumentId}
              open={open}
              setOpen={setOpen}
            />
          )}
          {openIndicators && (
            <DocumentsIndicators
              openIndicators={openIndicators}
              setOpenIndicators={setOpenIndicators}
            />
          )}
          <CustomAlert
            open={openAlert}
            title="Gestión guardada con éxito"
            onClose={() => {
              setOpenAlert(false);
            }}
            setOpen={setOpenAlert}
            text=""
            severity="success"
            size="small"
            variant="outlined"
          />
          <CustomAlert
            open={customAlert.open}
            setOpen={setCustomAlert}
            severity={customAlert.severity}
            title={customAlert.message}
          />
          <PublicationForm
            open={openPublishModal}
            setOpen={setOpenPublishModal}
            setReload={setReload}
            reload={reload}
            setCustomAlert={setCustomAlert}
            data={documentToPublish}
          />
        </div>
      )}
    </>
  );
};

export default CollectionDocuments;
