import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import { unenrollCollectionFlow } from '../../../../../../../../lib/api/collectionFlow';
import { showAlert } from '../../../../../../../../lib/functions/utils';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
interface UnenrollmentProps {
  setModal: (modal: any) => void;
  open: boolean;
  folio: string;
  collectionFlowExecutions: any[];
  setReload: (reload: number) => void;
  reload: number;
}

const Unenrollment = (props: UnenrollmentProps) => {
  const { open, setModal, folio, collectionFlowExecutions, setReload, reload } =
    props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const execution = collectionFlowExecutions[0];

  const handleAutomaticUnEnroll = async () => {
    try {
      setLoading(true);
      const id = execution.id;
      await unenrollCollectionFlow(dispatch, id);
      showAlert(dispatch, 'success', 'Documento desenrolado con éxito');
      setReload(reload + 1);
      handleClose();
    } catch (error) {
      showAlert(dispatch, 'error', 'Ha ocurrido un error');
      setReload(reload + 1);
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setModal({ open: false, folio: '', collectionFlowExecutions: [] });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Desenrolar documento folio <strong>#{folio}</strong> del flujo de
          cobranza <strong>{execution.collectionFlow.name}</strong>
        </DialogTitle>
        {loading && (
          <div style={{ minHeight: 200 }}>
            <CenteredCircularProgress type="dialog" />
          </div>
        )}
        {!loading && (
          <>
            <DialogContent>
              <Typography>
                ¿Estás seguro de querer desenrolar este documento?
              </Typography>
              <Typography>
                - Se eliminarán todas las gestiones manuales no completadas.
              </Typography>
              <Typography>
                - Se eliminarán todas las gestiones automáticas no completadas.
              </Typography>
            </DialogContent>
            <DialogActions>
              <CustomButton color="secondary" onClick={handleClose}>
                Cancelar
              </CustomButton>
              <CustomButton color="error" onClick={handleAutomaticUnEnroll}>
                Desenrolar
              </CustomButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default Unenrollment;
