import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import TableWithPagination from '../../../../../../lib/designSystem/TableWithPagination';
import {
  getPayableAccounts,
  getReceivableAccounts,
} from '../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import NewAccount from './components/NewAccount';
import { Typography } from '@mui/material';
import DeleteDialog from './components/DeleteDialog';
import GlobalSearcher, {
  Filter,
} from '../../../../../../lib/common/GlobalSearcher';
import {
  validaRut,
  showAlert,
  validatePermissions,
  rutChanger,
} from '../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../services/redux/authSlice';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'folio', label: 'Folio' },
  { key: 'businessName', label: 'Acreedor' },
  { key: 'stakeholderName', label: 'Deudor' },
  { key: 'category', label: 'Categoría' },
  { key: 'extraData', label: 'Observación' },
  { key: 'issuedDate', label: 'Fecha', type: 'date' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'paidAt', label: 'Pagado', type: 'date' },
];

const Accounts = (props: any) => {
  const { businessIdentifier, profile } = props;
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [payableAccounts, setPayableAccounts] = useState<any[]>([]);
  const [receivableAccounts, setReceivableAccounts] = useState<any[]>([]);
  const [payablePagination, setPayablePagination] = useState<any>({
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
  });
  const [receivablePagination, setReceivablePagination] = useState<any>({
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
  });
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState('');
  const [accountType, setAccountType] = useState('Pagar');
  const [searchRut, setSearchRut] = useState(businessIdentifier || '');
  const [searchPaid, setSearchPaid] = useState('all');

  const [selectedAccount, setSelectedAccount] = useState<any>(null);

  const newAccount = {
    id: '',
    category: '',
    observation: '',
    type: '',
    createdAt: null,
    amount: 0,
  };
  const [account, setAccount] = useState(newAccount);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const createQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = `page=${page}&pageSize=${pageSize}`;
    if (searchRut) query += `&identifier=${searchRut}`;
    if (searchPaid !== 'all') query += `&paid=${searchPaid}`;
    return query;
  };

  const checkRut = () => {
    if (searchRut != '' && !validaRut(searchRut)) {
      showAlert(dispatch, 'error', 'Rut inválido');
      return false;
    }
    return true;
  };

  const fetchPendingAccounts = async () => {
    setLoading(true);
    try {
      if (!checkRut()) return setLoading(false);
      const promises = [
        getPayableAccounts(dispatch, createQuery({ page: 1, pageSize: 10 })),
        getReceivableAccounts(dispatch, createQuery({ page: 1, pageSize: 10 })),
      ];
      const [payableAccounts, receivableAccounts] = await Promise.all(promises);
      setPayableAccounts(payableAccounts.data);
      setReceivableAccounts(receivableAccounts.data);
      setPayablePagination(payableAccounts.pagination);
      setReceivablePagination(receivableAccounts.pagination);
    } catch (error) {
      alert('Error al cargar las cuentas pendientes');
    }
    setLoading(false);
  };

  const searchData = async ({
    page,
    pageSize,
  }: {
    page?: number;
    pageSize?: number;
  }) => {
    setLoading(true);
    try {
      if (!checkRut()) return setLoading(false);
      const data =
        accountType === 'Pagar'
          ? await getPayableAccounts(
              dispatch,
              createQuery({ page: page || 1, pageSize: pageSize || 10 }),
            )
          : await getReceivableAccounts(
              dispatch,
              createQuery({ page: page || 1, pageSize: pageSize || 10 }),
            );
      (accountType === 'Pagar' ? setPayableAccounts : setReceivableAccounts)(
        data.data,
      );
      (accountType === 'Pagar'
        ? setPayablePagination
        : setReceivablePagination)(data.pagination);
      setLoading(false);
      return data;
    } catch (error) {
      alert('Error al cargar las cuentas pendientes');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPendingAccounts();
  }, []);

  useEffect(() => {
    const canDelete = validatePermissions(user, 'delete_pending_accounts');
    setCanDelete(canDelete);
  }, [user]);

  const handleOpen = () => {
    setOpen(true);
  };

  const filters: Filter[] = [
    {
      type: 'select',
      label: 'Estado',
      value: searchPaid,
      setter: setSearchPaid,
      options: [
        { label: 'Todos', value: 'all' },
        { label: 'Pagado', value: 'true' },
        { label: 'No Pagado', value: 'false' },
      ],
    },
  ];

  const customSetter = (value: string) => {
    rutChanger(value, setSearchRut);
  };

  if (!profile) {
    filters.push({
      type: 'text',
      label: accountType === 'Pagar' ? 'Rut Acreedor' : 'Rut Deudor',
      value: searchRut,
      setter: customSetter,
    });
  }

  const buttons: CustomButtonProps[] = [
    {
      name: 'Buscar',
      color: 'primary',
      action: () => {},
      customButton: (
        <GlobalSearcher
          filters={filters}
          triggerSearch={() => searchData({ page: 1, pageSize: 10 })}
        />
      ),
    },
  ];

  if (!profile) {
    buttons.push({
      name: 'Nueva Cuenta',
      action: handleOpen,
      color: 'primary',
    });
  }

  const handleOpenDeleteDialog = (row: any) => {
    const accounts =
      accountType === 'Pagar' ? payableAccounts : receivableAccounts;
    const account = accounts.find((x: any) => x.id === row);
    setSelectedAccount(account);
    setOpenDeleteDialog(true);
  };

  const actions = [
    {
      name: 'Eliminar',
      action: handleOpenDeleteDialog,
      actionLabel: 'Eliminar',
      disabled: !canDelete,
    },
  ];

  const handleChangeType = (newValue: any) => {
    setAccountType(newValue);
    setSearchRut('');
    setSearchPaid('all');
  };

  if (loading) return <CenteredCircularProgress relative />;

  return (
    <div>
      <HeaderWithActions
        buttons={buttons}
        variant="body1"
        customTitle={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              onClick={() => handleChangeType('Pagar')}
              style={{
                ...(accountType === 'Pagar' ? { fontWeight: 'bold' } : {}),
                cursor: 'pointer',
              }}
            >
              Por pagar
            </Typography>
            <Typography style={{ padding: '0px 4px' }}> | </Typography>
            <Typography
              onClick={() => handleChangeType('Cobrar')}
              style={{
                ...(accountType === 'Cobrar' ? { fontWeight: 'bold' } : {}),
                cursor: 'pointer',
              }}
            >
              Por cobrar
            </Typography>
          </div>
        }
      />
      <TableWithPagination
        exportToFile="xlsx"
        data={accountType === 'Pagar' ? payableAccounts : receivableAccounts}
        setData={
          accountType === 'Pagar' ? setPayableAccounts : setReceivableAccounts
        }
        searchData={searchData}
        pagination={
          accountType === 'Pagar' ? payablePagination : receivablePagination
        }
        setPagination={
          accountType === 'Pagar'
            ? setPayablePagination
            : setReceivablePagination
        }
        dataHeaders={
          accountType === 'Pagar'
            ? headers.filter((x: any) => x.key !== 'stakeholderName')
            : headers.filter((x: any) => x.key !== 'businessName')
        }
        actions={actions}
        loading={loading}
        finalSumRow
        lastElement
      />
      {open && (
        <NewAccount
          open={open}
          setOpen={setOpen}
          account={account}
          setAccount={setAccount}
          setUp={fetchPendingAccounts}
          setSuccessOpen={setSuccess}
        />
      )}
      {openDeleteDialog && canDelete && (
        <DeleteDialog
          open={openDeleteDialog}
          onClose={() => {
            setSelectedAccount(null);
            setOpenDeleteDialog(false);
          }}
          searchData={searchData}
          accountType={accountType}
          account={selectedAccount}
        />
      )}
      <CustomAlert
        open={success.length}
        title="Éxito"
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        text={success}
        severity="success"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default Accounts;
