import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Divider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { fetchInvoicingContacts } from '../../../../../../../../lib/api/contacts';
import {
  getCollectionFlows,
  executeCollectionFlow,
} from '../../../../../../../../lib/api/collectionFlow';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import ContactFormDialog from '../../../../../../../../lib/common/ContactFormDialog';
import CustomTimeline from '../../../../../../../../lib/designSystem/CustomTimeline';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import {
  showAlert,
  loggerDev,
} from '../../../../../../../../lib/functions/utils';
import TwoColumnDocumentResume from '../../../../../../../../lib/designSystem/TwoColumnDocumentResume';
import { createUsersBusinessesPortfolio } from '../../../../../../../../lib/api';
import { getExecutivesForDocumentCollection } from '../../../../../../../../lib/api/collections';
import NoExecutive from './components/NoExecutive.tsx';
import FlowDetails from './components/FlowDetails';
import {
  getNextWorkingDay,
  isWorkingDay,
} from '../../../../../../../../lib/functions/dates';

interface Props {
  id: number;
  stakeholderIdentifier: string;
  paymentDate: string;
  open: boolean;
  invoiceData: any;
  setModal: any;
  setReload: any;
  reload: number;
}

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const NewEnrollment: React.FC<Props> = (props) => {
  const {
    id,
    stakeholderIdentifier,
    paymentDate,
    invoiceData,
    setReload,
    reload,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // For date
  const [selectedDate, setSelectedDate] = useState(dayjs.utc(paymentDate));

  // For contacts
  const [openContactForm, setOpenContactForm] = useState(false);
  const [invoicingContacts, setInvoicingContacts] = useState<any>([]);

  // For flows
  const [collectionFlows, setCollectionFlows] = useState<any>([]);
  const [selectedFlow, setSelectedFlow] = useState<any>(null);

  // For timeline
  const [timelineItemsCreation, setTimelineItemsCreation] = useState<any[]>([]);
  const [timelineItemsDue, setTimelineItemsDue] = useState<any[]>([]);

  // For executives
  const [selectedExecutive, setSelectedExecutive] = useState<any>(null);
  const [executivesOptions, setExecutivesOptions] = useState<any[]>([]);
  const [documentHasExecutives, setDocumentHasExecutives] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [byStakeholder, setByStakeholder] = useState(false);
  const [portfolioExecutiveId, setPortfolioExecutiveId] = useState(0);

  // For Steps
  const [createExpiredSteps, setCreateExpiredSteps] = useState(false);

  const handleFlowEnrollment = async () => {
    try {
      setLoading(true);
      await executeCollectionFlow(dispatch, {
        collectionFlowId: selectedFlow,
        dueDate: selectedDate.format(),
        documentId: id,
        collectorId: selectedExecutive,
        createExpiredSteps,
      });
      showAlert(dispatch, 'success', 'Documento enrolado con éxito');
      props.setModal({ open: false, id: 0 });
      setReload(reload + 1);
    } catch (error) {
      loggerDev(error);
      showAlert(dispatch, 'error', 'Ha ocurrido un error');
    }
    setLoading(false);
  };

  const setUp = async () => {
    setLoading(true);
    try {
      const contactsResponse = await fetchInvoicingContacts(
        dispatch,
        stakeholderIdentifier,
      );
      const flowsResponse = await getCollectionFlows(dispatch);
      setInvoicingContacts(contactsResponse);
      setCollectionFlows(flowsResponse);
      if (flowsResponse.length == 0) {
        showAlert(dispatch, 'error', 'No hay flujos de cobranza disponibles');
        setLoading(false);
        props.setModal({ open: false, id: 0 });
      }
    } catch (error) {
      loggerDev(error);
      showAlert(dispatch, 'error', 'Ha ocurrido un error');
    }
    setLoading(false);
  };

  const handleSetSelectedDate = (date: any) => {
    setSelectedDate(dayjs.utc(date));
    buildTimeline(selectedFlow, dayjs.utc(date));
  };

  const handleSelectFlow = (flowId: number) => {
    setSelectedFlow(flowId);
    buildTimeline(flowId, selectedDate);
  };

  const buildTimeline = (flowId: number, date: any) => {
    const flow = collectionFlows.find((flow: any) => flow.id === flowId);
    const today = dayjs.utc();
    const dueDate = date;
    const itemsCreation: any[] = [
      {
        left: {
          textPrimary: today.format('DD/MM/YYYY'),
        },
        right: {
          textPrimary: 'Hoy',
        },
        icon: <TodayIcon />,
        days: -Infinity,
        iconVariant: 'filled',
      },
    ];
    const itemsDue: any[] = [
      {
        left: {
          textPrimary: dueDate.format('DD/MM/YYYY'),
        },
        right: {
          textPrimary: 'Fecha de vencimiento',
        },
        icon: <EventIcon />,
        days: 0,
        iconVariant: 'filled',
      },
    ];
    for (const step of flow.steps) {
      const executionDayRaw = (step.regard === 'DUEDATE' ? dueDate : today).add(
        step.days,
        'day',
      );
      const executionDateRaw = new Date(executionDayRaw);
      const executionDay = dayjs(
        isWorkingDay(executionDateRaw) || step.manualStep
          ? executionDayRaw
          : getNextWorkingDay(executionDateRaw),
      );
      const unixTime = executionDay.unix();
      const unixDiff =
        unixTime - (step.regard === 'DUEDATE' ? dueDate.unix() : today.unix());
      const item = {
        left: {
          textPrimary: executionDay.format('DD/MM/YYYY'),
        },
        right: {
          textPrimary: step.name,
        },
        icon: step.manualStep ? <PersonIcon /> : <SmartToyOutlinedIcon />,
        iconVariant: 'outlined',
        days: unixDiff,
      };
      if (step.regard === 'CREATIONDATE') {
        itemsCreation.push(item);
      } else {
        itemsDue.push(item);
      }
    }
    setTimelineItemsCreation(itemsCreation.sort((a, b) => a.days - b.days));
    setTimelineItemsDue(itemsDue.sort((a, b) => a.days - b.days));
  };

  const setUpOptions = async () => {
    setLoading(true);
    try {
      const query = `businessIdentifier=${invoiceData.businessIdentifier}&stakeholderIdentifier=${invoiceData.stakeholderIdentifier}`;
      const resultExecutives = await getExecutivesForDocumentCollection(
        dispatch,
        query,
      );
      setDocumentHasExecutives(resultExecutives.documentHasExecutives);
      setExecutivesOptions(resultExecutives.executives);
      setIsManager(resultExecutives.isManager);
      setByStakeholder(resultExecutives.byStakeholder);
      setPortfolioExecutiveId(0);
    } catch (error) {
      loggerDev(error);
    }
    setLoading(false);
  };

  const handleAssignExecutive = async () => {
    setLoading(true);
    try {
      await createUsersBusinessesPortfolio(dispatch, portfolioExecutiveId, {
        businessIdentifiers: byStakeholder
          ? [invoiceData.stakeholderIdentifier]
          : [invoiceData.businessIdentifier],
      });
      showAlert(dispatch, 'success', 'Ejecutivo asignado correctamente');
      setUpOptions();
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al asignar ejecutivo');
      loggerDev(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
    setUpOptions();
  }, []);

  const showCreationTimeline = timelineItemsCreation.length > 1;
  const showDueTimeline = timelineItemsDue.length > 1;
  const creationXs = showCreationTimeline && showDueTimeline ? 6 : 12;
  const dueXs = showCreationTimeline && showDueTimeline ? 6 : 12;

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setModal({ open: false })}
        maxWidth="xl"
      >
        {loading && (
          <div>
            <DialogTitle>
              Enrolar documento <strong>#{invoiceData?.folio}</strong> a flujo
              de cobranza
            </DialogTitle>
            <div style={{ minHeight: 200 }}>
              <CenteredCircularProgress type="dialog" />
            </div>
          </div>
        )}
        {!loading && (
          <div>
            <DialogContent style={{ minWidth: '60vw' }}>
              <Grid container>
                <Grid item xs={5} md={5}>
                  <div
                    style={{
                      padding: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    <Typography variant="h6" marginBottom={2}>
                      Enrolar documento <strong>#{invoiceData?.folio}</strong> a
                      flujo de cobranza
                    </Typography>
                    <TwoColumnDocumentResume
                      document={invoiceData}
                      maxBusinessLength={selectedFlow ? 40 : 30}
                    />
                    <Divider />
                    <Typography variant="body1">
                      Selecciona un flujo de cobranza:
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="flow-label">Flujo de cobranza</InputLabel>
                      <Select
                        labelId="flow-label"
                        id="flow-select"
                        value={selectedFlow}
                        onChange={(e) => handleSelectFlow(e.target.value)}
                        label="Flujo de cobranza"
                      >
                        {collectionFlows.map((flow: any) => (
                          <MenuItem key={flow.id} value={flow.id}>
                            {flow.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {selectedFlow && (
                      <>
                        {documentHasExecutives ? (
                          <>
                            {invoicingContacts.length ? (
                              <FlowDetails
                                invoicingContacts={invoicingContacts}
                                selectedExecutive={selectedExecutive}
                                setSelectedExecutive={setSelectedExecutive}
                                selectedDate={selectedDate}
                                handleSetSelectedDate={handleSetSelectedDate}
                                executivesOptions={executivesOptions}
                                createExpiredSteps={createExpiredSteps}
                                setCreateExpiredSteps={setCreateExpiredSteps}
                              />
                            ) : (
                              <Typography>
                                <strong>
                                  No hay contactos de facturación asociados al
                                  deudor.
                                </strong>{' '}
                                <span
                                  style={{ cursor: 'pointer', color: 'blue' }}
                                  onClick={() => setOpenContactForm(true)}
                                >
                                  Asigna uno
                                </span>
                                .
                              </Typography>
                            )}
                          </>
                        ) : (
                          <NoExecutive
                            byStakeholder={byStakeholder}
                            document={invoiceData}
                            portfolioExecutiveId={portfolioExecutiveId}
                            setPortfolioExecutiveId={setPortfolioExecutiveId}
                            loading={loading}
                            executivesOptions={executivesOptions}
                            handleAssignExecutive={handleAssignExecutive}
                            documentHasExecutives={documentHasExecutives}
                            isManager={isManager}
                          />
                        )}
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={7} md={7}>
                  <Typography variant="h6" sx={{ marginLeft: '10px' }}>
                    Previsualización
                  </Typography>
                  <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                    <Grid container>
                      <Grid item xs={creationXs}>
                        {showCreationTimeline && (
                          <CustomTimeline items={timelineItemsCreation} />
                        )}
                      </Grid>
                      <Grid item xs={dueXs}>
                        {showDueTimeline && (
                          <CustomTimeline items={timelineItemsDue} />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <CustomButton
                color="secondary"
                onClick={() => {
                  props.setModal({ open: false, id: 0 });
                }}
              >
                Cancelar
              </CustomButton>
              <CustomButton
                color="primary"
                disabled={
                  invoicingContacts.length === 0 ||
                  selectedDate === undefined ||
                  !selectedFlow ||
                  !selectedExecutive
                }
                onClick={() => {
                  handleFlowEnrollment();
                }}
              >
                Enrolar
              </CustomButton>
            </DialogActions>
          </div>
        )}
      </Dialog>
      <ContactFormDialog
        open={openContactForm}
        onClose={() => setOpenContactForm(false)}
        setOpen={setOpenContactForm}
        businessId={stakeholderIdentifier}
        setUp={setUp}
        invoicingOption
      />
    </div>
  );
};

export default NewEnrollment;
