import { Typography } from '@mui/material';
import { TextField } from '@mui/material';

import { Grid } from '@mui/material';
import React from 'react';

interface FlowGridProps {
  flowName: string;
  setFlowName: (name: string) => void;
  flowDescription: string;
  setFlowDescription: (description: string) => void;
  missingData: boolean;
}

const FlowGrid: React.FC<FlowGridProps> = ({
  flowName,
  setFlowName,
  flowDescription,
  setFlowDescription,
  missingData,
}) => {
  return (
    <div>
      <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>
        Datos del flujo
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Nombre"
            value={flowName}
            onChange={(e) => setFlowName(e.target.value)}
            fullWidth
            error={missingData && !flowName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Descripción"
            value={flowDescription}
            onChange={(e) => setFlowDescription(e.target.value)}
            fullWidth
            error={missingData && !flowDescription}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FlowGrid;
