import { CHILEAN_HOLIDAYS } from './const/holidays';

export const calculatePaymentDate = (paymentDays: number, issuedDate: Date) => {
  const dueDate = new Date(issuedDate);

  // Create a new Date object from the issuedDate in UTC

  // Add the payment days
  dueDate.setUTCDate(dueDate.getUTCDate() + paymentDays);
  console.log(dueDate);
  const paymentDate = getLiberationDate(dueDate);
  console.log(paymentDate);

  // Check if the payment date falls on a weekend and adjust
  return { paymentDate, dueDate };
};

export const calculateDays = (start: Date, end: Date) => {
  const days = Math.floor(
    (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24),
  );
  return days;
};

export const isHoliday = (date: Date) => {
  return CHILEAN_HOLIDAYS.some(
    (holiday: any) =>
      date.getUTCDate() === holiday.getUTCDate() &&
      date.getUTCMonth() === holiday.getUTCMonth() &&
      date.getUTCFullYear() === holiday.getUTCFullYear(),
  );
};

export const isWorkingDay = (date: Date) => {
  return date.getUTCDay() !== 0 && date.getUTCDay() !== 6 && !isHoliday(date);
};

export const getNextWorkingDay = (date: Date) => {
  do {
    date.setUTCDate(date.getUTCDate() + 1);
  } while (!isWorkingDay(date));
  return date;
};

export const getLiberationDate = (paymentDate: Date) => {
  let date = new Date(paymentDate);

  date.setUTCHours(12, 0, 0, 0);

  if (date.getUTCDay() === 0 || date.getUTCDay() === 6 || isHoliday(date)) {
    date = getNextWorkingDay(date);
    date = getNextWorkingDay(date); // Skip deposit day
  } else {
    date = getNextWorkingDay(date);
  }

  return date;
};

export const getDueWorkingDay = (paymentDate: Date) => {
  let date = new Date(paymentDate);

  if (date.getUTCDay() === 0 || date.getUTCDay() === 6 || isHoliday(date)) {
    date = getNextWorkingDay(date);
  }

  date.setUTCHours(12, 0, 0, 0);

  return date;
};

export const isDateAWeekDay = (paymentDate: Date) => {
  const date = new Date(paymentDate);
  const dayOfWeek = date.getUTCDay();
  return dayOfWeek !== 0 && dayOfWeek !== 6;
};

export const getRealDaysToCount = (paymentDate: Date, startingDate?: Date) => {
  const today = startingDate ? startingDate : new Date();
  const date = new Date(paymentDate);
  const timeDiff = date.getTime() - today.getTime();
  const daysDiff = timeDiff / (1000 * 3600 * 24);
  return Math.ceil(daysDiff);
};
