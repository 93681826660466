import { requestHandler } from '../../api';

export const editCreditLineComitee = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/creditLine/${id}/comitee`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteCreditLineApproval = async (
  dispatch: any,
  id: number,
  approvalId: any,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/creditLine/${id}/approval/${approvalId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCreditLineApprovals = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditLine/${id}/approval`,
    dispatch,
  });
  if (response) return response.data;
};

export const exportFactoringCreditLine = async (
  dispatch: any,
  id: number,
  query: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/report/pdf/factoring/creditLine/${id}?${query}`,
    dispatch,
  });
  if (response) return response.data;
};
