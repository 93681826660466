import { requestHandler } from '../../api';

export const getCompanyLogo = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/files/companyLogo`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCompanyConfig = async (dispatch: any, name: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/configuration/${name}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getOwnEnrollmentStatus = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/ownEnrollmentStatus`,
    dispatch,
  });
  if (response) return response.data;
};
