import React from 'react';
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { formatMoney } from '../../../../../../../../lib/functions/utils';
import { LightTooltip } from '../../../../../../../../lib/designSystem/WhiteTooltip';
import { Info } from '@mui/icons-material';
import { Dayjs } from 'dayjs';
import PushPinIcon from '@mui/icons-material/PushPin';

export const ManualSummary = (props: {
  selectedDate: Dayjs;
  filteredManualNotifications: any[];
}) => {
  const { selectedDate, filteredManualNotifications } = props;

  return (
    <div>
      <Typography variant="h5">
        Gestiones para {selectedDate.format('DD/MM/YYYY')}
      </Typography>
      <Divider />
      <List>
        {filteredManualNotifications.map((management) => (
          <ListItem
            key={management.id}
            secondaryAction={
              <LightTooltip
                placement="left"
                title={
                  <div style={{ padding: '16px', minWidth: '300px' }}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography fontWeight={600}>Ejecutivo</Typography>
                        <Typography>{management.collectorName}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontWeight={600}>Fecha emisión</Typography>
                        <Typography>
                          {management.issuedDate.slice(0, 10)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontWeight={600}>Folio</Typography>
                        <Typography>{management.folio}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontWeight={600}>Monto</Typography>
                        <Typography>
                          {formatMoney(management.amount)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography fontWeight={600}>Emisor</Typography>
                        <Typography>{management.businessName}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                }
              >
                <IconButton edge="end" aria-label="ver más">
                  <Info />
                </IconButton>
              </LightTooltip>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <LightTooltip
                  title={
                    <Typography style={{ padding: '4px' }}>
                      {management.status}
                    </Typography>
                  }
                >
                  <PushPinIcon color={management.color} />
                </LightTooltip>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <LightTooltip
                  title={
                    <div style={{ padding: '4px', maxWidth: '500px' }}>
                      <Typography style={{ padding: '4px' }}>
                        {management.description}
                      </Typography>
                    </div>
                  }
                >
                  {management.description.length > 70
                    ? `${management.description.slice(0, 70)}...`
                    : management.description}
                </LightTooltip>
              }
              secondary={management.stakeholderName}
            />
          </ListItem>
        ))}
        {filteredManualNotifications.length === 0 && (
          <Typography variant="body1">
            No hay gestiones para esta fecha.
          </Typography>
        )}
      </List>
    </div>
  );
};

export default ManualSummary;
