import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  getCollectionsDocumentIndicators,
  getCollectionsManagementsIndicators,
  getCollectionsRecoveryIndicators,
} from '../../../../../../../../lib/api/collections';
import { useDispatch, useSelector } from 'react-redux';
import EnhacedBox from '../../../../../../../../lib/designSystem/Box';
import CustomPieChart from './components/PieChart';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import {
  DocumentsDialog,
  AmountsDialog,
  ManagementsDialog,
  RecoveryDialog,
  greenPalette,
} from './components/Dialog';
import ByExecutiveCharts from './components/ByExecutiveCharts';
import CustomSwitch from '../../../../../../../../lib/designSystem/CustomSwitch';
import { validatePermissions } from '../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../services/redux/authSlice';

const boxStyle = {
  height: '300px',
  paddingTop: '10px',
};

const CollectionsIndicators = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [documentsCountData, setDocumentsCountData] = useState([]);
  const [documentsAmountData, setDocumentsAmountData] = useState([]);
  const [showDocumentType, setShowDocumentType] = useState('amount');

  const [dueByBusiness, setDueByBusiness] = useState([]);
  const [dueByStakeholder, setDueByStakeholder] = useState([]);
  const [showDueBy, setShowDueBy] = useState('business');

  const [dueManagementsData, setDueManagementsData] = useState([]);
  const [nextManagementsData, setNextManagementsData] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState<any>(null);
  const [selectedNestedEntry, setSelectedNestedEntry] = useState<any>(null);

  const [recoveryData, setRecoveryData] = useState<any[]>([]);
  const [showRecoveryBy, setShowRecoveryBy] = useState('amount');

  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [openAmountsDialog, setOpenAmountsDialog] = useState(false);
  const [openManagementsDialog, setOpenManagementsDialog] = useState(false);
  const [openRecoveryDialog, setOpenRecoveryDialog] = useState(false);

  const [tableData, setTableData] = useState<any[]>([]);
  const [canHaveSubordinates, setCanHaveSubordinates] = useState(false);
  const dispatch = useDispatch();

  const setUpCollectionsIndicators = async () => {
    setLoading(true);
    try {
      const promises = [
        getCollectionsDocumentIndicators(dispatch),
        getCollectionsManagementsIndicators(dispatch),
        getCollectionsRecoveryIndicators(dispatch),
      ];
      const [documentsIndicators, managementsIndicators, recoveryIndicators] =
        await Promise.all(promises);

      setDocumentsCountData(documentsIndicators.chartData.countData);
      setDocumentsAmountData(documentsIndicators.chartData.amountData);

      setDueByBusiness(documentsIndicators.chartData2.dueByBusiness);
      setDueByStakeholder(documentsIndicators.chartData2.dueByStakeholder);

      setTableData(documentsIndicators.tableData);

      setDueManagementsData(managementsIndicators.chartData.dueManagementsData);
      setNextManagementsData(
        managementsIndicators.chartData.nextManagementsData,
      );

      setRecoveryData(recoveryIndicators);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const handleDialogClose = () => {
    setOpenDocumentsDialog(false);
    setOpenAmountsDialog(false);
    setOpenManagementsDialog(false);
    setOpenRecoveryDialog(false);
    setSelectedNestedEntry(null);
    setSelectedEntry(null);
  };

  const handleDocumentsPieChartClick = (entry: any) => {
    setSelectedEntry(entry);
    setOpenDocumentsDialog(true);
  };

  const handleAmountsPieChartClick = (entry: any) => {
    setSelectedEntry(entry);
    setOpenAmountsDialog(true);
  };

  const handleManagementsPieChartClick = (entry: any) => {
    setSelectedEntry(entry);
    setOpenManagementsDialog(true);
  };

  const handleRecoveryPieChartClick = (entry: any) => {
    setSelectedEntry(entry);
    setOpenRecoveryDialog(true);
  };

  const handleNestedPieChartClick = (entry: any) => {
    setSelectedNestedEntry(entry);
  };

  useEffect(() => {
    setUpCollectionsIndicators();
  }, []);

  useEffect(() => {
    const boss = validatePermissions(user, 'have_subordinates');
    const admin = validatePermissions(user, 'edit_subordinates');
    setCanHaveSubordinates(boss || admin);
  }, [user]);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div style={{ padding: '16px 0px' }}>
      <Grid container spacing={2} style={{ marginBottom: '16px' }}>
        <Grid item xs={3}>
          <EnhacedBox padding="0px 18px 18px 18px">
            <div style={boxStyle}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={'bold'}
              >
                {showDocumentType === 'count' ? 'Documentos' : 'Monto'}
              </Typography>
              <Typography variant="body2" textAlign={'center'}>
                por tramo vencimiento
              </Typography>
              <div style={{ padding: '6px 0px' }}>
                <CustomPieChart
                  data={
                    showDocumentType === 'count'
                      ? documentsCountData
                      : documentsAmountData
                  }
                  onClick={handleDocumentsPieChartClick}
                  money={showDocumentType === 'amount'}
                  colors={[
                    '#14BA25',
                    '#E7EB2A',
                    '#DB6723',
                    '#E14F2C',
                    '#E83832',
                    '#D62839',
                  ]}
                />
              </div>
              <CustomSwitch
                leftText="Monto"
                rightText="Documentos"
                onChange={() =>
                  setShowDocumentType(
                    showDocumentType === 'count' ? 'amount' : 'count',
                  )
                }
              />
            </div>
          </EnhacedBox>
        </Grid>
        <Grid item xs={3}>
          <EnhacedBox padding="0px 18px 18px 18px">
            <div style={boxStyle}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={'bold'}
              >
                Monto vencido
              </Typography>
              <Typography variant="body2" textAlign={'center'}>
                {showDueBy === 'business' ? 'por cliente' : 'por deudor'}
              </Typography>
              <div style={{ padding: '6px 0px' }}>
                <CustomPieChart
                  data={
                    showDueBy === 'business' ? dueByBusiness : dueByStakeholder
                  }
                  onClick={handleAmountsPieChartClick}
                  money
                  colors={['#DB6723', '#E14F2C', '#E83832', '#D62839']}
                />
              </div>
              <CustomSwitch
                leftText="Por cliente"
                rightText="Por deudor"
                onChange={() =>
                  setShowDueBy(
                    showDueBy === 'business' ? 'stakeholder' : 'business',
                  )
                }
              />
            </div>
          </EnhacedBox>
        </Grid>
        <Grid item xs={3}>
          <EnhacedBox padding="0px 18px 18px 18px">
            <div style={boxStyle}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={'bold'}
              >
                Gestiones
              </Typography>
              <Typography variant="body2" textAlign={'center'}>
                por tramo vencimiento
              </Typography>
              <div style={{ padding: '6px 0px' }}>
                <CustomPieChart
                  data={[...nextManagementsData, ...dueManagementsData]}
                  onClick={handleManagementsPieChartClick}
                  colors={['#14BA25', '#E7EB2A', '#DB6723', '#D62839']}
                  customKeyColorMap={{
                    'Venció hace menos de 7 días': '#DB6723',
                    'Venció hace más de 7 días': '#D62839',
                    'Vence en más de 7 días': '#14BA25',
                    'Vence en menos de 7 días': '#E7EB2A',
                  }}
                />
              </div>
            </div>
          </EnhacedBox>
        </Grid>
        <Grid item xs={3}>
          <EnhacedBox padding="0px 18px 18px 18px">
            <div style={boxStyle}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={'bold'}
              >
                {showRecoveryBy === 'count'
                  ? 'Documentos recuperados'
                  : 'Monto recuperado'}
              </Typography>
              <Typography variant="body2" textAlign={'center'}>
                {canHaveSubordinates
                  ? 'por ejecutivo en el último mes'
                  : 'en el último mes'}
              </Typography>
              <div style={{ padding: '6px 0px' }}>
                <CustomPieChart
                  data={recoveryData}
                  onClick={handleRecoveryPieChartClick}
                  money={showRecoveryBy === 'amount'}
                  valueKey={showRecoveryBy === 'amount' ? 'amount' : 'count'}
                  colors={greenPalette}
                />
              </div>
              <CustomSwitch
                leftText="Monto"
                rightText="Documentos"
                onChange={() =>
                  setShowRecoveryBy(
                    showRecoveryBy === 'count' ? 'amount' : 'count',
                  )
                }
              />
            </div>
          </EnhacedBox>
        </Grid>
      </Grid>
      {canHaveSubordinates && <ByExecutiveCharts tableData={tableData} />}
      <DocumentsDialog
        openDialog={openDocumentsDialog}
        handleDialogClose={handleDialogClose}
        selectedEntry={selectedEntry}
        selectedNestedEntry={selectedNestedEntry}
        handleNestedPieChartClick={handleNestedPieChartClick}
      />
      <AmountsDialog
        openDialog={openAmountsDialog}
        handleDialogClose={handleDialogClose}
        selectedEntry={selectedEntry}
      />
      <ManagementsDialog
        openDialog={openManagementsDialog}
        handleDialogClose={handleDialogClose}
        selectedEntry={selectedEntry}
      />
      <RecoveryDialog
        openDialog={openRecoveryDialog}
        handleDialogClose={handleDialogClose}
        selectedEntry={selectedEntry}
        amount={showRecoveryBy === 'amount'}
        money={showRecoveryBy === 'amount'}
      />
    </div>
  );
};

export default CollectionsIndicators;
