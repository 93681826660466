import React from 'react';
import CustomPieChart from '../PieChart';
import EnhancedTable from '../../../../../../../../../../lib/designSystem/Table';
import ColoredCircle from '../../../../../../../../../../lib/designSystem/ColoredCircle';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';

export const greenPalette = [
  '#14ba25', // Original color
  '#28d73a', // Brighter lime green
  '#0f7d21', // Dark forest green
  '#33cc66', // Soft pastel green
  '#009e4f', // Bold teal-green
  '#1e8e21', // Muted earthy green
  '#006b2e', // Deep emerald green
];

const yellowPalette = [
  '#e7eb2a', // original color
  '#f7f9a8', // Pale Lemon
  '#f0f371', // Soft Sun
  '#d1c924', // Goldenrod Yellow
  '#b89f1e', // Mustard Yellow
  '#8f7318', // Dark Amber
];

const orangePalette = [
  '#db6723', // original color
  '#e87b3a', // slightly lighter
  '#c85f1f', // darker tone
  '#f27a2e', // brighter orange
  '#d96d2a', // muted orange
  '#ff8c47', // vibrant orange
];

const redPalette = [
  '#D62839', // original color
  '#E03C48', // slightly lighter
  '#B7202C', // darker tone
  '#F03D48', // brighter red
  '#D92236', // muted red
  '#F74F5A', // vibrant red
];

const rainbowPalette = [
  '#C04040', // Muted Red
  '#D88030', // Warm Orange
  '#D4B830', // Soft Yellow
  '#14BA25', // Your Green
  '#3080D8', // Gentle Blue
  '#6040A0', // Subtle Indigo
  '#9050D8', // Soft Violet
];

const paletteMap = {
  '#D62839': redPalette,
  '#E7EB2A': yellowPalette,
  '#DB6723': orangePalette,
  '#14BA25': greenPalette,
};

const redRainbowPalette = [
  '#C04040', // original color
  '#D25050', // slightly lighter
  '#A83232', // darker tone
  '#902828', // deeper red
  '#E06060', // brighter red
  '#7A2020', // muted red
];

const orangeRainbowPalette = [
  '#D88030', // original color
  '#E29040', // slightly lighter
  '#B06620', // darker tone
  '#904C18', // deeper orange
  '#F0A050', // brighter orange
  '#7A3E14', // muted orange
];

const yellowRainbowPalette = [
  '#D4B830', // original color
  '#E0C840', // slightly lighter
  '#B09A20', // darker tone
  '#908218', // deeper yellow
  '#F0DA50', // brighter yellow
  '#7A6E14', // muted yellow
];

const greenRainbowPalette = [
  '#14ba25', // Original color
  '#28d73a', // Brighter lime green
  '#0f7d21', // Dark forest green
  '#33cc66', // Soft pastel green
  '#009e4f', // Bold teal-green
  '#1e8e21', // Muted earthy green
  '#006b2e', // Deep emerald green
];

const blueRainbowPalette = [
  '#3080D8', // original color
  '#4090E2', // slightly lighter
  '#2066B0', // darker tone
  '#185490', // deeper blue
  '#509AF0', // brighter blue
  '#14487A', // muted blue
];

const indigoRainbowPalette = [
  '#6040A0', // original color
  '#7050B0', // slightly lighter
  '#503080', // darker tone
  '#402068', // deeper indigo
  '#8060C0', // brighter indigo
  '#301858', // muted indigo
];

const violetRainbowPalette = [
  '#9050D8', // original color
  '#A060E2', // slightly lighter
  '#7838B0', // darker tone
  '#602890', // deeper violet
  '#B070F0', // brighter violet
  '#501878', // muted violet
];

const rainbowPaletteMap = {
  '#C04040': redRainbowPalette,
  '#D4B830': yellowRainbowPalette,
  '#D88030': orangeRainbowPalette,
  '#14BA25': greenRainbowPalette,
  '#3080D8': blueRainbowPalette,
  '#6040A0': indigoRainbowPalette,
  '#9050D8': violetRainbowPalette,
};

export const DocumentsDialog = ({
  openDialog,
  handleDialogClose,
  selectedEntry,
  selectedNestedEntry,
  handleNestedPieChartClick,
}: {
  openDialog: boolean;
  handleDialogClose: () => void;
  selectedEntry: any;
  selectedNestedEntry: any;
  handleNestedPieChartClick: any;
}) => {
  return (
    <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="lg">
      <DialogTitle>
        <ColoredCircle color={selectedEntry?.color} size={15} />
        {` ${selectedEntry?.name}${selectedNestedEntry ? ` - ${selectedNestedEntry?.name}` : ''}`}
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={selectedNestedEntry ? 4 : 12}
              sm={selectedNestedEntry ? 4 : 12}
            >
              <Typography align="center">
                Selecciona para más detalle
              </Typography>
            </Grid>
            <Grid
              item
              xs={selectedNestedEntry ? 4 : 0}
              sm={selectedNestedEntry ? 4 : 0}
            >
              <Typography align="center">
                {selectedNestedEntry ? `Por cliente` : ''}
              </Typography>
            </Grid>
            <Grid
              item
              xs={selectedNestedEntry ? 4 : 0}
              sm={selectedNestedEntry ? 4 : 0}
            >
              <Typography align="center">
                {selectedNestedEntry ? 'Por pagador' : ''}
              </Typography>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
            {selectedEntry?.collectorChart && (
              <CustomPieChart
                data={selectedEntry?.collectorChart}
                money={selectedEntry?.money}
                onClick={handleNestedPieChartClick}
                colors={rainbowPalette}
              />
            )}
            {selectedNestedEntry && (
              <CustomPieChart
                data={selectedNestedEntry?.byBusinessChart}
                money={selectedEntry?.money}
                colors={
                  rainbowPaletteMap[
                    selectedNestedEntry?.color as keyof typeof rainbowPaletteMap
                  ] || rainbowPalette
                }
              />
            )}
            {selectedNestedEntry && (
              <CustomPieChart
                data={selectedNestedEntry?.byStakeholderChart}
                money={selectedEntry?.money}
                colors={
                  rainbowPaletteMap[
                    selectedNestedEntry?.color as keyof typeof rainbowPaletteMap
                  ] || rainbowPalette
                }
              />
            )}
          </div>
          <EnhancedTable
            dataHeaders={[
              { key: 'invoiceType', label: 'Tipo', type: 'invoice-type' },
              { key: 'collectorName', label: 'Ejecutivo' },
              { key: 'folio', label: 'Folio' },
              { key: 'businessName', label: 'Cliente' },
              { key: 'stakeholderName', label: 'Pagador' },
              {
                key: 'paymentDate',
                label: 'Vencimiento',
                type: 'date',
              },
              { key: 'amount', label: 'Monto', type: 'money' },
            ]}
            data={
              selectedNestedEntry
                ? selectedEntry?.documents.filter(
                    (doc: any) =>
                      doc.collectorName === selectedNestedEntry?.name,
                  ) || []
                : selectedEntry?.documents || []
            }
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const AmountsDialog = ({
  openDialog,
  handleDialogClose,
  selectedEntry,
}: {
  openDialog: boolean;
  handleDialogClose: () => void;
  selectedEntry: any;
}) => {
  return (
    <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="lg">
      <DialogTitle>
        <ColoredCircle color={selectedEntry?.color} size={15} />
        {` ${selectedEntry?.name}`}
      </DialogTitle>
      <DialogContent>
        <EnhancedTable
          dataHeaders={[
            { key: 'invoiceType', label: 'Tipo', type: 'invoice-type' },
            { key: 'collectorName', label: 'Ejecutivo' },
            { key: 'folio', label: 'Folio' },
            { key: 'businessName', label: 'Cliente' },
            { key: 'stakeholderName', label: 'Pagador' },
            {
              key: 'paymentDate',
              label: 'Vencimiento',
              type: 'date',
            },
            { key: 'amount', label: 'Monto', type: 'money' },
          ]}
          data={selectedEntry?.documents || []}
        />
      </DialogContent>
    </Dialog>
  );
};

export const ManagementsDialog = ({
  openDialog,
  handleDialogClose,
  selectedEntry,
}: {
  openDialog: boolean;
  handleDialogClose: () => void;
  selectedEntry: any;
}) => {
  return (
    <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="lg">
      <DialogTitle>
        <ColoredCircle color={selectedEntry?.color} size={15} />
        {` ${selectedEntry?.name}`}
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {selectedEntry?.collectorChart && (
            <CustomPieChart
              data={selectedEntry?.collectorChart}
              money={selectedEntry?.money}
              colors={
                paletteMap[selectedEntry?.color as keyof typeof paletteMap] || [
                  '#DB6723',
                  '#E14F2C',
                  '#E83832',
                  '#D62839',
                ]
              }
            />
          )}
          <EnhancedTable
            dataHeaders={[
              { key: 'id', label: 'ID' },
              { key: 'collectorName', label: 'Ejecutivo' },
              { key: 'type', label: 'Tipo' },
              { key: 'folio', label: 'Folio' },
              { key: 'businessName', label: 'Cliente' },
              { key: 'stakeholderName', label: 'Pagador' },
              { key: 'dueDate', label: 'Vencimiento', type: 'date' },
              { key: 'createdAt', label: 'Creado', type: 'date' },
            ]}
            data={selectedEntry?.documents || []}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const RecoveryDialog = ({
  openDialog,
  handleDialogClose,
  selectedEntry,
  amount,
  money,
}: {
  openDialog: boolean;
  handleDialogClose: () => void;
  selectedEntry: any;
  amount: boolean;
  money: boolean;
}) => {
  return (
    <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="lg">
      <DialogTitle>
        <ColoredCircle color={selectedEntry?.color} size={15} />
        {` ${amount ? 'Monto recuperado' : 'Documentos recuperados'} - ${selectedEntry?.name}`}
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1} sm={6}>
              <Typography align="center">Por cliente</Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography align="center">Por pagador</Typography>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
            <CustomPieChart
              data={selectedEntry?.businessChart || []}
              money={money}
              colors={greenPalette}
              valueKey={amount ? 'amount' : 'count'}
            />
            <CustomPieChart
              data={selectedEntry?.stakeholderChart || []}
              money={money}
              colors={greenPalette}
              valueKey={amount ? 'amount' : 'count'}
            />
          </div>
          <EnhancedTable
            dataHeaders={[
              { key: 'invoiceType', label: 'Tipo', type: 'invoice-type' },
              { key: 'collectorName', label: 'Ejecutivo' },
              { key: 'folio', label: 'Folio' },
              { key: 'businessName', label: 'Cliente' },
              { key: 'stakeholderName', label: 'Pagador' },
              {
                key: 'paymentDate',
                label: 'Vencimiento',
                type: 'date',
              },
              {
                key: 'paidAt',
                label: 'Pagado',
                type: 'date',
              },
              { key: 'amount', label: 'Monto', type: 'money' },
            ]}
            data={selectedEntry?.documents || []}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
