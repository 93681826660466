import React, { useState } from 'react';
import FinanceHubForm from './components/financeHubForm';
import SimulationForm from './components/simluationForm';
import { formatMoneyBack } from '../../../../../../lib/functions/utils';
import dayjs from 'dayjs';

const Parameters = (props: any) => {
  const {
    formData,
    setFormData,
    selectedRequest,
    setDocumentsToApply,
    onlySimulation,
    subProduct,
    onEditing,
    operationDetails,
    documentsToApply,
    formErrors,
    setFormErrors,
    availableArrearTables,
  } = props;
  const [documents, setDocuments] = useState<any>([]);

  const inRange = (value: number | string) => {
    return Number(value) >= 0 && Number(value) <= 100;
  };

  const handleSelectDocument = (ids: string[]) => {
    setFormData({
      ...formData,
      selectedDocumentsToApply: ids,
    });
  };

  const handleChange = (id: string, newValue: string) => {
    const moneyIds = [
      'operationCostComissionFixed',
      'expensesCost',
      'guaranteeAmount',
    ];
    const value = moneyIds.includes(id)
      ? formatMoneyBack(newValue) || 0
      : newValue;
    if (
      isNaN(Number(value)) &&
      id !== 'guaranteeObservation' &&
      id !== 'arrearType' &&
      id !== 'arrearTableId'
    )
      return;
    if (id === 'advance' && !inRange(value)) return;
    if (id === 'operationCostComissionVariable') {
      if (!inRange(value)) return;
      const variableAmount = selectedRequest.amount * (Number(value) / 100);
      return setFormData({
        ...formData,
        operationCostComissionVariable: variableAmount,
        operationCostComissionVariablePercentage: value,
      });
    }
    if (id === 'rate' && !inRange(value)) return;
    if (id === 'arrearRate' && !inRange(value)) return;
    if (id === 'requestDate') {
      const date = dayjs(value).toDate();
      return setFormData({
        ...formData,
        requestDate: date.toISOString(),
      });
    }
    return setFormData({
      ...formData,
      [id]: value,
    });
  };

  return (
    <div>
      {onlySimulation ? (
        <SimulationForm
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          selectedRequest={selectedRequest}
        />
      ) : (
        <FinanceHubForm
          operationDetails={operationDetails}
          setFormData={setFormData}
          formData={formData}
          selectedRequest={selectedRequest}
          setDocumentsToApply={setDocumentsToApply}
          handleChange={handleChange}
          subProduct={subProduct}
          documents={documents}
          setDocuments={setDocuments}
          selected={formData.selectedDocumentsToApply}
          setSelected={handleSelectDocument}
          onEditing={onEditing}
          documentsToApply={documentsToApply}
          operationDate={selectedRequest.requestDate}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          availableArrearTables={availableArrearTables}
        />
      )}
    </div>
  );
};

export default Parameters;
