import React, { useState, useEffect } from 'react';
import EnhancedTable from '../../../../lib/designSystem/Table';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../lib/designSystem/HeaderWithActions';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRoles, getCompanyUsers } from '../../../../lib/api';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import { AuthState } from '../../../../services/redux/authSlice';
import EditUserDialog from './components/EditUser';
import EditUserBusinesses from './components/EditUserBusinesses';
import CollectionPortfolioDialog from './components/CollectionPortfolio';
import CreateUserDialog from './components/CreateUser';
import { validatePermissions } from '../../../../lib/functions/utils';
import ResendUserEmailDialog from './components/ResendUserEmail';
import EnrollmentLinkDialog from './components/EnrollmentLink';
import PortfolioTransfer from '../../../../lib/common/PortfolioTransfer';
import DeactivateUser from './components/DeactivateUser';
import { useSearchParams } from 'react-router-dom';
import DeleteUser from './components/DeleteUser';
import Subordinate from './components/Subordinate';
import CollectionPortfolioTransfer from './components/CollectionPortfolioTransfer';

export interface UserInterface {
  id?: number;
  name: string;
  email?: string;
  permissionPolicy: string;
  password?: string;
  roles?: string[];
  active?: boolean;
  permissionPolicyCode?: number;
  subortinatedUsers?: UserInterface[];
}

interface Role {
  id?: number;
  name: string;
}
export interface CompanyRelations {
  id?: number;
  businessName: string;
  businessId: number;
}

export const nullUserState = {
  id: 0,
  name: '',
  email: '',
  permissionPolicy: '',
  rolesNames: [],
  password: '',
  subordinatedUsers: [],
};

const UsersLists: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [companyRelations, setCompanyRelations] = useState<CompanyRelations[]>(
    [],
  );
  const [openEnrollmentLinkDialog, setOpenEnrollmentLinkDialog] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openResendDialog, setOpenResendDialog] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const [editType, setEditType] = useState('info');

  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [openCollectionPortfolioDialog, setOpenCollectionPortfolioDialog] =
    useState(false);
  const [
    openCollectionPortfolioTransferDialog,
    setOpenCollectionPortfolioTransferDialog,
  ] = useState(false);

  const [selectedUser, setSelectedUser] =
    useState<UserInterface>(nullUserState);
  const [roles, setRoles] = useState<Role[]>([]);
  const loggedUser = useSelector(
    (state: { auth: AuthState }) => state.auth.user,
  );
  const [openPortfolioTransfer, setOpenPortfolioTransfer] = useState(
    searchParams.has('opentransfer') ? true : false,
  );
  const [reload, setReload] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userToTransfer, setUserToTransfer] = useState<any>();
  const [openSubordinates, setOpenSubordinates] = useState(false);

  const handleOpenAddDialog = async () => {
    setLoading(true);
    const rolesData = await getAllRoles(dispatch);
    setRoles(rolesData);
    setSelectedUser(nullUserState);
    setOpenAddDialog(true);
    setLoading(false);
  };
  const handleOpenEditDialog = async (id: number) => {
    const user = users.find((x) => x.id === id);
    if (!user) return alert('Ha ocurrido un errorf');

    setLoading(true);
    const rolesData = await getAllRoles(dispatch);
    setRoles(rolesData);
    setEditType('info');
    setSelectedUser(user);
    setOpenEditDialog(true);
    setLoading(false);
  };

  const handleOpenEditPasswordDialog = (id: number) => {
    const user = users.find((x) => x.id === id);
    if (!user) return alert('Ha ocurrido un errore');

    user.password = '';

    setEditType('password');
    setSelectedUser(user);
    setOpenEditDialog(true);
  };

  const handleResendEditDialog = (id: number) => {
    const user = users.find((x) => x.id === id);
    if (!user) return alert('Ha ocurrido un errora');

    setSelectedUser(user);
    setOpenResendDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenAddDialog(false);
    setSelectedUser(nullUserState);
    setOpenEditDialog(false);
    // setOpenDeleteDialog(false);
    setOpenDeactivateDialog(false);
    setOpenCompanyDialog(false);
    setOpenCollectionPortfolioDialog(false);
    setOpenResendDialog(false);
    setOpenDeleteDialog(false);
    setOpenSubordinates(false);
    setOpenEnrollmentLinkDialog(false);
  };

  const handleOpenEnrollmentLinkDialog = (id: number) => {
    const user = users.find((x) => x.id === id);
    if (!user) return alert('Ha ocurrido un error');

    setSelectedUser(user);
    setOpenEnrollmentLinkDialog(true);
  };

  const handleOpenCompaniesDialog = async (id: number) => {
    setLoading(true);
    const user = users.find((x) => x.id === id);
    if (!user) {
      setLoading(false);
      return alert('Ha ocurrido un error-');
    }
    setSelectedUser(user);
    setOpenCompanyDialog(true);
    setLoading(false);
  };

  const handleOpenCollectionPortfolioDialog = async (id: number) => {
    setLoading(true);
    const user = users.find((x) => x.id === id);
    if (!user) {
      setLoading(false);
      return alert('Ha ocurrido un error');
    }
    setSelectedUser(user);
    setOpenCollectionPortfolioDialog(true);
    setLoading(false);
  };

  const handleOpenCollectionPortfolioTransferDialog = async (id: number) => {
    setLoading(true);
    const user = users.find((x) => x.id === id);
    if (!user) {
      setLoading(false);
      return alert('Ha ocurrido un error');
    }
    setSelectedUser(user);
    setOpenCollectionPortfolioTransferDialog(true);
    setLoading(false);
  };

  const handleDeactivateDialog = async (id: number) => {
    setLoading(true);
    const user = users.find((x) => x.id === id);
    if (!user) {
      setLoading(false);
      return alert('Ha ocurrido un error');
    }
    setSelectedUser(user);
    setOpenDeactivateDialog(true);
    setLoading(false);
  };

  const handleDeleteDialog = async (id: number) => {
    setLoading(true);
    const user = users.find((x) => x.id === id);
    if (!user) {
      setLoading(false);
      return alert('Ha ocurrido un error');
    }
    setSelectedUser(user);
    setOpenDeleteDialog(true);
    setLoading(false);
  };

  const handleOpenSubordinatesDialog = async (id: number) => {
    setLoading(true);
    const user = users.find((x) => x.id === id);
    if (!user) {
      setLoading(false);
      return alert('Ha ocurrido un error');
    }
    setSelectedUser(user);
    setOpenSubordinates(true);
    setLoading(false);
  };

  const fetchUsers = async () => {
    setLoading(true);
    const users = await getCompanyUsers(dispatch);
    console.log(users);
    setUsers(users || []);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, [reload]);

  if (loading) return <CenteredCircularProgress />;

  const canShowEditDialog = (id: number) => {
    const user = users.find((x) => x.id === id);
    if (!user) return alert('Ha ocurrido un error');

    if (user.id === loggedUser?.id) return true;

    return validatePermissions(loggedUser, 'users_create_new');
  };

  const canShowResendEmail = (id: number) => {
    const user = users.find((x: any) => x.id === id);
    if (!user) return alert('Ha ocurrido un erro');

    return !user.active;
  };

  const canShowDeactivateDialog = (id: number) => {
    const user = users.find((x: any) => x.id === id);
    if (!user) return alert('Ha ocurrido un error');

    if (!user.active) return false;

    return validatePermissions(loggedUser, 'users_create_new');
  };

  const canShowClientDialog = () => {
    return validatePermissions(loggedUser, 'users_create_business_association');
  };

  const canShowCollectionPortfolioDialog = (id: number) => {
    const user = users.find((x: any) => x.id === id);
    if (!user) return alert('Ha ocurrido un error');

    return (
      validatePermissions(user, 'have_collection_portfolio') &&
      validatePermissions(loggedUser, 'edit_collection_portfolio')
    );
  };

  const canShowSubordinatesDialog = (id: number) => {
    const user = users.find((x: any) => x.id === id);
    if (!user) return alert('Ha ocurrido un error');

    return (
      (validatePermissions(loggedUser, 'users_create_new') ||
        validatePermissions(loggedUser, 'edit_subordinates')) &&
      validatePermissions(user, 'have_subordinates')
    );
  };

  const canShowCollectionPortfolioTransferDialog = (id: number) => {
    const user = users.find((x: any) => x.id === id);
    if (!user) return alert('Ha ocurrido un error');

    return (
      validatePermissions(user, 'have_collection_portfolio') &&
      validatePermissions(loggedUser, 'edit_collection_portfolio')
    );
  };

  const actions = [
    {
      action: handleOpenEnrollmentLinkDialog,
      actionLabel: 'Link de enrolamiento',
    },
    {
      action: handleOpenCompaniesDialog,
      actionLabel: 'Ver Clientes',
      canShow: canShowClientDialog,
    },
    {
      action: handleOpenCollectionPortfolioDialog,
      actionLabel: 'Ver cartera cobranza',
      canShow: canShowCollectionPortfolioDialog,
    },
    {
      action: handleOpenCollectionPortfolioTransferDialog,
      actionLabel: 'Transferir cartera cobranza',
      canShow: canShowCollectionPortfolioTransferDialog,
    },
    {
      action: handleOpenSubordinatesDialog,
      actionLabel: 'Editar subordinados',
      canShow: canShowSubordinatesDialog,
    },
    {
      action: handleOpenEditDialog,
      actionLabel: 'Editar permisos',
      canShow: canShowEditDialog,
    },
    {
      action: handleOpenEditPasswordDialog,
      actionLabel: 'Editar información',
      canShow: canShowEditDialog,
    },
    {
      action: handleResendEditDialog,
      actionLabel: 'Reactivar',
      canShow: canShowResendEmail,
    },
    {
      action: handleDeactivateDialog,
      actionLabel: 'Desactivar usuario',
      canShow: canShowDeactivateDialog,
    },
    {
      action: handleDeleteDialog,
      actionLabel: 'Eliminar usuario',
      canShow: canShowEditDialog,
    },
  ];

  const startButtons: CustomButtonProps[] = [
    {
      action: () => setOpenPortfolioTransfer(true),
      name: 'Transferir cartera',
      color: 'secondary',
    },
    {
      action: () => handleOpenAddDialog(),
      name: 'Crear usuario',
      color: 'primary',
      disabled: !validatePermissions(loggedUser, 'users_create_new'),
    },
  ];

  return (
    <div>
      <HeaderWithActions
        title="Aquí encontrarás todos los usuarios de tu empresa."
        variant="body1"
        buttons={startButtons}
        type="subHeader"
      />
      <EnhancedTable
        dataHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'email', label: 'Email' },
          { key: 'active', label: 'Activo', type: 'boolean-active' },
          { key: 'rolesNamesShow', label: 'Nivel de permisos' },
        ]}
        data={users}
        actions={actions}
        showActions
        rowsPerPageDefault={10}
      />
      <EditUserDialog
        loading={loading}
        setLoading={setLoading}
        handleCloseDialog={handleCloseDialog}
        openEditDialog={openEditDialog}
        fetchUsers={fetchUsers}
        editType={editType}
        roles={roles}
        setRoles={setRoles}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      {openCompanyDialog && (
        <EditUserBusinesses
          handleCloseDialog={handleCloseDialog}
          openCompanyDialog={openCompanyDialog}
          selectedUser={selectedUser}
          setCompanyRelations={setCompanyRelations}
          companyRelations={companyRelations}
        />
      )}
      {openCollectionPortfolioDialog && (
        <CollectionPortfolioDialog
          handleCloseDialog={handleCloseDialog}
          openCollectionPortfolioDialog={openCollectionPortfolioDialog}
          selectedUser={selectedUser}
        />
      )}
      <CreateUserDialog
        loading={loading}
        setLoading={setLoading}
        handleCloseDialog={handleCloseDialog}
        openAddDialog={openAddDialog}
        roles={roles}
        setRoles={setRoles}
        fetchUsers={fetchUsers}
      />
      {openEnrollmentLinkDialog && (
        <EnrollmentLinkDialog
          user={selectedUser}
          handleCloseDialog={handleCloseDialog}
          openAddDialog={openEnrollmentLinkDialog}
          setLoading={setLoading}
        />
      )}
      <ResendUserEmailDialog
        loading={loading}
        setLoading={setLoading}
        selectedUser={selectedUser}
        handleCloseDialog={handleCloseDialog}
        openAddDialog={openResendDialog}
      />
      {openPortfolioTransfer && (
        <PortfolioTransfer
          open={openPortfolioTransfer}
          setOpen={setOpenPortfolioTransfer}
          selectedUserId={userToTransfer}
        />
      )}
      {openCollectionPortfolioTransferDialog && (
        <CollectionPortfolioTransfer
          open={openCollectionPortfolioTransferDialog}
          setOpen={setOpenCollectionPortfolioTransferDialog}
          users={users}
          selectedUser={selectedUser}
        />
      )}
      <DeactivateUser
        open={openDeactivateDialog}
        userId={selectedUser.id as number}
        userEmail={selectedUser.email as string}
        handleClose={handleCloseDialog}
        reload={reload}
        setReload={setReload}
        setUserToTransfer={setUserToTransfer}
        setOpenPortfolioTransfer={setOpenPortfolioTransfer}
      />
      <DeleteUser
        open={openDeleteDialog}
        userId={selectedUser.id as number}
        userEmail={selectedUser.email as string}
        handleClose={handleCloseDialog}
        reload={reload}
        setReload={setReload}
        setUserToTransfer={setUserToTransfer}
        setOpenPortfolioTransfer={setOpenPortfolioTransfer}
      />
      <Subordinate
        open={openSubordinates}
        setClose={() => handleCloseDialog()}
        user={selectedUser}
        users={users}
      />
    </div>
  );
};

export default UsersLists;
