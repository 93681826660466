import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import { getCollectionFlows } from '../../../../../../lib/api/collectionFlow';
import { useDispatch } from 'react-redux';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import CreateFlow from './components/CreateFlow';
import EditFlow from './components/EditFlow';
import SeeFlow from './components/SeeFlow';
import { deleteCollectionFlow } from '../../../../../../lib/api/collectionFlow';
import { showAlert } from '../../../../../../lib/functions/utils';
import DeleteFlow from './components/DeleteFlow';

const CollectionFlows: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [collectionFlows, setCollectionFlows] = useState([]);
  const [openCreateFlow, setOpenCreateFlow] = useState(false);
  const [openEditFlow, setOpenEditFlow] = useState(false);
  const [openSeeFlow, setOpenSeeFlow] = useState(false);
  const [openDeleteFlow, setOpenDeleteFlow] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);

  const pageActions: CustomButtonProps[] = [
    {
      action: () => setOpenCreateFlow(true),
      name: 'Crear flujo',
      color: 'primary',
    },
  ];

  const handleDelete = async (id: number) => {
    try {
      const flow = collectionFlows.find((flow: any) => flow.id === id);
      if (!flow) {
        showAlert(dispatch, 'error', 'Flujo no encontrado');
        return;
      }
      setOpenDeleteFlow(true);
      setSelectedFlow(flow);
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al eliminar flujo');
    }
  };

  const handleEdit = async (id: number) => {
    try {
      const flow = collectionFlows.find((flow: any) => flow.id === id);
      if (!flow) {
        showAlert(dispatch, 'error', 'Flujo no encontrado');
        return;
      }
      setOpenEditFlow(true);
      setSelectedFlow(flow);
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al editar flujo');
    }
  };

  const handleSee = async (id: number) => {
    try {
      const flow = collectionFlows.find((flow: any) => flow.id === id);
      if (!flow) {
        showAlert(dispatch, 'error', 'Flujo no encontrado');
        return;
      }
      setOpenSeeFlow(true);
      setSelectedFlow(flow);
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al ver flujo');
    }
  };
  const tableActions: any[] = [
    {
      action: (value: number) => {
        handleSee(value);
      },
      actionLabel: 'Ver',
      canShow: () => true,
    },
    {
      action: (value: number) => {
        handleEdit(value);
      },
      actionLabel: 'Editar',
      canShow: () => true,
    },
    {
      action: (value: number) => {
        handleDelete(value);
      },
      actionLabel: 'Eliminar',
      canShow: () => true,
    },
  ];

  const setUp = async () => {
    setLoading(true);
    try {
      const collectionFlows = await getCollectionFlows(dispatch);
      setCollectionFlows(collectionFlows);
    } catch (error) {
      console.error('Error fetching collection flows:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  if (loading) {
    return <CenteredCircularProgress />;
  }

  return (
    <div>
      <HeaderWithActions
        title="Aquí encontrarás todos los flujos de cobranza."
        variant="body1"
        buttons={pageActions}
        type="subHeader"
      />
      <EnhancedTable
        dataHeaders={[
          { key: 'id', label: 'ID' },
          {
            key: 'name',
            label: 'Nombre',
          },
          {
            key: 'steps',
            label: 'Gestiones',
          },
          {
            key: 'executions',
            label: 'Ejecuciones',
          },
        ]}
        data={collectionFlows.map((flow: any) => ({
          id: flow.id,
          name: flow.name,
          steps: flow.steps.length,
          executions: flow.executions.length,
        }))}
        actions={tableActions}
        showActions
        rowsPerPageDefault={10}
      />
      {openCreateFlow && (
        <CreateFlow
          open={openCreateFlow}
          setOpen={setOpenCreateFlow}
          parentSetUp={setUp}
        />
      )}
      {openEditFlow && (
        <EditFlow
          open={openEditFlow}
          setOpen={setOpenEditFlow}
          parentSetUp={setUp}
          flow={selectedFlow}
        />
      )}
      {openSeeFlow && (
        <SeeFlow
          open={openSeeFlow}
          setOpen={setOpenSeeFlow}
          flow={selectedFlow}
        />
      )}
      {openDeleteFlow && (
        <DeleteFlow
          open={openDeleteFlow}
          setOpen={setOpenDeleteFlow}
          flow={selectedFlow}
          parentSetUp={setUp}
        />
      )}
    </div>
  );
};

export default CollectionFlows;
