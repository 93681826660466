import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

export const buttonsAvailable: any = {
  myInvoices: {
    label: 'Mis Facturas',
    id: 'factoring/invoices',
    icon: <HomeIcon style={{ fontSize: '19px' }} />,
  },
  requests: {
    label: 'Mis Solicitudes',
    id: 'factoring/requests',
    icon: <BusinessIcon style={{ fontSize: '19px' }} />,
  },
  pendingAccounts: {
    label: 'Mis Cuentas',
    id: 'factoring/pending-accounts',
    icon: <AccountBalanceWalletIcon style={{ fontSize: '19px' }} />,
  },
};
