import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DialogActions, Typography, Dialog, TextField } from '@mui/material';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions from '../../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import { createFundPayments } from '../../../../../../../lib/api';
import { formatDate } from '../../../../../../../lib/functions/utils';

const MultiPaymentFundResell = (props: any) => {
  const {
    open,
    setOpen,
    setSuccess,
    setUp,
    setFailure,
    payments,
    setPayments,
    setSelected,
    pagination,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const newOperationDate = new Date();
  // Set to 12:00:00
  newOperationDate.setUTCHours(12);
  const [date, setDate] = useState<any>(newOperationDate);

  const validParams = () => {
    if (!date) {
      alert('Debes seleccionar una fecha');
      return false;
    }
    const allPaymentsHaveAmount = payments.every(
      (payment: any) => payment.amount,
    );
    const allPaymentsHaveSmallerThanTotal = payments.every(
      (payment: any) => payment.amount <= payment.documentAmount,
    );
    if (!allPaymentsHaveAmount) {
      alert('Debes completar el monto a registrar');
      return false;
    }
    if (!allPaymentsHaveSmallerThanTotal) {
      alert('El monto a conciliar no puede ser mayor al monto');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validParams()) {
      return;
    }
    setLoading(true);
    try {
      const paymentsWithDate = payments.map((payment: any) => ({
        ...payment,
        date,
      }));
      await createFundPayments(dispatch, { payments: paymentsWithDate });
      setOpen(false);
      setSuccess('Pagos guardados correctamente');
      setSelected([]);
      await setUp({ page: pagination.page, pageSize: pagination.pageSize });
    } catch (error) {
      console.log(error);
      setFailure('Error al crear la venta');
    }
    setLoading(false);
  };

  const handleChangeAmountToConciliate = (event: any) => {
    const amount = event.target.value.split('.').join('').split('$').join('');
    const payment = payments.find(
      (payment: any) => String(payment.id) === event.target.name,
    );

    payment.amount = Number(amount);
    setPayments([...payments]);
  };

  const DateSelector = () => {
    return (
      <div>
        <TextField
          label="Fecha"
          value={date ? formatDate(date) : null}
          type="date"
          onChange={(event) => setDate(event.target.value)}
        />
      </div>
    );
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open}>
      <div style={{ padding: 20, minHeight: 200 }}>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <div>
            <HeaderWithActions title="Registrar pagos" item={DateSelector()} />
            <Typography variant="body1"></Typography>
            <div style={{ padding: '10px 0px' }}>
              <EnhancedTable
                data={payments}
                dataHeaders={[
                  {
                    key: 'factoringResellId',
                    label: 'Id',
                  },
                  {
                    key: 'folio',
                    label: 'Folio',
                  },
                  {
                    key: 'businessName',
                    label: 'Cliente',
                  },
                  {
                    key: 'stakeholderName',
                    label: 'Deudor',
                  },
                  {
                    key: 'fundName',
                    label: 'Fondo',
                  },
                  {
                    key: 'documentAmount',
                    label: 'Monto Documento',
                    type: 'money',
                  },
                  {
                    key: 'amount',
                    label: 'Monto a registrar',
                    type: 'money',
                    handleChange: handleChangeAmountToConciliate,
                  },
                ]}
              />
            </div>
            <DialogActions>
              <CustomButton onClick={() => setOpen(false)} color="secondary">
                Salir
              </CustomButton>
              <CustomButton onClick={() => handleSubmit()} color="primary">
                Confirmar
              </CustomButton>
            </DialogActions>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default MultiPaymentFundResell;
