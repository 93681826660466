import { FormControl, InputLabel, Select } from '@mui/material';
import { Grid, MenuItem } from '@mui/material';
import { TextField } from '@mui/material';
import React from 'react';

interface ManualGridProps {
  missingFields: boolean;
  stateId: number;
  optionId: number;
  setOptionId: (id: number) => void;
  contactMethod: string;
  setContactMethod: (method: string) => void;
  disabled: boolean;
  loading: boolean;
  stateOptions: any[];
  optionOptions: any[];
  handleSelectState: (id: number) => void;
  manualDescription: string;
  setManualDescription: (description: string) => void;
  setStepName: (name: string) => void;
  setStepDescription: (description: string) => void;
}
const ManualGrid: React.FC<ManualGridProps> = (props) => {
  const {
    missingFields,
    stateId,
    optionId,
    setOptionId,
    contactMethod,
    setContactMethod,
    disabled,
    loading,
    stateOptions,
    optionOptions,
    handleSelectState,
    manualDescription,
    setManualDescription,
    setStepName,
    setStepDescription,
  } = props;

  const handleOptionChange = (e: any) => {
    const newValue = e.target.value as unknown as number;
    setOptionId(newValue);
    const option = optionOptions.find((option) => option.id === newValue);
    const optionsText = option?.name;
    setStepName(optionsText);
    setStepDescription(optionsText);
  };

  return (
    <div>
      <Grid container spacing={2} style={{ paddingTop: '12px' }}>
        <Grid item xs={6}>
          <TextField
            error={missingFields && !stateId}
            select
            value={stateId}
            label="Estado gestión"
            variant="outlined"
            fullWidth
            onChange={(e) =>
              handleSelectState(e.target.value as unknown as number)
            }
            disabled={loading}
          >
            {stateOptions.map((element: any) => {
              return (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={missingFields && !optionId}
            select
            value={optionId}
            label="Subestado de gestión"
            variant="outlined"
            fullWidth
            onChange={(e) => handleOptionChange(e)}
            disabled={loading || !stateId}
          >
            {optionOptions.map((element: any) => {
              return (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Método de contacto
            </InputLabel>
            <Select
              error={missingFields && !contactMethod}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={contactMethod}
              onChange={(e) => setContactMethod(e.target.value)}
              disabled={disabled || !stateId || !optionId}
              label="Método de contacto"
            >
              {['Llamada', 'Correo', 'Whatsapp'].map((element) => {
                return (
                  <MenuItem key={element} value={element}>
                    {element}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={missingFields && !manualDescription}
            id="outlined-basic"
            label="Observación"
            variant="outlined"
            value={manualDescription}
            fullWidth
            multiline
            disabled={disabled || !stateId || !optionId}
            onChange={(e) => setManualDescription(e.target.value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ManualGrid;
