import React from 'react';

import { DialogActions, Typography, Dialog } from '@mui/material';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import HeaderWithActions from '../../../../../../../lib/designSystem/HeaderWithActions';
import { LightTooltip } from '../../../../../../../lib/designSystem/WhiteTooltip';
import { Info } from '@mui/icons-material';

const RegisterSelector = (props: any) => {
  const {
    open,
    setOpen,
    setOpenMultiPayment,
    setOpenMultiCreateResell,
    resells,
    selectedResellIds,
    setPayments,
  } = props;

  const handleMultiPayment = () => {
    const resellsSelected = resells.filter((resell: any) =>
      selectedResellIds.includes(resell.id),
    );
    const paymentsWithAmount = resellsSelected.map((resell: any) => ({
      ...resell,
      factoringResellId: resell.id,
      documentAmount: resell.amount,
      amount: resell.amount - resell.amountPaid,
      businessIdentifier: resell.document.businessIdentifier,
    }));
    setPayments(paymentsWithAmount);
    setOpenMultiPayment(true);
    setOpen(false);
  };

  const handleCreateResell = () => {
    setOpenMultiCreateResell(true);
    setOpen(false);
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={open}>
      <div style={{ padding: 20, minHeight: 200 }}>
        <div>
          <HeaderWithActions title="Registro" />
          <div style={{ padding: '10px 0px', textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <div>
                <div>
                  <CustomButton
                    onClick={() => handleMultiPayment()}
                    color="primary"
                    disabled={selectedResellIds.length === 0}
                  >
                    Registrar pagos
                  </CustomButton>
                </div>
                <div>
                  {selectedResellIds.length === 0 && (
                    <LightTooltip
                      title={
                        <Typography variant="body1" style={{ padding: 10 }}>
                          Selecciona al menos una venta
                        </Typography>
                      }
                    >
                      <Info sx={{ cursor: 'pointer' }} />
                    </LightTooltip>
                  )}
                </div>
              </div>
              <div>
                <CustomButton
                  onClick={() => handleCreateResell()}
                  color="primary"
                >
                  Registrar venta
                </CustomButton>
              </div>
            </div>
          </div>
          <DialogActions>
            <CustomButton onClick={() => setOpen(false)} color="secondary">
              Salir
            </CustomButton>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};

export default RegisterSelector;
