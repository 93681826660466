import { requestHandler } from '../../api';

export const getCollectionFlows = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: '/internal/collectionFlows/',
    dispatch,
  });
  if (response) return response.data;
};

export const createCollectionFlow = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: '/internal/collectionFlows/create',
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const updateCollectionFlow = async (
  dispatch: any,
  id: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'put',
    url: `/internal/collectionFlows/${id}`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const deleteCollectionFlow = async (dispatch: any, id: any) => {
  const response = await requestHandler({
    method: 'delete',
    url: `/internal/collectionFlows/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const executeCollectionFlow = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: '/internal/collectionFlows/execute',
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const unenrollCollectionFlow = async (dispatch: any, id: any) => {
  const response = await requestHandler({
    method: 'delete',
    url: `/internal/collectionFlows/execution/${id}`,
    dispatch,
  });
  if (response) return response.data;
};
