import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import {
  formatDate,
  formatMoney,
  formatMoneyBack,
  loggerDev,
  showAlert,
} from '../../../../../../../../../../../../lib/functions/utils';
import {
  Add,
  Delete,
  ExpandLess,
  ExpandMore,
  Receipt,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import {
  getLiberationDate,
  getRealDaysToCount,
  isDateAWeekDay,
  calculateDays,
} from '../../../../../../../../../../../../lib/functions/dates';
import Uploader from '../../../../../../../../../../../../lib/designSystem/Uploader';
import CustomButton from '../../../../../../../../../../../../lib/designSystem/Button';
import {
  expresGetDocumentFile,
  expressUploadFilesFromRequest,
} from '../../../../../../../../../../../../lib/api/express/factoring';
import CenteredCircularProgress from '../../../../../../../../../../../../lib/designSystem/CircularProgress';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '100%', // ensure the table fits its container
    },
    tableCell: {
      padding: '4px !important',
      maxWidth: '200px',
      maxHeight: '10px',
      overflow: 'hidden', // Ensure the content doesn't overflow.
      whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
    selectFullWidth: {
      width: '100%',
    },
    selectSmall: {
      width: '200px', // or any other small width
    },
  }),
);

const firstCellStyle: any = {
  maxWidth: '150px',
  overflow: 'hidden', // Ensure the content doesn't overflow.
  textOverflow: 'ellipsis', // If the text overflows, it will end with "..."
  whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
  padding: '4px 16px',
};

const DocumentsTable = (props: any) => {
  const {
    selectedDocuments,
    setSelectedDocuments,
    exchangeDay,
    canForcePaymentDate,
    minimumDays,
    isExpress,
    clientId,
    operationDate,
  } = props;
  const classes = useStyles();
  const [openUploadDocumentPDF, setOpenUploadDocumentPDF] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [documentToUpload, setDocumentToUpload] = useState('');
  const [loading, setLoading] = useState(false);
  const [anchorEls, setAnchorEls] = useState<any>(
    selectedDocuments.map(() => null),
  );
  const [openMenus, setOpenMenus] = useState<any>(
    selectedDocuments.map(() => false),
  );
  const [expandedRows, setExpandedRows] = useState<any>({});

  // Functions to handle documents changes
  const dateValidators = (date: Date, rowIndex: number) => {
    if (canForcePaymentDate) return true;

    const actualDate = selectedDocuments[rowIndex].paymentDate;
    if (!isDateAWeekDay(date)) {
      showAlert(
        dispatch,
        'warning',
        'La fecha de vencimiento debe ser un día hábil',
      );
      handleChangeTempDate(formatDate(actualDate), rowIndex);
      return false;
    }
    const minimumDate = new Date(operationDate);
    minimumDate.setDate(minimumDate.getDate() + minimumDays - 1);
    if (date < minimumDate) {
      showAlert(
        dispatch,
        'warning',
        `La fecha de vencimiento no puede ser menor a ${minimumDays} días a partir de la fecha de operación`,
      );
      handleChangeTempDate(formatDate(actualDate), rowIndex);
      return false;
    }
    const days = calculateDays(
      new Date(selectedDocuments[rowIndex].issuedDate),
      new Date(date),
    );
    if (days <= 0) {
      showAlert(
        dispatch,
        'warning',
        'La fecha de vencimiento no puede ser menor a la fecha de emisión',
      );
      return false;
    }
    return days;
  };

  const handleChangeTempDate = (value: any, rowIndex: number) => {
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          tempDueDate: value,
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  const handleChangeDateNormal = (index: number) => {
    const value = selectedDocuments[index].tempDueDate;
    const date = new Date(value);

    const days = dateValidators(date, index);
    if (!days) return;
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === index) {
        return {
          ...doc,
          dueDate: date?.toISOString(),
          paymentDate: date,
          tempDueDate: formatDate(date),
          daysToCount: getRealDaysToCount(date, operationDate),
        };
      }
      return doc;
    });

    setSelectedDocuments(newSelectedDocuments);
  };

  const handleChangeDateExchange = (value: any, index: number) => {
    const date = new Date(value);
    const liberationDate = getLiberationDate(date);
    const daysToCount = getRealDaysToCount(liberationDate, operationDate);
    const valid = dateValidators(date, index);
    if (!valid) return;
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === index) {
        return {
          ...doc,
          dueDate: date?.toISOString(),
          paymentDate: liberationDate,
          daysToCount,
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  // Functions to handle sub-documents changes
  const dateValidatorsSubIndex = (
    date: Date,
    rowIndex: number,
    subIndex: number,
  ) => {
    if (canForcePaymentDate) return true;

    const actualDate =
      selectedDocuments[rowIndex].subDocuments[subIndex].paymentDate;
    if (!isDateAWeekDay(date)) {
      showAlert(
        dispatch,
        'warning',
        'La fecha de vencimiento debe ser un día hábil',
      );
      handleChangeSubDocumentTempDate(
        formatDate(actualDate),
        rowIndex,
        subIndex,
      );
      return false;
    }
    const minimumDate = new Date(operationDate);
    minimumDate.setDate(minimumDate.getDate() + minimumDays - 1);
    if (date < minimumDate) {
      showAlert(
        dispatch,
        'warning',
        `La fecha de vencimiento no puede ser menor a ${minimumDays} días a partir de la fecha de operación`,
      );
      handleChangeSubDocumentTempDate(
        formatDate(actualDate),
        rowIndex,
        subIndex,
      );
      return false;
    }
    const days = calculateDays(
      new Date(selectedDocuments[rowIndex].subDocuments[subIndex].issuedDate),
      new Date(date),
    );
    if (days <= 0) {
      showAlert(
        dispatch,
        'warning',
        'La fecha de vencimiento no puede ser menor a la fecha de emisión',
      );
      return false;
    }
    return days;
  };

  const handleChangeSubDocumentTempDate = (
    value: any,
    rowIndex: number,
    subIndex: number,
  ) => {
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          subDocuments: doc.subDocuments.map((subDoc: any, j: any) =>
            j === subIndex
              ? {
                  ...subDoc,
                  tempDueDate: value,
                }
              : subDoc,
          ),
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  const handleChangeSubDocumentDateNormal = (
    rowIndex: number,
    subIndex: number,
  ) => {
    const value =
      selectedDocuments[rowIndex].subDocuments[subIndex].tempDueDate;
    const date = new Date(value);
    const days = dateValidatorsSubIndex(date, rowIndex, subIndex);
    if (!days) return;
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          subDocuments: doc.subDocuments.map((subDoc: any, j: any) => {
            if (j === subIndex) {
              return {
                ...subDoc,
                dueDate: date?.toISOString(),
                paymentDate: date,
                daysToCount: getRealDaysToCount(date, operationDate),
              };
            }
            return subDoc;
          }),
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  const handleChangeSubDocumentDateExchange = (
    value: any,
    rowIndex: number,
    subIndex: number,
  ) => {
    const date = new Date(value);
    const liberationDate = getLiberationDate(date);
    const valid = dateValidatorsSubIndex(date, rowIndex, subIndex);
    if (!valid) return;
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          subDocuments: doc.subDocuments.map((subDoc: any, j: any) => {
            if (j === subIndex) {
              const daysToCount = getRealDaysToCount(
                liberationDate,
                operationDate,
              );
              return {
                ...subDoc,
                dueDate: date?.toISOString(),
                paymentDate: liberationDate,
                daysToCount,
              };
            }
            return subDoc;
          }),
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  const addSubDocument = (rowIndex: number) => {
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          daysToCount: null,
          subDocuments: [
            ...(doc.subDocuments ? doc.subDocuments : []),
            {
              id: null,
              amount: 0,
              amountAvailable: 0,
              businessIdentifier:
                selectedDocuments[rowIndex].businessIdentifier,
              cancelDate: null,
              companyId: selectedDocuments[rowIndex].companyId,
              confirmDate: null,
              factoringQuotes: [],
              dueDate: undefined,
              paymentDate: undefined,
              tempDueDate: undefined,
              fatherDocumentId: selectedDocuments[rowIndex].id,
              fatherId: selectedDocuments[rowIndex].id,
              folio: null,
              isOld: false,
              issuer: true,
              nulledAt: null,
              paidAt: null,
              issuedDate: selectedDocuments[rowIndex].issuedDate,
              pendingAccountId: null,
              stakeholder: selectedDocuments[rowIndex].stakeholder,
              stakeholderIdentifier:
                selectedDocuments[rowIndex].stakeholderIdentifier,
              stakeholderName: selectedDocuments[rowIndex].stakeholderName,
              subDocuments: [],
              type: selectedDocuments[rowIndex].type,
              daysToCount: null,
            },
          ],
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
    if (!expandedRows[rowIndex]) toggleRowExpansion(rowIndex);
  };

  const handleChangeSubDocument = (
    value: any,
    name: any,
    rowIndex: number,
    subIndex: number,
  ) => {
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          subDocuments: doc.subDocuments.map((subDoc: any, j: any) => {
            if (j === subIndex) {
              return {
                ...subDoc,
                [name]: name === 'amount' ? formatMoneyBack(value) : value,
              };
            }
            return subDoc;
          }),
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  const deleteSubDocument = (rowIndex: number, subIndex: number) => {
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          subDocuments: doc.subDocuments.filter(
            (subDoc: any, j: any) => j !== subIndex,
          ),
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  // Functions to handle row expansion
  const toggleRowExpansion = (rowIndex: number) => {
    setExpandedRows({
      ...expandedRows,
      [rowIndex]: !expandedRows[rowIndex],
    });
  };

  const toggleRowExpansionPdf = (
    rowIndex: number,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const newOpenMenus = openMenus.map((el: any, i: number) =>
      i === rowIndex ? !el : false,
    );
    const newAnchorEls = anchorEls.map((el: any, i: number) =>
      i === rowIndex ? event.currentTarget : null,
    );
    setAnchorEls(newAnchorEls);
    setOpenMenus(newOpenMenus);
    const document = selectedDocuments[rowIndex];
    const documentKey = `${document.businessIdentifier}-${document.folio}`;
    setDocumentToUpload(documentKey);
  };

  useEffect(() => {
    const newSelectedDocuments = selectedDocuments.map((doc: any) => {
      return {
        ...doc,
        paymentDate: doc.dueDate,
        tempDueDate: formatDate(doc.dueDate),
        daysToCount:
          doc.dueDate && doc.dueDate !== ''
            ? getRealDaysToCount(doc.dueDate, operationDate)
            : '',
        isOld: doc.isOld,
        subDocuments:
          doc?.subDocuments?.map((subDoc: any) => ({
            ...subDoc,
            folio: subDoc.folio
              ? subDoc.folio.split('-')[1] || subDoc.folio
              : null,
            daysToCount:
              subDoc.dueDate && subDoc.dueDate !== ''
                ? getRealDaysToCount(subDoc.dueDate, operationDate)
                : '',
            isOld: subDoc.isOld,
            paymentDate: subDoc.dueDate,
            tempDueDate: formatDate(subDoc.dueDate),
          })) || [],
      };
    });
    setSelectedDocuments(newSelectedDocuments);
  }, []);

  const dispatch = useDispatch();

  const handleFileChange = (event: any) => {
    const uploadedFile = event.target.files[0];
    setUploadedFileName(uploadedFile.name);
    setFile(uploadedFile);
  };

  const handleUploadDocumentPDF = () => {
    setOpenUploadDocumentPDF(true);
  };

  const handleDownloadDocumentPDF = async (documentId: string) => {
    const document = selectedDocuments.find((x: any) => x.id === documentId);
    const documentKey = `${document.businessIdentifier}-${document.folio}.pdf`;
    setLoading(true);
    try {
      const response = await expresGetDocumentFile(
        dispatch,
        clientId,
        documentKey,
      );
      if (response) {
        window.open(response);
      } else {
        showAlert(dispatch, 'warning', 'No hay archivo para descargar');
      }
    } catch (error) {
      loggerDev(error);
    }
    setLoading(false);
  };

  const uploadFiles = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      const fileToUpload = new File([file], `${documentToUpload}.pdf`, {
        type: file.type,
      });
      formData.append('files', fileToUpload);
      await expressUploadFilesFromRequest(dispatch, clientId, formData);
      showAlert(dispatch, 'success', 'Se han subido tus archivos con éxito');
      setDocumentToUpload('');
      const documents = selectedDocuments.map((document: any) => {
        if (
          `${document.businessIdentifier}-${document.folio}` ===
          documentToUpload
        ) {
          return {
            ...document,
            fileStatus: true,
          };
        }
        return document;
      });
      setSelectedDocuments(documents);
      setOpenUploadDocumentPDF(false);
    } catch (error) {
      loggerDev(error);
    }
    setLoading(false);
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead style={{ background: '#efefef' }}>
          <TableCell style={firstCellStyle}>Sub documentos</TableCell>
          <TableCell style={firstCellStyle}>Folio</TableCell>
          {isExpress && <TableCell style={firstCellStyle}>PDF</TableCell>}
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Nombre deudor
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Monto total
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Monto disp.
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Fecha emisión
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Fecha vencimiento
          </TableCell>
          {exchangeDay && (
            <TableCell
              className={classes.tableCell}
              style={{ textAlign: 'right' }}
            >
              Fecha canje
            </TableCell>
          )}
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'center' }}
          >
            Días
          </TableCell>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {selectedDocuments.flatMap((row: any, rowIndex: number) => {
            let subDocumentRows = [];
            const isExpanded = !!expandedRows[rowIndex];
            const hasSubDocuments =
              row.subDocuments && row.subDocuments.length > 0;
            const parentRow = (
              <TableRow key={rowIndex}>
                <TableCell style={firstCellStyle}>
                  <IconButton onClick={() => addSubDocument(rowIndex)}>
                    <Add />
                  </IconButton>
                  {row.subDocuments &&
                    row.subDocuments.length > 0 &&
                    row.subDocuments.length}{' '}
                  <IconButton
                    onClick={() => toggleRowExpansion(rowIndex)}
                    aria-label="expand row"
                    size="small"
                  >
                    {row.subDocuments && row.subDocuments.length > 0 ? (
                      expandedRows[rowIndex] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : null}
                  </IconButton>
                </TableCell>

                <TableCell style={firstCellStyle}>{row.folio}</TableCell>
                {isExpress && (
                  <TableCell style={firstCellStyle}>
                    <IconButton
                      onClick={(e) => toggleRowExpansionPdf(rowIndex, e)}
                      color={row.fileStatus ? 'primary' : 'inherit'}
                    >
                      <Receipt />
                      <Menu
                        anchorEl={anchorEls[rowIndex]}
                        open={openMenus[rowIndex]}
                      >
                        <MenuItem
                          onClick={() => handleDownloadDocumentPDF(row.id)}
                          disabled={!row.fileStatus}
                        >
                          Descargar
                        </MenuItem>
                        <MenuItem onClick={() => handleUploadDocumentPDF()}>
                          Subir
                        </MenuItem>
                      </Menu>
                    </IconButton>
                  </TableCell>
                )}
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'right' }}
                >
                  {row.stakeholderName}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'right' }}
                >
                  {formatMoney(row.amount)}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'right' }}
                >
                  {formatMoney(row.amountAvailable)}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'right' }}
                >
                  {formatDate(row.issuedDate)}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'right' }}
                >
                  {hasSubDocuments ? null : (
                    <TextField
                      value={
                        exchangeDay
                          ? row.dueDate
                            ? formatDate(row.dueDate)
                            : null
                          : row.tempDueDate
                      }
                      type="date"
                      onChange={
                        exchangeDay
                          ? (e) => {
                              handleChangeDateExchange(
                                e.target.value,
                                rowIndex,
                              );
                            }
                          : (e) => {
                              handleChangeTempDate(e.target.value, rowIndex);
                            }
                      }
                      onBlur={() =>
                        exchangeDay ? null : handleChangeDateNormal(rowIndex)
                      }
                    />
                  )}
                </TableCell>
                {exchangeDay && (
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {hasSubDocuments
                      ? null
                      : row.paymentDate
                        ? formatDate(row.paymentDate)
                        : null}
                  </TableCell>
                )}
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'center' }}
                >
                  {row.daysToCount}
                </TableCell>
              </TableRow>
            );

            if (isExpanded && hasSubDocuments) {
              subDocumentRows = row.subDocuments.map(
                (subDoc: any, subIndex: number) => (
                  <TableRow key={`sub-${rowIndex}-${subIndex}`}>
                    <TableCell>
                      <IconButton
                        onClick={() => deleteSubDocument(rowIndex, subIndex)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'left' }}
                    >
                      {subDoc.isOld ? (
                        subDoc.folio
                      ) : (
                        <TextField
                          value={subDoc.folio}
                          type="number"
                          label="Folio"
                          onChange={(e) =>
                            handleChangeSubDocument(
                              e.target.value,
                              'folio',
                              rowIndex,
                              subIndex,
                            )
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'right' }}
                    >
                      {subDoc.stakeholderName}{' '}
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}> - </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'right' }}
                    >
                      {subDoc.isOld ? (
                        formatMoney(subDoc.amount)
                      ) : (
                        <TextField
                          value={formatMoney(subDoc.amount)}
                          inputProps={{ style: { textAlign: 'right' } }}
                          label="Monto"
                          onChange={(e) =>
                            handleChangeSubDocument(
                              e.target.value,
                              'amount',
                              rowIndex,
                              subIndex,
                            )
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'right' }}
                    >
                      {formatDate(subDoc.issuedDate)}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'right' }}
                    >
                      <TextField
                        value={
                          exchangeDay
                            ? subDoc.dueDate
                              ? subDoc.dueDate.split('T')[0]
                              : null
                            : subDoc.tempDueDate
                        }
                        type="date"
                        onChange={
                          exchangeDay
                            ? (e) => {
                                handleChangeSubDocumentDateExchange(
                                  e.target.value,
                                  rowIndex,
                                  subIndex,
                                );
                              }
                            : (e) => {
                                handleChangeSubDocumentTempDate(
                                  e.target.value,
                                  rowIndex,
                                  subIndex,
                                );
                              }
                        }
                        onBlur={() =>
                          exchangeDay
                            ? null
                            : handleChangeSubDocumentDateNormal(
                                rowIndex,
                                subIndex,
                              )
                        }
                      />
                    </TableCell>
                    {exchangeDay && (
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: 'right' }}
                      >
                        {subDoc.paymentDate
                          ? formatDate(subDoc.paymentDate)
                          : null}
                      </TableCell>
                    )}
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'center' }}
                    >
                      {subDoc.daysToCount}
                    </TableCell>
                  </TableRow>
                ),
              );
            }
            // Combine the parent row with its sub-document rows
            return [parentRow, ...subDocumentRows];
          })}
        </TableBody>
      </Table>
      {openUploadDocumentPDF && (
        <Dialog
          open={openUploadDocumentPDF}
          onClose={() => setOpenUploadDocumentPDF(false)}
        >
          <DialogTitle id="form-dialog-title">Subir factura</DialogTitle>
          {loading ? (
            <CenteredCircularProgress type="dialog" />
          ) : (
            <DialogContent style={{ minHeight: 100, padding: 50 }}>
              <Uploader
                buttonText="Subir documento"
                handleFileChange={handleFileChange}
                uploadedFileName={uploadedFileName}
                setUploadedFileName={setUploadedFileName}
              />
            </DialogContent>
          )}
          <DialogActions>
            <CustomButton
              onClick={() => setOpenUploadDocumentPDF(false)}
              color="secondary"
            >
              Cancelar
            </CustomButton>
            <CustomButton onClick={uploadFiles} color="primary">
              Subir
            </CustomButton>
          </DialogActions>
        </Dialog>
      )}
    </TableContainer>
  );
};

export default DocumentsTable;
