import React from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineConnector,
  TimelineOppositeContent,
  TimelineDot,
} from '@mui/lab';
import { Grid, Typography, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body1'
  | 'body2';

interface Content {
  variantPrimary?: Variant;
  variantSecondary?: Variant;
  textPrimary: string;
  textSecondary?: string;
}

interface Item {
  left?: Content;
  right?: Content;
  canDelete?: boolean;
  onDelete?: () => void;
  icon: React.ReactNode;
  iconVariant?: 'outlined' | 'filled';
}

interface CustomTimelineProps {
  items: Item[];
}

const CustomTimeline = ({ items }: CustomTimelineProps) => {
  return (
    <Timeline
      sx={{
        align: 'center',
      }}
    >
      {items.map((item, index) => (
        <TimelineItem key={index}>
          {item.left && (
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              align="right"
              alignItems="center"
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <div
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    {item.canDelete && (
                      <IconButton onClick={item.onDelete}>
                        <Delete />
                      </IconButton>
                    )}
                  </div>
                </Grid>

                <Grid item xs>
                  <Typography variant={item.left.variantPrimary}>
                    {item.left.textPrimary}
                  </Typography>
                  {item.left.textSecondary && (
                    <Typography variant={item.left.variantSecondary}>
                      {item.left.textSecondary}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </TimelineOppositeContent>
          )}
          <TimelineSeparator
            sx={{
              alignItems: 'center',
            }}
          >
            <TimelineConnector />
            <TimelineDot color="primary" variant={item.iconVariant}>
              {item.icon}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          {item.right && (
            <TimelineContent
              sx={{ m: 'auto 0' }}
              align="left"
              alignItems="center"
            >
              <Typography variant={item.right.variantPrimary}>
                {item.right.textPrimary}
              </Typography>
              {item.right.textSecondary && (
                <Typography variant={item.right.variantSecondary}>
                  {item.right.textSecondary}
                </Typography>
              )}
            </TimelineContent>
          )}
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default CustomTimeline;
