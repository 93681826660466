import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import { getBusinessFactoringOperativeSummary } from '../../../../../lib/api';
import { useDispatch } from 'react-redux';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import EnhancedTab from '../../../../../lib/designSystem/TabSimulation';
import ActiveDocuments from '../../../../Finance/Components/Financing/CreditLine/components/Recieved/components/ShoppingDialog/components/ActiveDocuments';
import Accounts from '../../../../Finance/Components/Payments/components/Accounts';
import Summary from './components/Summary';
import FactoringRequestsRecieved from '../../../../Finance/Components/Financing/Factoring/components/Recieved';
import { Typography } from '@mui/material';
import CreditsRequested from '../../../../Finance/Components/Financing/CreditsRequests/components/Recieved';

const StakeholderConcentrations = (props: any) => {
  const { creditLineAssignation } = props;
  return (
    <div style={{ padding: '10px 0px' }}>
      <EnhancedTable
        dataHeaders={[
          { key: 'businessName', label: 'Nombre' },
          { key: 'businessIdentifier', label: 'RUT' },
          { key: 'amountUsed', label: 'En uso', type: 'money' },
          { key: 'amountArrear', label: 'Mora', type: 'money' },
          { key: 'amount', label: 'Límite', type: 'money' },
        ]}
        data={
          creditLineAssignation
            ? creditLineAssignation.stakeholderConcentrationUses
            : []
        }
      />
    </div>
  );
};

const SubProductConcentrations = (props: any) => {
  const { creditLineAssignation } = props;
  return (
    <div style={{ padding: '10px 0px' }}>
      <EnhancedTable
        dataHeaders={[
          { key: 'subProductName', label: 'Subproducto' },
          { key: 'amountUsed', label: 'En uso', type: 'money' },
          { key: 'amountArrear', label: 'Mora', type: 'money' },
          { key: 'concentrationLimit', label: 'Límite', type: 'money' },
        ]}
        data={
          creditLineAssignation
            ? creditLineAssignation.subProductConcentrationUses
            : []
        }
      />
    </div>
  );
};

const OperativeInfo = (props: any) => {
  const { businessIdentifier } = props;
  const [loading, setLoading] = React.useState(false);
  const [startValue, setStartValue] = useState(0);
  const [creditLineData, setCreditLineData] = useState<any>({});
  const [operativeBusinessDetails, setOperativeBusinessDetails] =
    React.useState<any>({});
  const dispatch = useDispatch();
  const { id } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();
  const businessIdentifierReal = businessIdentifier || id;
  const [businessId, setBusinessId] = useState('');

  const setUp = async () => {
    if (!businessIdentifierReal) {
      alert('No se ha encontrado el id del cliente');
      return;
    }
    setBusinessId(businessIdentifierReal);
    setStartValue(0);
    setLoading(true);
    const operativeBusinessDetailsResponse =
      await getBusinessFactoringOperativeSummary(
        dispatch,
        businessIdentifierReal,
      );
    const data: any = {};
    if (operativeBusinessDetailsResponse.factoringCreditLine) {
      data.status = operativeBusinessDetailsResponse.factoringCreditLine.status;
      data.validUntil =
        operativeBusinessDetailsResponse.factoringCreditLine.validUntil;
      data.total = operativeBusinessDetailsResponse.factoringCreditLine.amount;
    } else {
      data.status = 'Inactiva';
      data.validUntil = '';
      data.total = 0;
    }
    if (operativeBusinessDetailsResponse.creditLineBalance) {
      data.activeAmount =
        operativeBusinessDetailsResponse.creditLineBalance.activeAmount;
      data.dueAmount =
        operativeBusinessDetailsResponse.creditLineBalance.dueAmount;
      data.amountUsed =
        operativeBusinessDetailsResponse.creditLineBalance.amountUsed;
      data.amountArrear =
        operativeBusinessDetailsResponse.creditLineBalance.amountArrear;
      data.amountTotalArrear =
        operativeBusinessDetailsResponse.creditLineBalance.amountTotalArrear;
      data.segmentedActive =
        operativeBusinessDetailsResponse.creditLineBalance.segmentedActive;
      data.segmentedArrear =
        operativeBusinessDetailsResponse.creditLineBalance.segmentedArrear;
    } else {
      data.activeAmount = 0;
      data.dueAmount = 0;
      data.amountUsed = 0;
      data.amountArrear = 0;
      data.amountTotalArrear = 0;
      data.segmentedActive = [];
      data.segmentedArrear = [];
    }
    if (operativeBusinessDetailsResponse.collections) {
      data.conciliatedByBusiness =
        operativeBusinessDetailsResponse.collections.conciliatedByBusinessAmount;
      data.conciliatedByStakeholderAmount =
        operativeBusinessDetailsResponse.collections.conciliatedByStakeholderAmount;
    } else {
      data.conciliatedByBusiness = 0;
      data.conciliatedByStakeholderAmount = 0;
    }
    setCreditLineData(data);
    console.log(operativeBusinessDetailsResponse);
    setOperativeBusinessDetails(operativeBusinessDetailsResponse || {});
    // setOperativeBusinessDetails(operativeBusinessDetailsResponse || {});
    // const businessOperativeDetails = await getBusinessBankruptcyPosts(dispatch, Number(id));
    // console.log(operativeBusinessDetailsResponse);
    setLoading(false);
  };

  const tabs = [
    {
      index: 0,
      label: 'Deudor',
      component: (
        <StakeholderConcentrations
          creditLineAssignation={operativeBusinessDetails.creditLineBalance}
        />
      ),
    },
    {
      index: 0,
      label: 'Sub Producto',
      component: (
        <SubProductConcentrations
          creditLineAssignation={operativeBusinessDetails.creditLineBalance}
        />
      ),
    },
  ];
  const factoringCreditLineComponent = (
    <div style={{ padding: '20px 0px' }}>
      <Typography variant="body1" fontWeight={600}>
        Uso de línea
      </Typography>
      <EnhancedTab startValue={startValue} tabs={tabs} />
    </div>
  );

  useEffect(() => {
    setUp();
  }, []);
  console.log(businessIdentifierReal);

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <EnhancedTab
        startValue={0}
        tabs={[
          {
            label: 'Inicio',
            component: <Summary creditLineData={creditLineData} />,
          },
          {
            label: 'Factoring',
            component: (
              <div>
                {creditLineData.factoringCreditLine
                  ? factoringCreditLineComponent
                  : null}
                <FactoringRequestsRecieved
                  businessIdentifierProp={businessIdentifierReal}
                />
              </div>
            ),
          },
          {
            label: 'Créditos',
            component: (
              <CreditsRequested
                businessIdentifierProp={businessIdentifierReal}
              />
            ),
          },

          {
            label: 'Cartera',
            component: (
              <div style={{ padding: '20px 0px' }}>
                <Typography variant="body1" fontWeight={600}>
                  Factoring
                </Typography>
                <ActiveDocuments
                  businessIdentifier={businessId}
                  notDialog
                  request={{
                    productType: 'factoring',
                  }}
                />
                <Typography variant="body1" fontWeight={600}>
                  Créditos
                </Typography>
                <ActiveDocuments
                  businessIdentifier={businessId}
                  notDialog
                  request={{
                    productType: 'credit',
                  }}
                />
              </div>
            ),
          },
          {
            label: 'Cuentas',
            component: (
              <Accounts businessIdentifier={businessIdentifierReal} profile />
            ),
          },
        ]}
      />
    </div>
  );
};

export default OperativeInfo;
