import React from 'react';
import CollectionsManagementsCreated from './components/Created';
import CollectionsManagementsSuggested from './components/Suggested';

const CollectionsManagements: React.FC = () => {
  return (
    <div>
      <CollectionsManagementsCreated />
      <CollectionsManagementsSuggested />
      <CollectionsManagementsCreated completed />
    </div>
  );
};

export default CollectionsManagements;
