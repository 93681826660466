import {
  Grid,
  Typography,
  IconButton,
  Popper,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { Add, Close, KeyboardArrowDown } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../../lib/functions/utils';
import DocumentsSuggestedContainer from '../../../documentSugestion';
import { LightTooltip } from '../../../../../../../../lib/designSystem/WhiteTooltip';
import { Info } from '@mui/icons-material';
import ArrearPopper from './components/arrearPopper';
import GuaranteeObservationPopper from './components/guaranteeObservationPopper';

const CustomTooltip = (text: Array<Array<string>>) => (
  <div style={{ padding: 10 }}>
    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
      <Typography variant="body1">Descripción</Typography>
    </div>
    <Table size="small">
      <TableBody>
        {text.map((row) => (
          <TableRow key={row[0]}>
            <TableCell key={row[0]}>{row[0]}</TableCell>
            <TableCell key={row[1]}>{row[1]}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

const FinanceHubForm = (props: any) => {
  const {
    operationDetails,
    setFormData,
    formData,
    selectedRequest,
    setDocumentsToApply,
    subProduct,
    handleChange,
    documents,
    selected,
    setSelected,
    setDocuments,
    onEditing,
    documentsToApply,
    operationDate,
    formErrors,
    setFormErrors,
    availableArrearTables,
  } = props;

  const [openGuaranteePopper, setOpenGuaranteePopper] = useState(false);
  const [openArrearPopper, setOpenArrearPopper] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const gridStyle = {
    alignContent: 'center',
    alignItems: 'center',
    padding: '4px',
    paddingRight: 23,
  };

  const handleAmountToApply = (value: string) => {
    const newValue = Number(value.replace('$', '').replaceAll('.', ''));

    setFormData({
      ...formData,
      amountToApply: newValue,
    });
  };

  const maxAmount =
    operationDetails.operationAdvanced -
    operationDetails.operationCostComissionVariable -
    operationDetails.operationCostComissionFixed -
    operationDetails.expensesCost -
    operationDetails.operationCostRate -
    operationDetails.operationIva -
    operationDetails.expensesIva;

  useEffect(() => {
    if (
      (formErrors.guaranteeObservation &&
        formData.guaranteeAmount > 0 &&
        formData.guaranteeObservation) ||
      formData.guaranteeAmount <= 0
    ) {
      setFormErrors({ ...formErrors, guaranteeObservation: false });
    }
  }, [formData.guaranteeAmount, formData.guaranteeObservation]);

  return (
    <div
      style={{
        padding: '0px 0px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <Grid container style={gridStyle} columnSpacing={3} rowSpacing={1}>
        <Grid item xs={6}>
          <LightTooltip
            title={
              <Typography variant="body1">
                {selectedRequest.businessName}
              </Typography>
            }
          >
            <div>
              <div>
                <Typography>
                  <b>Cliente: </b>
                  {selectedRequest.businessIdentifier}
                </Typography>
              </div>
              <Typography>
                {selectedRequest.businessName.length > 40
                  ? `${selectedRequest.businessName.substring(0, 40)}...`
                  : selectedRequest.businessName}
              </Typography>
            </div>
          </LightTooltip>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            <b>Producto:</b>{' '}
          </Typography>
          <Typography>
            {subProduct ? subProduct.name : selectedRequest.subProductName}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={600}>Fecha operación:</Typography>
          <Typography>{formatDate(selectedRequest.requestDate)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography fontWeight={600}>Montos</Typography>
            <LightTooltip
              title={CustomTooltip([
                [
                  'Anticipo',
                  'Porcentaje a financiar respecto al total del valor de la operación.',
                ],
              ])}
            >
              <Info
                style={{
                  marginLeft: 6,
                  fontSize: 14,
                }}
              />
            </LightTooltip>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight={600}>Tasas</Typography>
            <LightTooltip
              title={CustomTooltip([
                [
                  'Interés',
                  'Interés aplicado a la operación. Se descuenta del monto a financiar como diferencia de precio.',
                ],
                [
                  'Interés mora',
                  'Interés aplicado por cada día de mora en incrementos diarios.',
                ],
              ])}
            >
              <Info style={{ marginLeft: 6, fontSize: 14 }} />
            </LightTooltip>
          </div>
        </Grid>
      </Grid>
      <Grid container style={gridStyle} columnSpacing={3} rowSpacing={1}>
        <Grid item xs={6}>
          <Typography variant="body1">Anticipo (%) </Typography>

          <TextField
            value={formData.advance}
            placeholder="100"
            type="number"
            disabled={!onEditing}
            fullWidth
            style={{ padding: '0px !important' }}
            onChange={(e) => handleChange('advance', e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1">Interés (%)</Typography>
          <TextField
            value={formData.rate}
            type="number"
            fullWidth
            disabled={!onEditing}
            placeholder="2.2"
            onChange={(e) => handleChange('rate', e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1">Interés mora (%)</Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              value={
                formData.arrearType === 'table' ? 'Tabla' : formData.arrearRate
              }
              type={formData.arrearType === 'table' ? 'text' : 'number'}
              fullWidth
              disabled={!onEditing || formData.arrearType === 'table'}
              placeholder="2.2"
              onChange={(e) => handleChange('arrearRate', e.target.value)}
              error={formErrors?.arrearTableId}
            />
            {availableArrearTables.length > 0 && (
              <>
                <IconButton
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setOpenArrearPopper(true);
                  }}
                  disabled={!onEditing}
                  color={formErrors?.arrearTableId ? 'error' : undefined}
                >
                  <KeyboardArrowDown />
                </IconButton>
                <ArrearPopper
                  openArrearPopper={openArrearPopper}
                  setOpenArrearPopper={setOpenArrearPopper}
                  anchorEl={anchorEl}
                  formData={formData}
                  handleChange={handleChange}
                  onEditing={onEditing}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  availableArrearTables={availableArrearTables}
                />
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight={600}>Descuentos</Typography>
            <LightTooltip
              title={CustomTooltip([
                [
                  'Monto en garantía',
                  'Monto reservado o en garantía que será liberado cuando el cliente cumpla con los requisitos de la operación.',
                ],
                [
                  'Documentos a aplicar',
                  'Documentos aplicados a la operación abonar al pago de otras facturas.',
                ],
              ])}
            >
              <Info style={{ marginLeft: 6, fontSize: 14 }} />
            </LightTooltip>
          </div>
        </Grid>
      </Grid>
      <Grid container style={gridStyle} columnSpacing={3} rowSpacing={1}>
        <Grid item xs={6}>
          <Typography variant="body1">Monto en garantía</Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              value={formatMoney(formData.guaranteeAmount)}
              type="text"
              disabled={!onEditing}
              fullWidth
              onChange={(e) => handleChange('guaranteeAmount', e.target.value)}
              error={formErrors?.guaranteeObservation}
            />
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setOpenGuaranteePopper(true);
              }}
              disabled={!onEditing || formData.guaranteeAmount <= 0}
              color={formErrors?.guaranteeObservation ? 'error' : undefined}
            >
              <Add />
            </IconButton>
            <GuaranteeObservationPopper
              openGuaranteePopper={openGuaranteePopper}
              setOpenGuaranteePopper={setOpenGuaranteePopper}
              anchorEl={anchorEl}
              formData={formData}
              handleChange={handleChange}
              onEditing={onEditing}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Documentos a aplicar ($)</Typography>
          <DocumentsSuggestedContainer
            businessIdentifier={selectedRequest.businessIdentifier}
            setAmountToApply={handleAmountToApply}
            amountToApply={formData.amountToApply}
            maxAmount={maxAmount}
            selected={selected}
            setSelected={setSelected}
            documents={documents}
            setDocuments={setDocuments}
            setDocumentsToApply={setDocumentsToApply}
            addIconPlacement="end"
            disabled={!onEditing}
            documentsToApply={documentsToApply}
            operationDate={operationDate}
          />
        </Grid>
      </Grid>
      <Grid container style={gridStyle} columnSpacing={3} rowSpacing={1}>
        <Grid item xs={6}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <Typography variant="body1" fontWeight={600}>
              Comisión operación
            </Typography>
            <LightTooltip
              title={CustomTooltip([
                [
                  'Fijo',
                  'Monto fijo a cobrar por la operación. Reducirá el monto a financiar',
                ],
                [
                  'Variable',
                  'Porcentaje a cobrar por la operación. Reducirá el monto a financiar',
                ],
              ])}
            >
              <Info style={{ marginLeft: 6, fontSize: 14 }} />
            </LightTooltip>
          </div>
          <Grid container columnSpacing={3}>
            <Grid item xs={6}>
              <Typography variant="body1">Fijo ($)</Typography>
              <TextField
                value={formatMoney(formData.operationCostComissionFixed)}
                type="text"
                disabled={!onEditing}
                fullWidth
                onChange={(e) =>
                  handleChange('operationCostComissionFixed', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Variable (%)</Typography>
              <TextField
                value={formData.operationCostComissionVariablePercentage}
                type="number"
                fullWidth
                disabled={!onEditing}
                onChange={(e) =>
                  handleChange('operationCostComissionVariable', e.target.value)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <Typography fontWeight={600}>Otros gastos</Typography>
            <LightTooltip
              title={CustomTooltip([
                [
                  'Fijo',
                  'Valor de cualquier otro gasto en el que se incurra en la operación',
                ],
              ])}
            >
              <Info style={{ marginLeft: 6, fontSize: 14 }} />
            </LightTooltip>
          </div>
          <Typography variant="body1">Fijo ($)</Typography>
          <TextField
            value={formatMoney(formData.expensesCost)}
            type="text"
            disabled={!onEditing}
            fullWidth
            onChange={(e) => handleChange('expensesCost', e.target.value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FinanceHubForm;
