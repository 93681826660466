import React from 'react';
import { Grid, Typography } from '@mui/material';
import CustomTimeline from '../../../../../../../../../../lib/designSystem/CustomTimeline';

interface PreviewProps {
  timelineItemsCreation: any[];
  timelineItemsDue: any[];
}

const Preview: React.FC<PreviewProps> = (props) => {
  const { timelineItemsCreation, timelineItemsDue } = props;
  const showCreationTimeline = timelineItemsCreation.length > 1;
  const showDueTimeline = timelineItemsDue.length > 1;
  const creationXs = showCreationTimeline && showDueTimeline ? 6 : 12;
  const dueXs = showCreationTimeline && showDueTimeline ? 6 : 12;
  return (
    <div
      style={{
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Typography variant="subtitle1" sx={{ marginLeft: '10px' }}>
        Previsualización
      </Typography>
      <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
        <Grid container>
          <Grid item xs={creationXs}>
            {showCreationTimeline && (
              <CustomTimeline items={timelineItemsCreation} />
            )}
          </Grid>
          <Grid item xs={dueXs}>
            {showDueTimeline && <CustomTimeline items={timelineItemsDue} />}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Preview;
