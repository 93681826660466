// LoginForm.tsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import Logo from '../../../../assets/img/pruff9.svg';
import { authenticateUser } from '../../../../lib/api';
import { authenticate } from '../../../../services/redux/authSlice';
import { DATA_VERSION } from '../../../../App';
import { TextField, Typography } from '@mui/material';
import CustomButton from '../../../../lib/designSystem/Button';

const LoginForm = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoding] = useState(false);

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setLoding(true);
    const response = await authenticateUser(username, password);
    console.log(response);
    if (response.success) {
      if (!response.active) {
        alert(
          'Debes activar tu cuenta con el link que se te envió al email. Si no has recibido el link, comunicate con soporte.',
        );
        return setLoding(false);
      }
      console.log(response);
      const userDetails = {
        email: response.email,
        name: response.name,
        companyName: response.companyName,
        companyId: response.companyId,
        permissionPolicy: response.permissionPolicy,
        id: response.id,
        roles: response.roles,
        companyTypes: response.companyTypes,
        dataVersion: DATA_VERSION,
        intercomHash: response.intercomHash,
      };
      dispatch(authenticate(userDetails));
      // window.location.href = ('/last-invoices');
    } else {
      alert('Credenciales inválidas');
    }
    setLoding(false);
  };

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={0}
          md={5}
          style={{
            background: 'linear-gradient(to bottom, #0F23DF, #0F23DF, #0F23DF)',
            opacity: 0.8,
            height: '100vh',
            display: 'flex',
          }}
        >
          {/* Content goes here */}
        </Grid>
        <Grid item xs={12} md={7}>
          {loading ? (
            <CenteredCircularProgress type="login" />
          ) : (
            <div style={{ width: '50%', margin: 'auto', textAlign: 'center' }}>
              <div style={{ padding: '120px 15px 40px' }}>
                <img width={230} src={Logo} />
              </div>
              <TextField
                margin="normal"
                fullWidth
                label="Email"
                variant="outlined"
                id="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                label="Contraseña"
                fullWidth
                margin="dense"
                type="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                autoComplete="new-password"
              />
              <div style={{ margin: '20px 0' }}>
                <CustomButton
                  onClick={(e) => handleSubmit(e)}
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  Entrar
                </CustomButton>
                <Box sx={{ mt: 2 }} display="flex">
                  <Typography>
                    <a
                      style={{ textDecoration: 'none' }}
                      href={`/password-recovery`}
                    >
                      Olvidé mi constraseña{' '}
                    </a>
                  </Typography>
                </Box>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginForm;
