import React, { useState, useEffect } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../lib/designSystem/HeaderWithActions';
import { getFinancialTrees } from '../../../../../lib/api/finnacialStatements';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { Collapse, Typography } from '@mui/material';
import EditNode from './components/editNode';
import CustomAlert from '../../../../../lib/designSystem/Alert';

const AccountsSettings = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState<string>('');
  const [treeData, setTreeData] = useState<any>([]);
  const [selectedNode, setSelectedNode] = useState<any>({});
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [newNode, setNewNode] = useState(false);

  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const activateSuccess = (message: string) => {
    setSuccessMessage(message);
    setSuccess(true);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedNode((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const fetchSetupData = async () => {
    setLoading(true);
    const financialTreesResponse = await getFinancialTrees(dispatch);
    if (financialTreesResponse.length) {
      setExpanded(financialTreesResponse[0].id);
    }
    setTreeData(financialTreesResponse);
    setLoading(false);
  };

  useEffect(() => {
    fetchSetupData();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  const handleEdit = (id: number) => {
    const selectedFather = treeData.find((x: any) => x.id === expanded);
    const node = selectedFather.financialStatementsTreeNodes.find(
      (x: any) => x.id === id,
    );
    console.log(node);
    if (!node) return alert('Ha ocurrido un error');

    setSelectedNode(node);
    setOpenEditDialog(true);
  };
  const handleAdd = () => {
    setSelectedNode({});
    setNewNode(true);
    setOpenEditDialog(true);
  };

  const actions: any = [{ action: handleEdit, actionLabel: 'Editar' }];

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => handleAdd(),
      name: 'Agregar cuenta',
      color: 'primary',
      disabled: !treeData.length,
    },
  ];

  const titleButtons = treeData.map((treeKey: any) => ({
    name: treeKey.name,
    action: () => setExpanded(treeKey.id),
    color: 'secondary',
    disabled: expanded === treeKey.id,
  }));

  return (
    <div>
      <HeaderWithActions
        buttons={buttonsData}
        type="subHeader"
        titleButtons={titleButtons}
      />
      {!treeData.length && (
        <Typography variant="h6">No hay ninguna estructura creada</Typography>
      )}
      {treeData.map((treeKey: any) => (
        <div key={treeKey.id} style={{ width: '100%' }}>
          <Collapse in={expanded === treeKey.id}>
            <EnhancedTable
              dataHeaders={[
                { key: 'key', label: 'Cuenta' },
                { key: 'fatherKey', label: 'Padre' },
              ]}
              showActions
              actions={actions}
              data={
                treeData.find((x: any) => x.id === treeKey.id)
                  .financialStatementsTreeNodes
              }
              rowsPerPageDefault={25}
            />
          </Collapse>
        </div>
      ))}
      <EditNode
        setLoading={setLoading}
        dispatch={dispatch}
        activateSuccess={activateSuccess}
        setFailed={setFailed}
        setOpenEditDialog={setOpenEditDialog}
        openEditDialog={openEditDialog}
        fetchSetupData={fetchSetupData}
        handleInputChange={handleInputChange}
        selectedNode={selectedNode}
        fatherOptions={
          treeData.find(
            (x: any) => x.id === selectedNode.financialStatementsTreeTypeId,
          )?.financialStatementsTreeNodes || []
        }
        open={openEditDialog}
        treeData={treeData}
        newNode={newNode}
        setNewNode={setNewNode}
      />
      <CustomAlert
        setOpen={setSuccess}
        open={success}
        title="Exito"
        text={successMessage}
        severity="success"
      />
      <CustomAlert
        setOpen={setFailed}
        open={failed}
        title="Error"
        text={'Oops hubo un error'}
        severity="error"
      />
    </div>
  );
};

export default AccountsSettings;
