import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import {
  createFundPayments,
  updateFundPayment,
} from '../../../../../../../../../../../lib/api';
import HeaderWithActions from '../../../../../../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';
import {
  formatMoney,
  formatMoneyBack,
} from '../../../../../../../../../../../lib/functions/utils';

const FundPayment = (props: any) => {
  const {
    open,
    resell,
    setOpen,
    setSuccess,
    setUp,
    fundId,
    edit,
    fundPayment,
    setFundPayment,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const validParams = () => {
    if (!fundPayment.amount || !fundPayment.date) {
      alert('Debes completar todos los campos');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validParams()) {
      return;
    }
    setLoading(true);
    try {
      const payments = [
        {
          factoringResellId: resell.id,
          businessIdentifier: resell.document.businessIdentifier,
          documentId: resell.document.id,
          date: new Date(fundPayment.date),
          amount: fundPayment.amount,
          fundId: resell.fundId,
        },
      ];
      if (edit) {
        await updateFundPayment(dispatch, {
          id: fundId,
          paymentId: fundPayment.id,
          payment: payments[0],
        });
      } else {
        const newPayload = {
          payments,
        };
        await createFundPayments(dispatch, newPayload);
      }
      setOpen(false);
      setSuccess('Pago guardado correctamente');
      await setUp();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
      >
        <HeaderWithActions
          title={edit ? `Editar pago` : `Nuevo pago`}
          type="dialog"
        />
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <DialogContent>
            <Typography variant="body1">Ingrese los datos del pago</Typography>
            <TextField
              label="Monto"
              fullWidth
              margin="dense"
              value={formatMoney(fundPayment.amount)}
              onChange={(e) => {
                setFundPayment({
                  ...fundPayment,
                  amount: formatMoneyBack(e.target.value),
                });
              }}
            />
            <TextField
              label="fecha"
              fullWidth
              type="date"
              margin="dense"
              value={fundPayment.date}
              onChange={(e) => {
                setFundPayment({
                  ...fundPayment,
                  date: e.target.value,
                });
              }}
            />
          </DialogContent>
        )}
        <DialogActions>
          <CustomButton onClick={() => setOpen(false)} color="secondary">
            Salir
          </CustomButton>
          <CustomButton onClick={() => handleSubmit()} color="primary">
            Guardar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FundPayment;
