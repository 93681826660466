import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../lib/designSystem/Table';
import {
  deleteBusinessContact,
  getAllCompanyContacts,
  selectContact,
} from '../../../../lib/api';
import { useDispatch } from 'react-redux';
import CustomAlert from '../../../../lib/designSystem/Alert';
import NewContact from './components/NewContact';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';

const headers = [
  { key: 'name', label: 'Nombre contacto' },
  { key: 'email', label: 'Folio' },
  { key: 'phone', label: 'Teléfono' },
  { key: 'role', label: 'Fecha de emisión' },
  { key: 'businessName', label: 'Empresa' },
  { key: 'selected', label: 'Principal', type: 'boolean-active-null' },
];

const emptyContact = {
  name: '',
  email: '',
  role: '',
  phone: '',
};

const Main: React.FC = () => {
  const dispatch = useDispatch();
  // get invoices from /api/internal/collection/invoices/:company_id
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [contact, setContact] = useState(emptyContact);
  const [contacts, setContacts] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');

  const setSuccessOpen = (message: string) => {
    setSuccessMessage(message);
    setOpenSuccess(true);
  };

  const setFailureOpen = (message: string) => {
    setFailureMessage(message);
    setOpenFailure(true);
  };

  const hanldeNewContact = async () => {
    setContact(emptyContact);
    setOpenModal(true);
  };

  const handleEdit = (id: number) => {
    const contact = contacts.find((x: any) => x.id === id);
    if (!contact) return alert('Ha ocurrido un error');
    setContact(contact);
    setOpenModal(true);
  };

  const handleDelete = async (id: number) => {
    const contact: any = contacts.find((x: any) => x.id === id);
    setLoading(true);
    try {
      const response = await deleteBusinessContact(
        dispatch,
        contact.business.id,
        {
          contactId: contact.id,
        },
      );
      if (response) {
        setOpenSuccess(true);
        setSuccessOpen('Contacto eliminado con éxito');
        await setUp();
      }
    } catch (error) {
      setFailureOpen('Ha ocurrido un error');
    }
    setLoading(false);
  };

  const handleSelectMain = async (id: number) => {
    const contact: any = contacts.find((x: any) => x.id === id);
    if (!contact) return alert('Ha ocurrido un error');
    try {
      const response = await selectContact(
        dispatch,
        contact?.business.identifier,
        {
          id: contact.business.identifier,
          contactId: contact.id,
          main: true,
        },
      );
      console.log(response);
      if (response) {
        setSuccessOpen('Contacto principal seleccionado con éxito');
        setUp();
      } else {
        setFailureOpen('Ha ocurrido un error');
      }
    } catch (error) {
      console.log(error);
      setFailureOpen('Ha ocurrido un error');
    }
  };

  const actions = [
    {
      action: handleEdit,
      actionLabel: 'Editar',
    },
    {
      action: handleSelectMain,
      actionLabel: 'Seleccionar como principal',
    },
    {
      action: handleDelete,
      actionLabel: 'Eliminar contacto',
    },
  ];

  const buttons: CustomButtonProps[] = [
    {
      name: 'Nuevo contacto',
      action: () => hanldeNewContact(),
      color: 'primary',
    },
  ];

  const filters = [
    {
      key: 'businessName',
      label: 'Empresa',
      options: [...new Set(contacts.map((x: any) => x.businessName))],
    },
  ];

  const setUp = async () => {
    try {
      const contactsResponse = await getAllCompanyContacts(dispatch);
      console.log(contactsResponse);
      setContacts(contactsResponse);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions type="subHeader" variant="body1" />
      <EnhancedTable
        dataHeaders={headers}
        caption="Esta es toda tu red de contactos"
        data={contacts}
        showActions
        rowsPerPageDefault={50}
        actions={actions}
        openFilters={true}
        filters={filters}
        lastElement
      />
      <NewContact
        open={openModal}
        setOpen={setOpenModal}
        contact={contact}
        setContact={setContact}
        setOpenAlert={setOpenAlert}
        setSuccessOpen={setSuccessOpen}
        setFailureOpen={setFailureOpen}
        setUp={setUp}
      />
      <CustomAlert
        open={openAlert}
        title="Gestión guardada con éxito"
        onClose={() => {
          setOpenAlert(false);
        }}
        setOpen={setOpenAlert}
        text=""
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={openSuccess}
        setOpen={setOpenSuccess}
        severity="success"
        text={successMessage}
        handleClose={() => setOpenSuccess(false)}
      />
      <CustomAlert
        open={openFailure}
        setOpen={setOpenFailure}
        severity="error"
        text={failureMessage}
        handleClose={() => setOpenFailure(false)}
      />
    </div>
  );
};

export default Main;
