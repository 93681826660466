import React, { useState, useEffect } from 'react';
import { getFinancialStatementsStats } from '../../../../../lib/api/finnacialStatements';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import { Grid, Typography } from '@mui/material';
import EnhacedBox from '../../../../../lib/designSystem/Box';
import SimpleBarChart from '../../../../../lib/designSystem/BarChart';
import CustomPieChart from '../../../../../lib/designSystem/PieChart';
import { useDispatch } from 'react-redux';

const UsagesSummary = () => {
  const [loading, setLoading] = useState(false);

  const [statsObject, setStatsObject] = useState<any>({});
  const dispatch = useDispatch();

  const fetchSetupData = async () => {
    setLoading(true);
    try {
      const statsResponse = await getFinancialStatementsStats(dispatch);
      console.log(statsResponse);
      setStatsObject(statsResponse);
    } catch (error) {
      alert('error en usos');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSetupData();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  if (!statsObject.haveBalances)
    return (
      <div style={{ paddingTop: 10 }}>
        <Typography variant="body1">No hay balances para mostrar</Typography>
      </div>
    );

  return (
    <div style={{ paddingTop: 10 }}>
      <Grid container spacing={2} rowSpacing={3}>
        <Grid item xs={8}>
          <EnhacedBox>
            <Typography style={{ paddingBottom: 8 }}>Facturados</Typography>
            <SimpleBarChart
              aspect={4.0 / 1.15}
              xName="Analizados"
              xKey="ASSIGNATED"
              noMoney
              data={
                statsObject.historic?.statusFinancialStatementByMonthArray || []
              }
            />
          </EnhacedBox>
        </Grid>
        <Grid item xs={4}>
          {statsObject?.statusFinancialStatementArray && (
            <EnhacedBox>
              <div style={{ minHeight: '5%' }}>
                <Typography style={{ paddingBottom: 8 }}>
                  Estado actual
                </Typography>
                <div style={{ padding: '20px 0px' }}>
                  <CustomPieChart
                    data={statsObject?.statusFinancialStatementArray}
                  />
                </div>
              </div>
            </EnhacedBox>
          )}
        </Grid>
        <Grid item xs={6}>
          <EnhacedBox>
            <Typography style={{ paddingBottom: 8 }}>
              Tiempo en asignar promedio
            </Typography>
            <SimpleBarChart
              aspect={4.0 / 1.15}
              noMoney
              data={statsObject.timeCreatedToAssignedPerUserArray || []}
            />
          </EnhacedBox>
        </Grid>
        <Grid item xs={6}>
          <EnhacedBox>
            <Typography style={{ paddingBottom: 8 }}>
              Asignados histórico por usuario
            </Typography>
            <SimpleBarChart
              aspect={4.0 / 1.15}
              noMoney
              data={
                statsObject.historic?.assignedFinancialStatementByUserArray ||
                []
              }
            />
          </EnhacedBox>
        </Grid>
      </Grid>
    </div>
  );
};

export default UsagesSummary;
