import React from 'react';
import { Box } from '@mui/material';

const ColoredCircle: React.FC<{ color: string; size?: number }> = ({
  color,
  size = 50, // Default size
}) => {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        backgroundColor: color,
        borderRadius: '50%',
        display: 'inline-block',
      }}
    />
  );
};

export default ColoredCircle;
