import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import CustomPieChart from '../PieChart';
import CustomSwitch from '../../../../../../../../lib/designSystem/CustomSwitch';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import { useDispatch } from 'react-redux';
import { getDocumentsChartsData } from '../../../../../../../../lib/api/collections';
import { loggerDev } from '../../../../../../../../lib/functions/utils';

const rainbowPalette = [
  '#C04040', // Muted Red
  '#D88030', // Warm Orange
  '#D4B830', // Soft Yellow
  '#14BA25', // Your Green
  '#3080D8', // Gentle Blue
  '#6040A0', // Subtle Indigo
  '#9050D8', // Soft Violet
];

const boxStyle = {
  height: '300px',
  paddingTop: '10px',
};

const DocumentsIndicators = (props: any) => {
  const { openIndicators, setOpenIndicators } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [noManagementsChartData, setNoManagementsChartData] = useState([]);
  const [noManagementsSwitch, setNoManagementsSwitch] = useState('count');
  const [managementsChartData, setManagementsChartData] = useState([]);
  const [managementsSwitch, setManagementsSwitch] = useState('count');

  const setUp = async () => {
    setLoading(true);
    try {
      const response = await getDocumentsChartsData(dispatch);
      setManagementsChartData(response.managementsChart);
      setNoManagementsChartData(response.noManagementsChart);
    } catch (error) {
      loggerDev(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  return (
    <Dialog
      open={openIndicators}
      onClose={() => setOpenIndicators(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Indicadores</DialogTitle>
      {loading && (
        <div style={{ height: 300 }}>
          <CenteredCircularProgress type="dialog" />
        </div>
      )}
      {!loading && (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div style={boxStyle}>
                <Typography
                  variant="body1"
                  textAlign={'center'}
                  fontWeight={'bold'}
                >
                  {noManagementsSwitch === 'count' ? 'Documentos' : 'Monto'} sin
                  gestion
                </Typography>
                <Typography variant="body2" textAlign={'center'}>
                  por ejecutivo
                </Typography>
                <div style={{ padding: '6px 0px' }}>
                  <CustomPieChart
                    data={noManagementsChartData}
                    money={noManagementsSwitch === 'amount'}
                    colors={rainbowPalette}
                    valueKey={
                      noManagementsSwitch === 'amount' ? 'amount' : 'count'
                    }
                  />
                </div>
                <CustomSwitch
                  leftText="Documentos"
                  rightText="Monto"
                  onChange={() => {
                    setNoManagementsSwitch(
                      noManagementsSwitch === 'count' ? 'amount' : 'count',
                    );
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={boxStyle}>
                <Typography
                  variant="body1"
                  textAlign={'center'}
                  fontWeight={'bold'}
                >
                  {managementsSwitch === 'count' ? 'Documentos' : 'Monto'} con
                  gestion
                </Typography>
                <Typography variant="body2" textAlign={'center'}>
                  por ejecutivo
                </Typography>
                <div style={{ padding: '6px 0px' }}>
                  <CustomPieChart
                    data={managementsChartData}
                    money={managementsSwitch === 'amount'}
                    colors={rainbowPalette}
                    valueKey={
                      managementsSwitch === 'amount' ? 'amount' : 'count'
                    }
                  />
                </div>
                <CustomSwitch
                  leftText="Documentos"
                  rightText="Monto"
                  onChange={() => {
                    setManagementsSwitch(
                      managementsSwitch === 'count' ? 'amount' : 'count',
                    );
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <CustomButton
          onClick={() => setOpenIndicators(false)}
          variant="contained"
          color="secondary"
        >
          Cerrar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentsIndicators;
