import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { exportFactoringCreditLine } from '../../../../../../../lib/api/creditLine';
import { useState } from 'react';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import { useDispatch } from 'react-redux';
const ExportDialog = (props: any) => {
  const dispatch = useDispatch();
  const { openExportDialog, setOpenExportDialog, openRequest } = props;
  const [exportMainInfo, setExportMainInfo] = useState(true);
  const [exportRisks, setExportRisks] = useState(true);
  const [datahub, setDatahub] = useState(true);
  const [loading, setLoading] = useState(false);

  const exportCreditLine = async () => {
    try {
      setLoading(true);
      const query = `mainInfo=${exportMainInfo}&risks=${exportRisks}&datahub=${datahub}`;
      const response = await exportFactoringCreditLine(
        dispatch,
        openRequest.id,
        query,
      );
      window.open(response.fileUrl, '_blank');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <Dialog open={openExportDialog} onClose={() => setOpenExportDialog(false)}>
      <DialogTitle>Exportar línea de crédito</DialogTitle>
      <DialogContent style={{ minHeight: '100px' }}>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <div>
            <Typography>
              Selecciona que información quieres incluir en el archivo PDF.
            </Typography>
            <div style={{ marginBottom: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportMainInfo}
                    onChange={(e) => setExportMainInfo(e.target.checked)}
                  />
                }
                label="Línea de crédito"
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportRisks}
                    onChange={(e) => setExportRisks(e.target.checked)}
                  />
                }
                label="Evaluación de riesgos"
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={datahub}
                    onChange={(e) => setDatahub(e.target.checked)}
                  />
                }
                label="Datahub"
              />
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={() => setOpenExportDialog(false)}
          color="secondary"
        >
          Cancelar
        </CustomButton>
        <CustomButton onClick={exportCreditLine}>Exportar</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
