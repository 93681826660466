import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Typography,
  TextField,
} from '@mui/material';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import CustomButton from '../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import {
  loggerDev,
  validaRut,
  rutChanger,
  showAlert,
} from '../../../../../../lib/functions/utils';
import {
  createUsersBusinessesPortfolio,
  deleteUserBusinessPortfolio,
  getUserCollectionPortfolio,
} from '../../../../../../lib/api';

export interface Business {
  id: string;
  businessName: string;
  identifier: string;
}

const CollectionPortfolioDialog = ({
  handleCloseDialog,
  openCollectionPortfolioDialog,
  selectedUser,
}: any) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [businessIdToAdd, setBusinessIdToAdd] = useState('');
  const [businessIdsToAdd, setBusinessIdsToAdd] = React.useState<string[]>([]);
  const [openAddBusiness, setOpenAddBusiness] = useState(false);
  const [userPortfolio, setUserPortfolio] = useState<Business[]>([]);

  const setUpPortfolio = async () => {
    setLoading(true);
    try {
      const businesses = await getUserCollectionPortfolio(
        dispatch,
        selectedUser.id,
      );
      setUserPortfolio(businesses);
    } catch (error) {
      loggerDev(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpPortfolio();
  }, []);

  const handleOpenAddBusiness = async () => {
    setOpenAddBusiness(true);
    setBusinessIdToAdd('');
    setBusinessIdsToAdd([]);
  };

  const handleAddBusiness = async () => {
    if (
      businessIdsToAdd.includes(businessIdToAdd) ||
      userPortfolio.some((x: Business) => x.identifier === businessIdToAdd)
    ) {
      return showAlert(dispatch, 'error', 'La empresa ya está en la cartera');
    }
    if (!validaRut(businessIdToAdd)) {
      return showAlert(dispatch, 'error', 'Rut inválido');
    }
    setBusinessIdsToAdd([...businessIdsToAdd, businessIdToAdd]);
    setBusinessIdToAdd('');
  };

  const handleCreateResponse = (response: any) => {
    if (response.success) {
      return showAlert(
        dispatch,
        'success',
        'Se ha/n agregado la/s asociación/es',
      );
    }
    const message = response.message
      ? `${response.message}\n${response?.businessIdentifiers?.join('\n')}\nPuedes agregar las empresas que falten en la sección 'Mi Red'.`
      : 'Ha ocurrido un error';
    showAlert(dispatch, response.message ? 'warning' : 'error', message);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await createUsersBusinessesPortfolio(
        dispatch,
        selectedUser.id,
        {
          businessIdentifiers: businessIdsToAdd,
        },
      );
      handleCreateResponse(response);
    } catch (error) {
      showAlert(dispatch, 'error', 'Ha ocurrido un error');
    }
    await closeAdding();
    setLoading(false);
  };

  const handleDeleteRelation = async (id: string) => {
    setLoading(true);
    if (!userPortfolio.some((x: Business) => x.id === id)) {
      setLoading(false);
      return showAlert(dispatch, 'error', 'Ha ocurrido un error');
    }
    await deleteUserBusinessPortfolio(dispatch, selectedUser.id, {
      businessIdentifier: id,
    });
    showAlert(dispatch, 'success', 'Se ha eliminado la asociación');
    await closeAdding();
    setBusinessIdsToAdd([]);
    setLoading(false);
  };

  const closeAdding = async () => {
    setOpenAddBusiness(false);
    setBusinessIdToAdd('');
    setBusinessIdsToAdd([]);
    await setUpPortfolio();
  };

  const actionsUserRelations: any[] = [
    { action: handleDeleteRelation, actionLabel: 'Desasociar' },
  ];

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => handleOpenAddBusiness(),
      name: 'Asociar Empresa',
      color: 'primary',
    },
  ];

  if (loading)
    return (
      <Dialog
        fullWidth
        open={openCollectionPortfolioDialog}
        onClose={() => handleCloseDialog()}
      >
        <CenteredCircularProgress type="dialog" />
      </Dialog>
    );

  return (
    <div>
      <Dialog
        fullWidth
        open={openCollectionPortfolioDialog}
        onClose={() => handleCloseDialog()}
      >
        <div>
          <DialogContent>
            <HeaderWithActions
              type="subHeader"
              buttons={!openAddBusiness ? buttonsData : []}
              title="Cartera de cobranza"
            />
            {!openAddBusiness ? (
              <>
                <Typography margin="8px 0px">
                  Aqui puedes ver la cartera de cobranza de {selectedUser.name}{' '}
                  ({selectedUser.email})
                </Typography>
                <EnhancedTable
                  dataHeaders={[
                    { key: 'name', label: 'Empresa' },
                    { key: 'identifier', label: 'Rut' },
                  ]}
                  data={userPortfolio}
                  showActions={true}
                  actions={actionsUserRelations}
                />
              </>
            ) : (
              <>
                <FormControl fullWidth>
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      marginBottom: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      id="identifier"
                      label="Rut"
                      fullWidth
                      value={businessIdToAdd}
                      onChange={(e) =>
                        rutChanger(e.target.value, setBusinessIdToAdd)
                      }
                      variant="outlined"
                    />
                    <div>
                      <CustomButton onClick={handleAddBusiness} color="primary">
                        Agregar
                      </CustomButton>
                    </div>
                  </div>
                  <EnhancedTable
                    dataHeaders={[{ key: 'identifier', label: 'Nuevos Rut' }]}
                    data={businessIdsToAdd.map((x: string) => ({
                      identifier: x,
                    }))}
                    hideTablePagination
                    rowsPerPageDefault={100}
                  />
                </FormControl>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={() => handleCloseDialog()} color="secondary">
              Salir
            </CustomButton>
            {openAddBusiness && (
              <div style={{ display: 'flex', gap: '10px' }}>
                <CustomButton onClick={closeAdding} color="secondary">
                  Cancelar
                </CustomButton>
                <CustomButton
                  color="primary"
                  disabled={businessIdsToAdd.length === 0}
                  onClick={handleSave}
                >
                  Guardar
                </CustomButton>
              </div>
            )}
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default CollectionPortfolioDialog;
