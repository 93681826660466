import jsPDF from 'jspdf';
import { formatDate, formatMoney } from '../../../../../../lib/functions/utils';
import { Cursor } from '../../../../../../lib/classes/jsPdfCursor';

interface PayIn {
  id: number;
  amount: number;
  amountConciliated: number;
  status: string;
  statusTranslated: string;
  bankAccountDestinationName: string;
  bankAccountDestinationId: number;
  businessDestinationName: string;
  businessDestinationIdentifier: string;
  bankAccountOriginName: string;
  bankAccountOriginId: number;
  businessOriginName: string;
  businessOriginIdentifier: string;
  createdAt: string;
}

interface Business {
  id: number;
  name: string;
  identifier: string;
  startDate: string | null;
  segment: string | null;
}

interface Stakeholder {
  id: number;
  name: string;
  identifier: string;
  startDate: string | null;
  segment: string | null;
}

interface Document {
  [key: string]: any; // Add index signature
  payInId: number;
  payInConciliationId: number;
  payInDate: string; // ISO date string
  documentId: string;
  type: string;
  typeEsp: string;
  folio: string;
  businessIdentifier: string;
  stakeholderIdentifier: string;
  businessName: string;
  stakeholderName: string;
  amount: number;
  paidAt: string; // ISO date string
  nulledAt: string | null;
  fatherDocumentId: string | null;
  pendingAccountId: number;
  business: Business;
  stakeholder: Stakeholder;
  productType: 'factoring' | 'credit' | 'invoice';
  amountConciliated: number;
  amountConciliatedByFactoring: number;
  amountConciliatedByPayIns: number;
  amountConciliatedByFunds: number;
  amountConciliatedByPassives: number;
  amountConciliatedByDiscounts: number;
  amountConciliatedByCreditNotes: number;
  extensions: any[];
  documentAmountDebtBefore: number;
  arrearAmountDebtBefore: number;
  documentAmountDebtAfter: number;
  arrearAmountDebtAfter: number;
  documentAmountConciliated: number;
  arrearAmountConciliated: number;
}

const productTypeMap = {
  factoring: 'Factoring',
  credit: 'Credito',
  invoice: 'Factura',
};

function getShortString(string: string, maxLength: number = 25) {
  return string.length > maxLength
    ? string.substring(0, maxLength) + '...'
    : string;
}

function reduceDocumentsByProperty(
  documents: Document[],
  property: string,
  keep: 'highest' | 'lowest' | 'all' = 'all',
) {
  // Group documents by folio
  const groupedByFolio = documents.reduce(
    (acc, doc) => {
      if (!acc[doc.folio]) {
        acc[doc.folio] = [];
      }
      acc[doc.folio].push(doc);
      return acc;
    },
    {} as Record<string, Document[]>,
  );

  // Process each group according to the keep parameter
  return Object.values(groupedByFolio).reduce((total, docs) => {
    if (keep === 'all') {
      return total + docs.reduce((sum, doc) => sum + doc[property], 0);
    }

    const value = docs.reduce(
      (result, doc) => {
        const currentValue = doc[property];
        return keep === 'highest'
          ? Math.max(result, currentValue)
          : Math.min(result, currentValue);
      },
      keep === 'highest' ? -Infinity : Infinity,
    );

    return total + value;
  }, 0);
}

export const getImageDimensionsFromBase64 = (
  base64: string,
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.src = base64;
  });
};

export const generatePayInReportPDF = async (
  payIn: PayIn,
  documents: Document[],
  fncConciliations: any[],
  userName: string,
  imageBase64: string,
) => {
  const doc = new jsPDF();

  const addTitle = async () => {
    // Add the image
    const imgX = 10; // X position for the image
    const imgY = 5; // Y position for the image
    const imgHeight = 15; // Height of the image

    const { width: naturalWidth, height: naturalHeight } =
      await getImageDimensionsFromBase64(imageBase64);
    const aspectRatio = naturalWidth / naturalHeight;
    const imgWidth = imgHeight * aspectRatio;

    // Load and add the image
    doc.addImage(imageBase64 as any, 'PNG', imgX, imgY, imgWidth, imgHeight);

    // Add the title text
    doc.setFontSize(16);
    doc.text('Detalle Conciliaciones Pago', 100, 15, { align: 'center' });

    // Add the date and user
    doc.setFontSize(10);
    doc.text(`Fecha: ${new Date().toLocaleString()}`, 160, 10);
    doc.text(`Usuario: ${userName}`, 160, 15);
  };

  const addSummary = (c: Cursor) => {
    c.writeLine('Rut origen', true, 'col11');
    c.writeLine(`: ${payIn.businessOriginIdentifier}`, false, 'col12');
    c.writeLine('Rut destino', true, 'col21');
    c.writeLineAndNewLine(
      `: ${payIn.businessDestinationIdentifier}`,
      false,
      'col22',
    );

    c.writeLine('Nombre origen', true, 'col11');
    c.writeLine(`: ${payIn.businessOriginName}`, false, 'col12');
    c.writeLine('Nombre destino', true, 'col21');
    c.writeLineAndNewLine(
      `: ${getShortString(payIn.businessDestinationName)}`,
      false,
      'col22',
    );

    c.writeLine('Banco origen', true, 'col11');
    c.writeLine(
      `: ${getShortString(payIn.bankAccountOriginName)}`,
      false,
      'col12',
    );
    c.writeLine('Banco destino', true, 'col21');
    c.writeLineAndNewLine(
      `: ${getShortString(payIn.bankAccountDestinationName)}`,
      false,
      'col22',
    );

    c.newLine();
    c.writeTitle('Resumen operación');

    c.writeLine('Fecha pago', true, 'col11');
    c.writeLine(`: ${formatDate(new Date(payIn.createdAt))}`, false, 'col12');
    c.writeLine('Estado', true, 'col21');
    c.writeLineAndNewLine(`: ${payIn.statusTranslated}`, false, 'col22');

    c.writeLine('Monto pago', true, 'col11');
    c.writeLine(`: ${formatMoney(payIn.amount)}`, false, 'col12');
    c.writeLine('Monto conciliado', true, 'col21');
    c.writeLineAndNewLine(
      `: ${formatMoney(payIn.amountConciliated)}`,
      false,
      'col22',
    );

    c.newLine();
    c.writeTitle('Resumen totales');

    const totalConciliated = documents.reduce(
      (acc, document) => acc + document.amountConciliated,
      0,
    );
    const totalDocumentAmountConciliated = documents.reduce(
      (acc, document) => acc + document.documentAmountConciliated,
      0,
    );
    const totalArrearAmountConciliated = documents.reduce(
      (acc, document) => acc + document.arrearAmountConciliated,
      0,
    );

    c.writeLine('Conciliado', true, 'col11');
    c.writeLine(`: ${formatMoney(totalConciliated)}`, false, 'col12');
    c.writeLine('Documentos', true, 'col21');
    c.writeLineAndNewLine(
      `: ${formatMoney(totalDocumentAmountConciliated)}`,
      false,
      'col22',
    );

    c.writeLine('Pendiente', true, 'col11');
    c.writeLine(
      `: ${formatMoney(payIn.amount - totalConciliated)}`,
      false,
      'col12',
    );
    c.writeLine('Mora', true, 'col21');
    c.writeLineAndNewLine(
      `: ${formatMoney(totalArrearAmountConciliated)}`,
      false,
      'col22',
    );

    c.newLine();
    c.doc.setFontSize(7);
    c.writeLineAndNewLine(
      `* Los valores en las siguientes tablas son calculados respecto a la fecha de pago (${formatDate(new Date(payIn.createdAt))}).`,
      true,
      'col11',
    );
    c.doc.setFontSize(c.textFontSize);
  };

  const addDocumentsTable = (c: Cursor) => {
    c.newLine();
    c.writeTitle('Detalle conciliaciones documentos');
    c.writeTable(
      [
        'Folio',
        'Tipo',
        'Cliente',
        'Deudor',
        'Monto',
        'Saldo',
        'Conciliado',
        'Restante',
      ],
      documents
        .sort((a, b) => a.productType.localeCompare(b.productType))
        .map((document) => [
          document.folio,
          productTypeMap[document.productType as keyof typeof productTypeMap],
          `${document.businessIdentifier}\n${getShortString(document.businessName)}`,
          `${document.stakeholderIdentifier}\n${getShortString(document.stakeholderName)}`,
          `${formatMoney(document.amount)}`,
          `Documento: ${formatMoney(document.documentAmountDebtBefore)}\nMora: ${formatMoney(document.arrearAmountDebtBefore)}\nTotal: ${formatMoney(document.documentAmountDebtBefore + document.arrearAmountDebtBefore)}`,
          `Documento: ${formatMoney(document.documentAmountConciliated)}\nMora: ${formatMoney(document.arrearAmountConciliated)}\nTotal: ${formatMoney(document.documentAmountConciliated + document.arrearAmountConciliated)}`,
          `Documento: ${formatMoney(document.documentAmountDebtAfter)}\nMora: ${formatMoney(document.arrearAmountDebtAfter)}\nTotal: ${formatMoney(document.documentAmountDebtAfter + document.arrearAmountDebtAfter)}`,
        ]),
    );
  };

  const addFncTable = (c: Cursor) => {
    c.newLine();
    c.writeTitle('Detalle conciliaciones FNC');
    c.writeTable(
      ['Id', 'Monto', 'Fecha conciliación FNC', 'Monto conciliado FNC'],
      fncConciliations.map((fnc) => [
        fnc.id,
        formatMoney(fnc.amount),
        formatDate(new Date(fnc.createdAt)),
        formatMoney(fnc.amount),
      ]),
    );
  };

  const addResumeTable = (c: Cursor) => {
    c.newLine();
    c.writeTitle('Resumen conciliaciones');

    const totalDocumentAmountDebtBefore = reduceDocumentsByProperty(
      documents,
      'documentAmountDebtBefore',
      'highest',
    );
    const totalArrearAmountDebtBefore = reduceDocumentsByProperty(
      documents,
      'arrearAmountDebtBefore',
      'highest',
    );
    const totalDocumentAmountConciliated = reduceDocumentsByProperty(
      documents,
      'documentAmountConciliated',
      'all',
    );
    const totalArrearAmountConciliated = reduceDocumentsByProperty(
      documents,
      'arrearAmountConciliated',
      'all',
    );
    const totalDocumentAmountDebtAfter = reduceDocumentsByProperty(
      documents,
      'documentAmountDebtAfter',
      'lowest',
    );
    const totalArrearAmountDebtAfter = reduceDocumentsByProperty(
      documents,
      'arrearAmountDebtAfter',
      'lowest',
    );
    const totalFncAmount = fncConciliations.reduce(
      (acc, fnc) => acc + fnc.amount,
      0,
    );
    const data = [
      [
        'Documento',
        `${formatMoney(totalDocumentAmountDebtBefore)}`,
        `${formatMoney(totalDocumentAmountConciliated)}`,
        `${formatMoney(totalDocumentAmountDebtAfter)}`,
      ],
      [
        'Mora',
        `${formatMoney(totalArrearAmountDebtBefore)}`,
        `${formatMoney(totalArrearAmountConciliated)}`,
        `${formatMoney(totalArrearAmountDebtAfter)}`,
      ],
      fnc
        ? [
            'Subtotal documentos',
            `${formatMoney(totalDocumentAmountDebtBefore + totalArrearAmountDebtBefore)}`,
            `${formatMoney(totalDocumentAmountConciliated + totalArrearAmountConciliated)}`,
            `${formatMoney(totalDocumentAmountDebtAfter + totalArrearAmountDebtAfter)}`,
          ]
        : null,
      fnc
        ? [
            'FNC',
            `${formatMoney(totalFncAmount)}`,
            `${formatMoney(totalFncAmount)}`,
            `${formatMoney(0)}`,
          ]
        : null,
      [
        'Total',
        `${formatMoney(totalDocumentAmountDebtBefore + totalArrearAmountDebtBefore + totalFncAmount)}`,
        `${formatMoney(totalDocumentAmountConciliated + totalArrearAmountConciliated + totalFncAmount)}`,
        `${formatMoney(totalDocumentAmountDebtAfter + totalArrearAmountDebtAfter)}`,
      ],
    ].filter(Boolean);
    const headers = ['', 'Saldo', 'Conciliado', 'Restante'];
    c.writeTable(headers, data, {
      didParseCell: function (d: any) {
        if (d.row.index === (fnc ? 2 : -1) || d.row.index === data.length - 1) {
          d.cell.styles.fontStyle = 'bold';
        }
      },
    });
  };

  const fnc = fncConciliations.length > 0;
  const c = new Cursor(doc);
  await addTitle();
  addSummary(c);
  addDocumentsTable(c);

  if (fnc) addFncTable(c);
  addResumeTable(c);
  doc.save(`REPORTE-CONCILIACIONES-PAGO-${payIn.businessOriginName}.pdf`);
};
