import { requestHandler } from '../../api';

export const getUsersToTransfer = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/portfolio`,
    dispatch,
  });
  if (response) return response.data;
};

export const postTransferPortfolio = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/portfolio/transfer`,
    data,
    dispatch,
  });
  return response;
};

export const getPortfolio = async (dispatch: any, userId: number) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/portfolio/${userId}`,
    dispatch,
  });
  if (response) return response.data;
};
