import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
} from '@mui/material';
import CustomButton from '../../../../../../../../../lib/designSystem/Button';
import { showAlert } from '../../../../../../../../../lib/functions/utils';
import { useDispatch } from 'react-redux';
import { updateFinancialStatement } from '../../../../../../../../../lib/api/finnacialStatements';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';

const EditFSDialog = (props: any) => {
  const { id, setEditId, editId, observation, setObservation, setUp } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleUpdate = async () => {
    if (!editId) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    try {
      setLoading(true);
      const response = await updateFinancialStatement(dispatch, id, editId, {
        observation,
      });
      console.log(response);
      await setUp();
      setEditId(null);
      showAlert(dispatch, 'success', 'Se ha actualizado tu balance con éxito');
    } catch (error) {
      showAlert(dispatch, 'error', 'Ha ocurrido un error');
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Dialog open={editId !== null} onClose={() => setEditId(null)}>
      <DialogTitle>Editar Balance</DialogTitle>
      {loading ? (
        <CenteredCircularProgress type="dialog" />
      ) : (
        <DialogContent>
          <Typography>
            Acá puedes editar las observaciones del balance
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Observaciones"
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
          />
        </DialogContent>
      )}
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setEditId(null)}>
          Cancelar
        </CustomButton>
        <CustomButton color="primary" onClick={() => handleUpdate()}>
          Guardar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditFSDialog;
