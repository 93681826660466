import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { formatMoney } from '../../../../../../../../../../../../../lib/functions/utils';

const Graph = (props: any) => {
  const { amortizationSchedule } = props;

  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        data={amortizationSchedule}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis tickFormatter={formatMoney} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="balance" stroke="#8884d8" name="Saldo" />
        <Line type="monotone" dataKey="payment" stroke="#82ca9d" name="Cuota" />
        <Line
          type="monotone"
          dataKey="interest"
          stroke="#ffc658"
          name="Interés"
        />
        <Line
          type="monotone"
          dataKey="principal"
          stroke="#ff7300"
          name="Capital"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Graph;
