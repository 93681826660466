import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
  Checkbox,
  Grid,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { useSelector } from 'react-redux';
import { expressGetCompanyLogo } from '../../../../../../../../lib/api/express';
import { useDispatch } from 'react-redux';
import { AuthState } from '../../../../../../../../services/redux/authSlice';
import { getCompanyLogo } from '../../../../../../../../lib/api/company';
import { generateSimulationPDF } from '../../../exportSimulation';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import { showAlert } from '../../../../../../../../lib/functions/utils';

interface ExportDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRequest: any;
  operationDetails: any;
}
const ExportDialog = (props: ExportDialogProps) => {
  const { open, setOpen, selectedRequest, operationDetails } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  // For export shows
  const [showAdvance, setShowAdvance] = useState(true);
  const [showRate, setShowRate] = useState(true);
  const [showArrearRate, setShowArrearRate] = useState(true);
  const [showAmount, setShowAmount] = useState(true);
  const [showAmountAdvance, setShowAmountAdvance] = useState(true);
  const [showAmountRetained, setShowAmountRetained] = useState(true);
  const [showAmountDifference, setShowAmountDifference] = useState(true);
  const [showAmountComissionIva, setShowAmountComissionIva] = useState(true);
  const [showAmountTotalApplied, setShowAmountTotalApplied] = useState(true);
  const [showDraftBalance, setShowDraftBalance] = useState(true);
  const [showExpenses, setShowExpenses] = useState(true);
  const [showGuaranteeAmount, setShowGuaranteeAmount] = useState(true);

  const operationAmount = operationDetails.operationAmount;
  const operationCostComissionFixed =
    operationDetails.operationCostComissionFixed;
  const operationCostComissionVariable =
    operationDetails.operationCostComissionVariable;
  const operationToDepost = operationDetails.operationToDepost;
  const operationIva = operationDetails.operationIva;
  const expensesIva = operationDetails.expensesIva;
  const operationAmountToApply = operationDetails.operationAmountToApply;
  const expensesCost = operationDetails.expensesCost;
  const arrearRate = operationDetails.arrearRate;
  const advance = operationDetails.advance;
  const rate = operationDetails.rate;

  const buildSelectedRequest = () => {
    const documentsApplied = selectedRequest.quoteActive
      ? selectedRequest.quoteActive.documentsToApply
      : [];
    return {
      id: selectedRequest.id,
      executiveName: user?.name,
      businessIdentifier: selectedRequest.businessIdentifier,
      subProductName: selectedRequest.subProductName,
      advance,
      amount: operationAmount,
      businessName: selectedRequest.businessName,
      createdAt: new Date(),
      operationDate:
        selectedRequest.operationDate || selectedRequest.requestDate,
      financingAt: selectedRequest.financingAt,
      rate,
      arrearRate: arrearRate || selectedRequest.arrearRate,
      amountToApply: operationAmountToApply,
      expenses: expensesCost,
      amountToDepositTotal: operationToDepost,
      documentsToApply: documentsApplied,
      documents: selectedRequest.documents,
      operationCostFixed: operationCostComissionFixed,
      operationCostVariable: operationCostComissionVariable,
      operationCostIva: operationIva,
      expensesCostIva: expensesIva,
      guaranteeAmount: operationDetails.guaranteeAmount,
      showAdvance,
      showRate,
      showArrearRate,
      showAmount,
      showAmountAdvance,
      showAmountRetained,
      showAmountDifference,
      showAmountComissionIva,
      showAmountTotalApplied,
      showDraftBalance,
      showExpenses,
      showGuaranteeAmount,
      expensesHasIVA: selectedRequest.expensesIVA,
    };
  };

  const handleExportSimulation = async () => {
    setLoading(true);
    const request = buildSelectedRequest();
    try {
      const imageBase64 = user?.express
        ? await expressGetCompanyLogo(dispatch, user.clientId as string)
        : await getCompanyLogo(dispatch);
      await generateSimulationPDF(request, user?.name || '', imageBase64);
      showAlert(dispatch, 'success', 'Simulación exportada correctamente');
    } catch (error) {
      console.log(error);
      showAlert(dispatch, 'error', 'Error al exportar simulación');
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        Exportar simulación solicitud <strong>#{selectedRequest.id}</strong>
      </DialogTitle>
      <DialogContent>
        {loading && (
          <div style={{ minHeight: 200, minWidth: 500 }}>
            <CenteredCircularProgress type="dialog" />
          </div>
        )}
        {!loading && (
          <div>
            <Typography>
              Seleccione los datos que desea incluir/ocultar en la simulación.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAdvance}
                      onChange={(e) => setShowAdvance(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Mostrar avance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showRate}
                      onChange={(e) => setShowRate(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Mostrar tasa de negocio"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showArrearRate}
                      onChange={(e) => setShowArrearRate(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Mostrar tasa de mora"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAmount}
                      onChange={(e) => setShowAmount(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Mostrar monto total"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAmountAdvance}
                      onChange={(e) => setShowAmountAdvance(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Mostrar monto anticipado"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAmountRetained}
                      onChange={(e) => setShowAmountRetained(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Mostrar monto retenido"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAmountDifference}
                      onChange={(e) =>
                        setShowAmountDifference(e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Mostrar diferencia de precio"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAmountComissionIva}
                      onChange={(e) =>
                        setShowAmountComissionIva(e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Mostrar monto comisión + IVA"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showExpenses}
                      onChange={(e) => setShowExpenses(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={`Mostrar monto gastos ${
                    selectedRequest.expensesIVA ? '+ IVA' : ''
                  }`}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAmountTotalApplied}
                      onChange={(e) =>
                        setShowAmountTotalApplied(e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Mostrar monto total aplicado"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showDraftBalance}
                      onChange={(e) => setShowDraftBalance(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Mostrar monto saldo giro"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showGuaranteeAmount}
                      onChange={(e) => setShowGuaranteeAmount(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Mostrar monto en garantía"
                />
              </Grid>
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          Cancelar
        </CustomButton>
        <CustomButton color="primary" onClick={() => handleExportSimulation()}>
          Exportar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
