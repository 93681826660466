import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect } from 'react';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import { DialogActions, Typography } from '@mui/material';
import { DialogContent } from '@mui/material';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { Divider } from '@mui/material';
import { getDocumentsAvailablesForFactoring } from '../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';

interface EditorProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRequest: any;
  searched: boolean;
  setSearched: (searched: boolean) => void;
  subProductSelected: any;
  businessIdentifier: string;
  subProduct: any;
  selectedDocuments: any;
  setSelectedDocuments: (selectedDocuments: any) => void;
  setOpenShoppingCart: (openShoppingCart: boolean) => void;
}

function getSelectedDocuments(selectedRequest: any, allDocuments: any[]) {
  const selectedDocuments: any[] = [];
  const fatherDocumentsIds: any[] = [];
  for (const document of selectedRequest.documents) {
    if (
      document.fatherDocumentId &&
      !fatherDocumentsIds.includes(document.fatherDocumentId)
    ) {
      fatherDocumentsIds.push(document.fatherDocumentId);
    } else if (!document.fatherDocumentId) {
      selectedDocuments.push({ ...document, isOld: true });
    }
  }
  for (const fatherDocumentId of fatherDocumentsIds) {
    const fatherDocument = allDocuments.find(
      (document: any) => document.id === fatherDocumentId,
    );
    const childDocuments = selectedRequest.documents.filter(
      (document: any) => document.fatherDocumentId === fatherDocumentId,
    );
    selectedDocuments.push({
      ...fatherDocument,
      subDocuments: childDocuments.map((childDocument: any) => ({
        ...childDocument,
        isOld: true,
      })),
    });
  }
  return selectedDocuments.map((document: any) => ({
    ...document,
    subDocuments: document.subDocuments || [],
  }));
}

const Editor = (props: EditorProps) => {
  const {
    open,
    setOpen,
    selectedRequest,
    subProductSelected,
    businessIdentifier,
    subProduct,
    selectedDocuments,
    setSelectedDocuments,
    setOpenShoppingCart,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [documents, setDocuments] = React.useState<any>([]);
  const [searchedBySubProduct, setSearchedBySubProduct] = React.useState(false);
  const [isEnrolled, setIsEnrolled] = React.useState(false);
  const [selectedIds, setSelectedIds] = React.useState<any>([]);

  const handleSearchSuggestedDocuments = async () => {
    setLoading(true);
    try {
      const documentsResponse = await getDocumentsAvailablesForFactoring(
        dispatch,
        businessIdentifier,
        subProductSelected,
      );
      const selectedDocs = getSelectedDocuments(
        selectedRequest,
        documentsResponse.documents,
      );
      setSelectedIds(selectedDocs.map((document: any) => document.id));
      setSelectedDocuments(selectedDocs);
      setDocuments(documentsResponse.documents);
      setIsEnrolled(documentsResponse.isEnrolled);
      setSearchedBySubProduct(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSelectDocuments = async () => {
    const documentsSelected: any[] = documents.filter((document: any) =>
      selectedIds.includes(document.id),
    );
    if (!documentsSelected) {
      return;
    }
    // Add paymentDate from selectedDocuments to documentsSelected in case it exists
    documentsSelected.forEach((document: any) => {
      const selectedDocument = selectedDocuments.find(
        (doc: any) => doc.id === document.id,
      );
      if (selectedDocument) {
        if (selectedDocument.paymentDate) {
          document.dueDate = selectedDocument.paymentDate;
        }
        if (selectedDocument.subDocuments) {
          document.subDocuments = selectedDocument.subDocuments;
          document.subDocuments.forEach((subDocument: any) => {
            if (subDocument.paymentDate) {
              subDocument.dueDate = subDocument.paymentDate;
            }
          });
        }
      }
    });
    setSelectedDocuments(documentsSelected);
    setOpenShoppingCart(true);
    setOpen(false);
  };

  useEffect(() => {
    handleSearchSuggestedDocuments();
  }, []);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>
        <HeaderWithActions title="Editar Documentos" type="dialogHeader" />
      </DialogTitle>
      {loading ? (
        <CenteredCircularProgress type="dialog" />
      ) : (
        <DialogContent>
          {searchedBySubProduct && (
            <div style={{ padding: '20px 0px' }}>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Typography variant="h6" gutterBottom>
                    Documentos disponibles{' '}
                    {isEnrolled ? '(Conectado SII)' : '(No conectado SII)'}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Estas son los documentos disponibles para factorizar en el
                    subproducto {subProduct}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedIds.length} documentos seleccionados
                  </Typography>
                </div>
              </div>
              <div>
                <EnhancedTable
                  dataHeaders={[
                    { key: 'folio', label: 'Folio' },
                    { key: 'amount', label: 'Monto', type: 'money' },
                    {
                      key: 'amountAvailable',
                      label: 'Monto disponible',
                      type: 'money',
                    },
                    {
                      key: 'stakeholderName',
                      label: 'Nombre receptor',
                    },
                    {
                      key: 'issuedDate',
                      label: 'Fecha de emisión',
                      type: 'date',
                    },
                    {
                      key: 'confirmDate',
                      label: 'Fecha de confirmación',
                      type: 'date',
                    },
                  ]}
                  data={documents}
                  selected={selectedIds}
                  setSelected={setSelectedIds}
                />
              </div>
            </div>
          )}
          <DialogActions>
            <CustomButton
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
            >
              {' '}
              Salir
            </CustomButton>
            {searchedBySubProduct && (
              <CustomButton
                variant="contained"
                color="primary"
                disabled={!selectedDocuments.length}
                onClick={handleSelectDocuments}
              >
                {' '}
                Seleccionar
              </CustomButton>
            )}
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default Editor;
