import React, { useEffect, useState } from 'react';

import { DialogActions } from '@mui/material';

import { useDispatch } from 'react-redux';
import HeaderWithActions from '../../../../../../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';
import { getActiveMoneyReports } from '../../../../../../../../../../../lib/api/reports';
import TableWithPagination from '../../../../../../../../../../../lib/designSystem/TableWithPagination';

const ActiveDocuments = (props: any) => {
  const { businessIdentifier, setOpenShoppingCart, request, notDialog } = props;
  const [loading, setLoading] = useState(false);
  const [documentsSuggested, setDocumentsSuggested] = useState<any[]>([]);
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });
  const dispatch = useDispatch();

  const setUp = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    if (!businessIdentifier) return;
    console.log(request);
    setLoading(true);
    try {
      const queryParams = `businessIdentifier=${businessIdentifier}&page=${page}&pageSize=${pageSize}&documentType=${request.productType}&dataType=active`;
      const response = await getActiveMoneyReports(dispatch, queryParams);
      console.log(response);
      setDocumentsSuggested(response.data);
      setPagination(response.pagination);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp({ page: 1, pageSize: PAGE_SIZE });
  }, []);
  if (loading && !businessIdentifier)
    return <CenteredCircularProgress type="layout" />;
  if (loading && businessIdentifier)
    return <CenteredCircularProgress relative />;

  return (
    <div>
      <div>
        <HeaderWithActions title="Documentos" variant={'body1'} buttons={[]} />
        <TableWithPagination
          dataHeaders={[
            { key: 'documentNumber', label: 'Folio' },
            { key: 'documentType', label: 'Fuente' },
            { key: 'stakeholderName', label: 'Deudor' },
            { key: 'operationId', label: 'Solicitud' },
            { key: 'amount', label: 'Monto', type: 'money' },
            { key: 'arrearAmount', label: 'En mora', type: 'money' },
            { key: 'amountConciliated', label: 'Conciliado', type: 'money' },
            { key: 'totalRemaining', label: 'Pendiente', type: 'money' },
          ]}
          finalSumRow
          pagination={pagination}
          setPagination={setPagination}
          setData={setDocumentsSuggested}
          data={documentsSuggested}
        />
      </div>
      {!notDialog && (
        <DialogActions>
          <CustomButton
            onClick={() => setOpenShoppingCart(false)}
            variant="outlined"
            color="secondary"
          >
            Cerrar
          </CustomButton>
        </DialogActions>
      )}
    </div>
  );
};

export default ActiveDocuments;
