// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNotifications, markNotificationsAsRead } from '../../lib/api';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import { Divider, IconButton, Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { formatDate } from '../../lib/functions/utils';
import {
  decrementPendingNotifications,
  setPendingNotifications,
} from '../../services/redux/notificactionSlice';

const Notifications = (props: any) => {
  const { drawer, setOpen } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [paginationKey, setPaginationKey] = useState<string | null>(null);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await getNotifications(dispatch, null);
      console.log(response);
      setNotifications(response.data);
      setPaginationKey(response.paginationKey);
      const unreadNotifications = response.data.filter(
        (x: any) => !x.read,
      ).length;
      dispatch(setPendingNotifications(unreadNotifications));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNextNotifications = async () => {
    if (!paginationKey) return;
    setLoading(true);
    try {
      const response = await getNotifications(dispatch, paginationKey);
      const newNotifications = response.data;
      setNotifications([...notifications, ...newNotifications]);
      setPaginationKey(response.paginationKey);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const markAsReaded = async (timestamp: number) => {
    setLoading(true);
    try {
      await markNotificationsAsRead(dispatch, { timestamps: [timestamp] });
      await fetchNotifications();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const markAllAsReaded = async () => {
    setLoading(true);
    try {
      const allUnReadTimestamps = notifications
        .filter((x: any) => !x.read)
        .map((x: any) => x.timestamp);
      await markNotificationsAsRead(dispatch, {
        timestamps: allUnReadTimestamps,
      });
      fetchNotifications();
      dispatch(decrementPendingNotifications());
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  if (loading && !drawer) return <CenteredCircularProgress />;

  const shimmerAnimation = `
@keyframes shimmer {
  0% {
    background-position: -40rem 0;
  }
  100% {
    background-position: 40rem 0;
  }
}`;

  const paperStyle = {
    padding: '28px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background:
      'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
    backgroundSize: '80rem 100%', // Large size to ensure smooth animation
    animation: 'shimmer 2s linear infinite',
  };

  const handleOpenLink = (link: string) => {
    if (link) window.open(link, '_blank');
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography variant={drawer ? 'h6' : 'h4'}>
            Notificaciones (
            {loading ? '...' : notifications.filter((x: any) => !x.read).length}
            )
          </Typography>
          <Typography
            onClick={() => markAllAsReaded()}
            style={{
              color: '#0F23DF',
              fontWeight: 'bold',
              cursor: 'pointer',
              fontSize: drawer ? 12 : 18,
            }}
          >
            Marcar todas como leídas
          </Typography>
        </div>

        {drawer && (
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        )}
      </div>
      <Divider />
      {notifications.map((notification: any) => {
        return (
          <div
            style={{
              backgroundColor: notification.read ? '#f5f5f5' : '#e0e0e0',
              cursor: notification.link ? 'pointer' : 'default',
            }}
            key={notification.id}
            onClick={() => handleOpenLink(notification.link)}
          >
            <div style={{ textAlign: 'right', padding: '4px 8px' }}>
              <Typography style={{ fontSize: drawer ? 12 : 18 }}>
                {formatDate(new Date(notification.timestamp), 'large')}
              </Typography>
            </div>
            <div
              style={{
                padding: '0px 10px 4px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <Typography
                  variant={drawer ? 'body1' : 'h6'}
                  style={{ fontSize: drawer ? 16 : 18 }}
                >
                  {notification.message}
                </Typography>
              </div>
              {!notification.read && (
                <div>
                  <IconButton
                    onClick={() => {
                      markAsReaded(notification.timestamp);
                    }}
                  >
                    <Check />
                  </IconButton>
                </div>
              )}
            </div>

            <Divider />
          </div>
        );
      })}
      <div style={{ textAlign: 'center', padding: '10px' }}>
        <Typography
          onClick={() => fetchNextNotifications()}
          color={paginationKey ? 'primary' : 'textSecondary'}
          style={{
            cursor: paginationKey ? 'pointer' : 'default',
            fontWeight: 'bold',
          }}
        >
          Ver más
        </Typography>
      </div>

      {loading && drawer && (
        <div>
          <style>{shimmerAnimation}</style>
          <div style={paperStyle} />
        </div>
      )}
    </div>
  );
};

export default Notifications;
