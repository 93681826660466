import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { Grid } from '@mui/material';
import EnhacedBox from '../../../../../../lib/designSystem/BigBox';
import {
  getAutomaticCollectionNotificationsForMonth,
  getManualCollectionNotificationsForMonth,
  getAvailableUsersForSearch,
} from '../../../../../../lib/api/collectionNotifications';
import { useDispatch } from 'react-redux';
import {
  AutomaticNotification,
  AutomaticSummary,
} from './components/AutomaticSummary';
import ManualSummary from './components/ManualSummary';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import GenericSearcher from '../../../../../../lib/common/GlobalSearcher';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});
const initialValue = dayjs();

function CalendarDay(
  props: PickersDayProps<Dayjs> & {
    highlightedAutomaticDays?: number[];
    highlightedManualDays?: number[];
    selectedDate?: Dayjs;
  },
) {
  const {
    highlightedAutomaticDays = [],
    highlightedManualDays = [],
    selectedDate,
    day,
    outsideCurrentMonth,
    ...other
  } = props;
  const dayAutomatic = highlightedAutomaticDays.includes(day.date());
  const dayManual = highlightedManualDays.includes(day.date());
  const highlight = dayAutomatic || dayManual;

  const isSelected = day.date() === selectedDate?.date();

  return (
    <Badge key={day.toString()} overlap="circular">
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        sx={{
          backgroundColor: highlight ? 'lightblue' : 'transparent',
          border: isSelected ? '1px solid black' : 'none',
        }}
      />
    </Badge>
  );
}

export default function NotificationsCalendar() {
  const [loading, setLoading] = useState(true);
  const [highlightedAutomaticDays, setHighlightedAutomaticDays] = useState([]);
  const [highlightedManualDays, setHighlightedManualDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [automaticNotifications, setAutomaticNotifications] = useState<
    AutomaticNotification[]
  >([]);
  const [filteredAutomaticNotifications, setFilteredAutomaticNotifications] =
    useState<AutomaticNotification[]>([]);
  const [manualNotifications, setManualNotifications] = useState<any[]>([]);
  const [filteredManualNotifications, setFilteredManualNotifications] =
    useState<any[]>([]);
  const dispatch = useDispatch();

  const [searchBusinessIdentifier, setSearchBusinessIdentifier] =
    useState<string>('');
  const [searchStakeholderIdentifier, setSearchStakeholderIdentifier] =
    useState<string>('');
  const [executiveId, setExecutiveId] = useState<string>('all');
  const [executiveOptions, setExecutiveOptions] = useState<any[]>([]);
  const [canHaveSubordinates, setCanHaveSubordinates] =
    useState<boolean>(false);

  const buildQuery = () => {
    const queryParams = new URLSearchParams();
    if (searchBusinessIdentifier) {
      queryParams.append('businessIdentifier', searchBusinessIdentifier);
    }
    if (searchStakeholderIdentifier) {
      queryParams.append('stakeholderIdentifier', searchStakeholderIdentifier);
    }
    if (executiveId && executiveId !== 'all') {
      queryParams.append('executiveId', executiveId);
    }
    return queryParams.toString();
  };

  const fetchHighlightedDays = async (date: Dayjs) => {
    setLoading(true);
    try {
      const query = buildQuery();
      const promises = [
        getAutomaticCollectionNotificationsForMonth(
          dispatch,
          date.format('MM-YY'),
          query,
        ),
        getManualCollectionNotificationsForMonth(
          dispatch,
          date.format('YYYY-MM-DD'),
          query,
        ),
      ];
      const [automaticData, manualData] = await Promise.all(promises);
      setHighlightedAutomaticDays(automaticData.map((item: any) => item.day));
      setHighlightedManualDays(manualData.map((item: any) => item.day));
      setAutomaticNotifications(automaticData);
      setManualNotifications(manualData);
      const day = date.date();
      const automaticNotificationsForDay = automaticData.filter(
        (item: any) => item.day === day,
      );
      const manualNotificationsForDay = manualData.filter(
        (item: any) => item.day === day,
      );
      setFilteredAutomaticNotifications(automaticNotificationsForDay);
      setFilteredManualNotifications(manualNotificationsForDay);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const triggerSearch = async () => {
    await fetchHighlightedDays(selectedDate);
  };

  useEffect(() => {
    fetchHighlightedDays(initialValue);
    getAvailableUsersForSearch(dispatch).then((res) => {
      setExecutiveOptions([
        {
          label: 'Todos',
          value: 'all',
        },
        ...res.users.map((item: any) => ({
          label: item.name,
          value: item.id,
        })),
      ]);
      setCanHaveSubordinates(res.canHaveSubordinates);
    });
  }, []);

  const handleMonthChange = (date: Dayjs) => {
    setLoading(true);
    setHighlightedAutomaticDays([]);
    setHighlightedManualDays([]);
    setSelectedDate(date);
    fetchHighlightedDays(date);
  };

  const handleDayChange = (date: Dayjs) => {
    const day = date.date();
    setSelectedDate(date);
    const automaticNotificationsForDay = automaticNotifications.filter(
      (item) => item.day === day,
    );
    const manualNotificationsForDay = manualNotifications.filter(
      (item) => item.day === day,
    );
    setFilteredAutomaticNotifications(automaticNotificationsForDay);
    setFilteredManualNotifications(manualNotificationsForDay);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <HeaderWithActions
        title={
          'Acá encontrarás todas las gestiones y correos de cobranzas programados.'
        }
        variant={'body1'}
        buttons={[
          {
            name: 'Filtro',
            action: () => {},
            color: 'primary',
            customButton: (
              <GenericSearcher
                filters={[
                  {
                    type: 'text',
                    label: 'Emisor',
                    value: searchBusinessIdentifier,
                    setter: setSearchBusinessIdentifier,
                  },
                  {
                    type: 'text',
                    label: 'Deudor',
                    value: searchStakeholderIdentifier,
                    setter: setSearchStakeholderIdentifier,
                  },
                  ...(canHaveSubordinates
                    ? [
                        {
                          type: 'select' as const,
                          label: 'Ejecutivo',
                          value: executiveId,
                          setter: setExecutiveId,
                          options: executiveOptions,
                        },
                      ]
                    : []),
                ]}
                triggerSearch={triggerSearch}
              />
            ),
            hidden: loading,
          },
        ]}
      />
      <EnhacedBox>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {loading ? (
              <CenteredCircularProgress relative />
            ) : (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  defaultValue={selectedDate}
                  loading={loading}
                  onMonthChange={handleMonthChange}
                  onChange={handleDayChange}
                  renderLoading={() => <DayCalendarSkeleton />}
                  slots={{
                    day: CalendarDay,
                  }}
                  slotProps={{
                    day: {
                      highlightedAutomaticDays,
                      highlightedManualDays,
                      selectedDate,
                    } as any,
                  }}
                />
              </LocalizationProvider>
            )}
          </Grid>
          <Grid item xs={8}>
            <AutomaticSummary
              selectedDate={selectedDate}
              filteredAutomaticNotifications={filteredAutomaticNotifications}
            />
            <ManualSummary
              selectedDate={selectedDate}
              filteredManualNotifications={filteredManualNotifications}
            />
          </Grid>
        </Grid>
      </EnhacedBox>
    </div>
  );
}
