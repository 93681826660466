import React from 'react';
import { Typography } from '@mui/material';
import { LightTooltip } from '../WhiteTooltip';

interface LongTextWithTooltipProps {
  text: string;
  maxLength?: number;
  variant?: 'body1' | 'body2';
}

const LongTextWithTooltip: React.FC<LongTextWithTooltipProps> = ({
  text,
  maxLength = 40,
  variant = 'body1',
}) => {
  const textToShow = text || '';
  return (
    <LightTooltip title={textToShow}>
      <Typography variant={variant}>
        {textToShow.length > maxLength
          ? textToShow.slice(0, maxLength) + '...'
          : textToShow}
      </Typography>
    </LightTooltip>
  );
};

export default LongTextWithTooltip;
