import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../lib/designSystem/HeaderWithActions';
import { getCompanyNetwork } from '../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import NetworkBusinessDetails from './components/Details';
import DocumentSearcher from '../../../../lib/common/DocumentSearcher';
import TableWithPagination from '../../../../lib/designSystem/TableWithPagination';
import { loggerDev } from '../../../../lib/functions/utils';

const headers = [
  { key: 'name', label: 'Empresa' },
  { key: 'identifier', label: 'Rut' },
];

const NetworkBusinesses: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [business, setBusiness] = useState<any>(null);
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });
  const [businessIdentifier, setBusinessIdentifier] = useState('');

  const setUp = async () => {
    try {
      const businessesResponse = await getCompanyNetwork(dispatch, '');
      setBusinesses(businessesResponse.data);
      setPagination(businessesResponse.pagination);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const handleDetails = (id: number) => {
    loggerDev('id', id);
    setBusiness(businesses.find((x: any) => x.id === id));
    setOpenModal(true);
  };

  const actions = [
    {
      action: handleDetails,
      actionLabel: 'Ver',
    },
  ];

  const generateQuery = ({
    page,
    pageSize,
    businessIdentifier,
  }: {
    page: number;
    pageSize: number;
    businessIdentifier: string;
  }) => {
    let query = '';
    if (businessIdentifier)
      query += `businessIdentifier=${businessIdentifier}&`;
    if (page && pageSize) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const searchNetwork = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    try {
      const query = generateQuery({
        page,
        pageSize,
        businessIdentifier,
      });
      const response = await getCompanyNetwork(dispatch, query);
      setBusinesses(response.data);
      setPagination(response.pagination);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const documentSearcher = (
    <div>
      <DocumentSearcher
        searchData={searchNetwork}
        setData={setBusinesses}
        setLoading={setLoading}
        businessIdentifier={businessIdentifier}
        setBusinessIdentifier={setBusinessIdentifier}
        pagination={pagination}
      />
    </div>
  );

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        type="subHeader"
        title="Esta es toda tu red de empresas."
        variant="body1"
        buttons={[]}
        item={documentSearcher}
      />

      <TableWithPagination
        dataHeaders={headers}
        searchData={searchNetwork}
        data={businesses}
        setData={setBusinesses}
        showActions
        rowsPerPageDefault={PAGE_SIZE}
        actions={actions}
        pagination={pagination}
        setPagination={setPagination}
        lastElement
      />
      {openModal && (
        <NetworkBusinessDetails
          open={openModal}
          setOpen={setOpenModal}
          business={business}
        />
      )}
    </div>
  );
};

export default NetworkBusinesses;
