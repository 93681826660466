import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import { showAlert } from '../../../../../../../../lib/functions/utils';
import {
  checkPendingAccountDeletion,
  deletePendingAccount,
} from '../../../../../../../../lib/api';

const DeleteDialog = (props: any) => {
  const { open, accountType, onClose, account, searchData } = props;
  const [loading, setLoading] = useState(false);
  const [aboutMessages, setAboutMessages] = useState<any[]>([]);
  const [warningMessages, setWarningMessages] = useState<any[]>([]);
  const [canBeDeleted, setCanBeDeleted] = useState(true);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deletePendingAccount(dispatch, account.id);
      showAlert(dispatch, 'success', 'Cuenta eliminada correctamente');
      searchData({});
      onClose();
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al eliminar la cuenta');
    }
    setLoading(false);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await checkPendingAccountDeletion(dispatch, account.id);
      setAboutMessages(response.aboutMessages);
      setWarningMessages(response.warningMessages);
      setCanBeDeleted(response.canBeDeleted);
    } catch (error) {
      console.log(error);
      showAlert(dispatch, 'error', 'Error al obtener información de la cuenta');
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{`Eliminar Cuenta por ${accountType} - Folio ${account.folio}`}</DialogTitle>
      {loading ? (
        <CenteredCircularProgress relative />
      ) : (
        <>
          <DialogContent>
            <Typography variant="body1">Sobre la cuenta:</Typography>
            <div style={{ padding: '10px 0px' }}>
              {aboutMessages.map((message) => (
                <Typography key={message} variant="body1">
                  - {message}
                </Typography>
              ))}
            </div>
            <Typography variant="body1">
              {canBeDeleted &&
                `¿Estás seguro que quieres eliminar esta cuenta? ${warningMessages.length > 0 ? 'Se ejecutarán las siguientes acciones:' : ''}`}
            </Typography>
            <div style={{ padding: '10px 0px' }}>
              {warningMessages.map((message) => (
                <Typography key={message} variant="body1" color="error">
                  - {message}
                </Typography>
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <CustomButton color="secondary" onClick={() => onClose()}>
              Salir
            </CustomButton>
            <CustomButton
              onClick={() => handleDelete()}
              color="error"
              disabled={!canBeDeleted}
            >
              Eliminar
            </CustomButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DeleteDialog;
