import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import HeaderWithActions from '../../../../../../../lib/designSystem/HeaderWithActions';
import DocumentSearcher from '../../../../../../../lib/common/DocumentSearcher';
import TableWithPagination from '../../../../../../../lib/designSystem/TableWithPagination';
import { getBusinessInvoices } from '../../../../../../../lib/api/business';

const BusinessInvoicesReceived = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [receivedInvoices, setReceivedInvoices] = useState([]);
  const { id } = useParams<{ id: string }>();
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (startDate) query += `startDate=${startDate}&`;
    if (endDate) query += `endDate=${endDate}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const searchReceivedInvoices = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    if (!id) return;

    setLoading(true);
    try {
      const query = generateQuery({ page, pageSize });
      const response = await getBusinessInvoices(
        dispatch,
        id,
        'received',
        query,
      );
      setReceivedInvoices(response.data);
      setPagination(response.pagination);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    searchReceivedInvoices({ page: 0, pageSize: PAGE_SIZE });
  }, []);

  return (
    <div>
      <HeaderWithActions
        buttons={[
          {
            name: 'Filtro',
            action: () => {},
            color: 'primary',
            customButton: (
              <DocumentSearcher
                searchData={searchReceivedInvoices}
                setData={setReceivedInvoices}
                setLoading={setLoading}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                stakeholderIdentifier={stakeholderIdentifier}
                setStakeholderIdentifier={setStakeholderIdentifier}
                pagination={pagination}
              />
            ),
          },
        ]}
        title="Aquí verás las facturas recibidas."
        variant="body1"
      />
      <TableWithPagination
        tableSize="small"
        exportToFile="xlsx"
        dataHeaders={[
          { key: 'folio', label: 'Folio' },
          { key: 'businessIdentifier', label: 'Rut Emisor' },
          { key: 'businessName', label: 'Nombre Emisor' },
          { key: 'amount', label: 'Monto', type: 'money' },
          { key: 'confirmDate', label: 'Fecha confirmación', type: 'date' },
          { key: 'issuedDate', label: 'Fecha emisión', type: 'date' },
          { key: 'cessionary', label: 'Cedida' },
          { key: 'status', label: 'Estado', type: 'cession' },
        ]}
        data={receivedInvoices}
        loading={loading}
        setData={setReceivedInvoices}
        searchData={searchReceivedInvoices}
        setPagination={setPagination}
        pagination={pagination}
        lastElement
      />
    </div>
  );
};

export default BusinessInvoicesReceived;
