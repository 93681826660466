import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Contacts from '../../../../../BusinessProfile/Profile/components/Contacts';
import EnhancedTab from '../../../../../../lib/designSystem/TabSimulation';
import BankAccounts from '../../../../../BusinessProfile/Profile/components/BankAccounts';
import CustomButton from '../../../../../../lib/designSystem/Button';
import AutoCollection from './components/AutoCollection';
import Client from '../../../../../BusinessProfile/Profile/components/Client';
const NetworkBusinessDetails = (props: any) => {
  const { business, open, setOpen } = props;
  const [startValue, setStartValue] = useState(0);

  const tabs = [
    {
      index: 0,
      label: 'Resumen',
      component: <Client businessId={business.id} />,
    },
    {
      index: 1,
      label: 'Contactos',
      component: <Contacts businessId={business.id} />,
    },
    {
      index: 2,
      label: 'Banco',
      component: <BankAccounts businessId={business.id} />,
    },
    // {
    //   index: 3,
    //   label: 'Cobranza automática',
    //   component: <AutoCollection businessIdentifier={business.id} />,
    // },
  ];
  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <div style={{ padding: 20, minHeight: 200 }}>
          <EnhancedTab
            title={`${business.name}`}
            startValue={startValue}
            tabs={tabs}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          {' '}
          Salir{' '}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NetworkBusinessDetails;
