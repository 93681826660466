import React from 'react';
import { Box } from '@mui/material';
import EnhancedTable from '../../../../../../../../../../../../../lib/designSystem/Table';

const AmortizationSchedule = (props: any) => {
  const { amortizationSchedule } = props;

  return (
    <Box sx={{ flexGrow: 1, overflow: 'auto', maxHeight: 300 }}>
      <EnhancedTable
        dataHeaders={[
          { key: 'month', label: 'Mes' },
          { key: 'paymentDate', label: 'Pago', type: 'date' },
          { key: 'balance', label: 'Saldo', type: 'money' },
          { key: 'amount', label: 'Cuota', type: 'money' },
          { key: 'interest', label: 'Interés', type: 'money' },
          { key: 'principal', label: 'Capital', type: 'money' },
          {
            key: 'totalAmortization',
            label: 'Amortización',
            type: 'money',
          },
        ]}
        data={amortizationSchedule}
        finalSumRow
        completeTable
      />
    </Box>
  );
};

export default AmortizationSchedule;
