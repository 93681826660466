import React, { useState } from 'react';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
import ShoppingCartFactoringReviewed from './components/2_ShoppingCartReviewed';
import ShoppingCartFactoringInReview from './components/1_ShoppingCartInReview';
import ShoppingCartFactoringValidating from './components/0.5_ShoppingCartInValidating';
import ShoppingCartCreditLineMissingInfo from './components/0.7_ShoppingCartInMissingInfo';
import ShoppingCartFactoringAssigned from './components/3_ShoppingCartAssigned';
import ShoppingCartFactoringRejected from './components/0.1_ShoppingCartRejected';
import ShoppingCartFactoringNotRequested from './components/0_ShoppingCartNotRequested';
import ShoppingCartFactoringAlive from './components/4_ShoppingCartActive';

export interface FactoringRequest {
  id: number;
  status: string;
  sender: string;
  recipient: string;
  validUntil: Date;
  paymentDate: Date;
  amount: number;
  extraData: boolean;
  relation?: any;
  businessId: number;
}

const Summary = (props: any) => {
  const {
    shoppingCartType,
    openShoppingCart,
    setOpenShoppingCart,
    openRequest,
    setSuccessOpen,
    setUp,
    handleDetails,
  } = props;
  const [loading, setLoading] = useState(false);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      {loading ? <CenteredCircularProgress type="dialog" /> : <div></div>}
      {shoppingCartType === 'No solicitada' && (
        <ShoppingCartFactoringNotRequested
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          openRequest={openRequest}
          setSuccessOpen={setSuccessOpen}
          setUp={setUp}
          setLoading={setLoading}
        />
      )}
      {shoppingCartType === 'Validando' && (
        <ShoppingCartFactoringValidating
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          openRequest={openRequest}
          setSuccessOpen={setSuccessOpen}
          setUp={setUp}
          setLoading={setLoading}
        />
      )}
      {shoppingCartType === 'Rechazada' && (
        <ShoppingCartFactoringRejected
          openShoppingCart={openShoppingCart}
          setOpenShoppingcart={setOpenShoppingCart}
          openRequest={openRequest}
          setUp={setUp}
        />
      )}
      {shoppingCartType === 'Faltan datos' && (
        <ShoppingCartCreditLineMissingInfo
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          openRequest={openRequest}
          setSuccessOpen={setSuccessOpen}
          setUp={setUp}
          setLoading={setLoading}
        />
      )}
      {shoppingCartType === 'En revisión' && (
        <ShoppingCartFactoringInReview
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          openRequest={openRequest}
          setSuccessOpen={setSuccessOpen}
          setUp={setUp}
          setLoading={setLoading}
        />
      )}
      {shoppingCartType === 'Revisada' && (
        <ShoppingCartFactoringReviewed
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          openRequest={openRequest}
          setSuccessOpen={setSuccessOpen}
          updateStatus
          setUp={setUp}
          handleDetails={handleDetails}
          setLoading={setLoading}
        />
      )}
      {shoppingCartType === 'Asignada' && (
        <ShoppingCartFactoringAssigned
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          openRequest={openRequest}
          setSuccessOpen={setSuccessOpen}
          handleDetails={handleDetails}
          setUp={setUp}
          setLoading={setLoading}
        />
      )}
      {(shoppingCartType === 'Activa' || shoppingCartType === 'Congelada') && (
        <ShoppingCartFactoringAlive
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          openRequest={openRequest}
          setSuccessOpen={setSuccessOpen}
          handleDetails={handleDetails}
          setUp={setUp}
          setLoading={setLoading}
        />
      )}
    </div>
  );
};

export default Summary;
