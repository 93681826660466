import { CircularProgress, IconButton, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { syncInvoicesCessionWithSII } from '../../../../../../lib/api';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import Editor from './components/Editor';
import EditorShoppingCart from './components/EditorShoppingCart';
import { loggerDev } from '../../../../../../lib/functions/utils';

export const ExpandMore = styled((props: any) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }: { theme: any; expand: any }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginRight: '10px',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const DocumentsPreview = (props: any) => {
  const {
    selectedRequest,
    setSelectedRequest,
    simulationInProgress,
    onEditing,
    setSelected,
  } = props;
  const [miniLoading, setMiniLoading] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openFailed, setOpenFailed] = React.useState(false);
  const [openEditor, setOpenEditor] = React.useState(false);
  const [selectedDocuments, setSelectedDocuments] = React.useState<any>([]);
  const [openShoppingCart, setOpenShoppingCart] = React.useState(false);
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const [ownEnrollmentStatus, setOwnEnrollmentStatus] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  let headers: any = [
    { key: 'folio', label: 'Folio' },
    { key: 'cedded', label: 'Cedida', type: 'cession-lights' },
    {
      key: 'cancelDate',
      label: 'Reclamos?',
      type: 'cancel-lights',
      canNull: true,
    },
    {
      key: 'confirmDate',
      label: 'Confirmada',
      type: 'confirm-lights',
      canNull: true,
    },
    { key: 'stakeholderIdentifier', label: 'Rut pagador' },
    { key: 'stakeholderName', label: 'Pagador' },
    { key: 'stakeholderSegment', label: 'Segmento pagador' },
    { key: 'issuedDate', label: 'Emisión', type: 'date' },
    { key: 'paymentDate', label: 'Pago', type: 'date' },
    { key: 'amount', label: 'Monto Doc.', type: 'money' },
  ];

  if (simulationInProgress) {
    headers = [
      ...headers,
      { key: 'costRate', label: 'Costo tasa', type: 'money' },
      { key: 'daysToCount', label: 'Días' },
      { key: 'amountRetention', label: 'Excedente', type: 'money' },
      { key: 'amountDocument', label: 'Saldo documento', type: 'money' },
      { key: 'amountToDeposit', label: 'Saldo deuda', type: 'money' },
    ];
  }

  const handleSyncWithSII = async () => {
    setMiniLoading(true);
    try {
      const response = await syncInvoicesCessionWithSII(dispatch, {
        quoteId: selectedRequest.quoteActive.id,
      });
      if (response.update) {
        addNewSyncDataToInvoices(
          response.documentsWithCessionData,
          selectedRequest.documents,
        );
        setOpenSuccess(true);
      } else {
        setOpenFailed(true);
      }
    } catch (error) {
      loggerDev('error', error);
    }
    setMiniLoading(false);
  };

  const addNewSyncDataToInvoices = (
    invoicesWithNewData: any,
    oldsInvoices: any,
  ) => {
    const invoicesMerged = oldsInvoices.map((oldInvoice: any) => {
      const newInvoice = invoicesWithNewData.find(
        (invoice: any) => invoice.id === oldInvoice.id,
      );
      if (newInvoice) {
        return {
          ...oldInvoice,
          cancelDate: newInvoice.cancelled,
          cedded: newInvoice.cedded,
        };
      }
    });
    setSelectedRequest({
      ...selectedRequest,
      documents: invoicesMerged,
    });
  };

  const handleEditDocuments = () => {
    setOpenEditor(true);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ padding: 0, display: 'flex', alignItems: 'center' }}>
          <Typography fontWeight="bold" padding="0px 0px 6px" variant="h6">
            Detalle documentos
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </div>
        <div>
          <div style={{ padding: '20px 0px' }}>
            {miniLoading ? (
              <CircularProgress />
            ) : (
              selectedRequest.status === 'En revisión' && (
                <div style={{ display: 'flex', gap: 10 }}>
                  {onEditing && (
                    <CustomButton
                      color="secondary"
                      onClick={handleEditDocuments}
                      disabled={!onEditing}
                    >
                      Editar documentos y fechas
                    </CustomButton>
                  )}
                  {!onEditing && (
                    <CustomButton
                      onClick={handleSyncWithSII}
                      disabled={!ownEnrollmentStatus}
                    >
                      Sincronizar SII
                    </CustomButton>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <EnhancedTable
          dataHeaders={headers}
          data={selectedRequest.documents}
          rowsPerPageDefault={5}
          finalSumRow
        />
      </Collapse>
      <CustomAlert
        open={openSuccess}
        severity="success"
        text={
          'Se han actualizado los datos de los documentos con los datos del SII'
        }
        setOpen={setOpenSuccess}
      />
      <CustomAlert
        open={openFailed}
        title="No hay cambios"
        severity="warning"
        text="No hemos encontrado cambios en los documentos"
        setOpen={setOpenFailed}
      />
      <Editor
        open={openEditor}
        setOpen={setOpenEditor}
        selectedRequest={selectedRequest}
        subProductSelected={selectedRequest.subProduct}
        searched={false}
        setSearched={() => {}}
        businessIdentifier={selectedRequest.businessIdentifier}
        subProduct={selectedRequest.subProductName}
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={setSelectedDocuments}
        setOpenShoppingCart={setOpenShoppingCart}
      />
      {openShoppingCart && (
        <EditorShoppingCart
          openShoppingCart={openShoppingCart}
          setOpenShoppingcart={setOpenShoppingCart}
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          subProductName={selectedRequest.subProductName}
          setOpenDocumentsSelector={setOpenEditor}
          selectedRequest={selectedRequest}
          setSelectedRequest={setSelectedRequest}
          setSelected={setSelected}
        />
      )}
    </div>
  );
};

export default DocumentsPreview;
