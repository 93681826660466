import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

export class Cursor {
  x: number;
  y: number;
  defaultX: number;
  defaultY: number;
  col11: number;
  col12: number;
  col21: number;
  col22: number;
  doc: jsPDF;
  textFontSize: number;
  titleFontSize: number;
  boldFont: string;
  normalFont: string;
  boldFontStyle: string;
  normalFontStyle: string;
  newLineSpacing: number;
  newLineSpacingTitle: number;

  constructor(doc: jsPDF | undefined = undefined) {
    this.doc = doc || new jsPDF();
    this.defaultX = 10;
    this.newLineSpacing = 5;
    this.newLineSpacingTitle = 10;
    this.defaultY = 35;
    this.x = this.defaultX;
    this.y = this.defaultY;
    this.col11 = 10;
    this.col12 = 45;
    this.col21 = 110;
    this.col22 = 145;
    this.textFontSize = 10;
    this.titleFontSize = 12;
    this.boldFont = 'helvetica';
    this.normalFont = 'helvetica';
    this.boldFontStyle = 'bold';
    this.normalFontStyle = 'normal';

    this.doc.setFontSize(this.textFontSize);
    this.doc.setFont(this.normalFont, this.normalFontStyle);
  }

  newLine(title: boolean = false) {
    this.x = this.defaultX;
    this.y += title ? this.newLineSpacingTitle : this.newLineSpacing;
  }

  write(text: string, x: number, y: number, bold: boolean = false) {
    this.doc.setFont(
      bold ? this.boldFont : this.normalFont,
      bold ? this.boldFontStyle : this.normalFontStyle,
    );
    this.doc.text(text, x, y);
  }

  writeLine(text: string, bold: boolean = false, col: string = 'col11') {
    this.x =
      col === 'col11'
        ? this.col11
        : col === 'col12'
          ? this.col12
          : col === 'col21'
            ? this.col21
            : this.col22;
    this.write(text, this.x, this.y, bold);
  }

  writeLineAndNewLine(
    text: string,
    bold: boolean = false,
    col: string = 'col11',
  ) {
    this.x =
      col === 'col11'
        ? this.col11
        : col === 'col12'
          ? this.col12
          : col === 'col21'
            ? this.col21
            : this.col22;
    this.write(text, this.x, this.y, bold);
    this.newLine();
  }

  writeTitle(text: string) {
    this.doc.setFontSize(this.titleFontSize);
    this.write(text, this.x, this.y, true);
    this.newLine(true);
    this.doc.setFontSize(this.textFontSize);
  }

  resetX() {
    this.x = this.defaultX;
  }

  resetY() {
    this.y = this.defaultY;
  }

  reset() {
    this.resetX();
    this.resetY();
  }

  moveX(x: number) {
    this.x += x;
  }

  moveXTo(x: number) {
    this.x = x;
  }

  moveY(y: number) {
    this.y += y;
  }

  moveYTo(y: number) {
    this.y = y;
  }

  move(x: number, y: number) {
    this.x += x;
    this.y += y;
  }

  moveTo(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  writeTable(headers: string[], data: any[], customProps: any = {}) {
    autoTable(this.doc, {
      startY: this.y,
      columns: headers,
      body: data,
      theme: 'grid',
      headStyles: {
        fillColor: '#efefef', // or '#2980b9' in hex format
        textColor: '#1b1b1b',
        fontStyle: 'bold',
        halign: 'left', // horizontal alignment
        valign: 'middle', // vertical alignment
        lineColor: [44, 62, 80],
        fontSize: 9,
      },
      bodyStyles: {
        textColor: [50, 50, 50],
        fontSize: 8,
      },
      styles: {
        font: 'helvetica', // choose the font
        lineColor: [44, 62, 80],
        lineWidth: 0.1,
      },
      ...customProps,
    });
    this.moveYTo((this.doc as any).lastAutoTable.finalY || this.y);
    this.newLine();
  }
}
