import React from 'react';
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { formatMoney } from '../../../../../../../../lib/functions/utils';
import { LightTooltip } from '../../../../../../../../lib/designSystem/WhiteTooltip';
import { Info } from '@mui/icons-material';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { Dayjs } from 'dayjs';

export interface AutomaticNotification {
  id: string;
  day: number;
  type: keyof typeof NOTIFICATION_TYPES;
  sent: boolean;
  company: string;
  documentId: string;
  document: any;
}

const NOTIFICATION_TYPES = {
  issuedInvoices: 'Notificación de facturas emitidas',
  documentCession: 'Notificación de cesión',
  documentCessionReminder: 'Notificación próximo vencimiento',
  documentCessionDue: 'Notificación de vencimiento',
};

export const AutomaticSummary = (props: {
  selectedDate: Dayjs;
  filteredAutomaticNotifications: AutomaticNotification[];
}) => {
  const { selectedDate, filteredAutomaticNotifications } = props;

  return (
    <div>
      <Typography variant="h5">
        Correos para {selectedDate.format('DD/MM/YYYY')}
      </Typography>
      <Divider />
      <List>
        {filteredAutomaticNotifications.map((notification) => (
          <ListItem
            key={notification.id}
            secondaryAction={
              <LightTooltip
                placement="left"
                title={
                  <div style={{ padding: '16px', minWidth: '300px' }}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography fontWeight={600}>Folio</Typography>
                        <Typography>{notification.document?.folio}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontWeight={600}>Emisor</Typography>
                        <Typography>
                          {notification.document?.businessName}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontWeight={600}>Fecha emisión</Typography>
                        <Typography>
                          {notification.document?.issuedDate.slice(0, 10)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontWeight={600}>Monto</Typography>
                        <Typography>
                          {formatMoney(notification.document?.amount)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                }
              >
                <IconButton edge="end" aria-label="ver más">
                  <Info />
                </IconButton>
              </LightTooltip>
            }
          >
            <ListItemAvatar>
              <Avatar>
                {notification.sent ? (
                  <LightTooltip
                    title={
                      <Typography style={{ padding: '4px' }}>
                        Correo enviado
                      </Typography>
                    }
                  >
                    <MarkEmailReadIcon color="success" />
                  </LightTooltip>
                ) : (
                  <LightTooltip
                    title={
                      <Typography style={{ padding: '4px' }}>
                        Correo pendiente
                      </Typography>
                    }
                  >
                    <ScheduleSendIcon color="info" />
                  </LightTooltip>
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={NOTIFICATION_TYPES[notification.type]}
              secondary={notification.document?.stakeholderName}
            />
          </ListItem>
        ))}
        {filteredAutomaticNotifications.length === 0 && (
          <Typography variant="body1">
            No hay correos para esta fecha.
          </Typography>
        )}
      </List>
    </div>
  );
};

export default AutomaticSummary;
