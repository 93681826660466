import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableDocumentsForCollection } from '../../../../../../../../../../lib/api/collections';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Dialog } from '@mui/material';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';
import TableWithPagination from '../../../../../../../../../../lib/designSystem/TableWithPagination';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';
import { validatePermissions } from '../../../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../../../services/redux/authSlice';
import DocumentSearcher from '../../../../../../../../../../lib/common/DocumentSearcher';

const DocumentSelector = (props: any) => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const { open, setOpen, handleConfirmSelection } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState<any[]>([]);
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });
  const [executiveId, setExecutiveId] = useState('all');
  const [executiveOptions, setExecutiveOptions] = useState<any[]>([]);
  const [canHaveSubordinates, setCanHaveSubordinates] = useState(false);
  const [documentType, setDocumentType] = useState('all');
  const [endDate, setEndDate] = useState(null);
  const [businessIdentifier, setBusinessIdentifier] = useState('');
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const documentTypes = [
    { label: 'Todos', value: 'all' },
    { label: 'Factura', value: 'invoice' },
    { label: 'Solicitud de factoring', value: 'factoring' },
    { label: 'Solicitud de crédito', value: 'credit' },
  ];

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (endDate) query += `endDate=${endDate}&`;
    if (businessIdentifier)
      query += `businessIdentifier=${businessIdentifier}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (documentType) query += `documentType=${documentType}&`;
    if (executiveId && executiveId !== 'all')
      query += `executiveId=${executiveId}&`;
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const setUp = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    try {
      const query = generateQuery({ page, pageSize });
      const response = await getAvailableDocumentsForCollection(
        dispatch,
        query,
      );
      setLoading(false);
      setDocuments(response.data);
      setPagination(response.pagination);
      setExecutiveOptions(response.executiveOptions);
    } catch (error) {
      setLoading(false);
    }
  };

  const confirmSelection = () => {
    if (!selectedDocuments.length) {
      return alert('Debes seleccionar al menos un documento');
    }
    const id = selectedDocuments[0];
    const document = documents.find((x: any) => x.id === id);
    if (!document) {
      return alert('Documento no encontrado');
    }
    handleConfirmSelection(document);
    setOpen(false);
  };

  const cantSelect = (document: any) => {
    if (selectedDocuments.length === 0) {
      return false;
    }
    if (selectedDocuments.find((id: string) => id === document.id)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setUp({ page: 0, pageSize: PAGE_SIZE });
  }, []);

  useEffect(() => {
    const boss = validatePermissions(user, 'have_subordinates');
    const admin = validatePermissions(user, 'edit_subordinates');
    setCanHaveSubordinates(boss || admin);
  }, [user]);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Selecciona un documento</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Typography variant="body1">
              Selecciona un documento para crear una nueva gestión
            </Typography>
            <DocumentSearcher
              documentTypes={documentTypes}
              searchData={setUp}
              setData={setDocuments}
              setLoading={setLoading}
              endDate={endDate}
              setEndDate={setEndDate}
              businessIdentifier={businessIdentifier}
              setBusinessIdentifier={setBusinessIdentifier}
              stakeholderIdentifier={stakeholderIdentifier}
              setStakeholderIdentifier={setStakeholderIdentifier}
              documentType={documentType}
              setDocumentType={setDocumentType}
              executiveId={canHaveSubordinates ? executiveId : ''}
              setExecutiveId={canHaveSubordinates ? setExecutiveId : ''}
              executiveOptions={
                canHaveSubordinates ? executiveOptions : undefined
              }
              pagination={pagination}
            />
          </div>
          <TableWithPagination
            dataHeaders={[
              {
                label: 'Folio',
                key: 'folio',
              },
              {
                label: 'Cliente',
                key: 'businessName',
              },
              {
                label: 'Deudor',
                key: 'stakeholderName',
              },
              {
                label: 'Monto',
                key: 'amount',
                type: 'money',
              },
            ]}
            searchData={setUp}
            data={documents}
            rowsPerPageDefault={10}
            selected={selectedDocuments}
            setSelected={setSelectedDocuments}
            cantSelectRow={cantSelect}
            setData={setDocuments}
            pagination={pagination}
            setPagination={setPagination}
          />
          <DialogActions>
            <CustomButton color="secondary" onClick={() => setOpen(false)}>
              Cancelar
            </CustomButton>
            <CustomButton onClick={confirmSelection}>Seleccionar</CustomButton>
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentSelector;
