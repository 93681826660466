import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import {
  formatDate,
  formatMoney,
  validatePermissions,
} from '../../../../../../../../lib/functions/utils';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../../../../../../services/redux/authSlice';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import { getDocumentDetailedForCollection } from '../../../../../../../../lib/api/collections';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import { formatDateToLocal } from '../../../../../../../../lib/designSystem/Table/cellFormat';
import NewManagement from '../NewManagement';
interface ManagementProps {
  documentId: string;
  open: boolean;
  setOpen: any;
}

const DocumentManagements: React.FC<ManagementProps> = (props) => {
  const { documentId, open, setOpen } = props;
  const [managements, setManagements] = useState([]);
  const [document, setDocument] = useState<null | any>(null);
  const [loading, setLoading] = useState(false);
  const [openCreateNewManagement, setOpenCreateNewManagement] = useState(false);

  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();

  const setUp = async () => {
    setLoading(true);
    try {
      const document = await getDocumentDetailedForCollection(
        dispatch,
        documentId,
      );
      setDocument(document);
      setManagements(document.collectionManagements);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Detalles documento </DialogTitle>
        <DialogContent style={{ minWidth: '500px' }}>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="body1">
                  <b>Folio</b>
                </Typography>
                <Typography variant="body1">{`${document?.folio}`}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  <b>Emisión</b>
                </Typography>
                <Typography variant="body1">
                  {` ${formatDate(document?.issuedDate)}`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  <b>Monto</b>
                </Typography>
                <Typography variant="body1">
                  {` ${formatMoney(document?.amount)}`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  <b>Deudor</b>
                </Typography>
                <Typography variant="body1">
                  {` ${document?.stakeholderName}`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  <b>Vencimiento</b>
                </Typography>
                <Typography variant="body1">{`${formatDateToLocal(document?.paymentDate)}`}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  <b>Abonado</b>
                </Typography>
                <Typography variant="body1">
                  {` ${formatMoney(document?.amountConciliated)}`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  <b>Mora</b>
                </Typography>
                <Typography variant="body1">
                  {` ${formatMoney(document?.arrearAmount)}`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  <b>Saldo Restante</b>
                </Typography>
                <Typography variant="body1">
                  {` ${formatMoney(document?.totalRemaining)}`}
                </Typography>
              </Grid>
            </Grid>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 0px',
            }}
          >
            <div>
              <Typography variant="body1" fontWeight="bold">
                Gestiones
              </Typography>
            </div>
            <div>
              <CustomButton
                color="primary"
                onClick={() => {
                  setOpenCreateNewManagement(true);
                }}
                disabled={!validatePermissions(user, 'collections_create')}
              >
                {' '}
                Crear{' '}
              </CustomButton>
            </div>
          </div>
          <EnhancedTable
            dataHeaders={[
              { key: 'type', label: 'Tipo' },
              { key: 'description', label: 'Descripción' },
              { key: 'state', label: 'Estado' },
              { key: 'optionName', label: 'Subestado' },
              { key: 'createdAt', label: 'Creado', type: 'date' },
              { key: 'dueDate', label: 'Vencimiento', type: 'date' },
            ]}
            data={managements}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cerrar
          </CustomButton>
        </DialogActions>
        {openCreateNewManagement && (
          <NewManagement
            openModal={openCreateNewManagement}
            setOpenModal={setOpenCreateNewManagement}
            document={document}
            setUp={setUp}
          />
        )}
      </Dialog>
    </div>
  );
};

export default DocumentManagements;
