import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { postCollectionManagement } from '../../../../../../../../lib/api';
import {
  getCollectionManagementOptionsByStateId,
  getCollectionManagementStates,
} from '../../../../../../../../lib/api/collectionManagementStates';
import { getExecutivesForDocumentCollection } from '../../../../../../../../lib/api/collections';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import LongTextWithTooltip from '../../../../../../../../lib/designSystem/LongTextWithTooltip';
import moment from 'moment';
import {
  loggerDev,
  showAlert,
  formatMoney,
} from '../../../../../../../../lib/functions/utils';
import { createUsersBusinessesPortfolio } from '../../../../../../../../lib/api';
import { OptionsGrid, CollectionState } from './components/OptionsGrid';
import { Actions } from './components/Actions';
import TwoColumnDocumentResume from '../../../../../../../../lib/designSystem/TwoColumnDocumentResume';
interface propsType {
  openModal: boolean;
  setOpenModal: any;
  document: any;
  completed?: boolean;
  setUp: any;
}

const NewManagement: React.FC<propsType> = (props: propsType) => {
  const { completed, document, setOpenModal, setUp } = props;
  const [option, setOption] = useState<any>(null);
  const [description, setDescription] = useState('');
  const [checkFields, setCheckField] = useState(false);
  const [options, setOptions] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [stateId, setStateId] = useState<number>(0);
  const [stateName, setStateName] = useState('');
  const [stateOptions, setStateOptions] = useState<CollectionState[]>([]);
  const [completedCheckbox, setCompletedCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactMethod, setContactMethod] = useState('');
  const today = moment()
    .utcOffset(new Date().getTimezoneOffset() * -1)
    .format('YYYY-MM-DD');
  const [dueDate, setDueDate] = useState(today);
  const [completedDate, setCompletedDate] = useState(today);
  const [documentHasExecutives, setDocumentHasExecutives] = useState(false);
  const [executivesOptions, setExecutivesOptions] = useState([]);
  const [executiveId, setExecutiveId] = useState(0);
  const [isManager, setIsManager] = useState(false);
  const [byStakeholder, setByStakeholder] = useState(false);
  const [portfolioExecutiveId, setPortfolioExecutiveId] = useState(0);
  const dispatch = useDispatch();

  const checkInputFields = () => {
    if (
      !description.length ||
      option === '' ||
      stateId === 0 ||
      contactMethod === '' ||
      dueDate === ''
    ) {
      return true;
    }
    return false;
  };

  const handleSend = async () => {
    setCheckField(checkInputFields());
    if (checkInputFields()) return;
    setLoading(true);
    try {
      await postCollectionManagement(dispatch, {
        description: description,
        type: contactMethod,
        optionId: option,
        documentId: document.id,
        state: stateName,
        dueDate: new Date(dueDate).toISOString(),
        completedAt: completedCheckbox
          ? new Date(completedDate).toISOString()
          : null,
        executiveId: executiveId,
      });
      setOpenModal(false);
      showAlert(dispatch, 'success', 'Tu gestión de cobranza ha sido creada');
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await setUp();
    } catch (error) {
      showAlert(
        dispatch,
        'error',
        'Hubo un error al crear la gestión de cobranza',
      );
      loggerDev(error);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSelectState = async (value: number) => {
    setDisabled(true);
    setStateId(value);
    const selectedState = stateOptions.find((element) => element.id === value);
    setStateName(selectedState ? selectedState.name : '');
    await getOptions(value);
    setDisabled(false);
  };

  const getOptions = async (selectedStateId: number) => {
    setLoading(true);
    try {
      const result = await getCollectionManagementOptionsByStateId(
        dispatch,
        selectedStateId,
      );
      setOptions(result);
    } catch (error) {
      loggerDev(error);
    }
    setLoading(false);
  };

  const setUpOptions = async () => {
    setLoading(true);
    try {
      const result = await getCollectionManagementStates(dispatch);
      setStateOptions(result);
      const query = `businessIdentifier=${document.businessIdentifier}&stakeholderIdentifier=${document.stakeholderIdentifier}`;
      const resultExecutives = await getExecutivesForDocumentCollection(
        dispatch,
        query,
      );
      setDocumentHasExecutives(resultExecutives.documentHasExecutives);
      setExecutivesOptions(resultExecutives.executives);
      setIsManager(resultExecutives.isManager);
      setByStakeholder(resultExecutives.byStakeholder);
      setPortfolioExecutiveId(0);
      setExecutiveId(0);
    } catch (error) {
      loggerDev(error);
    }
    setLoading(false);
  };

  const handleAssignExecutive = async () => {
    setLoading(true);
    try {
      await createUsersBusinessesPortfolio(dispatch, portfolioExecutiveId, {
        businessIdentifiers: byStakeholder
          ? [document.stakeholderIdentifier]
          : [document.businessIdentifier],
      });
      showAlert(dispatch, 'success', 'Ejecutivo asignado correctamente');
      setUpOptions();
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al asignar ejecutivo');
      loggerDev(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpOptions();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <Dialog open={props.openModal} onClose={() => handleClose()}>
      <DialogTitle> Crear nueva gestión de cobranza</DialogTitle>
      <DialogContent style={{ minWidth: '500px' }}>
        <TwoColumnDocumentResume document={document} maxBusinessLength={40} />
        <Divider style={{ margin: '12px 0px' }} />
        <OptionsGrid
          executiveId={executiveId}
          documentHasExecutives={documentHasExecutives}
          byStakeholder={byStakeholder}
          document={document}
          checkFields={checkFields}
          option={option}
          setOption={setOption}
          setExecutiveId={setExecutiveId}
          loading={loading}
          executivesOptions={executivesOptions}
          portfolioExecutiveId={portfolioExecutiveId}
          setPortfolioExecutiveId={setPortfolioExecutiveId}
          stateId={stateId}
          handleSelectState={handleSelectState}
          stateOptions={stateOptions}
          isManager={isManager}
          completedCheckbox={completedCheckbox}
          setCompletedCheckbox={setCompletedCheckbox}
          completedDate={completedDate}
          dueDate={dueDate}
          description={description}
          setDescription={setDescription}
          contactMethod={contactMethod}
          disabled={disabled}
          completed={completed}
          setDueDate={setDueDate}
          setCompletedDate={setCompletedDate}
          setContactMethod={setContactMethod}
          options={options}
        />
      </DialogContent>
      <Actions
        handleClose={handleClose}
        documentHasExecutives={documentHasExecutives}
        handleSend={handleSend}
        loading={loading}
        stateId={stateId}
        option={option}
        contactMethod={contactMethod}
        description={description}
        dueDate={dueDate}
        handleAssignExecutive={handleAssignExecutive}
        portfolioExecutiveId={portfolioExecutiveId}
      />
    </Dialog>
  );
};

export default NewManagement;
