import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import { getBusinessRelation } from '../../../../../../../lib/api';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../lib/designSystem/HeaderWithActions';
import ShoppingCartFactoring from '../../components/ShoppingCart';
import CustomAlert from '../../../../../../../lib/designSystem/Alert';
import {
  validateCompanyTypes,
  showAlert,
} from '../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../services/redux/authSlice';
import ShoppingDialogRecieved from '../../../../../../Finance/Components/Financing/Factoring/components/Recieved/components/ShoppingDialog';
import AddInvoicesManually from '../../components/addInvoices';
import { getBlockedInvoices } from '../../../../../../../lib/api/express';
import { getBusinessInvoices } from '../../../../../../../lib/api/business';
import DocumentSearcher from '../../../../../../../lib/common/DocumentSearcher';
import TableWithPagination from '../../../../../../../lib/designSystem/TableWithPagination';
import ReEnrollment from '../../../../../../Express/ReEnrollment';

const BusinessInvoicesEmitted = (props: any) => {
  const { business, id } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [emittedInvoices, setEmittedInvoices] = useState([]);
  const [exchangeDay, setExchangeDay] = useState(false);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [selectedDocuments, setSelectedDocuments] = React.useState([]);
  const [openShoppingCart, setOpenShoppingcart] = useState(false);
  const [continueToSimulation, setContinueToSimulation] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<any>({});
  const [blockedFolios, setBlockedFolios] = useState<any>([]);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [successText, setSuccesText] = useState('');
  const [failedMessage, setFailedText] = useState('');
  const [buttonsData, setButtonsData] = useState<CustomButtonProps[]>([]);
  const [recentlyCreated, setRecentlyCreated] = useState(false);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [onlySimulation, setOnlySimulation] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [openAddInvoices, setOpenAddInvoices] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const PAGE_SIZE = 10;

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });
  const [expressNotEnrolled, setExpressNotEnrolled] = useState(false);

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (startDate) query += `startDate=${startDate}&`;
    if (endDate) query += `endDate=${endDate}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const setSuccessOpen = (text: string) => {
    setSuccess(true);
    setSuccesText(text);
  };

  const setFailureOpen = (text: string) => {
    setFailed(true);
    setFailedText(text);
  };
  const handleContinueToSimulation = () => {
    setContinueToSimulation(true);
  };

  const blockInvoicesInOperations = async (invoices: any) => {
    if (!user?.express) return setBlockedFolios([]);
    const invoicesFolios = invoices.map((x: any) => x.folio);
    const blockedInvoices = await getBlockedInvoices(
      dispatch,
      user.clientId as string,
      invoicesFolios,
    );
    const emittedInvoicesWithBlockStatus = invoices.map((x: any) => {
      if (blockedInvoices.includes(x.folio)) {
        x.status = 'Solicitud';
      }
      return x;
    });
    setEmittedInvoices(emittedInvoicesWithBlockStatus);
    setBlockedFolios(blockedInvoices);
  };

  const executeExpressMainSetup = async (
    invoices: any,
    businessIdentifier: string,
  ) => {
    const businessRelationResponse = await getBusinessRelation(
      dispatch,
      businessIdentifier,
    );
    const isRecent =
      new Date(businessRelationResponse?.createdAt) >
      new Date(new Date().getTime() - 300 * 1000);
    if (isRecent) {
      setRecentlyCreated(true);
    }
    await blockInvoicesInOperations(invoices);
  };

  const searchEmmitedInvoices = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    try {
      setLoading(true);
      console.log('setting up', id, user?.businessIdentifier);
      const businessIdentifier = id ? id : user?.businessIdentifier;
      if (!businessIdentifier) return setLoading(false);

      const query = generateQuery({ page, pageSize });
      const response = await getBusinessInvoices(
        dispatch,
        businessIdentifier,
        'emitted',
        query,
      );
      setEmittedInvoices(response.data);
      setPagination(response.pagination);
      if (user?.express) {
        await executeExpressMainSetup(response.data, businessIdentifier);
      }
      setFirstLoad(true);
    } catch (error) {
      if (user?.express) {
        showAlert(
          dispatch,
          'error',
          `${user.companyName} RUT ${user.businessIdentifier} ya no se encuentra enrolado en Pruff.`,
        );
        setExpressNotEnrolled(true);
      } else {
        showAlert(
          dispatch,
          'error',
          'Ocurrió un error al cargar las facturas emitidas',
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const setUp = async () => {
    let newButtonsData: CustomButtonProps[] = [];
    if (!firstLoad)
      await searchEmmitedInvoices({ page: 0, pageSize: PAGE_SIZE });
    if (validateCompanyTypes(user, 'Accountant')) {
      newButtonsData = [
        {
          action: () => handleFactoring(),
          name: 'Factorizar',
          color: 'primary',
          disabled: !selected.length,
        },
      ];
    }
    if (validateCompanyTypes(user, 'FactoringQuoting')) {
      if (validateCompanyTypes(user, 'FinancingHub')) {
        setOnlySimulation(false);
      } else {
        newButtonsData = [
          {
            action: () => handleSimulation(),
            name: 'Simular',
            color: 'primary',
            disabled: !selected.length,
          },
        ];
      }
    }
    setButtonsData(newButtonsData);
  };

  useEffect(() => {
    setUp();
  }, [selected]);

  if (loading && user?.express)
    return (
      <CenteredCircularProgress type={user?.express ? 'noLayout' : 'layout'} />
    );

  const handleFactoring = () => {
    const selectedInvoices = emittedInvoices.filter((x: any) =>
      selected.includes(x.id),
    );
    setSelectedDocuments(selectedInvoices);
    setOpenShoppingcart(true);
  };

  const handleSimulation = () => {
    const selectedInvoices = emittedInvoices.filter((x: any) =>
      selected.includes(x.id),
    );
    setSelectedDocuments(selectedInvoices);
    setOpenShoppingcart(true);
  };

  const cantSelectRow = (row: any) => {
    if (row.status !== 'Transable') return true;
    if (blockedFolios.includes(row.folio)) return true;

    if (row.cancelDate !== null) return true;

    return false;
  };

  const canSelect = () => {
    if (validateCompanyTypes(user, 'FinancingHub')) return false;
    return (
      validateCompanyTypes(user, 'Accountant') ||
      validateCompanyTypes(user, 'FactoringQuoting')
    );
  };

  if (expressNotEnrolled && user?.express) return <ReEnrollment />;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <HeaderWithActions
          buttons={[
            {
              name: 'Filtro',
              action: () => {},
              color: 'primary',
              customButton: (
                <DocumentSearcher
                  searchData={searchEmmitedInvoices}
                  setData={setEmittedInvoices}
                  setLoading={setLoading}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  stakeholderIdentifier={stakeholderIdentifier}
                  setStakeholderIdentifier={setStakeholderIdentifier}
                  pagination={pagination}
                />
              ),
            },
            ...buttonsData,
          ]}
          title={
            user?.express
              ? 'Estas son las facturas que has emitido, selecciona para factorizar.'
              : 'Aquí verás las facturas emitidas.'
          }
          variant="body1"
        />
        {recentlyCreated && (
          <Typography variant="body1" color="error">
            *Esta empresa fue conectada hace menos de 5 minutos, algunas
            facturas todavía se están cargando...
          </Typography>
        )}
        <TableWithPagination
          tableSize="small"
          exportToFile="xlsx"
          dataHeaders={[
            { key: 'folio', label: 'Folio' },
            { key: 'stakeholderName', label: 'Cliente' },
            { key: 'stakeholderIdentifier', label: 'Rut cliente' },

            { key: 'amount', label: 'Monto', type: 'money' },
            { key: 'issuedDate', label: 'Emisión', type: 'date' },
            {
              key: 'creditNoteAmount',
              label: 'Nota de crédito',
              type: 'money-colored',
            },
            {
              key: 'cancelDate',
              label: 'Reclamo',
              type: 'date-colored',
            },
            {
              key: 'confirmDate',
              label: 'Acuse',
              type: 'date-colored-ok',
            },
            { key: 'cessionary', label: 'Cedida' },
            { key: 'status', label: 'Estado', type: 'cession' },
          ]}
          rowsPerPageDefault={20}
          loading={loading}
          setData={setEmittedInvoices}
          searchData={searchEmmitedInvoices}
          setPagination={setPagination}
          pagination={pagination}
          data={emittedInvoices}
          selected={canSelect() ? selected : null}
          setSelected={setSelected}
          cantSelectRow={cantSelectRow}
        />
      </Grid>
      {openAddInvoices && (
        <AddInvoicesManually
          open={openAddInvoices}
          setOpen={setOpenAddInvoices}
          loading={loading}
          setLoading={setLoading}
          setSuccessText={setSuccesText}
          setSuccess={setSuccess}
          businessId={id}
          business={business}
          setUp={setUp}
        />
      )}

      {openShoppingCart && (
        <ShoppingCartFactoring
          selectedDocuments={selectedDocuments}
          setOpenShoppingcart={setOpenShoppingcart}
          openShoppingCart={openShoppingCart}
          exchangeDay={exchangeDay}
          setExchangeDay={setExchangeDay}
          setSuccess={setSuccess}
          setFailed={setFailed}
          setSuccesText={setSuccesText}
          setFailedText={setFailedText}
          setContinueToSimulation={handleContinueToSimulation}
          setSelectedRequest={setSelectedRequest}
          setSelectedDocuments={setSelectedDocuments}
        />
      )}
      {continueToSimulation && (
        <ShoppingDialogRecieved
          openShoppingCart={continueToSimulation}
          setOpenShoppingCart={setContinueToSimulation}
          selectedRequest={selectedRequest}
          setSelectedRequest={setSelectedRequest}
          newRequest
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          setUp={setUp}
          executiveCreating
          shoppingCartType="Revisada"
          onlySimulation={onlySimulation}
        />
      )}

      <CustomAlert
        setOpen={setSuccess}
        open={success}
        title="Exito"
        text={successText}
        severity="success"
      />
      <CustomAlert
        setOpen={setFailed}
        open={failed}
        title="Oops"
        text={failedMessage}
        severity="error"
      />
    </Grid>
  );
};

export default BusinessInvoicesEmitted;
