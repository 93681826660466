import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from '../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from '@mui/material';
import CustomButton from '../../../../../../lib/designSystem/Button';
import {
  copyToClipboard,
  showAlert,
} from '../../../../../../lib/functions/utils';
import CopyIcon from '@mui/icons-material/FileCopy'; // You can choose hanother appropriate icon

interface UserProfile {
  name: string;
  email: string;
  enrollmentLink: string;
}

const EnrollmentLinkDialog = (props: any) => {
  const { handleCloseDialog, openAddDialog, user } = props;

  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState<UserProfile>({
    name: '',
    email: '',
    enrollmentLink: '',
  });
  const [loading, setLoading] = useState(true);

  const setUpProfile = async () => {
    setLoading(true);
    try {
      const userProfile = await getUser(dispatch, { id: user.id });
      setUserProfile(userProfile);
    } catch (error) {
      setLoading(false);
      showAlert(
        dispatch,
        'error',
        'No se pudo obtener el link de enrolamiento',
      );
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpProfile();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  return (
    <Dialog fullWidth open={openAddDialog} onClose={() => handleCloseDialog()}>
      <DialogTitle>Link de enrolamiento</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Este es el link de enrolamiento para el usuario {userProfile.name} (
          {userProfile.email})
        </Typography>
        <div style={{ marginTop: '16px' }}>
          <Typography variant="body2">
            {' '}
            <b>Link de enrolamiento: </b>
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 0px',
            }}
          >
            <IconButton
              onClick={() => copyToClipboard(userProfile.enrollmentLink)}
              size="small"
              aria-label="copy"
            >
              <CopyIcon fontSize="small" />
            </IconButton>
            <Typography>
              {userProfile?.enrollmentLink.slice(0, 45)}
              ...
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => handleCloseDialog()} color="secondary">
          Salir
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default EnrollmentLinkDialog;
