import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Users from './Components/Users';
import EnhancedTab from '../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../lib/functions/utils';
import { useSelector } from 'react-redux';
import { AuthState } from '../../services/redux/authSlice';
import Products from './Components/Products';
import RiskTab from './Components/Risk';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import FinanceTab from './Components/Finance';
import SettingsSii from './Components/SII';

const companyTabs = [
  {
    label: 'Usuarios',
    component: <Users />,
    mainPage: 'users',
    subPage: 'summary',
    index: 0,
  },
  {
    label: 'Productos',
    component: <Products />,
    mainPage: 'products',
    subPage: 'summary',
    index: 1,
  },
  {
    label: 'Riesgo',
    component: <RiskTab />,
    mainPage: 'risk',
    subPage: 'engines',
    index: 2,
  },
  {
    label: 'Finanzas',
    component: <FinanceTab />,
    mainPage: 'finance',
    subPage: 'collections',
    index: 3,
  },
  {
    label: 'SII',
    component: <SettingsSii />,
    mainPage: 'sii',
    subPage: 'certificates',
    index: 4,
  },
];

const AdminDashboard: React.FC = () => {
  const { mainPage } = useParams<{ page: string; mainPage: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [compnayTabs, setCompanyTabs] = useState<any>(companyTabs);
  const [startValue, setStartValue] = useState(0);

  const handleNavigation = (index: number) => {
    navigate(
      `/settings/${compnayTabs[index].mainPage}/${compnayTabs[index].subPage}`,
    );
  };
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const setTabs = async () => {
    setLoading(true);
    const newCompanyTabs: any = [companyTabs[0]];
    if (validateCompanyTypes(user, 'FinancingHub')) {
      if (validatePermissions(user, 'settings')) {
        newCompanyTabs.push(companyTabs[1]);
        newCompanyTabs.push(companyTabs[2]);
        newCompanyTabs.push(companyTabs[3]);
      } else {
        if (validatePermissions(user, 'settings_products')) {
          newCompanyTabs.push(companyTabs[1]);
        }
        if (validatePermissions(user, 'settings_risk')) {
          newCompanyTabs.push(companyTabs[2]);
        }
        if (validatePermissions(user, 'settings_finance')) {
          newCompanyTabs.push(companyTabs[3]);
        }
      }
    }
    if (validateCompanyTypes(user, 'Accountant')) {
      if (validatePermissions(user, 'settings_finance')) {
        newCompanyTabs.push(companyTabs[3]);
      }
    }
    if (validateCompanyTypes(user, 'CreditHubPro')) {
      if (validatePermissions(user, 'settings_products')) {
        newCompanyTabs.push(companyTabs[1]);
      }
      if (validatePermissions(user, 'settings_risk')) {
        newCompanyTabs.push(companyTabs[2]);
      }
    }
    if (validatePermissions(user, 'settings_sii')) {
      newCompanyTabs.push(companyTabs[4]);
    }
    const uniqueTabs = newCompanyTabs.filter(
      (v: any, i: any, a: any) =>
        a.findIndex((t: any) => t.mainPage === v.mainPage) === i,
    );
    setCompanyTabs(uniqueTabs);
    const startValue = newCompanyTabs.findIndex(
      (x: any) => x.mainPage === `${mainPage}`,
    );
    setStartValue(startValue);
    setLoading(false);
  };

  useEffect(() => {
    setTabs();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <EnhancedTab
        title="Configuración"
        startValue={startValue}
        tabs={compnayTabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default AdminDashboard;
