import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { cellFormat } from '../../../../../../lib/designSystem/Table/cellFormat';
import { useDispatch } from 'react-redux';
import ExportDialog from './ExportDialog';

const statusSteps: any = {
  sent: {
    Validando:
      'Estamos revisando que los documentos sean los correctos. Cualquier duda, te contactaremos por el chat.',
    Revisada:
      'Los documentos ya fueron revisados por nuestros analistas. En breve te se te asignará un monto de línea de crédito.',
    'En revisión':
      'Nos encontramos realizando el análisis de riesgo. En breve te notificaremos el resultado.',
    'Faltan datos':
      'Tienes que corregir los documentos que subiste. En el chat encontrás que correcciones hacer. Si ya lo hiciste, confirma la revisión para proceder con el análisis de riesgo.',
  },
  received: {
    Validando:
      'Ya están los documentos requeridos. Si estos están correctos confirma la revisión para proceder con el análisis de riesgo.',
    Revisada:
      'Esta línea tiene aprobado el análisis de riesgo, ahora debes asignarle el monto y las concentraciones correspondientes.',
    'En revisión':
      'Los documentos ya han sido validados, ahora se debe rechazar o dar por revisado el riesgo. Luego de esto podremos asignarle el monto y las concentraciones correspondientes.',
    'Faltan datos':
      'Se le notificó al cliente que corrija sus documentos. Apenas lo haga esta solicitud cambiará de estado a Validando.',
  },
};

const CreditLineStatus = (props: any) => {
  const { openRequest, audienceType } = props;
  const dispatch = useDispatch();
  const [openExportDialog, setOpenExportDialog] = useState(false);

  return (
    <div>
      {openExportDialog && (
        <ExportDialog
          openExportDialog={openExportDialog}
          setOpenExportDialog={setOpenExportDialog}
          openRequest={openRequest}
          dispatch={dispatch}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Resumen
        </Typography>
        <Typography
          variant="body1"
          onClick={() => setOpenExportDialog(true)}
          style={{ cursor: 'pointer', color: '#0F23DF', fontWeight: 'bold' }}
        >
          Exportar
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '6px 0px',
        }}
      >
        <Typography variant="body1">
          <b>Estado</b>
        </Typography>
        <Typography variant="body1">
          {cellFormat(openRequest.status, 'status')}
        </Typography>
      </div>
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 0px',
        }}
      >
        <Typography variant="body1">
          <b>Fecha de solicitud</b>
        </Typography>
        <Typography variant="body1">
          {cellFormat(openRequest.createdAt, 'date')}
        </Typography>
      </div> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 0px',
        }}
      >
        <Typography variant="body1">
          <b>Producto</b>
        </Typography>
        <Typography variant="body1">{openRequest.productName}</Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 0px',
        }}
      >
        <Typography variant="body1">
          <b>Vencimiento</b>
        </Typography>
        <Typography variant="body1">
          {cellFormat(openRequest.validUntil, 'date')}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 0px',
        }}
      >
        <Typography variant="body1">
          <b>Monto</b>
        </Typography>
        <Typography variant="body1">
          {cellFormat(openRequest.amount, 'money')}
        </Typography>
      </div>

      {statusSteps[audienceType][openRequest.status] && (
        <div
          style={{
            padding: '10px 0px',
          }}
        >
          <div>
            <Typography variant="body1">
              <b>Pasos a seguir</b>
            </Typography>

            <Typography variant="body1">
              {statusSteps[audienceType][openRequest.status]}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreditLineStatus;
