import { requestHandler } from '../../api';

export const deleteFinancialStatement = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/business/${businessId}/financialStatement/${data.financialStatementId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const uploadFinancialStatement = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/financialStatement`,
    data: data.data,
    headers,
    dispatch,
  });
  if (response) return response.data;
};

export const getFinancialStatements = async (
  dispatch: any,
  businessId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/financialStatement`,
    dispatch,
  });
  if (response) return response.data;
};

export const analizeFinancialStatement = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/financialStatement/${data.financialStatementId}/analize`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFinancialNodesForAssignation = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/financialStatement/${data.financialStatementId}/analize`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateFinancialStatement = async (
  dispatch: any,
  businessId: string,
  financialStatementId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'put',
    url: `internal/business/${businessId}/financialStatement/${financialStatementId}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteUploadedBalance = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/business/${businessId}/financialStatement/${data.financialStatementId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getUploadedSignedUrl = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/financialStatement/${data.financialStatementId}/download`,
    dispatch,
  });
  if (response) return response.data;
};

export const assignFinancialStatement = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/business/${businessId}/financialStatement/${data.financialStatementId}/assign`,
    data: data.assignations,
    dispatch,
  });
  if (response) return response.data;
};

export const getFinancialStatementAssignationsSummary = async (
  dispatch: any,
  businessId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/financialStatement/summary${data.emptyer ? `?emptyer=true` : ''}`,
    dispatch,
  });
  if (response) return response.data;
};

// Company financialTrees
export const getFinancialTrees = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/financialTrees`,
    dispatch,
  });
  if (response) return response.data;
};

export const reportFinancialStamentError = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/events/fs-error`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getFinancialStatementsStats = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/financialStatements/getFinancialStatementsStats`,
    dispatch,
  });
  if (response) return response.data;
};

export const getPendingFinancialStatementsForAssignation = async (
  dispatch: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/financialStatements/getPendingFinancialStatementsForAssignation`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFinancialStatementsForAssignated = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/financialStatements/getFinancialStatementsForAssignated`,
    dispatch,
  });
  if (response) return response.data;
};
