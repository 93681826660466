import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import EnhancedTab from '../../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { getBusiness } from '../../../lib/api';
import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import Client from './components/Client';
import Contacts from './components/Contacts';
import BankAccounts from './components/BankAccounts';
import OperativeProfile from './components/Operative';
import ProfileCompliance from './components/Compliance';

const Profile = () => {
  const { id, page } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();
  const currentPath = window.location.pathname;
  const initialPath = currentPath.split('/')[2];
  const temporary = initialPath === 'temporary';
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState({ name: '', companies: [] });
  const [companyTabs, setCompanyTabs] = useState<any>([]);
  const navigate = useNavigate();

  const [startValue, setStartValue] = useState(0);
  const handleNavigation = (index: number) => {
    navigate(`/companies/${id}/profile/${companyTabs[index].path}`);
  };

  const handleNavigationTemporary = (index: number) => {
    navigate(`/companies/temporary/${id}/profile/${companyTabs[index].path}`);
  };
  console.log(page);

  const mainBusiness = async () => {
    if (!id) {
      setLoading(false);
      return alert('Ha ocurrido un error');
    }
    const business = await getBusiness(dispatch, id);
    console.log(business);
    const newProfileTabs: any = [...tabs];
    let startValueDefault = 0;
    startValueDefault = newProfileTabs.findIndex((x: any) => x.path === page);
    setCompanyTabs(newProfileTabs);
    console.log(newProfileTabs);
    console.log(startValueDefault);
    setStartValue(startValueDefault === -1 ? 0 : startValueDefault);
    setBusiness(business);
  };

  const tabs = [
    {
      id: 1,
      label: 'Resumen',
      component: <Client />,
      path: 'summary',
    },
    {
      id: 2,
      label: 'Contactos',
      component: <Contacts />,
      path: 'contacts',
    },
    {
      id: 3,
      label: 'Banco',
      component: <BankAccounts />,
      path: 'bankAccounts',
    },
    {
      id: 4,
      label: 'Operativo',
      component: <OperativeProfile businessIdentifier={id} />,
      path: 'operative',
    },
    {
      id: 5,
      label: 'Compliance',
      component: <ProfileCompliance />,
      path: 'compliance',
    },
  ];

  const fetchRelations = async () => {
    console.log(id, temporary);
    setLoading(true);
    if (!id) {
      return setLoading(false);
    }
    await mainBusiness();

    // if (!temporary) {
    //   await mainBusiness()
    // } else {
    //   await temporaryBusiness()
    // }

    setLoading(false);
  };

  useEffect(() => {
    fetchRelations();
  }, []);
  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <EnhancedTab
        tabType="secondary"
        startValue={startValue}
        title={temporary ? business.name : undefined}
        tabs={companyTabs}
        onTabChange={!temporary ? handleNavigation : handleNavigationTemporary}
        // canShowTab={checkCanAccessView()}
      />
    </div>
  );
};

export default Profile;
