import React, { useEffect, useState } from 'react';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { validaRut } from '../../functions/utils';
import { getBusinessRelationsForUser } from '../../api';
import { useDispatch } from 'react-redux';
import CustomButton from '../../designSystem/Button';
import CenteredCircularProgress from '../../designSystem/CircularProgress';

const CompanyBusinessSearcher = (props: any) => {
  const { setBusiness, setSearched } = props;
  const [loading, setLoading] = useState(false);
  const [businessSearched, setBusinessSearched] = useState<any>({});
  const [businesses, setBusinesses] = useState<any>([]);

  const dispatch = useDispatch();
  console.log('setup:');

  const setUpBusinesses = async () => {
    setLoading(true);
    console.log('Setting up businesses');
    try {
      const results = await getBusinessRelationsForUser(dispatch);
      if (results) {
        console.log(results);
        setBusinesses(results.relations);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleChange = (event: any, newValue: any) => {
    setSearched(false);
    setBusiness(newValue);
    console.log('Selected Business ID:', newValue ? newValue.id : '');
  };

  useEffect(() => {
    setUpBusinesses();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <div style={{ padding: '10px 0px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Autocomplete
              options={businesses}
              getOptionLabel={(option) =>
                `${option.businessName} - ${option.identifier}`
              }
              renderInput={(params) => (
                <TextField {...params} label="Seleccionar una empresa" />
              )}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} textAlign="center">
            <CustomButton color="primary" onClick={() => setSearched(true)}>
              Seleccionar
            </CustomButton>
          </Grid>
        </Grid>
      </div>
      {businesses.name && (
        <Typography variant="caption">
          {' '}
          Empresa encontrada: {businessSearched.name}
        </Typography>
      )}
    </div>
  );
};

export default CompanyBusinessSearcher;
