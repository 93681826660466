import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import { getCollectionManagementStates } from '../../../../../../../../lib/api/collectionManagementStates';
import { createCollectionFlow } from '../../../../../../../../lib/api/collectionFlow';
import { showAlert } from '../../../../../../../../lib/functions/utils';
import StepGrid from './components/StepGrid';
import ManualGrid from './components/ManualGrid';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import { Variant } from '../../../../../../../../lib/designSystem/CustomTimeline';
import Preview from './components/Preview';
import AutomaticGrid from './components/AutomaticGrid';
import FlowGrid from './components/FlowGrid';

const itemsCreation = [
  {
    left: {
      variantPrimary: 'body1' as Variant,
      textPrimary: 'Fecha de creación',
    },
    right: {
      textPrimary: '',
    },
    icon: <TodayIcon />,
    days: -Infinity,
    iconVariant: 'filled',
  },
];
const itemsDue = [
  {
    left: {
      variantPrimary: 'body1' as Variant,
      textPrimary: 'Fecha de vencimiento',
    },
    right: {
      textPrimary: '',
    },
    icon: <EventIcon />,
    days: 0,
    iconVariant: 'filled',
  },
];

interface CreateStateProps {
  open: boolean;
  setOpen: any;
  parentSetUp: () => void;
}

const CreateFlow: React.FC<CreateStateProps> = (props) => {
  const { open, setOpen, parentSetUp } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // For flow
  const [flowName, setFlowName] = useState('');
  const [flowDescription, setFlowDescription] = useState('');
  const [flowSteps, setFlowSteps] = useState<any[]>([]);
  const [missingData, setMissingData] = useState(false);

  // For steps
  const [stepType, setStepType] = useState<'Manual' | 'Automático'>(
    'Automático',
  );
  const [stepName, setStepName] = useState('');
  const [stepDescription, setStepDescription] = useState('');
  const [stepDays, setStepDays] = useState<number | null>(0);
  const [stepRegard, setStepRegard] = useState('DUEDATE');
  const [missingFields, setMissingFields] = useState(false);
  const [daysError, setDaysError] = useState(false);

  // For automatic steps
  const [automaticStepTemplateId, setAutomaticStepTemplateId] = useState('');

  // For manual steps
  const [stateOptions, setStateOptions] = useState<any[]>([]);
  const [optionOptions, setOptionOptions] = useState<any[]>([]);
  const [stateId, setStateId] = useState<number>(0);
  const [optionId, setOptionId] = useState<number>(0);
  const [disabled, setDisabled] = useState(false);
  const [contactMethod, setContactMethod] = useState<string>('');
  const [manualDescription, setManualDescription] = useState<string>('');

  // For timeline
  const [timelineItemsCreation, setTimelineItemsCreation] =
    useState<any[]>(itemsCreation);
  const [timelineItemsDue, setTimelineItemsDue] = useState<any[]>(itemsDue);
  const [countId, setCountId] = useState<number>(0);

  const handleSelectState = async (value: number) => {
    setDisabled(true);
    setStateId(value);
    const selectedState = stateOptions.find((element) => element.id === value);
    setOptionOptions(
      selectedState ? selectedState.collectionManagementOptions : [],
    );
    setDisabled(false);
  };

  const handleCreate = async () => {
    const missingDataCheck = !flowName || !flowDescription;
    setMissingData(missingDataCheck);
    if (missingDataCheck) {
      return;
    }
    if (flowSteps.length === 0) {
      showAlert(dispatch, 'error', 'No se pueden crear flujos sin gestiones');
      return;
    }
    setLoading(true);
    try {
      await createCollectionFlow(dispatch, {
        collectionFlowData: {
          name: flowName,
          description: flowDescription,
        },
        collectionFlowStepsData: flowSteps,
      });
      showAlert(dispatch, 'success', 'Flujo creado con éxito');
    } catch (error) {
      showAlert(dispatch, 'error', 'Ha habido un error al crear el flujo');
    }
    parentSetUp();
    setOpen(false);
    setLoading(false);
  };

  const handleSetStepType = (value: 'Manual' | 'Automático') => {
    setStepType(value);
    setManualDescription('');
    setContactMethod('');
    setStateId(0);
    setOptionId(0);
    setAutomaticStepTemplateId('');
    setDaysError(false);
  };

  const resetStepFields = () => {
    setStepName('');
    setStepDescription('');
    setStepDays(0);
    setStepRegard('DUEDATE');
    setDaysError(false);
    setManualDescription('');
    setContactMethod('');
    setStateId(0);
    setOptionId(0);
    setAutomaticStepTemplateId('');
  };

  const handleAddStep = () => {
    const missingFieldsCheck = checkMissingFields();
    setMissingFields(missingFieldsCheck);
    if (missingFieldsCheck) {
      return;
    }
    if (stepType === 'Manual') {
      setFlowSteps([
        ...flowSteps,
        {
          id: `${countId}`,
          name: stepName,
          description: stepDescription,
          days: stepDays,
          regard: stepRegard,
          manualStep: {
            contactMethod: contactMethod,
            description: manualDescription,
            stateId: stateId,
            optionId: optionId,
          },
        },
      ]);
    }
    if (stepType === 'Automático') {
      setFlowSteps([
        ...flowSteps,
        {
          id: `${countId}`,
          name: stepName,
          description: stepDescription,
          days: stepDays,
          regard: stepRegard,
          automaticStep: {
            name: automaticStepTemplateId,
            templateId: automaticStepTemplateId,
          },
        },
      ]);
    }
    const functionToAddTimelineItem =
      stepRegard === 'CREATIONDATE'
        ? setTimelineItemsCreation
        : setTimelineItemsDue;
    const icon =
      stepType === 'Manual' ? <PersonIcon /> : <SmartToyOutlinedIcon />;
    const previousTimelineItems =
      stepRegard === 'CREATIONDATE' ? timelineItemsCreation : timelineItemsDue;

    functionToAddTimelineItem(
      [
        ...previousTimelineItems,
        {
          id: `${countId}`,
          left: {
            variantPrimary: 'body1' as Variant,
            textPrimary: stepDays
              ? stepDays > 0
                ? `+${stepDays} días`
                : `${stepDays} días`
              : '0 días',
          },
          right: {
            textPrimary: stepName,
          },
          canDelete: true,
          onDelete: () => {
            setTimelineItemsCreation((prev) =>
              prev.filter((item) => item.id !== `${countId}`),
            );
            setTimelineItemsDue((prev) =>
              prev.filter((item) => item.id !== `${countId}`),
            );
            setFlowSteps((prev) =>
              prev.filter((item) => item.id !== `${countId}`),
            );
          },
          icon: icon,
          days: stepDays,
          iconVariant: 'outlined',
        },
      ].sort((a, b) => a.days - b.days),
    );
    setCountId(countId + 1);
    resetStepFields();
  };

  const setUp = async () => {
    setLoading(true);
    try {
      const states = await getCollectionManagementStates(dispatch);
      setStateOptions(states);
    } catch (error) {
      showAlert(dispatch, 'error', 'Ha habido un error al crear el flujo');
      setOpen(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const checkMissingFields = () => {
    if (
      !stepName ||
      !stepDescription ||
      (!stepDays && stepDays != 0) ||
      !stepRegard
    ) {
      return true;
    }
    if (stepRegard === 'CREATIONDATE' && stepDays < 0) {
      setDaysError(true);
      showAlert(
        dispatch,
        'error',
        'No se pueden asignar gestiones antes de la fecha de creación del flujo.',
      );
      return true;
    }

    if (stepType === 'Manual') {
      return !contactMethod || !manualDescription || !stateId || !optionId;
    }
    if (stepType === 'Automático') {
      return !automaticStepTemplateId;
    }
    return false;
  };

  return (
    <div>
      <Dialog open={open} maxWidth="xl" fullWidth>
        <DialogTitle>Crear flujo de cobranza</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={5} md={5}>
              <div
                style={{
                  padding: '0px 0px 5px 5px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                }}
              >
                <FlowGrid
                  flowName={flowName}
                  setFlowName={setFlowName}
                  flowDescription={flowDescription}
                  setFlowDescription={setFlowDescription}
                  missingData={missingData}
                />
                <StepGrid
                  stepType={stepType}
                  missingFields={missingFields}
                  handleSetStepType={handleSetStepType}
                  stepDays={stepDays}
                  setStepDays={setStepDays}
                  stepRegard={stepRegard}
                  setStepRegard={setStepRegard}
                  daysError={daysError}
                />
                {stepType === 'Manual' && (
                  <ManualGrid
                    missingFields={missingFields}
                    stateId={stateId}
                    optionId={optionId}
                    setOptionId={setOptionId}
                    contactMethod={contactMethod}
                    setContactMethod={setContactMethod}
                    disabled={disabled}
                    loading={loading}
                    stateOptions={stateOptions}
                    optionOptions={optionOptions}
                    handleSelectState={handleSelectState}
                    manualDescription={manualDescription}
                    setManualDescription={setManualDescription}
                    setStepName={setStepName}
                    setStepDescription={setStepDescription}
                  />
                )}
                {stepType === 'Automático' && (
                  <AutomaticGrid
                    missingFields={missingFields}
                    automaticStepTemplateId={automaticStepTemplateId}
                    setAutomaticStepTemplateId={setAutomaticStepTemplateId}
                    setStepName={setStepName}
                    setStepDescription={setStepDescription}
                  />
                )}
              </div>
              <DialogActions>
                <CustomButton
                  color="secondary"
                  onClick={() => handleAddStep()}
                  disabled={loading}
                >
                  Agregar paso
                </CustomButton>
              </DialogActions>
            </Grid>
            <Grid item xs={7} md={7}>
              <Preview
                timelineItemsCreation={timelineItemsCreation}
                timelineItemsDue={timelineItemsDue}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton color="secondary" onClick={() => setOpen(false)}>
            Cancelar
          </CustomButton>
          <CustomButton
            color="primary"
            onClick={() => handleCreate()}
            disabled={loading}
          >
            Crear flujo
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateFlow;
