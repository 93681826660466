import React, { useEffect } from 'react';

import PaymentConciliationAssginationSuggested from './Suggested';
import PaymentConciliationAssginationDone from './Done';

const PaymentConciliationAssgination = (props: any) => {
  const {
    payIn,
    setDocumentsSuggested,
    documentsSuggested,
    bigSetup,
    setOpen,
  } = props;
  useEffect(() => {}, []);

  return (
    <div>
      <div>
        <PaymentConciliationAssginationDone
          payIn={payIn}
          bigSetup={bigSetup}
          setOpen={setOpen}
        />
      </div>
      <div style={{ padding: '20px 0px' }}>
        {payIn.status !== 'Conciliado' && (
          <PaymentConciliationAssginationSuggested
            payIn={payIn}
            setDocumentsSuggested={setDocumentsSuggested}
            documentsSuggested={documentsSuggested}
            bigSetup={bigSetup}
            setOpen={setOpen}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentConciliationAssgination;
