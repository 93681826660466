import { Typography } from '@mui/material';
import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';

const FactoringSteps = (props: any) => {
  const { selectedRequest, status, type } = props;
  console.log();

  const missingInvoices = selectedRequest.documents.filter(
    (x: any) => !x.active,
  ).length;

  switch (status) {
    case 'Creada':
      if (type === 'Recieved') {
        return (
          <Typography>
            Se ha creado la solicitud, está siendo procesada por el bot de
            riesgo.
          </Typography>
        );
      } else {
        return (
          <Typography>
            Has enviado tu solicitud, se está a la espera de que nuestros
            analistas la reciban.
          </Typography>
        );
      }
      break;
    case 'En revisión':
      if (type === 'Recieved') {
        return (
          <Typography>
            La solicitud está siendo revisada por nuestros analistas.
          </Typography>
        );
      } else {
        return (
          <div>
            <Typography>
              Se está revisando las condiciones de la solicitud.
            </Typography>
          </div>
        );
      }
      break;
    case 'Faltan datos':
      if (type === 'Recieved') {
        return (
          <Typography>
            Estás a la espera de que el cliente te envíe más datos, de todas
            maneras, si lo requieres puedes aprobar o rechazar la solicitud
          </Typography>
        );
      } else {
        return (
          <Typography>
            El analista necesita más información para poder continuar con la
            solicitud. Dentro del Chat podrás ver que necesita. Luego lo puedes
            subir en Archivos.
          </Typography>
        );
      }
      break;
    case 'Revisada':
      if (type === 'Recieved') {
        return (
          <Typography>
            La solicitud ya fue revisada por riesgo, ahora debes asignarle la
            tasa.
          </Typography>
        );
      } else {
        return (
          <Typography>
            Tu solicitud ya fue revisada por nuestra área de riesgo, en estos
            minutos se le está asignando la tasa. Te notificaremos cuando esté
            lista.
          </Typography>
        );
      }
      break;
    case 'Cotizada':
      if (type === 'Recieved') {
        return (
          <Typography>
            Se ha enviado la solicitud al cliente. Te notificaremos cuando el
            cliente haya tomado la decisión.
          </Typography>
        );
      } else {
        return (
          <Typography>
            Esta es la solicitud de la empresa. A la derecha encontrarás la
            información resumida. Abajo está el detalle de la factura.
            {missingInvoices ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p
                  style={{
                    margin: '6px 0px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ErrorIcon style={{ color: '#D62839', marginRight: '5px' }} />
                  No todas tus facturas están en la solicitud.
                </p>
              </div>
            ) : (
              <div />
            )}
          </Typography>
        );
      }
      break;
    case 'Aceptada':
      if (type === 'Recieved') {
        return (
          <div>
            {selectedRequest.subProductCategory === 'invoice' ? (
              <Typography>
                La solicitud ya tiene las aprobaciones necesarias. Puedes
                proceder a realizar el giro.
              </Typography>
            ) : (
              <Typography>
                La solicitud ya tiene las aprobaciones necesarias. Puedes
                proceder a realizar el giro.
              </Typography>
            )}
          </div>
        );
      } else {
        return (
          <div>
            <Typography>
              Has aceptado la solicitud. Ahora debes ceder tus facturas.
              Cualquier duda, puedes contactar a tu ejecutivo.
            </Typography>
          </div>
        );
      }
    case 'Cedida':
      if (type === 'Recieved') {
        return (
          <Typography>
            El cliente ha cedido sus facturas. Ahora debes hacer el giro de la
            solicitud.
          </Typography>
        );
      } else {
        return (
          <Typography>
            Has cedido tus facturas. Se está procesando el giro a tu cuenta
          </Typography>
        );
      }
    case 'Activa':
      if (type === 'Recieved') {
        return (
          <Typography>
            La solicitud está activa, esta se mantendrá activa hasta que las
            facturas lleguen a su vencimiento.
          </Typography>
        );
      } else {
        return (
          <Typography>
            La solicitud está activa, esta se mantendrá activa hasta que las
            facturas lleguen a su vencimiento.
          </Typography>
        );
      }
    case 'Completada':
      if (type === 'Recieved') {
        return <Typography>La solicitud ha sido completada.</Typography>;
      } else {
        return <Typography>La solicitud ha sido completada.</Typography>;
      }
    case 'Cancelada':
      if (type === 'Recieved') {
        return (
          <Typography>
            La solicitud ha sido cancelada por {selectedRequest.lastUserName}.
          </Typography>
        );
      } else {
        return (
          <Typography>
            La solicitud ha sido cancelada por {selectedRequest.lastUserName}.
          </Typography>
        );
      }
      break;
    case 'Rechazada':
      if (type === 'Recieved') {
        return <Typography>La solicitud ha sido rechazada.</Typography>;
      } else {
        return <Typography>La solicitud ha sido rechazada.</Typography>;
      }
      break;
    default:
      break;
    // case 'Confirmada':
    //   if (type === 'Recieved') {
    //     return <Typography >
    //               Has confirmado la solicitud que aceptó el cliente. Debes contactarte con
    //               el y solicitarle la cesión de sus facturas.
    //             </Typography>
    //   } else {
    //     return <Typography >
    //               La solicitud ha sido Confirmada por la empresa. Dentro de algunos minutos serás
    //               contactado por tu ejecutivo. Cualquier duda, te dejamos su información.
    //             </Typography>
    //   }
    //   break;
  }
};

export default FactoringSteps;
