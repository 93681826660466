import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../lib/designSystem/HeaderWithActions';
import TableWithPagination from '../../../lib/designSystem/TableWithPagination';
import {
  expressGetPendingAccountsPayable,
  expressGetPendingAccountsReceivable,
} from '../../../lib/api/express/pendingAccounts';

import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import { Typography } from '@mui/material';
import GlobalSearcher, { Filter } from '../../../lib/common/GlobalSearcher';
import { showAlert } from '../../../lib/functions/utils';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'folio', label: 'Folio' },
  { key: 'category', label: 'Categoría' },
  { key: 'issuedDate', label: 'Fecha', type: 'date' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'paidAt', label: 'Pagado', type: 'date' },
];

const PendingAccounts = () => {
  const dispatch = useDispatch();
  const [payableAccounts, setPayableAccounts] = useState<any[]>([]);
  const [receivableAccounts, setReceivableAccounts] = useState<any[]>([]);
  const [payablePagination, setPayablePagination] = useState<any>({
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
  });
  const [receivablePagination, setReceivablePagination] = useState<any>({
    page: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
  });
  const [loading, setLoading] = useState(true);
  const [accountType, setAccountType] = useState('Pagar');
  const [searchPaid, setSearchPaid] = useState('all');

  const domain = window.location.hostname;
  const subdomain = domain.split('.')[0];

  const createQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = `page=${page}&pageSize=${pageSize}`;
    if (searchPaid !== 'all') query += `&paid=${searchPaid}`;
    return query;
  };

  const fetchPendingAccounts = async () => {
    setLoading(true);
    try {
      const promises = [
        expressGetPendingAccountsPayable(
          dispatch,
          subdomain,
          createQuery({ page: 1, pageSize: 10 }),
        ),
        expressGetPendingAccountsReceivable(
          dispatch,
          subdomain,
          createQuery({ page: 1, pageSize: 10 }),
        ),
      ];
      const [payableAccounts, receivableAccounts] = await Promise.all(promises);
      setPayableAccounts(payableAccounts.data);
      setReceivableAccounts(receivableAccounts.data);
      setPayablePagination(payableAccounts.pagination);
      setReceivablePagination(receivableAccounts.pagination);
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al cargar las cuentas pendientes');
    }
    setLoading(false);
  };

  const searchData = async ({
    page,
    pageSize,
  }: {
    page?: number;
    pageSize?: number;
  }) => {
    setLoading(true);
    try {
      const data =
        accountType === 'Pagar'
          ? await expressGetPendingAccountsPayable(
              dispatch,
              subdomain,
              createQuery({ page: page || 1, pageSize: pageSize || 10 }),
            )
          : await expressGetPendingAccountsReceivable(
              dispatch,
              subdomain,
              createQuery({ page: page || 1, pageSize: pageSize || 10 }),
            );
      (accountType === 'Pagar' ? setPayableAccounts : setReceivableAccounts)(
        data.data,
      );
      (accountType === 'Pagar'
        ? setPayablePagination
        : setReceivablePagination)(data.pagination);
      setLoading(false);
      return data;
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al cargar las cuentas pendientes');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPendingAccounts();
  }, []);

  const filters: Filter[] = [
    {
      type: 'select',
      label: 'Estado',
      value: searchPaid,
      setter: setSearchPaid,
      options: [
        { label: 'Todos', value: 'all' },
        { label: 'Pagado', value: 'true' },
        { label: 'No Pagado', value: 'false' },
      ],
    },
  ];

  const buttons: CustomButtonProps[] = [
    {
      name: 'Buscar',
      color: 'primary',
      action: () => {},
      customButton: (
        <GlobalSearcher
          filters={filters}
          triggerSearch={() => searchData({ page: 1, pageSize: 10 })}
        />
      ),
    },
  ];

  const handleChangeType = (newValue: any) => {
    setAccountType(newValue);
    setSearchPaid('all');
  };

  if (loading) return <CenteredCircularProgress relative />;

  return (
    <div>
      <HeaderWithActions
        buttons={buttons}
        variant="body1"
        customTitle={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              onClick={() => handleChangeType('Pagar')}
              style={{
                ...(accountType === 'Pagar' ? { fontWeight: 'bold' } : {}),
                cursor: 'pointer',
              }}
            >
              Por pagar
            </Typography>
            <Typography style={{ padding: '0px 4px' }}> | </Typography>
            <Typography
              onClick={() => handleChangeType('Cobrar')}
              style={{
                ...(accountType === 'Cobrar' ? { fontWeight: 'bold' } : {}),
                cursor: 'pointer',
              }}
            >
              Por cobrar
            </Typography>
          </div>
        }
      />
      <TableWithPagination
        exportToFile="xlsx"
        data={accountType === 'Pagar' ? payableAccounts : receivableAccounts}
        setData={
          accountType === 'Pagar' ? setPayableAccounts : setReceivableAccounts
        }
        searchData={searchData}
        pagination={
          accountType === 'Pagar' ? payablePagination : receivablePagination
        }
        setPagination={
          accountType === 'Pagar'
            ? setPayablePagination
            : setReceivablePagination
        }
        dataHeaders={headers}
        loading={loading}
        finalSumRow
      />
    </div>
  );
};

export default PendingAccounts;
