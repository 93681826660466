import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessSummary } from '../../../../../lib/api';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import { showAlert } from '../../../../../lib/functions/utils';
import { AuthState } from '../../../../../services/redux/authSlice';
import Summary from './components/Summary';
import Editor from './components/Editor';

interface BusinessSummary {
  id: number;
  businessIdentifier: string;
  companyId: number;
  email: string | null;
  region: string | null;
  province: string | null;
  commune: string | null;
  address: string | null;
  economicSector: string | null;
  economicSubSector: string | null;
  economicActivity: string | null;
  salesSegment: string | null;
}

const Client = (props: any) => {
  const { businessId } = props;
  const dispatch = useDispatch();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [businessSummary, setBusinessSummary] = useState<BusinessSummary>({
    id: 0,
    businessIdentifier: '',
    companyId: 0,
    email: '',
    region: '',
    province: '',
    commune: '',
    address: '',
    economicSector: '',
    economicSubSector: '',
    economicActivity: '',
    salesSegment: '',
  });

  const { id } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();

  const setUp = async () => {
    if (!id && !businessId) {
      showAlert(dispatch, 'error', 'No se ha encontrado el id del cliente');
      return;
    }
    setLoading(true);
    setEditing(false);
    try {
      const businessSummary = await getBusinessSummary(
        dispatch,
        id || businessId,
      );
      setBusinessSummary(businessSummary);
    } catch (error) {
      showAlert(dispatch, 'error', 'Ha ocurrido un error');
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress relative />;

  return (
    <div>
      {!editing && (
        <Summary
          setEditing={setEditing}
          user={user}
          businessIdentifier={businessSummary.businessIdentifier || ''}
          email={businessSummary.email || ''}
          region={businessSummary.region || ''}
          province={businessSummary.province || ''}
          commune={businessSummary.commune || ''}
          address={businessSummary.address || ''}
          economicSector={businessSummary.economicSector || ''}
          economicSubSector={businessSummary.economicSubSector || ''}
          economicActivity={businessSummary.economicActivity || ''}
          salesSegment={businessSummary.salesSegment || ''}
        />
      )}
      {editing && (
        <Editor
          setEditing={setEditing}
          user={user}
          setUp={setUp}
          businessIdentifier={businessSummary.businessIdentifier || ''}
          email={businessSummary.email || ''}
          region={businessSummary.region || ''}
          province={businessSummary.province || ''}
          commune={businessSummary.commune || ''}
          address={businessSummary.address || ''}
          economicSector={businessSummary.economicSector || ''}
          economicSubSector={businessSummary.economicSubSector || ''}
          economicActivity={businessSummary.economicActivity || ''}
          salesSegment={businessSummary.salesSegment || ''}
        />
      )}
    </div>
  );
};

export default Client;
