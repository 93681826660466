import React, { useEffect, useState } from 'react';

import {
  Typography,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton,
} from '@mui/material';

import { useDispatch } from 'react-redux';
import { getPayInConciliationSuggestions } from '../../../../../../../../../../lib/api/conciliations';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../../../../lib/functions/utils';
import { Info, SettingsBackupRestore } from '@mui/icons-material';
import { CustomTooltip } from '../../../../../../../../../../lib/designSystem/TooltipTable';
import { LightTooltip } from '../../../../../../../../../../lib/designSystem/WhiteTooltip';
import EnhancedTable from '../../../../../../../../../../lib/designSystem/Table';

const PaymentConciliationAssginationSuggested = (props: any) => {
  const { payIn, setDocumentsSuggested, documentsSuggested } = props;

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const setUp = async () => {
    setLoading(true);
    try {
      const response = await getPayInConciliationSuggestions(dispatch, {
        id: payIn.id,
      });
      setDocumentsSuggested(response.documentsProcessed);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const handleSelectAmount = (value: any, document: any) => {
    const amount = value.split('.').join('').split('$').join('');
    if (isNaN(amount)) return;

    const maxAmount = document.totalRemaining;

    console.log(maxAmount, Number(amount));
    if (maxAmount < Number(amount)) {
      alert('El monto no puede ser mayor a ninguna de las dos deudas');
      return;
    }
    const newInvoice = { ...document, amountToConciliate: Number(amount) };
    const newInvoices = documentsSuggested.map((doc: any) =>
      doc.id === document.id ? newInvoice : doc,
    );
    setDocumentsSuggested(newInvoices);
  };

  return (
    <div>
      <div
        style={{
          padding: '10px 0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" padding="10px 0px" fontWeight={600}>
            Conciliaciones sugeridas
          </Typography>
          <LightTooltip
            title={
              <Typography variant="body1" style={{ padding: 4 }}>
                Los cálculos están basados en la fecha registrada del pago. Esta
                se encuentra arriba a la derecha
              </Typography>
            }
          >
            <Info
              fontSize="small"
              style={{ marginLeft: 5, cursor: 'pointer' }}
            />
          </LightTooltip>
        </div>
        <Typography variant="body1" fontWeight={600}>
          Total a conciliar:{' '}
          {formatMoney(
            documentsSuggested.reduce(
              (acc: any, inv: any) => acc + (inv.amountToConciliate || 0),
              0,
            ),
          )}
        </Typography>
      </div>
      {loading && <CircularProgress />}
      {!loading && (
        <div>
          {documentsSuggested.length ? (
            <Grid container spacing={0}>
              <Grid item xs={1}>
                <Typography variant="body1" fontWeight={600}>
                  Folio
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1" fontWeight={600} textAlign="right">
                  Monto
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1" fontWeight={600} textAlign="right">
                  Anticipo
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1" fontWeight={600} textAlign="right">
                  Mora
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textAlign="right"
                    >
                      Anticipo + mora
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textAlign="right"
                    >
                      Abonado total
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LightTooltip
                      title={
                        <Typography variant="body1" style={{ padding: 4 }}>
                          Esta será la deuda sugerida si paga el deudor. Está es
                          el máximo entre la deuda del total de la factura más o
                          el anticipado más la mora.
                        </Typography>
                      }
                    >
                      <Typography
                        variant="body1"
                        fontWeight={600}
                        textAlign="right"
                        style={{ cursor: 'pointer' }}
                      >
                        Deuda completa
                      </Typography>
                    </LightTooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <LightTooltip
                      title={
                        <Typography variant="body1" style={{ padding: 4 }}>
                          Esta será la deuda sugerida si paga el cliente. Está
                          es la deuda de lo anticipado más la mora pendiente del
                          cliente.
                        </Typography>
                      }
                    >
                      <Typography
                        variant="body1"
                        fontWeight={600}
                        textAlign="right"
                        style={{ cursor: 'pointer' }}
                      >
                        Deuda anticipo
                      </Typography>
                    </LightTooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" fontWeight={600} textAlign="right">
                  Monto a conciliar
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body1">No hay facturas sugeridas</Typography>
          )}
          {documentsSuggested.map((document: any) => {
            const tooltipTitleValues = [
              {
                name: 'Monto',
                value: formatMoney(document.amount),
              },
              {
                name: 'Fuente',
                value: document.type,
              },
              {
                name: 'Deudor',
                value: document.stakeholderName,
              },
              {
                name: 'Rut deudor',
                value: document.stakeholderIdentifier,
              },
              {
                name: 'Estado',
                value: document.status,
              },
              {
                name: 'Fecha de pago',
                value: formatDate(document.paymentDate),
              },
              {
                name: 'Días vencida',
                value: document.dueDays,
              },
              {
                name: 'Anticipo',
                value: `${document.advance}%`,
              },
            ];
            const remainingClient = document.totalRemainingNet;
            const remainingPayer = Math.max(
              document.totalRemainingNet,
              document.amount - document.amountConciliated,
            );
            const remainingAmount =
              payIn.businessOriginIdentifier === document.businessIdentifier
                ? remainingClient
                : remainingPayer;
            return (
              <Grid
                key={document.folio}
                container
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={1}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <LightTooltip
                      title={CustomTooltip(
                        tooltipTitleValues,
                        'Concepto',
                        'Valor',
                      )}
                    >
                      <Info
                        style={{
                          fontSize: 16,
                          cursor: 'pointer',
                        }}
                      />
                    </LightTooltip>
                    <Typography variant="body1" marginLeft={1}>
                      {document.folio}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body1" textAlign="right">
                    {formatMoney(document.amount)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body1" textAlign="right">
                    {formatMoney(document.amountNet)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body1" textAlign="right">
                    {formatMoney(
                      document.arrearAmount + document.arrearConciliation,
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="body1" textAlign="right">
                        {formatMoney(
                          document.amountNet +
                            document.arrearAmount +
                            document.arrearConciliation,
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 10,
                          justifyContent: 'flex-end',
                        }}
                      >
                        <LightTooltip
                          title={
                            <div>
                              <EnhancedTable
                                data={[
                                  {
                                    name: 'Abonado documento',
                                    value: formatMoney(
                                      document.documentConciliation,
                                    ),
                                  },
                                  {
                                    name: 'Abonado mora',
                                    value: formatMoney(
                                      document.arrearConciliation,
                                    ),
                                  },
                                ]}
                                dataHeaders={[
                                  { label: 'Concepto', key: 'name' },
                                  { label: 'Valor', key: 'value' },
                                ]}
                              />
                            </div>
                          }
                        >
                          <Info
                            style={{ cursor: 'pointer' }}
                            fontSize="small"
                          />
                        </LightTooltip>
                        <Typography variant="body1" textAlign="right">
                          {formatMoney(document.amountConciliated)}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1" textAlign="right">
                        {formatMoney(remainingPayer)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1" textAlign="right">
                        {formatMoney(remainingClient)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <TextField
                    value={formatMoney(document.amountToConciliate)}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            color="primary"
                            style={{ cursor: 'pointer' }}
                            disabled={
                              formatMoney(remainingAmount) ===
                              formatMoney(document.amountToConciliate)
                            }
                            onClick={() =>
                              handleSelectAmount(
                                formatMoney(remainingAmount),
                                document,
                              )
                            }
                          >
                            <SettingsBackupRestore />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { textAlign: 'right' },
                    }}
                    onChange={(e) =>
                      handleSelectAmount(e.target.value, document)
                    }
                  />
                </Grid>
              </Grid>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PaymentConciliationAssginationSuggested;
