import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { formatMoney } from '../../../../../../lib/functions/utils';
import { Info } from '@mui/icons-material';
import { LightTooltip } from '../../../../../../lib/designSystem/WhiteTooltip';
import ExportDialog from './components/ExportDialog';

const CustomTooltip = (tableData: any) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Costo</TableCell>
        <TableCell>Valor</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {tableData.map((row: any) => (
        <TableRow key={row.index}>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.value}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const styledBox = {
  display: 'flex',
  padding: '12px 0px',
  justifyContent: 'space-between',
  alignContent: 'center',
};

const SimulationDoneLive = (props: any) => {
  const {
    selectedRequest,
    operationDetails,
    documentsToApply,
    requestConfiguration,
    onEditing,
    setAmountOverZero,
  } = props;
  const [openExportDialog, setOpenExportDialog] = useState(false);

  const operationAmount = operationDetails.operationAmount;
  const operationAdvanced = operationDetails.operationAdvanced;
  const operationCostRate = operationDetails.operationCostRate;
  const operationCostComissionFixed =
    operationDetails.operationCostComissionFixed;
  const operationCostComissionVariable =
    operationDetails.operationCostComissionVariable;
  const operationToDepost = operationDetails.operationToDepost;
  const operationIva = operationDetails.operationIva;
  const expensesIva = operationDetails.expensesIva;
  const operationAmountToApply = operationDetails.operationAmountToApply;
  const expensesCost = operationDetails.expensesCost;
  const advance = operationDetails.advance;
  const rate = operationDetails.rate;
  const guaranteeAmount = operationDetails.guaranteeAmount;

  useEffect(() => {
    if (setAmountOverZero) {
      if (operationToDepost < 0) {
        setAmountOverZero(false);
      } else {
        setAmountOverZero(true);
      }
    }
  }, [operationToDepost]);

  const handleExportSimulation = async () => {
    setOpenExportDialog(true);
  };

  const amountIVA = !('expensesIVA' in selectedRequest)
    ? requestConfiguration.expensesHasIVA
      ? expensesIva
      : 0
    : selectedRequest.expensesIVA
      ? expensesIva
      : 0;

  return (
    <div>
      <div
        style={{
          padding: '0px 0px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography fontWeight="bold" variant="h6">
          Detalles
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            onClick={() => handleExportSimulation()}
            variant="caption"
            color="primary"
            style={{ cursor: 'pointer', fontWeight: 'bold' }}
          >
            Exportar
          </Typography>
        </div>
      </div>
      <div style={styledBox}>
        <Typography variant="body1">Monto operación</Typography>
        <Typography variant="body1">{formatMoney(operationAmount)}</Typography>
      </div>
      <div style={styledBox}>
        <Typography variant="body1">
          Monto a financiar {`(${advance || 0}%)`}
        </Typography>
        <Typography variant="body1">
          {formatMoney(operationAdvanced)}
        </Typography>
      </div>
      {guaranteeAmount !== 0 && guaranteeAmount !== undefined && (
        <div style={styledBox}>
          <Typography variant="body1">Monto en garantía</Typography>
          <Typography variant="body1">
            {formatMoney(guaranteeAmount)}
          </Typography>
        </div>
      )}
      <div style={styledBox}>
        <Typography variant="body1">
          Diferencia precio {`(${rate || 0}%)`}
        </Typography>
        <Typography variant="body1">
          {formatMoney(operationCostRate)}
        </Typography>
      </div>
      {operationAmountToApply !== 0 && (
        <div style={styledBox}>
          <Typography variant="body1">Documentos aplicados</Typography>
          <Typography variant="body1">
            {formatMoney(operationAmountToApply)}
          </Typography>
        </div>
      )}
      {expensesCost !== 0 && (
        <div style={styledBox}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1">
              Otros gastos{amountIVA > 0 ? ' + IVA' : ''}
            </Typography>
            <LightTooltip
              title={CustomTooltip([
                { name: 'Costo', value: formatMoney(expensesCost) },
                ...(amountIVA > 0
                  ? [
                      {
                        name: 'IVA',
                        value: formatMoney(expensesIva),
                      },
                    ]
                  : []),
              ])}
            >
              <Info style={{ marginLeft: 6, fontSize: 14 }} />
            </LightTooltip>
          </div>
          <Typography variant="body1">
            {formatMoney(expensesCost + amountIVA)}
          </Typography>
        </div>
      )}
      <div style={styledBox}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">Comisión operación + IVA</Typography>
          <LightTooltip
            title={CustomTooltip([
              {
                name: 'Costo fijo',
                value: formatMoney(operationCostComissionFixed),
              },
              {
                name: 'Costo variable',
                value: formatMoney(operationCostComissionVariable),
              },
              { name: 'IVA', value: formatMoney(operationIva) },
              {
                name: 'Total',
                value: formatMoney(
                  operationCostComissionFixed +
                    operationCostComissionVariable +
                    operationIva,
                ),
              },
            ])}
          >
            <Info style={{ marginLeft: 6, fontSize: 14 }} />
          </LightTooltip>
        </div>
        <Typography variant="body1">
          {formatMoney(
            operationCostComissionFixed +
              operationCostComissionVariable +
              operationIva,
          )}
        </Typography>
      </div>
      <div style={{ marginTop: 10 }}>
        <Divider />
        <div style={styledBox}>
          <Typography fontWeight="bold" variant="body1">
            Monto a girar
          </Typography>
          <Typography fontWeight="bold" variant="body1">
            {formatMoney(operationToDepost)}
          </Typography>
        </div>
      </div>
      {openExportDialog && (
        <ExportDialog
          open={openExportDialog}
          setOpen={setOpenExportDialog}
          selectedRequest={selectedRequest}
          operationDetails={operationDetails}
          documentsToApply={documentsToApply}
        />
      )}
    </div>
  );
};

export default SimulationDoneLive;
