import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../../../../lib/functions/utils';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';
import PayersAssignation from './components/Payers';
import EnhancedTab from '../../../../../../../../../../lib/designSystem/TabSimulation';
import SubPrductAssingnation from './components/Subproducts';
import {
  assginCreditLine,
  getBusinessPayers,
  getCreditLineAssignations,
  getProductSubProducts,
} from '../../../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';
import { getCreditLineFactoringSelectedDecisionEngines } from '../../../../../../../../../../lib/api/decisionEngine';

const AssignationModule = (props: any) => {
  const {
    setOpenShoppingCart,
    openRequest,
    updateStatus,
    setInAssignation,
    setSuccessOpen,
    setUp,
    handleDetails,
  } = props;
  console.log('AssignationModule rendered');
  const [payers, setPayers] = useState<any[]>([]);
  const [subProducts, setSubProducts] = useState<any[]>([]);
  const [stakeholderConcentrations, setStakeholderConcentrations] = useState(
    [],
  );
  const [preAssignationDialog, setPreAssignationDialog] = useState(false);
  const [canUseClientDecisionEngine, setCanUseClientDecisionEngine] =
    useState(false);
  const [canUsePayersDecisionEngine, setCanUsePayersDecisionEngine] =
    useState(false);
  const [runDecisionEngineClient, setRunDecisionEngineClient] = useState(false);
  const [runDecisionEnginePayers, setRunDecisionEnginePayers] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [subProductConcentrations, setSubProductConcentrations] = useState([]);
  const [amount, setAmount] = useState(openRequest.amount);
  const [validUntil, setValidUntil] = useState(openRequest.validUntil);
  const validateConcentrationsAmount = () => {
    if (stakeholderConcentrations.length === 0) {
      alert(
        'Debes seleccionar al menos un pagador. Si no quieres agregar una concentración especififica, selecciona "Otros"',
      );
      return false;
    }
    const total = stakeholderConcentrations.every(
      (x: any) => x.amount <= amount,
    );

    if (!total) {
      alert(
        'El monto de las concentraciones de los pagadores no puede ser mayor al total de la línea.',
      );
      return false;
    }
    return true;
  };

  const handleCloseShoppingCart = async () => {
    setOpenShoppingCart(false);
    await handleDetails(openRequest.id);
  };

  const validateSubProductsConcentrationsAmount = () => {
    if (subProductConcentrations.length === 0) {
      alert('Debes seleccionar al menos un subproducto');
      return false;
    }
    console.log(subProductConcentrations, amount);
    const total = subProductConcentrations.every(
      (x: any) => x.amount <= amount,
    );

    if (!total) {
      alert(
        'El monto de las concentraciones de los subproductos no puede ser mayor al total de la línea.',
      );
      return false;
    }
    return true;
  };

  const validateAmount = () => {
    if (amount <= 0) {
      alert('El monto del total de la línea debe ser mayor a 0');
      return false;
    }
    return true;
  };

  const cleanStakeholderConcentrations = () => {
    return stakeholderConcentrations.map((x: any) => {
      return {
        businessIdentifier: x.stakeholderName === 'Otros' ? null : x.identifier,
        businessName: x.stakeholderName,
        amount: x.amount,
      };
    });
  };

  const cleanSubProductsConcentrations = () => {
    const newConcentrations = subProductConcentrations.map((x: any) => {
      const newConcentration = {
        subProductId: x.id,
        advance: Number(x.advance),
        arrearRate: Number(x.arrearRate),
        maxFinancingDays: Number(x.maxFinancingDays),
        spread: Number(x.spread),
        operationCostVariable: Number(x.operationCostVariable),
        operationCostFixed: Number(x.operationCostFixed),
        amount: Number(x.amount),
      };
      delete x.id;
      return newConcentration;
    });
    return newConcentrations;
  };
  const handleSubmit = async () => {
    if (!validateAmount()) return;
    if (!validateConcentrationsAmount()) return;
    if (!validateSubProductsConcentrationsAmount()) return;
    if (!validUntil)
      return alert('Por favor, selecciona una fecha de vencimiento');
    setLoading(true);
    try {
      const response =
        await getCreditLineFactoringSelectedDecisionEngines(dispatch);
      response.forEach((engine: any) => {
        if (engine.revisionObjectiveId === 'creditLine') {
          setCanUseClientDecisionEngine(true);
        }
        if (engine.revisionObjectiveId === 'creditLine-payers') {
          setCanUsePayersDecisionEngine(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setPreAssignationDialog(true);
  };

  const handleAssignations = async () => {
    setLoading(true);
    try {
      const cleannedStakeholdersData = cleanStakeholderConcentrations();
      const cleannedSubProductsData = cleanSubProductsConcentrations();

      await assginCreditLine(dispatch, {
        id: openRequest.id,
        amount,
        stakeholderConcentrations: cleannedStakeholdersData,
        subProductConcentrations: cleannedSubProductsData,
        validUntil,
        typeId: openRequest.productTypeId,
        runDecisionEngineClient,
        runDecisionEnginePayers,
      });
      setInAssignation(false);
      setSuccessOpen('Se ha asignado la línea con éxito.');
      setOpenShoppingCart(false);
      await setUp(openRequest.id);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setPreAssignationDialog(false);
  };

  const getConcentrations = async () => {
    setLoading(true);
    try {
      const response = await getCreditLineAssignations(dispatch, {
        id: openRequest.id,
      });
      const { stakeholderAssignations, subProductAssignations } = response;

      const parsedConcentrations = stakeholderAssignations.map(
        (concentration: any) => ({
          identifier: concentration.businessIdentifier,
          stakeholderName: concentration.businessName,
          amount: concentration.amount,
          new: false,
        }),
      );
      setStakeholderConcentrations(parsedConcentrations);
      setSubProductConcentrations(subProductAssignations);

      console.log(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const needToSetup = async () => {
    await setUpBusinessPayers();
    await setUpBusinessSubProducts();
    if (updateStatus) {
      return false;
    } else {
      await getConcentrations();
      setValidUntil(openRequest.validUntil);
    }
  };

  const setUpBusinessSubProducts = async () => {
    setLoading(true);
    try {
      const response = await getProductSubProducts(dispatch, openRequest.id);
      setSubProducts(response);
      console.log(response);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  const setUpBusinessPayers = async () => {
    setLoading(true);
    try {
      const response = await getBusinessPayers(
        dispatch,
        openRequest.businessIdentifier,
      );
      setPayers(response);
      console.log(response);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    needToSetup();
  }, []);

  const daysTillExpiration = validUntil
    ? (new Date(validUntil).getTime() - new Date().getTime()) /
      (1000 * 3600 * 24)
    : 0;
  const [days, setDays] = useState(Math.floor(daysTillExpiration));

  const handleChangeDays = (value: any) => {
    if (value < 0) return;

    setDays(parseInt(value));
    const date = new Date();
    date.setDate(date.getDate() + parseInt(value));
    setValidUntil(date);
  };

  const handleChangeAmount = (value: any) => {
    const cleanValue = value.split('.').join('').split('$').join('');
    if (isNaN(cleanValue)) return;
    if (cleanValue < 0) return;
    setAmount(Number(cleanValue));
  };

  const startValue = 0;

  const tabs = [
    {
      index: 0,
      label: 'Deudores',
      component: (
        <PayersAssignation
          concentrations={stakeholderConcentrations}
          setConcentrations={setStakeholderConcentrations}
          amount={amount}
          payers={payers}
          setAmount={setAmount}
        />
      ),
    },
    {
      index: 0,
      label: 'Subproductos',
      component: (
        <SubPrductAssingnation
          concentrations={subProductConcentrations}
          setConcentrations={setSubProductConcentrations}
          amount={amount}
          subProducts={subProducts}
        />
      ),
    },
  ];

  if (loading) {
    return <CenteredCircularProgress type="dialog" />;
  }
  return (
    <div>
      <div style={{ padding: '10px 0px' }}>
        <Typography variant="body1">
          Acá debes ingresar el monto de la línea y si tiene concentraciones que
          la limitan
        </Typography>
      </div>
      <div style={{ padding: '10px 0px' }}>
        <Grid container spacing={2} alignItems={'top'}>
          <Grid item xs={6}>
            <Typography fontWeight={600} variant="body1">
              Monto total
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="Monto"
              type="text"
              value={formatMoney(amount)}
              onChange={(e) => handleChangeAmount(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600} variant="body1">
              Días para el vencimiento
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="Días"
              type="number"
              value={days}
              onChange={(e) => handleChangeDays(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600} variant="body1">
              Fecha
            </Typography>
            <Typography marginTop={3} fontWeight={600} variant="body1">
              {validUntil ? formatDate(validUntil) : '-'}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: '10px 0px' }}>
        <EnhancedTab startValue={startValue} tabs={tabs} title="Asignaciones" />
      </div>
      <DialogActions>
        <CustomButton
          onClick={() => handleCloseShoppingCart()}
          color="secondary"
        >
          Cancelar
        </CustomButton>
        <CustomButton onClick={() => handleSubmit()} color="primary">
          Enviar
        </CustomButton>
      </DialogActions>
      <Dialog
        open={preAssignationDialog}
        onClose={() => setPreAssignationDialog(false)}
      >
        <DialogTitle>Nueva asignación</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Estás a punto de asignar una línea de crédito. Si lo necesitas,
            puedes ejecutar el motor de decisión para revisar la asignación de
            la línea.
          </Typography>
          <FormControl>
            <FormControlLabel
              control={<Checkbox />}
              disabled={!canUseClientDecisionEngine}
              label="Deseo ejecutar el motor de decisión para el cliente"
              onChange={(e: any) =>
                setRunDecisionEngineClient(e.target.checked)
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              disabled={!canUsePayersDecisionEngine}
              label="Deseo ejecutar el motor de decisión para los pagadores"
              onChange={(e: any) =>
                setRunDecisionEnginePayers(e.target.checked)
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={() => setPreAssignationDialog(false)}
            color="secondary"
          >
            Cancelar
          </CustomButton>
          <CustomButton onClick={() => handleAssignations()} color="primary">
            Enviar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AssignationModule;
