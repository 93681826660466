// DialogActionsWrapper.jsx
import React, { useState } from 'react';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import { validatePermissions } from '../../../../../../../../../../../../../lib/functions/utils';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../../../../../../../../../../../services/redux/authSlice';
import FactoringRequestDeleteConfirmationModal from './components/DeleteModal';
import FactoringRequestUpdateStatusModal from './components/UpdateStatusModal';
import CustomAlert from '../../../../../../../../../../../../../lib/designSystem/Alert';
import { Typography } from '@mui/material';
import CreditDeleteModal from './components/CreditDeleteModal';

export const updateStatusTransitions: Record<
  string,
  { key: string; label: string }[]
> = {
  Aceptada: [
    {
      key: 'IN_REVIEW',
      label: 'En revisión',
    },
  ],
};

const DialogActionsWrapper = ({
  type,
  onClose,
  actions,
  selectedRequest,
  setUp,
  setOpenShoppingCart,
  selectedId,
}: any) => {
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [failed, setFailed] = useState(false);
  const [failedMessage, setFailedMessage] = useState('');

  const setSuccessOpen = (text: string) => {
    setSuccess(true);
    setSuccessText(text);
    setOpenUpdateStatus(false);
    setOpenDeleteConfirm(false);
  };

  const setFailureOpen = (text: string) => {
    setFailed(true);
    setFailedMessage(text);
    setOpenUpdateStatus(false);
    setOpenDeleteConfirm(false);
  };

  const canDeleteRequest = () => {
    if (!selectedRequest.id) return false;
    if (validatePermissions(user, 'financing_factoring_delete')) return true;
    return false;
  };

  const canEditRequest = () => {
    if (
      !updateStatusTransitions[selectedRequest.status] ||
      updateStatusTransitions[selectedRequest.status].length === 0
    )
      return false;
    if (!validatePermissions(user, 'financing_factoring_edit_operation_status'))
      return false;
    return true;
  };

  const handleOpenDeleteConfirmModal = (id: number) => {
    console.log('id', id);
    setOpenDeleteConfirm(true);
  };

  const handleOpenUpdateStatusModal = (id: number) => {
    console.log('id', id);
    setOpenUpdateStatus(true);
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 0px',
        gap: '10px',
      }}
    >
      {canDeleteRequest() && (
        <Typography
          color="error"
          variant="body1"
          fontWeight="bold"
          style={{ cursor: 'pointer' }}
          onClick={() => handleOpenDeleteConfirmModal(selectedRequest.id)}
        >
          Eliminar
        </Typography>
      )}
      {canEditRequest() && (
        <Typography
          color="orange"
          variant="body1"
          fontWeight="bold"
          style={{ cursor: 'pointer' }}
          onClick={() => handleOpenUpdateStatusModal(selectedRequest.id)}
        >
          Editar Estado
        </Typography>
      )}
      <div style={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
        <CustomButton onClick={onClose} color="secondary">
          Salir
        </CustomButton>
        {actions}
      </div>
      {openDeleteConfirm &&
        (type === 'factoring' ? (
          <FactoringRequestDeleteConfirmationModal
            open={openDeleteConfirm}
            setOpen={setOpenDeleteConfirm}
            requestId={selectedRequest.id}
            setUp={setUp}
            setSuccessOpen={setSuccessOpen}
            setFailureOpen={setFailureOpen}
          />
        ) : (
          <CreditDeleteModal
            open={openDeleteConfirm}
            onClose={onClose}
            setOpenShoppingCart={setOpenShoppingCart}
            requestId={selectedRequest.id}
            setUp={setUp}
            setSuccessOpen={setSuccessOpen}
            setFailureOpen={setFailureOpen}
          />
        ))}
      {openUpdateStatus && (
        <FactoringRequestUpdateStatusModal
          open={openUpdateStatus}
          setOpen={setOpenUpdateStatus}
          requestId={selectedRequest.id}
          setUp={setUp}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          oldStatus={selectedRequest.status}
          selectedId={selectedId}
        />
      )}
      <CustomAlert
        setOpen={setSuccess}
        open={success}
        title="Exito"
        text={successText}
        severity="success"
      />
      <CustomAlert
        setOpen={setFailed}
        open={failed}
        title="Oops"
        text={failedMessage}
        severity="error"
      />
    </div>
  );
};
export default DialogActionsWrapper;
