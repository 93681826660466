// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  createBusinessPublic,
  getUserByEncryptedId,
  startEnrolationPublic,
  validateCredentialsPublic,
} from '../../../lib/api';
import Grid from '@mui/material/Grid';
import Login from './Validator';
import Logo from '../../../assets/img/pruff9.svg';
import { Link, Typography } from '@mui/material';
import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import { validaRut, rutChanger } from '../../../lib/functions/utils';
import { getCompanyStyles } from '../../../lib/api/express/company';

export function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Powered by'}
      <div style={{ padding: '10px 15px 10px' }}>
        <img width={90} src={Logo} />
      </div>
      <Link color="inherit" href="https://pruff.com/">
        Pruff
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const EnrolationForm = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invalid, setInvalid] = useState(false);
  const [termsAgree, setTermsAgree] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [userData, setUserData] = useState({ name: '', company: { id: 0 } });
  const [password, setPassword] = useState('');
  const [companyConfig, setCompanyConfig] = useState<any>({});
  const { id } = useParams<{ id: string }>();

  const customSetter = (value: string) => {
    rutChanger(value, setIdentifier);
  };

  const validation = () => {
    if (!companyName.length) {
      alert('Debe poner un nombre de Cliente');
      return false;
    }
    if (!password.length) {
      alert('Debe poner una contraseña');
      return false;
    }
    if (!termsAgree) {
      alert('Debe aceptar los términos y condiciones.');
      return false;
    }
    if (identifier.includes(' ') || identifier.includes('.')) {
      alert('El rut no debe contener puntos ni espacios');
      return false;
    }
    if (!validaRut(identifier)) {
      alert('Rut inválido!');
      return false;
    }
    return true;
  };
  const resetForm = async () => {
    setCompanyName('');
    setIdentifier('');
    setPassword('');
  };

  const newSubmit = async () => {
    try {
      setLoading(true);
      if (!validation()) return setLoading(false);

      console.log('id', id);

      await validateCredentialsPublic({
        taxPassword: password,
        taxIdentifier: identifier,
        id,
      });
      const response = await createBusinessPublic({
        name: companyName,
        identifier: identifier,
        taxPassword: password,
        enrolledPath: 'public',
        id,
      });
      await startEnrolationPublic({
        taxPassword: password,
        taxIdentifier: identifier,
        businessId: response.id,
        id,
      });
      setOpen(true);
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 429) {
        alert('Solo puedes enrolar a 10 usuarios por hora');
      } else {
        alert(error.response.data.message);
      }
    }
    setLoading(false);
    setTermsAgree(false);
    resetForm();
    setLoading(false);
  };

  const fetchFormData = async () => {
    try {
      if (!id) return;

      const userResponse = await getUserByEncryptedId({ id });
      console.log(userResponse);
      const companyConfig = await getCompanyStyles(
        userResponse.data.company.secretId,
      );
      setCompanyConfig(companyConfig);
      setUserData(userResponse.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setInvalid(true);
    }
  };
  useEffect(() => {
    fetchFormData();
  }, []);

  if (loading) return <CenteredCircularProgress type="noLayout" />;
  if (invalid) return <p>Enlace inválido</p>;

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={0}
          md={5}
          style={{
            background: companyConfig?.pruffConnect_styles_mainColor
              ? companyConfig?.pruffConnect_styles_mainColor
              : '#0F23DF',
            opacity: 0.8,
            height: '100vh',
            display: 'flex',
          }}
        >
          {/* Content goes here */}
        </Grid>
        <Grid item xs={12} md={7}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
              justifyContent: 'space-between',
            }}
          >
            <Login
              open={open}
              setOpen={setOpen}
              userData={userData}
              companyName={companyName}
              setCompanyName={setCompanyName}
              identifier={identifier}
              setIdentifier={customSetter}
              password={password}
              setPassword={setPassword}
              termsAgree={termsAgree}
              setTermsAgree={setTermsAgree}
              newSubmit={newSubmit}
              companyConfig={companyConfig}
            />
            <Copyright />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default EnrolationForm;
