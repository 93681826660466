import { requestHandler } from '../../api';

export const fetchInvoicingContacts = async (
  dispatch: any,
  businessId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/open/invoicingContacts`,
    dispatch,
  });
  if (response) return response.data;
};

export const getContacts = async (dispatch: any, query: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/businessesWithContacts?${query}`,
    dispatch,
  });
  if (response) return response.data;
};
