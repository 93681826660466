import {
  Popper,
  IconButton,
  Typography,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import EnhancedTable from '../../../../../../../../../lib/designSystem/Table';

const ArrearPopper = (props: any) => {
  const {
    openArrearPopper,
    setOpenArrearPopper,
    anchorEl,
    formData,
    handleChange,
    onEditing,
    formErrors,
    setFormErrors,
    availableArrearTables,
  } = props;

  const [selectedTable, setSelectedTable] = useState<any>(
    availableArrearTables.find((t: any) => t.id === formData.arrearTableId),
  );

  if (!onEditing) {
    return null;
  }

  const handleChangeType = (type: string) => {
    handleChange('arrearType', type);
    if (formErrors?.arrearTableId && type === 'lineal') {
      setFormErrors({ ...formErrors, arrearTableId: false });
    }
  };

  const handleChangeTable = (table: string) => {
    const selected = availableArrearTables.find((t: any) => t.id === table);
    setSelectedTable(selected);
    if (formErrors?.arrearTableId) {
      setFormErrors({ ...formErrors, arrearTableId: false });
    }
    handleChange('arrearTableId', table);
  };

  const buildTableData = (table: any) => {
    if (!table || !table.arrearSegments) return [];
    return table.arrearSegments.map((segment: any, index: number) => {
      const min = segment.min;
      const max = segment.max;
      const value = segment.value;
      let name = `${min} a ${max} días`;
      if (index === table.arrearSegments.length - 1) {
        name = `Mayor a ${min} días`;
      }
      return {
        segment: name,
        value: `${value}%`,
      };
    });
  };

  const tableData = selectedTable ? buildTableData(selectedTable) : [];

  return (
    <Popper
      sx={{ zIndex: 1300 }}
      open={openArrearPopper}
      anchorEl={anchorEl}
      placement={'bottom'}
      transition={false}
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      <Paper
        style={{
          padding: 20,
          minWidth: 300,
          maxHeight: 400,
          overflow: 'scroll',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body1">Interés mora (%)</Typography>
          <IconButton onClick={() => setOpenArrearPopper(false)}>
            <Close />
          </IconButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Tipo</InputLabel>
              <Select
                labelId="type-label"
                value={formData.arrearType}
                onChange={(e) => handleChangeType(e.target.value)}
                label="Tipo"
              >
                <MenuItem value="lineal">Lineal</MenuItem>
                <MenuItem value="table">Tabla</MenuItem>
              </Select>
            </FormControl>
            {formData.arrearType === 'table' && (
              <FormControl fullWidth>
                <InputLabel id="table-label">Tabla</InputLabel>
                <Select
                  labelId="table-label"
                  value={formData.arrearTableId}
                  onChange={(e) => handleChangeTable(e.target.value)}
                  label="Tabla"
                >
                  {availableArrearTables?.map((table: any) => (
                    <MenuItem key={table.id} value={table.id}>
                      {table.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
          {formData.arrearType === 'table' && (
            <EnhancedTable
              data={tableData}
              dataHeaders={[
                {
                  key: 'segment',
                  label: 'Tramo',
                },
                {
                  key: 'value',
                  label: 'Valor',
                },
              ]}
              hideTablePagination
            />
          )}
        </div>
      </Paper>
    </Popper>
  );
};

export default ArrearPopper;
