import { Dialog, DialogContent, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import moment from 'moment';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import { Download } from '@mui/icons-material';
import ExcelJS from 'exceljs';

interface DebtProjectorProps {
  open: boolean;
  documents: any[];
  // eslint-disable-next-line
  setOpen: (open: boolean) => void;
}

const headers = [
  {
    key: 'businessIdentifier',
    label: 'Rut cliente',
    type: 'report',
  },
  { key: 'businessName', label: 'Nombre cliente' },
  { key: 'operationDate', label: 'Fecha operación', type: 'date' },
  { key: 'documentNumber', label: 'Número documento' },
  { key: 'leftAmount', label: 'Saldo documento', type: 'money' },
  { key: 'dueDate', label: 'Fecha vencimiento', type: 'date' },
  { key: 'newPaymentDate', label: 'Fecha proyectada de pago' },
  { key: 'arrearDays', label: 'Días mora' },
  { key: 'arrear', label: 'Mora proyectada', type: 'money' },
];

const excelHeaders = [
  'Rut cliente',
  'Nombre cliente',
  'Fecha operación',
  'Número documento',
  'Saldo documento',
  'Fecha vencimiento',
  'Fecha proyectada de pago',
  'Días mora',
  'Mora proyectada',
];

const DebtProjector: React.FC<DebtProjectorProps> = (props) => {
  const [documents, setDocuments] = useState<any[]>(props.documents);
  const [paymentDate, setPaymentDate] = useState(dayjs());

  const exportToExcel = async () => {
    const plainData = documents.map((item: any) => {
      return [
        item.businessIdentifier,
        item.businessName,
        moment(item.operationDate).format('YYYY-MM-DD'),
        item.documentNumber,
        item.leftAmount,
        moment(item.dueDate).format('YYYY-MM-DD'),
        item.newPaymentDate,
        item.arrearDays,
        item.arrear,
      ];
    });
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Add data to worksheet
    [excelHeaders, ...plainData].forEach((rowData, rowIndex) => {
      const row = worksheet.addRow(rowData);
      if (rowIndex === 0) {
        // Make the first row bold, visible, and add grey background color
        row.eachCell((cell) => {
          cell.font = { bold: true };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
          worksheet.getColumn(cell.col).width = 20;
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDDDDDD' }, // Grey background color
          };
        });
      }
    });
    // Save workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.xlsx';
    a.click();

    URL.revokeObjectURL(url);
  };

  const calculateArrear = () => {
    const newDocuments = [];
    for (const document of props.documents) {
      const days = dayjs(paymentDate).diff(dayjs(document.dueDate), 'day');
      const daysReal = days > 0 ? days : 0;
      const arrear =
        ((document.leftAmount * document.arrearRate) / 100 / 30) * daysReal;
      const newDocument = {
        ...document,
        arrearDays: daysReal,
        arrear: Math.round(arrear),
        newPaymentDate: moment(paymentDate.toISOString()).format('YYYY-MM-DD'),
      };
      newDocuments.push(newDocument);
    }
    setDocuments(newDocuments);
  };

  useEffect(() => {
    calculateArrear();
  }, [paymentDate, props.documents]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent>
          <div>
            <Typography variant="h4">Proyectar mora</Typography>
            <HeaderWithActions
              buttons={[
                {
                  action: () => exportToExcel(),
                  name: 'Descargar XSLX',
                  color: 'primary',
                  startIcon: <Download />,
                },
              ]}
              customTitle={
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="es-mx"
                >
                  <DatePicker
                    label="Nueva fecha"
                    value={paymentDate}
                    onChange={(e) => setPaymentDate(dayjs(e))}
                  />
                </LocalizationProvider>
              }
            />

            <EnhancedTable dataHeaders={headers} data={documents} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DebtProjector;
