import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import { useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { getAccountingEntries } from '../../../../../../lib/api/accounting';
import MenuDialog from '../../../../../../lib/designSystem/MenuDialog';
import { InputLabel, Select } from '@mui/material';
import { FormControl } from '@mui/material';
import { MenuItem } from '@mui/material';
import CustomButton from '../../../../../../lib/designSystem/Button';

const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const YEARS = Array.from({ length: 10 }, (_, index) => 2025 - index);

import AccountingEntriesTable from './components/Table';
import EntryWithLines from './components/EntryWithLines';
import CustomAlert from '../../../../../../lib/designSystem/Alert';

const DatesSearcher = (props: any) => {
  const { setUp, year, month, setMonth, setYear } = props;
  const handleSearch = async () => {
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 1);
    await setUp(startDate, endDate);
  };

  return (
    <MenuDialog buttonLabel="Buscar" buttonColor="secondary">
      <div style={{ padding: 10 }}>
        <div style={{ padding: '10px 0px' }}>
          <FormControl fullWidth>
            <InputLabel>Mes</InputLabel>
            <Select
              label="Mes"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              {MONTHS.map((month, index) => (
                <MenuItem key={index} value={index + 1}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ padding: '10px 0px' }}>
          <FormControl fullWidth>
            <InputLabel>Año</InputLabel>
            <Select
              label="Año"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              {YEARS.map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ padding: '10px 0px', textAlign: 'center' }}>
          <CustomButton color="primary" onClick={() => handleSearch()}>
            Buscar
          </CustomButton>
        </div>
      </div>
    </MenuDialog>
  );
};

const AccountingEntries: React.FC = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const startDateDefault = new Date();
  startDateDefault.setDate(1);
  const endDateDefault = new Date();
  endDateDefault.setMonth(endDateDefault.getMonth() + 1, 1);
  const date = new Date();
  const [startDate, setStartDate] = useState<any>(startDateDefault);
  const [endDate, setEndDate] = useState<any>(endDateDefault);
  const [month, setMonth] = useState<any>(date.getMonth() + 1);
  const [year, setYear] = useState<any>(date.getFullYear());
  const [open, setOpen] = useState(false);
  const [entry, setEntry] = useState<any>({});
  const [successText, setSuccessText] = useState<any>(null);
  const [failedText, setFailedText] = useState<any>(null);
  const setUp = async (startDate: Date, endDate: Date) => {
    let startDateSearch = startDateDefault;
    if (startDate) startDateSearch = startDate;

    let endDateSearch = endDateDefault;
    if (endDate) endDateSearch = endDate;

    const startDateString = startDateSearch.toISOString().split('T')[0];
    const endDateString = endDateSearch.toISOString().split('T')[0];
    setStartDate(startDateSearch);
    setEndDate(endDateSearch);
    const queryParams = `startDate=${startDateString}&endDate=${endDateString}`;
    try {
      setLoading(true);
      const response = await getAccountingEntries(dispatch, queryParams);
      setData(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleNewEntry = () => {
    setOpen(true);
    setEntry({
      name: '',
      lines: [],
      date: new Date().toISOString().split('T')[0],
      typeId: 'free',
    });
  };

  const handleEditEntry = (entryId: number) => {
    const entry: any = data.find((entry: any) => entry.id === entryId);
    if (!entry) return alert('No se encontró la entrada.');

    const dateFormatted = entry.date.split('T')[0];
    setEntry({
      ...entry,
      date: dateFormatted,
    });
    setOpen(true);
  };

  useEffect(() => {
    setUp(startDateDefault, endDateDefault);
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;
  return (
    <div>
      <div>
        <HeaderWithActions
          title={`Período: ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}
          variant="body1"
          item={
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}
            >
              <div>
                <DatesSearcher
                  setUp={setUp}
                  year={year}
                  month={month}
                  setMonth={setMonth}
                  setYear={setYear}
                />
              </div>
              <div>
                <CustomButton color="primary" onClick={() => handleNewEntry()}>
                  Crear
                </CustomButton>
              </div>
            </div>
          }
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <AccountingEntriesTable
          entries={data}
          handleEditEntry={handleEditEntry}
        />
      </div>
      {open && (
        <EntryWithLines
          entry={entry}
          setEntry={setEntry}
          open={open}
          setOpen={setOpen}
          setUp={setUp}
          setSuccessText={setSuccessText}
          setFailedText={setFailedText}
        />
      )}
      <CustomAlert
        open={!!successText}
        onClose={() => setSuccessText(null)}
        severity="success"
        text={successText}
      />
      <CustomAlert
        open={!!failedText}
        onClose={() => setFailedText(null)}
        severity="error"
        text={failedText}
      />
    </div>
  );
};

export default AccountingEntries;
