import React, { useState } from 'react';
import { DialogActions } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import CreditLineStatus from '../../../../../../../../../common/creditLineStatus';
import FactoringCreditLineAssignationModule from '../../../../../Assignation/FactoringCreditLineAssignationModule';
import CreditCreditLineAssignationModule from '../../../../../Assignation/CreditCreditLineAssignationModule';

const ShoppingCartFactoringReviewed = (props: any) => {
  const {
    setOpenShoppingCart,
    openRequest,
    setSuccessOpen,
    setUp,
    inAssignationDefault,
    updateStatus,
    handleDetails,
  } = props;

  const [inAssignation, setInAssignation] = useState(
    inAssignationDefault ? true : false,
  );

  const hanldeAssignation = async () => {
    setInAssignation(true);
  };

  return (
    <div>
      <div>
        {inAssignation ? (
          <div>
            {openRequest.productName === 'Factoring' && (
              <FactoringCreditLineAssignationModule
                setOpenShoppingCart={setOpenShoppingCart}
                openRequest={openRequest}
                updateStatus={updateStatus}
                setInAssignation={setInAssignation}
                setSuccessOpen={setSuccessOpen}
                setUp={setUp}
                handleDetails={handleDetails}
              />
            )}
            {openRequest.productName === 'Créditos' && (
              <CreditCreditLineAssignationModule
                setOpenShoppingCart={setOpenShoppingCart}
                openRequest={openRequest}
                updateStatus={updateStatus}
                setInAssignation={setInAssignation}
                setSuccessOpen={setSuccessOpen}
                setUp={setUp}
                handleDetails={handleDetails}
              />
            )}
          </div>
        ) : (
          <div>
            <CreditLineStatus
              openRequest={openRequest}
              audienceType="received"
            />
            <DialogActions>
              <CustomButton onClick={() => hanldeAssignation()} color="primary">
                Asignar
              </CustomButton>
              <CustomButton
                onClick={() => setOpenShoppingCart(false)}
                color="secondary"
              >
                Salir
              </CustomButton>
            </DialogActions>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShoppingCartFactoringReviewed;
