import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { formatMoney } from '../../../../../../../../lib/functions/utils';

const CustomPieChart = ({
  data,
  money,
  onClick,
  colors = ['#D62839', '#0F23DF', '#03B4DC', '#07D2FE'],
  customKeyColorMap,
  valueKey = 'value',
}: {
  data: any;
  money?: boolean;
  onClick?: any;
  colors?: string[];
  customKeyColorMap?: any;
  valueKey?: string;
}) => {
  const coloredData = data.map((entry: any, index: any) => ({
    ...entry,
    color: customKeyColorMap
      ? customKeyColorMap[entry.name]
        ? customKeyColorMap[entry.name]
        : colors[index % colors.length]
      : colors[index % colors.length],
  }));

  // Custom label function
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radiusPonderation = percent == 1 ? 1 : 1.3;
    const radius =
      innerRadius + (outerRadius - innerRadius) * radiusPonderation;
    const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
    const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);

    // Determine text anchor based on position relative to the center
    const textAnchor = x > cx ? 'start' : 'end';

    // Adjust the dominantBaseline based on the angle
    const angleMod = midAngle % 360;
    const dominantBaseline =
      angleMod > 90 && angleMod < 270 ? 'hanging' : 'alphabetic';

    return (
      <text
        style={{
          fontFamily: " 'Nunito', sans-serif",
          fontSize: 13,
          fontWeight: 600,
          marginTop: 8,
        }}
        x={x}
        y={y}
        fill={colors[index % colors.length]}
        textAnchor={textAnchor}
        dominantBaseline={dominantBaseline}
      >
        {percent > 0 ? `${(percent * 100).toFixed(0)}%` : ''}
      </text>
    );
  };
  const total = data.reduce(
    (sum: any, entry: any) => sum + (entry[valueKey] || 0),
    0,
  );

  return (
    <ResponsiveContainer width="100%" height={220}>
      <PieChart
        style={{
          cursor: 'pointer',
          padding: '0px 0px',
          outline: 'none',
        }}
      >
        <Pie
          data={coloredData}
          cx="50%"
          cy="55%"
          z={1000}
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius={50}
          outerRadius={80}
          dataKey={valueKey}
        >
          {coloredData.map((entry: any, index: any) => (
            <Cell
              style={{ outline: 'none' }}
              key={`cell-${index}`}
              fill={entry.color}
              onClick={() => {
                if (onClick) {
                  onClick(entry);
                }
              }}
            />
          ))}
        </Pie>
        <Tooltip
          formatter={(value, name, props) => {
            console.log('VALUE', value);
            console.log('NAME', name);
            console.log('PROPS', props);
            return [money ? formatMoney(Number(value)) : value, name];
          }}
          contentStyle={{
            fontFamily: " 'Nunito', sans-serif",
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            border: 'none',
            borderRadius: '4px',
            color: 'black',
          }}
          itemStyle={{ color: 'black' }}
        />
        <text
          style={{ fontFamily: " 'Nunito', sans-serif", fontWeight: '400' }}
          x="50%"
          y="55%"
          textAnchor="middle"
          dominantBaseline="middle"
          className="total-value"
        >
          {money ? `${formatMoney(total / 1000000)}MM` : total}
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
