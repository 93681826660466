import React, { useState } from 'react';
import { DialogActions, Grid, TextField, Typography } from '@mui/material';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../../../../lib/functions/utils';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';
import { assginCreditLine } from '../../../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';

const AssignationModule = (props: any) => {
  const {
    setOpenShoppingCart,
    openRequest,
    updateStatus,
    setInAssignation,
    setSuccessOpen,
    setUp,
  } = props;
  console.log('AssignationModule rendered');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(openRequest.amount);
  const [validUntil, setValidUntil] = useState(openRequest.validUntil);

  const validateAmount = () => {
    if (amount <= 0) {
      alert('El monto del total de la línea debe ser mayor a 0');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateAmount()) return;
    if (!validUntil)
      return alert('Por favor, selecciona una fecha de vencimiento');

    setLoading(true);
    try {
      await assginCreditLine(dispatch, {
        id: openRequest.id,
        amount,
        validUntil,
        updateStatus,
        typeId: openRequest.productTypeId,
        stakeholderConcentrations: [],
        subProductConcentrations: [],
        runDecisionEngineClient: true,
      });
      setInAssignation(false);
      setSuccessOpen('Se ha asignado la línea con éxito.');
      await setUp();
      setOpenShoppingCart(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const daysTillExpiration = validUntil
    ? (new Date(validUntil).getTime() - new Date().getTime()) /
      (1000 * 3600 * 24)
    : 0;
  const [days, setDays] = useState(Math.floor(daysTillExpiration));

  const handleChangeDays = (value: any) => {
    if (value < 0) return;

    setDays(parseInt(value));
    const date = new Date();
    date.setDate(date.getDate() + parseInt(value));
    setValidUntil(date);
  };

  const handleChangeAmount = (value: any) => {
    const cleanValue = value.split('.').join('').split('$').join('');
    if (isNaN(cleanValue)) return;
    if (cleanValue < 0) return;
    setAmount(Number(cleanValue));
  };

  if (loading) {
    return <CenteredCircularProgress type="dialog" />;
  }
  return (
    <div>
      <div style={{ padding: '10px 0px' }}>
        <Typography variant="body1">
          Para asignar una línea de <b>Créditos</b> debes ingresar el monto y su
          vencimiento.
        </Typography>
      </div>
      <div style={{ padding: '10px 0px' }}>
        <Grid container spacing={2} alignItems={'top'}>
          <Grid item xs={6}>
            <Typography fontWeight={600} variant="body1">
              Monto total
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="Monto"
              type="text"
              value={formatMoney(amount)}
              onChange={(e) => handleChangeAmount(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600} variant="body1">
              Días para el vencimiento
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="Días"
              type="number"
              value={days}
              onChange={(e) => handleChangeDays(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600} variant="body1">
              Fecha
            </Typography>
            <Typography marginTop={3} fontWeight={600} variant="body1">
              {validUntil ? formatDate(validUntil) : '-'}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <DialogActions>
        <CustomButton
          onClick={() => setOpenShoppingCart(false)}
          color="secondary"
        >
          Cancelar
        </CustomButton>
        <CustomButton onClick={() => handleSubmit()} color="primary">
          Enviar
        </CustomButton>
      </DialogActions>
    </div>
  );
};

export default AssignationModule;
