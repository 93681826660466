import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { assignRoleToUser, updateUser } from '../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Checkbox,
  ListItemText,
} from '@mui/material';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { nullUserState } from '../..';
import { showAlert } from '../../../../../../lib/functions/utils';

const EditUserDialog = (props: any) => {
  const {
    handleCloseDialog,
    loading,
    setLoading,
    openEditDialog,
    editType,
    fetchUsers,
    roles,
    selectedUser,
    setSelectedUser,
  } = props;
  const dispatch = useDispatch();
  const handleSaveEditDialog = async () => {
    setLoading(true);
    const payload: any = {
      id: selectedUser.id,
      name: selectedUser.name,
    };
    if (selectedUser.password.length) {
      payload.password = selectedUser.password;
    }
    try {
      await updateUser(dispatch, payload);
    } catch (error) {
      setLoading(false);
      return alert('Usuario no existe');
    }
    setSelectedUser(nullUserState);
    handleCloseDialog();
    await fetchUsers();
    showAlert(dispatch, 'success', 'Se ha editado el usuario.');
  };

  const handleSaveAssignationsEditDialog = async () => {
    setLoading(true);
    const rolesIds = selectedUser.rolesNames.map(
      (roleName: any) => roles.find((role: any) => role.name === roleName).id,
    );
    const payload = {
      id: selectedUser.id,
      rolesIds,
    };
    try {
      await assignRoleToUser(dispatch, payload);
    } catch (error) {
      setLoading(false);
      return alert('Usuario no existe');
    }
    handleCloseDialog();
    showAlert(
      dispatch,
      'success',
      'Se ha editado el usuario. Recuerda que con esto cambian las notificaciones, para revisarlas el usuario puede ir a su perfil.',
    );
    setSelectedUser(nullUserState);
    await fetchUsers();
  };

  const handleInputChange = (e: any) => {
    setSelectedUser((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {}, []);

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <Dialog
        fullWidth
        open={openEditDialog}
        onClose={() => handleCloseDialog()}
      >
        <DialogTitle>Editar {selectedUser.email}</DialogTitle>
        {editType === 'password' ? (
          <DialogContent>
            <TextField
              margin="dense"
              name="name"
              label="Nombre"
              fullWidth
              value={selectedUser.name}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="password"
              label="Contraseña"
              value={selectedUser.password}
              onChange={handleInputChange}
              type="password"
              fullWidth
            />
          </DialogContent>
        ) : (
          <DialogContent style={{ paddingTop: 20 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-mutiple-checkbox-label">Permisos</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                label="Permisos"
                multiple
                value={selectedUser.rolesNames}
                name="rolesNames"
                onChange={handleInputChange}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
              >
                {roles.map((role: any) => (
                  <MenuItem key={role.id} value={role.name}>
                    <Checkbox
                      checked={selectedUser.rolesNames.indexOf(role.name) > -1}
                    />
                    <ListItemText primary={role.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
        )}
        <DialogActions>
          <CustomButton
            color="primary"
            onClick={() =>
              editType === 'password'
                ? handleSaveEditDialog()
                : handleSaveAssignationsEditDialog()
            }
          >
            Guardar
          </CustomButton>
          <CustomButton onClick={() => handleCloseDialog()} color="secondary">
            Salir
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditUserDialog;
