import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  createWhitelist,
  deleteWhitelist,
  getWhitelists,
} from '../../../../../../lib/api';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../lib/designSystem/Button';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import { rutChanger, validaRut } from '../../../../../../lib/functions/utils';

const WhiteList = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [whitelists, setWhitelists] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [name, setName] = useState('');
  const [identifier, setIdentifier] = useState('');
  const fetchWhitelists = async () => {
    setLoading(true);
    try {
      const response = await getWhitelists(dispatch);
      setWhitelists(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!name || !identifier) return alert('Completa todos los campos');
    if (!validaRut(identifier)) {
      alert('Rut inválido');
      return;
    }
    setLoading(true);
    try {
      await createWhitelist(dispatch, {
        businessIdentifier: identifier,
        businessName: name,
      });
      await fetchWhitelists();
      setSuccess(true);
      setSuccessMessage('Empresa agregada a la whitelist');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setFailed(true);
      setLoading(false);
    }
    setOpenDialog(false);
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteWhitelist(dispatch, { id });
      await fetchWhitelists();
      setSuccess(true);
      setSuccessMessage('Empresa eliminada de la whitelist');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setFailed(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWhitelists();
  }, []);

  const startButtons: CustomButtonProps[] = [
    {
      action: () => setOpenDialog(true),
      name: 'Agregar',
      color: 'primary',
    },
  ];

  const actions = [{ action: handleDelete, actionLabel: 'Eliminar' }];

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        variant="body1"
        subHeader
        buttons={startButtons}
        title="Acá encontrarás todas las empresas que estén dentro de tu whitelist"
      />
      <EnhancedTable
        dataHeaders={[
          { key: 'businessName', label: 'Nombre empresa' },
          { key: 'businessIdentifier', label: 'RUT' },
        ]}
        data={whitelists}
        actions={actions}
        showActions
        rowsPerPageDefault={100}
      />
      {loading && <CenteredCircularProgress relative />}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Agregar empresa</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Agrega una empresa a tu whitelist
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nombre de la empresa"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="identifier"
            label="RUT de la empresa"
            type="text"
            fullWidth
            value={identifier}
            onChange={(e) => rutChanger(e.target.value, setIdentifier)}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setOpenDialog(false)} color="secondary">
            Cancelar
          </CustomButton>
          <CustomButton onClick={() => handleSubmit()} color="primary">
            Agregar
          </CustomButton>
        </DialogActions>
      </Dialog>
      <CustomAlert
        open={success}
        title="Éxito"
        text={successMessage}
        setOpen={setSuccess}
      />
      <CustomAlert
        open={failed}
        title="Error"
        severity="error"
        text="Hubo un error"
        setOpen={setFailed}
      />
    </div>
  );
};

export default WhiteList;
