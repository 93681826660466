import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { getTransactBookReport } from '../../../../../../lib/api/reports';
import TableWithPagination from '../../../../../../lib/designSystem/TableWithPagination';
import DocumentSearcher from '../../../../../../lib/common/DocumentSearcher';

const TransactBook: React.FC = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [businessIdentifier, setBusinessIdentifier] = useState('');
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const [documentType, setDocumentType] = useState('factoring');
  const documentTypes = [
    { label: 'Solicitud de factoring', value: 'factoring' },
    { label: 'Solicitud de crédito', value: 'credit' },
  ];
  const [requestId, setRequestId] = useState('');
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });
  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (startDate) query += `startDate=${startDate}&`;
    if (endDate) query += `endDate=${endDate}&`;
    if (requestId) query += `requestId=${requestId}&`;
    if (businessIdentifier)
      query += `businessIdentifier=${businessIdentifier}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (documentType) query += `documentType=${documentType}&`;
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const setUp = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    const query = generateQuery({ page, pageSize });
    try {
      const response = await getTransactBookReport(dispatch, query);
      setData(response.data);
      setPagination(response.pagination);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    setUp({ page: 0, pageSize: PAGE_SIZE });
  }, []);
  if (loading) return <CenteredCircularProgress type="layout" />;
  return (
    <div>
      <HeaderWithActions
        title="Acá podrás ver todas las solicitudes que se han financiado."
        variant="body1"
        buttons={[
          {
            name: 'Filtro',
            action: () => {},
            color: 'primary',
            customButton: (
              <DocumentSearcher
                requestId={requestId}
                setRequestId={setRequestId}
                documentTypes={documentTypes}
                documentType={documentType}
                setDocumentType={setDocumentType}
                searchData={setUp}
                setData={setData}
                setLoading={setLoading}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                businessIdentifier={businessIdentifier}
                setBusinessIdentifier={setBusinessIdentifier}
                setEndDate={setEndDate}
                stakeholderIdentifier={stakeholderIdentifier}
                setStakeholderIdentifier={setStakeholderIdentifier}
                pagination={pagination}
              />
            ),
          },
        ]}
      />
      <TableWithPagination
        dataHeaders={[
          {
            key: 'operationDate',
            label: 'Fecha operación',
            type: 'date',
          },
          { key: 'requestType', label: 'Tipo' },
          { key: 'operationId', label: 'Id Solicitud', type: 'report' },
          { key: 'documentType', label: 'Tipo documento' },
          { key: 'businessIdentifier', label: 'RUT cliente' },
          { key: 'businessName', label: 'Nombre cliente' },
          { key: 'amount', label: 'Monto operación', type: 'money' },
          {
            key: 'priceDifference',
            label: 'Diferencia de precio',
            type: 'money',
          },
          { key: 'commissionAmount', label: 'Monto comisión', type: 'money' },
          { key: 'comissionIVA', label: 'IVA comisión', type: 'money' },
          { key: 'expensesCost', label: 'Gastos', type: 'money' },
          { key: 'expensesCostIVA', label: 'IVA gastos', type: 'money' },
          {
            key: 'documentsAmount',
            label: 'Aplica documentos',
            type: 'money',
          },
          { key: 'amountRemaining', label: 'Monto restante', type: 'money' },
          { key: 'remittedAmount', label: 'Total giro', type: 'money' },
          { key: 'advancePercentage', label: 'Porcentaje anticipado' },
          { key: 'interestRate', label: 'Tasa negociada' },
          { key: 'arrearRate', label: 'Tasa mora' },
          { key: 'executive', label: 'Ejecutivo' },
          { key: 'resellRate', label: 'Tasa venta', type: 'percentage' },
          { key: 'fundCostTotal', label: 'Costo total fondo', type: 'money' },
        ]}
        data={data}
        actions={[]}
        showActions
        rowsPerPageDefault={100}
        exportToFile="xlsx"
        pagination={pagination}
        setPagination={setPagination}
        setData={setData}
        searchData={setUp}
        lastElement
      />
    </div>
  );
};

export default TransactBook;
