import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '../../../../../../../services/redux/authSlice';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import { FactoringRequest } from '../Recieved';
import CustomAlert from '../../../../../../../lib/designSystem/Alert';
import RequestsTable from './components/Table';
import ShoppingDialogSent from './components/ShoppingDialog';
import HeaderWithActions from '../../../../../../../lib/designSystem/HeaderWithActions';
import {
  getExpressFactoringRequestsSent,
  getExpressFactoringRequestsSentDetails,
} from '../../../../../../../lib/api/express';

const FactoringRequestsSent: React.FC = () => {
  const dispatch = useDispatch();
  const [factoringRequests, setFactoringRequests] = useState<
    FactoringRequest[]
  >([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [shoppingCartType, setShoppingCartType] = useState('');
  const [openShoppingCart, setOpenShoppingCart] = useState(false);
  const [openRequest, setOpenRequest] = useState<FactoringRequest | null>(null);

  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [successText, setSuccesText] = useState('');
  const [failedMessage, setFailedText] = useState('');
  const setSuccessOpen = (text: string) => {
    setSuccess(true);
    setSuccesText(text);
  };

  const setFailureOpen = (text: string) => {
    setFailed(true);
    setFailedText(text);
  };

  const handleDetails = async ({
    requestId,
    quoteId,
  }: {
    requestId: number;
    quoteId: number;
  }) => {
    setLoading(true);
    try {
      console.log({ requestId, quoteId });
      if (!user?.clientId) return setLoading(false);
      const request = await getExpressFactoringRequestsSentDetails(
        dispatch,
        user?.clientId,
        requestId,
      );
      console.log(request);
      setOpenRequest(request);
      setLoading(false);
      setShoppingCartType(request.status);
      setOpenShoppingCart(true);
    } catch (error) {
      setLoading(false);
      return alert('error');
    }
  };

  const setUp = async () => {
    setLoading(true);
    setOpenShoppingCart(false);
    if (!user?.clientId) return setLoading(false);
    const factoringRequestsResponse = await getExpressFactoringRequestsSent(
      dispatch,
      user?.clientId,
    );
    setFactoringRequests(factoringRequestsResponse || []);
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  if (loading)
    return (
      <CenteredCircularProgress type={user?.express ? 'noLayout' : 'layout'} />
    );

  return (
    <div>
      {/* {user?.express ? (
        <QuotesTable
          factoringQuotes={factoringQuotes}
          handleDetails={handleDetails}
          handleOpenFiles={handleOpenFiles}
        />
      ) : ( */}
      <HeaderWithActions
        title="Acá podrás ver todas tus solicitudes de factoring"
        variant="body1"
      />
      <RequestsTable
        factoringRequests={factoringRequests}
        handleDetails={handleDetails}
      />
      {/* )} */}
      <ShoppingDialogSent
        shoppingCartType={shoppingCartType}
        openShoppingCart={openShoppingCart}
        setOpenShoppingCart={setOpenShoppingCart}
        selectedRequest={openRequest}
        setUp={setUp}
        openRequest={openRequest}
        setOpenRequest={setOpenRequest}
        setSuccessOpen={setSuccessOpen}
        setFailureOpen={setFailureOpen}
      />
      <CustomAlert
        setOpen={setSuccess}
        open={success}
        title="Exito"
        text={successText}
        severity="success"
      />
      <CustomAlert
        setOpen={setFailed}
        open={failed}
        title="Oops"
        text={failedMessage}
        severity="error"
      />
    </div>
  );
};

export default FactoringRequestsSent;
