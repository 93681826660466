import { DialogActions, Paper, Popper } from '@mui/material';
import React from 'react';
import PayersTable from './components/PayersTable';
import CustomButton from '../../../../../../../../../../../../lib/designSystem/Button';
import {
  calculateDays,
  calculatePaymentDate,
  getRealDaysToCount,
  isDateAWeekDay,
} from '../../../../../../../../../../../../lib/functions/dates';
import {
  formatDate,
  showAlert,
} from '../../../../../../../../../../../../lib/functions/utils';
import { useDispatch } from 'react-redux';

const PopperPayers = (props: any) => {
  const {
    selectedDocuments,
    requestPayers,
    setRequestPayers,
    setSelectedDocuments,
    showPayers,
    setShowPayers,
    anchorEl,
    placement,
    exchangeDay,
    canForcePaymentDate,
    minimumDays,
    operationDate,
  } = props;
  const dispatch = useDispatch();

  const generateSimulationDaysExchange = () => {
    const requestsInvoicesWithDates = [];
    for (const requestInvoice of selectedDocuments) {
      const { paymentDate, dueDate } = calculatePaymentDate(
        requestPayers[requestInvoice.stakeholderIdentifier].paymentDays + 1,
        requestInvoice.issuedDate,
      );
      if (!dateValidators(paymentDate, requestInvoice.folio)) return;
      const daysToCount = getRealDaysToCount(paymentDate, operationDate);
      const subDocuments = requestInvoice.subDocuments?.map((subDoc: any) => {
        const { paymentDate, dueDate } = calculatePaymentDate(
          requestPayers[requestInvoice.stakeholderIdentifier].paymentDays + 1,
          subDoc.issuedDate,
        );
        if (!dateValidators(paymentDate, requestInvoice.folio)) return;
        const daysToCount = getRealDaysToCount(paymentDate, operationDate);
        return {
          ...subDoc,
          daysToCount,
          dueDate: dueDate.toISOString(),
          paymentDate,
        };
      });
      const newRI = {
        ...requestInvoice,
        daysToCount,
        dueDate: dueDate.toISOString(),
        paymentDate,
        subDocuments,
      };
      requestsInvoicesWithDates.push(newRI);
    }
    setSelectedDocuments(requestsInvoicesWithDates);
  };

  const generateSimulationDays = () => {
    const newSelectedDocuments = [];
    for (const requestInvoice of selectedDocuments) {
      const paymentDate = new Date(requestInvoice.issuedDate);
      paymentDate.setUTCDate(
        paymentDate.getUTCDate() +
          requestPayers[requestInvoice.stakeholderIdentifier].paymentDays +
          1,
      );
      if (!dateValidators(paymentDate, requestInvoice.folio)) return;
      const daysToCount = getRealDaysToCount(paymentDate, operationDate);
      const subDocuments = requestInvoice.subDocuments?.map((subDoc: any) => {
        const paymentDate = new Date(subDoc.issuedDate);
        paymentDate.setUTCDate(
          paymentDate.getUTCDate() +
            requestPayers[requestInvoice.stakeholderIdentifier].paymentDays +
            1,
        );
        if (!dateValidators(paymentDate, requestInvoice.folio)) return;
        const daysToCount = getRealDaysToCount(paymentDate, operationDate);
        return {
          ...subDoc,
          daysToCount,
          dueDate: paymentDate.toISOString(),
          paymentDate,
          tempDueDate: formatDate(paymentDate),
        };
      });

      const newRI = {
        ...requestInvoice,
        daysToCount,
        dueDate: paymentDate.toISOString(),
        paymentDate: paymentDate,
        tempDueDate: formatDate(paymentDate),
        subDocuments,
      };

      newSelectedDocuments.push(newRI);
    }
    setSelectedDocuments(newSelectedDocuments);
  };

  const dateValidators = (date: Date, folio: string) => {
    if (canForcePaymentDate) return true;
    if (!isDateAWeekDay(date)) {
      showAlert(
        dispatch,
        'warning',
        `[Folio - ${folio}] La fecha de vencimiento no puede ser un día no hábil`,
      );
      return false;
    }
    const minimumDate = new Date(operationDate);
    minimumDate.setDate(minimumDate.getDate() + minimumDays - 1);
    if (date < minimumDate) {
      showAlert(
        dispatch,
        'warning',
        `[Folio - ${folio}] La fecha de vencimiento no puede ser menor a ${minimumDays} días a partir de la fecha actual`,
      );
      return false;
    }
    for (const requestInvoice of selectedDocuments) {
      const days = calculateDays(
        new Date(requestInvoice.issuedDate),
        new Date(date),
      );
      if (days <= 0) {
        showAlert(
          dispatch,
          'warning',
          `[Folio - ${folio}] La fecha de vencimiento no puede ser menor a la fecha de emisión`,
        );
        return false;
      }
    }
    return true;
  };

  const handlePayersApplication = () => {
    if (exchangeDay) {
      generateSimulationDaysExchange();
    } else {
      generateSimulationDays();
    }
    setShowPayers(false);
  };

  return (
    <Popper
      sx={{ zIndex: 1300 }}
      open={showPayers}
      anchorEl={anchorEl}
      placement={placement}
      transition={false}
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      <Paper style={{ padding: 8 }}>
        <PayersTable
          requestPayers={requestPayers}
          setRequestPayers={setRequestPayers}
        />
        <DialogActions>
          <CustomButton
            color="secondary"
            onClick={() => {
              setShowPayers(false);
            }}
          >
            Salir
          </CustomButton>
          <CustomButton onClick={() => handlePayersApplication()}>
            Aplicar
          </CustomButton>
        </DialogActions>
      </Paper>
    </Popper>
  );
};

export default PopperPayers;
