import React from 'react';
import {
  Typography,
  TextField,
  Grid,
  MenuItem,
  DialogActions,
} from '@mui/material';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';

interface NoExecutiveProps {
  byStakeholder: boolean;
  document: any;
  portfolioExecutiveId: number;
  setPortfolioExecutiveId: (id: number) => void;
  loading: boolean;
  executivesOptions: any[];
  handleAssignExecutive: () => void;
  documentHasExecutives: boolean;
  isManager: boolean;
}

const NoExecutive = (props: NoExecutiveProps) => {
  const {
    byStakeholder,
    document,
    portfolioExecutiveId,
    setPortfolioExecutiveId,
    loading,
    executivesOptions,
    handleAssignExecutive,
    documentHasExecutives,
    isManager,
  } = props;
  return (
    <>
      {isManager ? (
        <div>
          <Typography variant="body1">
            {`No hay ejecutivos con este ${
              byStakeholder ? 'deudor' : 'cliente'
            } en su cartera. Puedes asignar un ejecutivo a este documento asociando ${
              byStakeholder
                ? `${document.stakeholderName}`
                : `${document.businessName}`
            } a su cartera.`}
          </Typography>
          <Grid container spacing={2} style={{ paddingTop: '12px' }}>
            <Grid item xs={12}>
              <TextField
                select
                value={portfolioExecutiveId}
                label="Ejecutivo a asignar"
                variant="outlined"
                fullWidth
                onChange={(e) =>
                  setPortfolioExecutiveId(e.target.value as unknown as number)
                }
                disabled={loading}
              >
                {executivesOptions.map((element: any) => {
                  return (
                    <MenuItem key={element.id} value={element.id}>
                      {`${element.name} (${element.email})`}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Typography variant="body1">
          {`No hay ejecutivos con este documento en su cartera. Solicita a un
      gerente que asigne un ejecutivo a ${
        byStakeholder
          ? `${document.stakeholderName}`
          : `${document.businessName}`
      }.`}
        </Typography>
      )}
      {!documentHasExecutives ? (
        <DialogActions>
          <CustomButton
            color="primary"
            onClick={() => handleAssignExecutive()}
            disabled={loading || !portfolioExecutiveId}
          >
            Asignar ejecutivo
          </CustomButton>
        </DialogActions>
      ) : null}
    </>
  );
};

export default NoExecutive;
