import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
  Checkbox,
  Grid,
} from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import { useSelector } from 'react-redux';
import { expressGetCompanyLogo } from '../../../../../../../../../../../../../lib/api/express';
import { useDispatch } from 'react-redux';
import { AuthState } from '../../../../../../../../../../../../../services/redux/authSlice';
import { getCompanyLogo } from '../../../../../../../../../../../../../lib/api/company';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import {
  showAlert,
  formatDate,
} from '../../../../../../../../../../../../../lib/functions/utils';
import { generateSimulationCreditPDF } from '../../../../../../../../../common/exportSimulationCredit';

interface ExportDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  creditDetails: any;
  amortizationSchedule: any;
}

function calculateIRR(
  cuota: number,
  numCuotas: number,
  montoPrestamo: number,
): number {
  const maxIterations = 1000; // Máximo de iteraciones
  const tolerance = 1e-9; // Tolerancia para convergencia
  let tasa = 0.01; // Suposición inicial para la tasa de interés (1%)

  for (let i = 0; i < maxIterations; i++) {
    let f = 0; // Función f(tasa)
    let df = 0; // Derivada f'(tasa)

    for (let n = 1; n <= numCuotas; n++) {
      f += cuota / Math.pow(1 + tasa, n);
      df += (-n * cuota) / Math.pow(1 + tasa, n + 1);
    }

    f -= montoPrestamo;

    // Actualización de la tasa usando Newton-Raphson
    const nuevaTasa = tasa - f / df;

    if (Math.abs(nuevaTasa - tasa) < tolerance) {
      tasa = nuevaTasa; // Convergencia encontrada
      break;
    }

    tasa = nuevaTasa;
  }

  const finalRate = ((1 + tasa) ** 12 - 1) * 100;

  return finalRate;
}

const creditTypes = {
  WORKING_CAPITAL: 'Capital de Trabajo',
  CONSUMER: 'Consumo',
};

const interestTypes = {
  compound: 'Interés Compuesto',
  simple: 'Interés Simple',
};

const ExportDialog = (props: ExportDialogProps) => {
  const { open, setOpen, creditDetails, amortizationSchedule } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const [showWarranties, setShowWarranties] = useState(true);
  const [showPaymentTable, setShowPaymentTable] = useState(true);

  const buildData = () => {
    return {
      // Datos del crédito
      businessIdentifier: creditDetails.businessIdentifier,
      businessName: creditDetails.businessName,
      creditType: creditTypes[creditDetails.type as keyof typeof creditTypes],
      interestType:
        interestTypes[creditDetails.interestType as keyof typeof interestTypes],
      // Fechas
      startDate: formatDate(
        new Date(creditDetails.operationDate),
        'dd-mm-yyyy',
      ),
      firstPaymentDate: formatDate(
        new Date(creditDetails.firstPaymentDate),
        'dd-mm-yyyy',
      ),
      lastPaymentDate: formatDate(
        new Date(
          amortizationSchedule[amortizationSchedule.length - 1].paymentDate,
        ),
        'dd-mm-yyyy',
      ),
      months: creditDetails.months,
      totalPayments: amortizationSchedule.length,
      paymentDay: new Date(amortizationSchedule[0].paymentDate).getDate() + 1,
      // Valores
      totalAmount: creditDetails.amount || 0,
      operationAmount: creditDetails.operationCost || 0,
      insuranceAmount: creditDetails.otherExpenses || 0,
      totalLoanAmount:
        creditDetails.amount + creditDetails.operationCost ||
        0 + creditDetails.otherExpenses ||
        0,
      totalCost: amortizationSchedule.reduce(
        (acc: number, curr: any) => acc + curr.amount,
        0,
      ),
      // Tasas
      rate: parseFloat(creditDetails.rate),
      anualRate:
        parseFloat(creditDetails.anualRate) ||
        parseFloat(creditDetails.rate) * 12,
      arrearRate: parseFloat(creditDetails.arrearRate),
      anualArrearRate:
        parseFloat(creditDetails.anualArrearRate) ||
        parseFloat(creditDetails.arrearRate) * 12,
      cae: calculateIRR(
        amortizationSchedule[0].amount,
        amortizationSchedule.length,
        creditDetails.amount,
      ),

      workingDayActive: creditDetails.workingDayActive,
      warranties: creditDetails.warranties || [],

      amortizationSchedule: amortizationSchedule,
      showWarranties,
      showPaymentTable,
    };
  };

  const handleExportSimulation = async () => {
    setLoading(true);
    try {
      const data = buildData();
      const imageBase64 = user?.express
        ? await expressGetCompanyLogo(dispatch, user.clientId as string)
        : await getCompanyLogo(dispatch);
      await generateSimulationCreditPDF(data, user?.name || '', imageBase64);
      showAlert(dispatch, 'success', 'Simulación exportada correctamente');
    } catch (error) {
      console.log(error);
      showAlert(dispatch, 'error', 'Error al exportar simulación');
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>
        Exportar simulación solicitud <strong>#{creditDetails.id}</strong>
      </DialogTitle>
      <DialogContent>
        {loading && (
          <div style={{ minHeight: 200, minWidth: 500 }}>
            <CenteredCircularProgress type="dialog" />
          </div>
        )}
        {!loading && (
          <div>
            <Typography>
              Seleccione los datos que desea incluir/ocultar en la simulación.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showWarranties}
                      onChange={(e) => setShowWarranties(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Mostrar tabla de garantías"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showPaymentTable}
                      onChange={(e) => setShowPaymentTable(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Mostrar cuadro de pagos"
                />
              </Grid>
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          Cancelar
        </CustomButton>
        <CustomButton color="primary" onClick={() => handleExportSimulation()}>
          Exportar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
