import React from 'react';
import GTRangeAssign from '../GTRangeAssign';
import LTRangeAssign from '../LTRangeAssign';
import EQRangeAssign from '../EQRangeAssign';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

const RangesAssign = (props: any) => {
  const {
    newNode,
    addRange,
    handleChangeRange,
    deleteRange,
    availableFinalNodeForSelection,
    setNewNode,
    setOperation,
    operation,
  } = props;

  const handleChangeOperation = (e: any) => {
    const { value } = e.target;
    console.log('value', value);
    setOperation(value);
    setNewNode({ ...newNode, operation: value, ranges: [] });
  };

  const handleChangeBoolean = (e: any) => {
    const { value } = e.target;
    const { name } = e.target;
    console.log(name, value, newNode);
    const ranges = newNode.ranges.map((x: any, i: any) => {
      if (i === (name === 'correct' ? 1 : 0)) {
        return { ...x, nodeId: value };
      }
      return x;
    });
    console.log('ranges', ranges);
    setNewNode({ ...newNode, operation: 'eq', ranges });
  };

  if (newNode.type === 'boolean')
    return (
      <div>
        <Typography variant="body1" gutterBottom>
          <b>Opciones</b>
        </Typography>
        <FormControl fullWidth variant="outlined" margin="dense">
          <InputLabel id="filter-label">
            {' '}
            Cuando se cumple la evaluación asignar
          </InputLabel>
          <Select
            labelId="filter-label"
            id="Filtrar por estado"
            name="correct"
            margin="dense"
            value={newNode.ranges[1]?.nodeId}
            onChange={(e: any) => handleChangeBoolean(e)}
            label="Cuando se cumple la evaluación asignar"
          >
            {availableFinalNodeForSelection().map((x: any, index: any) => (
              <MenuItem key={index} value={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth variant="outlined" margin="dense">
          <InputLabel id="filter-label">
            {' '}
            Cuando no se cumple la evaluación asignar
          </InputLabel>
          <Select
            labelId="filter-label"
            id="Filtrar por estado"
            name="incorrect"
            value={newNode.ranges[0]?.nodeId}
            onChange={(e: any) => handleChangeBoolean(e)}
            label="Cuando no se cumple la evaluación asignar"
          >
            {availableFinalNodeForSelection().map((x: any, index: any) => (
              <MenuItem key={index} value={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  if (newNode.type !== 'boolean')
    return (
      <div>
        <div>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel id="filter-label">Operación</InputLabel>
            <Select
              labelId="filter-label"
              id="Filtrar por estado"
              name="operation"
              value={operation}
              onChange={(e: any) => handleChangeOperation(e)}
              label="Operación"
            >
              <MenuItem value="gt">Mayor a menor</MenuItem>
              <MenuItem value="lt">Menor a mayor</MenuItem>
              <MenuItem value="eq">Igual a</MenuItem>
            </Select>
          </FormControl>
        </div>
        {operation === 'eq' && (
          <EQRangeAssign
            newNode={newNode}
            addRange={addRange}
            handleChangeRange={handleChangeRange}
            deleteRange={deleteRange}
            availableFinalNodeForSelection={availableFinalNodeForSelection}
          />
        )}
        {operation === 'gt' && (
          <GTRangeAssign
            newNode={newNode}
            addRange={addRange}
            handleChangeRange={handleChangeRange}
            deleteRange={deleteRange}
            availableFinalNodeForSelection={availableFinalNodeForSelection}
          />
        )}
        {operation === 'lt' && (
          <LTRangeAssign
            newNode={newNode}
            addRange={addRange}
            handleChangeRange={handleChangeRange}
            deleteRange={deleteRange}
            availableFinalNodeForSelection={availableFinalNodeForSelection}
          />
        )}
      </div>
    );
};

export default RangesAssign;
