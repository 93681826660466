import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../../../../../../../lib/functions/utils';
import {
  getUserCollectionPortfolio,
  transferCollectionPortfolio,
} from '../../../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';

interface TransferListProps {
  setOpen: (open: boolean) => void;
  reload: number;
  setReload: (reload: number) => void;
  selectedUser: any;
  userPortfolio: any[];
  validUsers: any[];
}

function mergeById(arr1: any[], arr2: any[]) {
  const merged = [...arr1, ...arr2];
  return Array.from(new Map(merged.map((obj) => [obj.id, obj])).values());
}

function not(arr1: any[], arr2: any[]) {
  const idsInArr2 = new Set(arr2.map((obj) => obj.id));
  return arr1.filter((obj) => !idsInArr2.has(obj.id));
}

export default function SelectAllTransferList(props: TransferListProps) {
  const {
    setOpen,
    reload,
    setReload,
    selectedUser,
    userPortfolio,
    validUsers,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [leftChecked, setLeftChecked] = useState<any[]>([]);
  const [destinationUser, setDestinationUser] = useState<any>();
  const [originPortfolio, setOriginPortfolio] = useState<any[]>([]);
  const [destinationPortfolio, setDestinationPortfolio] = useState<any[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleToggle = (value: any) => () => {
    const currentIndex = leftChecked.indexOf(value);
    const newLeftChecked = [...leftChecked];
    if (currentIndex === -1) {
      newLeftChecked.push(value);
    } else {
      newLeftChecked.splice(currentIndex, 1);
    }

    setLeftChecked(newLeftChecked);
  };

  const handleMoveSelected = () => {
    setDestinationPortfolio(mergeById(destinationPortfolio, leftChecked));
    setOriginPortfolio(not(originPortfolio, leftChecked));
    setLeftChecked([]);
  };

  const handleMoveAll = () => {
    setDestinationPortfolio(mergeById(destinationPortfolio, originPortfolio));
    setOriginPortfolio([]);
    setLeftChecked([]);
  };

  const handleSelectDestinationUser = async (value: number) => {
    const user = validUsers.find((user) => user.id === value);
    setDestinationUser(user);
    setLoading(true);
    try {
      const response = await getUserCollectionPortfolio(dispatch, user.id);
      if (response) {
        setDestinationPortfolio(response);
      }
      setLeftChecked([]);
      setOriginPortfolio(userPortfolio);
    } catch {
      showAlert(
        dispatch,
        'error',
        'Error al obtener la cartera del usuario de destino',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    setLoadingButton(true);
    console.log('originPortfolio', originPortfolio);
    console.log('destinationPortfolio', destinationPortfolio);
    const data = {
      sourceUserId: selectedUser.id,
      targetUserId: destinationUser.id,
      sourcePortfolioIds: originPortfolio.map((portfolio) => portfolio.id),
      targetPortfolioIds: destinationPortfolio.map((portfolio) => portfolio.id),
    };
    try {
      await transferCollectionPortfolio(dispatch, data);
      showAlert(dispatch, 'success', 'Cartera transferida con éxito');
      setOpen(false);
      setLoading(false);
      setLoadingButton(false);
    } catch {
      showAlert(dispatch, 'error', 'Error al transferir la cartera');
      setLoading(false);
      setLoadingButton(false);
    } finally {
      setReload(reload + 1);
    }
  };

  useEffect(() => {
    setOriginPortfolio(userPortfolio);
  }, [userPortfolio]);

  if (loading) {
    return (
      <div
        style={{
          minHeight: '100px',
        }}
      >
        <CenteredCircularProgress type="dialog" />
      </div>
    );
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <Card>
            <CardHeader
              title={
                <FormControl sx={{ m: 1, width: '300px' }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Usuario origen
                  </InputLabel>
                  <Select
                    labelId="origin-user-select-label"
                    id="origin-user-select"
                    label="Usuario origen"
                    disabled={true}
                    value={selectedUser.id}
                    defaultValue={selectedUser.id}
                    fullWidth
                  >
                    {[selectedUser].map((option) => (
                      <MenuItem key={option.id} value={parseInt(option.id)}>
                        {option.name} ({option.email})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
              subheader={`${leftChecked.length}/${originPortfolio.length} seleccionados`}
            />
            <Divider />
            <List
              sx={{
                height: 230,
                bgcolor: 'background.paper',
                overflow: 'auto',
              }}
              dense
              component="div"
              role="list"
            >
              {originPortfolio.map((value: any) => {
                return (
                  <ListItemButton
                    key={value.id}
                    role="listitem"
                    onClick={handleToggle(value)}
                    sx={{ width: '100%' }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={leftChecked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': value.id,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText id={value.id} primary={value.name} />
                  </ListItemButton>
                );
              })}
            </List>
          </Card>
        </Grid>

        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleMoveSelected}
              disabled={leftChecked.length === 0 || !destinationUser}
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleMoveAll}
              disabled={originPortfolio.length === 0 || !destinationUser}
            >
              &gt; &gt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Card>
            <CardHeader
              title={
                <FormControl sx={{ m: 1, width: '300px' }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Usuario destino
                  </InputLabel>
                  <Select
                    labelId="origin-user-select-label"
                    id="origin-user-select"
                    label="Usuario destino"
                    value={destinationUser?.id}
                    defaultValue={destinationUser?.id}
                    fullWidth
                    onChange={(e) => {
                      handleSelectDestinationUser(parseInt(e.target.value));
                    }}
                  >
                    {validUsers.map((option) => (
                      <MenuItem key={option.id} value={parseInt(option.id)}>
                        {option.name} ({option.email})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
              subheader={'Nueva cartera destino'}
            />
            <Divider />
            <List
              sx={{
                height: 230,
                bgcolor: 'background.paper',
                overflow: 'auto',
              }}
              dense
              component="div"
              role="list"
            >
              {destinationPortfolio.map((value: any) => {
                return (
                  <ListItemButton
                    key={value.id}
                    role="listitem"
                    onClick={handleToggle(value)}
                    sx={{ width: '100%' }}
                  >
                    <ListItemText id={value.id} primary={value.name} />
                  </ListItemButton>
                );
              })}
            </List>
          </Card>
        </Grid>
      </Grid>
      <DialogActions>
        {' '}
        <CustomButton
          color="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {' '}
          Cancelar{' '}
        </CustomButton>
        <CustomButton
          disabled={loadingButton || !destinationUser}
          onClick={() => handleSave()}
        >
          {' '}
          Guardar{' '}
        </CustomButton>
      </DialogActions>
    </>
  );
}
