import React, { useEffect, useState } from 'react';
import {
  List,
  Avatar,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Logo from '../../assets/img/pruff9.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { getUnReadNotifications, logoutUser } from '../../lib/api';
import { AuthState, logout } from '../../services/redux/authSlice';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../lib/functions/utils';
import { RootState } from '../../services/redux/store';
import { setPendingNotifications } from '../../services/redux/notificactionSlice';
import NotificationsDrawer from '../notificationDrawer';
import { checkFinancingComplete } from '../../lib/permissionChecks';
import { buttonsAvailable } from './buttonsAvailable';
import { Notifications } from '@mui/icons-material';
import IntercomChat from '../../lib/thirdParties';

const activeFontColor = '#0F23DF';
const activeWeight = '300';

const StyledListItemButton = styled(ListItemButton)(
  ({ active }: { active: boolean }) => ({
    margin: '4px 8px',
    padding: '6px 12px',
    borderRadius: '16px',
    '& .MuiListItemText-primary': {
      fontSize: 17, // Set the weight you want on hover
    },
    '&:hover': {
      backgroundColor: '#efefef',
      // Target the icons on hover
      '& .MuiListItemIcon-root': {
        color: activeFontColor, // White on hover
      },
      // Also, make sure the text gets the intended weight
      '& .MuiListItemText-primary': {
        fontWeight: activeWeight, // Set the weight you want on hover
      },
    },
    ...(active && {
      backgroundColor: '#efefef',
      // Also, make sure the text gets the intended weight when active
      '& .MuiListItemText-primary': {
        fontWeight: activeWeight, // Set the weight you want when active
        fontSize: 17, // Set the weight you want on hover
      },
      // Ensure the icons are white when active
    }),
    // Assuming `active` class is applied when the item is active
    '&.active': {
      backgroundColor: '#efefef',
      fontWeight: activeWeight, // Set the weight you want when active
      '& .MuiListItemIcon-root': {},
      // Also, make sure the text gets the intended weight when active
      '& .MuiListItemText-primary': {
        fontWeight: activeWeight, // Set the weight you want when active
      },
    },
  }),
);

// Styled List Item Icon with conditional active styles
const StyledListItemIcon = styled(ListItemIcon)(
  ({ active }: { active: boolean }) => ({
    margin: '0px 10px 0px 0px',
    minWidth: 0,
    '&:hover': {
      color: '#ffffff',
    },
    ...(active && {
      color: activeFontColor,
    }),
  }),
);

const useStyles = makeStyles(() =>
  createStyles({
    sidebar: {
      minWidth: '14vw', // If changed, change the width in the layout.tsx file
      height: '100vh', // Set the sidebar to full height
      backgroundColor: 'ffffff', // Change the background color
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRight: '1px solid #eaecf0',
    },
    logo: {
      padding: '20px 0px 0px',
      textAlign: 'center',
      width: '100%',
      height: '52px',
      borderBottom: '1px solid #eaecf0',
    },
    upItems: {
      width: '100%',
    },
    navList: {
      width: '80%',
    },
    avatarSection: {
      cursor: 'pointer',
      width: '100%',
      padding: '10px',
      overflowX: 'scroll',
      borderTop: '1px solid #eaecf0',
      display: 'flex',
      alignItems: 'center',
    },
    avatarSectionActive: {
      borderTop: `1px solid ${activeFontColor}`,
    },
    avatarActive: {
      color: `${activeFontColor} !important`,
    },
    avatar: {
      width: 45, // Adjust the size of the avatar as needed
      height: 45, // Match the size of the avatar to the text
      margin: '0px 12px 0px 0px',
    },
  }),
);

const setNavButtons = (user: any) => {
  const navButtons = [buttonsAvailable['home'], buttonsAvailable['companies']];
  if (validateCompanyTypes(user, 'FactoringFS')) {
    if (validatePermissions(user, 'financial_states_create_assignment')) {
      navButtons.push(buttonsAvailable['financialStatements']);
    }
  }
  if (checkFinancingComplete(user)) {
    if (validateCompanyTypes(user, 'FactoringQuoting')) {
      navButtons.push(buttonsAvailable.financeFactoring);
    }

    if (validateCompanyTypes(user, 'FinancingHub')) {
      navButtons.push(buttonsAvailable.financeCreditLine);
    }
  }

  if (validateCompanyTypes(user, 'AccountantCollections')) {
    navButtons.push(buttonsAvailable.financeCollections);
  }
  if (
    validateCompanyTypes(user, 'FinancingHub') ||
    validateCompanyTypes(user, 'AccountantCollections')
  ) {
    if (validatePermissions(user, 'network_view')) {
      navButtons.push(buttonsAvailable['network']);
    }
  }

  if (validateCompanyTypes(user, 'CreditHubPro')) {
    if (validatePermissions(user, 'creditHubPro_evaluation_view')) {
      navButtons.push(buttonsAvailable.creditHubPro);
    }
  }

  navButtons.push(buttonsAvailable.consultor);
  if (validateCompanyTypes(user, 'Integrations')) {
    if (validatePermissions(user, 'integrations')) {
      navButtons.push(buttonsAvailable.integrations);
    }
  }
  if (validateCompanyTypes(user, 'PruffRush')) {
    navButtons.push(buttonsAvailable.pruffRush);
  }
  const uniqueNavButtons = Array.from(
    new Set(navButtons.map((a) => a.label)),
  ).map((label) => {
    return navButtons.find((a) => a.label === label);
  });
  return uniqueNavButtons;
};

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const initialPath = currentPath.split('/')[1];
  const [activeItem, setActiveItem] = useState(''); // Default active item
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false); // Default active item
  const notificactionsPending = useSelector(
    (state: RootState) => state.notification.pendingNotifications,
  );

  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const navButtons = setNavButtons(user);
  useEffect(() => {
    const newActiveItem = initialPath.length
      ? initialPath
      : navButtons[0].id.split('/')[0];
    console.log('active', newActiveItem);
    setActiveItem(newActiveItem);
  }, []);

  const adminButtons = [buttonsAvailable['settings']];

  const getPendingNotifications = async () => {
    try {
      const notifications = await getUnReadNotifications(dispatch);
      console.log(notifications);
      dispatch(setPendingNotifications(notifications.length));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPendingNotifications();
  }, []);

  const handleNavigation = (id: string) => {
    navigate(`/${id}`);
    setActiveItem(id.split('/')[0]);
  };

  const handleLogout = async () => {
    await logoutUser(dispatch);
    dispatch(logout());
  };

  const dispatch = useDispatch();

  const openSettings = () => {
    if (validatePermissions(user, 'settings')) {
      return true;
    } else if (
      validatePermissions(user, 'settings_users') ||
      validatePermissions(user, 'settings_risk') ||
      validatePermissions(user, 'settings_products') ||
      validatePermissions(user, 'settings_finance')
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes.sidebar}>
      <IntercomChat />
      {/* Logo Section */}
      <div className={classes.upItems}>
        <div className={classes.logo}>
          <img width={135} src={Logo} />
        </div>
        {/* Navigation Section */}
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            paddingTop: 0,
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          // subheader={
          //   <ListSubheader component="div" id="nested-list-subheader">
          //     Nested List Items
          //   </ListSubheader>
          // }
        >
          {navButtons.map((button, index) => {
            const active = button.id.split('/')[0] === activeItem;
            return (
              <div key={index} style={{ position: 'relative' }}>
                {' '}
                {/* Container with relative positioning */}
                <div style={{ padding: '0px 0px' }}>
                  <StyledListItemButton
                    key={index}
                    active={active}
                    onClick={() => handleNavigation(button.id)}
                  >
                    <StyledListItemIcon active={active}>
                      {button.icon}
                    </StyledListItemIcon>

                    <ListItemText primary={button.label} />
                  </StyledListItemButton>
                </div>
              </div>
            );
          })}
          <StyledListItemButton
            onClick={() => setNotificationDrawerOpen(true)}
            active={false}
          >
            <StyledListItemIcon active={false}>
              <Notifications style={{ fontSize: '19px' }} />
            </StyledListItemIcon>
            <ListItemText primary="Notificaciones" />
            {notificactionsPending !== 0 ? (
              <Typography
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  color: 'white',
                  fontSize: 12,
                  padding: '4px 8px',
                  borderRadius: 50,
                  fontWeight: '400',
                  margin: '1px 2px',
                  backgroundColor: '#E8C547',
                }}
              >
                {notificactionsPending}
              </Typography>
            ) : (
              <div />
            )}
          </StyledListItemButton>
        </List>

        {/* Avatar Section */}
      </div>
      <div className={classes.upItems}>
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            paddingTop: 0,
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {openSettings() &&
            adminButtons.map((button, index) => {
              const active = button.id.split('/')[0] === activeItem;

              return (
                <StyledListItemButton
                  key={index}
                  active={active}
                  onClick={() => handleNavigation(button.id)}
                >
                  <StyledListItemIcon active={active}>
                    {button.icon}
                  </StyledListItemIcon>
                  <ListItemText primary={button.label} />
                </StyledListItemButton>
              );
            })}
          <StyledListItemButton onClick={() => handleLogout()} active={false}>
            <StyledListItemIcon active={false}>
              <LogoutIcon style={{ fontSize: '19px' }} />
            </StyledListItemIcon>
            <ListItemText primary="Salir" />
          </StyledListItemButton>
        </List>
        <div
          className={`${classes.avatarSection} ${activeItem === 'profile' && classes.avatarSectionActive}`}
          onClick={() => handleNavigation('profile')}
        >
          <Avatar
            className={`${classes.avatar}  ${activeItem === 'profile' && classes.avatarActive}`}
          />
          <div>
            <Typography fontSize={16}>{user?.name}</Typography>
            <Typography fontSize={14} color="#667085">
              {user?.companyName}
            </Typography>
          </div>
        </div>
      </div>
      <NotificationsDrawer
        open={notificationDrawerOpen}
        setOpen={setNotificationDrawerOpen}
      />
    </div>
  );
};

export default Sidebar;
