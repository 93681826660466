import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import {
  getBusiness,
  getBusinessF22Summary,
  getBusinessF29Summary,
} from '../../../../../lib/api';
import { useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import Collapse from '@mui/material/Collapse';
import CustomButton from '../../../../../lib/designSystem/Button';
import IvaEmptyer from './IvaEmptyer';
import HeaderWithActions from '../../../../../lib/designSystem/HeaderWithActions';
import SimpleLineChart from '../BusinessSummary/Components/LineChart';
import CustomAlert from '../../../../../lib/designSystem/Alert';
import { LightTooltip } from '../../../../../lib/designSystem/WhiteTooltip';
import { Info } from '@mui/icons-material';
import { getIvaFolderLink } from '../../../../../lib/api/business';
import IVAFolderLoader from './IVAFolderLoader';
import { loggerDev } from '../../../../../lib/functions/utils';

interface SummaryBoxProps {
  title: string;
  content: React.JSX.Element;
}

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      border: '1px solid #e0e0e0',
      padding: '30px 0px',
      background: '#ffffff',
      borderRadius: '10px',
      width: '100%', // ensure the table fits its container
      tableLayout: 'fixed', // this can help with column overflow issues
    },
  }),
);

const SummaryBox: React.FC<SummaryBoxProps> = ({ content }) => {
  const classes = useStyles();
  return (
    <div className={classes.box}>
      {/* <Typography variant="h4">{title}</Typography> */}
      {content}
    </div>
  );
};

const BusinessIVA: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [f29Data, setF29Data] = useState({
    mainHash: { total: [], totalIVA: [] },
    dataForGraph: { total: [], totalIVA: [] },
    dataForGraphAccumulative: { total: [], totalIVA: [] },
    variation: { total: [], totalIVA: [] },
    accumulative: { total: [], totalIVA: [] },
    metadata: { months: [], years: [] },
  });
  const [withIva, setWithIva] = useState(false);
  const [f22Data, setF22Data] = useState({ cleanValuesTable: [], years: [] });
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('false');
  const [active, setActive] = useState(false);
  const activateSuccess = (message: string) => {
    setSuccessMessage(message);
    setSuccess(true);
  };
  const activateFailed = (message: string) => {
    setFailedMessage(message);
    setFailed(true);
  };
  const [expandedF29, setExpandedF29] = React.useState(false);
  const [expandedF22, setExpandedF22] = React.useState(false);

  const [openIVADialog, setOpenIVADialog] = useState(false);
  const [loadingIVAFolder, setLoadingIVAFolder] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [maxWidth, setMaxWidth] = useState('100%');

  useEffect(() => {
    const updateMaxWidth = () => {
      // Calculate and set the desired max-width here based on conditions
      setMaxWidth(`${window.innerWidth - 280}px`);
    };

    window.addEventListener('resize', updateMaxWidth);

    // Set initial value
    updateMaxWidth();

    return () => {
      window.removeEventListener('resize', updateMaxWidth);
    };
  }, []);

  const fetchRelations = async () => {
    if (!id) return;
    const f29Data = await getBusinessF29Summary(dispatch, id);
    const f22Data = await getBusinessF22Summary(dispatch, id);
    const business = await getBusiness(dispatch, id);

    setActive(business.companies[0].active);

    // console.log(f29Data)
    console.log(f29Data);
    setF22Data(f22Data || []);
    setF29Data(f29Data || []);
    setLoading(false);
    setExpandedF29(true);
  };

  const handleDownloadIva = async () => {
    try {
      setLoadingIVAFolder(true);
      if (!id) return;
      const data = await getIvaFolderLink(dispatch, id);
      window.open(data.link, '_blank');
    } catch (error) {
      console.log(error);
    }
    setLoadingIVAFolder(false);
  };

  const handleOpenIVA = () => {
    loggerDev('open', openIVADialog);
    setOpenIVADialog(true);
  };
  useEffect(() => {
    fetchRelations();
  }, []);
  if (loading) return <CenteredCircularProgress />;

  const emptyAndInactive =
    !Object.keys(f29Data.mainHash.total).length && !active;
  const emptyAndActive = !Object.keys(f29Data.mainHash.total).length && active;

  if (emptyAndInactive)
    return (
      <div>
        <HeaderWithActions
          variant={'body1'}
          title="Aquí econtrarás la información de la carpeta tributaria de tu empresa. Conecta con el SII o vacía su carpeta tributaria"
          buttons={[
            {
              action: () => handleOpenIVA(),
              name: 'Vaciar carpeta',
              color: 'primary',
            },
          ]}
        />
        {openIVADialog && (
          <IvaEmptyer
            setLoading={setLoading}
            activateSuccess={activateSuccess}
            activateFailed={activateFailed}
            setFailed={setFailed}
            setOpenIVADialog={setOpenIVADialog}
            openIVADialog={openIVADialog}
            fetchRelations={fetchRelations}
          />
        )}
      </div>
    );
  if (emptyAndActive)
    return (
      <div>
        <HeaderWithActions
          variant={'body1'}
          title="No se encontró información sobre este cliente. Descarga para corrorborar que no hay información en la carpeta tributaria"
          buttons={[
            {
              action: () => handleDownloadIva(),
              name: 'Descargar',
              color: 'secondary',
            },
            // {
            //   action: () => handleOpenIVA(),
            //   name: 'Reintentar',
            //   color: 'primary',
            // },
          ]}
        />
        <IVAFolderLoader
          loading={loadingIVAFolder}
          setLoading={setLoadingIVAFolder}
        />
      </div>
    );

  return (
    <div>
      <IvaEmptyer
        setLoading={setLoading}
        activateSuccess={activateSuccess}
        setFailed={setFailed}
        setOpenIVADialog={setOpenIVADialog}
        openIVADialog={openIVADialog}
        fetchRelations={fetchRelations}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4" padding={0}>
            Carpeta Tributaria ({expandedF29 ? 'F29' : 'F22'})
          </Typography>
          <LightTooltip
            placement="right"
            title={
              <Typography variant="body1" padding={1}>
                {withIva
                  ? 'Los valores mostrados incluyen Iva. '
                  : 'Los valores mostrados no incluyen Iva. '}
                <span
                  onClick={() => setWithIva(!withIva)}
                  style={{ color: 'blue' }}
                >
                  Ver {withIva ? 'sin Iva' : 'con Iva'}
                </span>
              </Typography>
            }
          >
            <Info style={{ fontSize: 16, marginLeft: 8 }} />
          </LightTooltip>
        </div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {active && (
              <div style={{ marginRight: 10 }}>
                <CustomButton
                  onClick={() => {
                    handleDownloadIva();
                  }}
                  color="secondary"
                >
                  {' '}
                  Descargar
                </CustomButton>
              </div>
            )}
            {!expandedF29 && (
              <CustomButton
                onClick={() => {
                  setExpandedF29(!expandedF29);
                  setExpandedF22(false);
                }}
              >
                {' '}
                F29
              </CustomButton>
            )}
            {active && expandedF29 && (
              <CustomButton
                onClick={() => {
                  setExpandedF22(!expandedF22);
                  setExpandedF29(false);
                }}
              >
                {' '}
                F22
              </CustomButton>
            )}
            {!active && (
              <CustomButton color="primary" onClick={() => handleOpenIVA()}>
                {' '}
                Vaciar carpeta
              </CustomButton>
            )}
          </div>
        </div>
      </div>
      <Collapse in={expandedF29} timeout="auto" unmountOnExit>
        <div style={{ maxWidth }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4">Total Ventas</Typography>
              <SummaryBox
                title={`Valores del F29`}
                content={
                  <SimpleLineChart
                    aspect={4.0 / 0.8}
                    data={f29Data.dataForGraph[!withIva ? 'total' : 'totalIVA']}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <EnhancedTable
                exportToFile="xlsx"
                tableSize="small"
                dataHeaders={[
                  { key: 'year', label: 'Año' },
                  ...f29Data.metadata.months.map((x: any) => ({
                    key: x,
                    label: x,
                    type: 'money',
                  })),
                ]}
                data={f29Data.mainHash[!withIva ? 'total' : 'totalIVA']}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Total Acumulado</Typography>
              <SummaryBox
                title={`Valores del F29`}
                content={
                  <SimpleLineChart
                    aspect={4.0 / 0.8}
                    data={
                      f29Data.dataForGraphAccumulative[
                        !withIva ? 'total' : 'totalIVA'
                      ]
                    }
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <EnhancedTable
                exportToFile="xlsx"
                tableSize="small"
                dataHeaders={[
                  { key: 'year', label: 'Año' },
                  ...f29Data.metadata.months.map((x: any) => ({
                    key: x,
                    label: x,
                    type: 'money',
                  })),
                ]}
                data={f29Data.accumulative[!withIva ? 'total' : 'totalIVA']}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">Variación acumulados anuales</Typography>
              <EnhancedTable
                exportToFile="xlsx"
                tableSize="small"
                dataHeaders={[
                  { key: 'year', label: 'Año' },
                  ...f29Data.metadata.months.map((x: any) => ({
                    key: x,
                    label: x,
                  })),
                ]}
                data={f29Data.variation[!withIva ? 'total' : 'totalIVA']}
              />
            </Grid>
          </Grid>
        </div>
      </Collapse>
      <Collapse in={expandedF22} timeout="auto" unmountOnExit>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Declaración de renta</Typography>
            <EnhancedTable
              tableSize="small"
              dataHeaders={[
                { key: 'nameCode', label: 'Año' },
                ...f22Data.years.map((x: any) => ({
                  key: x,
                  label: x,
                  type: 'money',
                })),
              ]}
              exportToFile="xlsx"
              data={f22Data.cleanValuesTable}
              rowsPerPageDefault={100}
            />
          </Grid>
        </Grid>
      </Collapse>
      <CustomAlert
        open={success}
        setOpen={setSuccess}
        severity="success"
        text={successMessage}
        handleClose={() => setSuccess(false)}
      />
      <CustomAlert
        open={failed}
        setOpen={setFailed}
        severity="error"
        text={failedMessage}
        handleClose={() => setFailed(false)}
      />
      <IVAFolderLoader
        loading={loadingIVAFolder}
        setLoading={setLoadingIVAFolder}
      />
    </div>
  );
};

export default BusinessIVA;
