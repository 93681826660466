import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

interface StepGridProps {
  stepType: 'Manual' | 'Automático';
  missingFields: boolean;
  handleSetStepType: (stepType: 'Manual' | 'Automático') => void;
  stepDays: number | null;
  setStepDays: (stepDays: number | null) => void;
  stepRegard: string;
  setStepRegard: (stepRegard: string) => void;
  daysError: boolean;
}

const StepGrid: React.FC<StepGridProps> = (props) => {
  const {
    stepType,
    missingFields,
    handleSetStepType,
    stepDays,
    setStepDays,
    stepRegard,
    setStepRegard,
    daysError,
  } = props;

  const daysText =
    stepType === 'Manual'
      ? 'Días para vencimiento gestión'
      : 'Días para ejecución automática';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <div style={{ padding: '5px' }}>
        <Typography variant="subtitle1">Agregar gestiones</Typography>
      </div>
      <FormControl fullWidth>
        <InputLabel id="type-label">Tipo</InputLabel>
        <Select
          labelId="type-label"
          label="Tipo"
          id="type-select"
          value={stepType}
          onChange={(e) =>
            handleSetStepType(e.target.value as 'Manual' | 'Automático')
          }
        >
          <MenuItem value="Manual">Manual</MenuItem>
          <MenuItem value="Automático">Automático</MenuItem>
        </Select>
      </FormControl>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            error={missingFields && ((!stepDays && stepDays != 0) || daysError)}
            label={daysText}
            type="number"
            value={stepDays}
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = Number(value);
              if (value === '') {
                setStepDays(null);
                return;
              }
              if (Number.isNaN(parsedValue) || !Number.isInteger(parsedValue)) {
                return;
              }
              setStepDays(parsedValue);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="regard-label">Respecto a</InputLabel>
            <Select
              error={missingFields && !stepRegard}
              labelId="regard-label"
              label="Respecto a"
              id="regard-select"
              value={stepRegard}
              onChange={(e) => setStepRegard(e.target.value)}
            >
              <MenuItem value="CREATIONDATE">Fecha de creación</MenuItem>
              <MenuItem value="DUEDATE">Fecha de vencimiento</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default StepGrid;
