import { DialogActions, Paper, Popper, TextField } from '@mui/material';
import React from 'react';
import CustomButton from '../../../../../../../../../../../../lib/designSystem/Button';
import {
  calculateDays,
  getLiberationDate,
  getRealDaysToCount,
  isDateAWeekDay,
} from '../../../../../../../../../../../../lib/functions/dates';
import {
  showAlert,
  formatDate,
} from '../../../../../../../../../../../../lib/functions/utils';
import { useDispatch } from 'react-redux';
const PopperUniqueDate = (props: any) => {
  const {
    selectedDocuments,
    setSelectedDocuments,
    open,
    setOpen,
    anchorEl,
    placement,
    exchangeDay,
    canForcePaymentDate,
    minimumDays,
    operationDate,
  } = props;
  const [uniqueDate, setUniqueDate] = React.useState<any>(null);
  const dispatch = useDispatch();

  const dateValidators = (date: Date) => {
    if (canForcePaymentDate) return true;

    if (!isDateAWeekDay(date)) {
      showAlert(
        dispatch,
        'warning',
        'La fecha de vencimiento debe ser un día hábil',
      );
      return false;
    }
    const minimumDate = new Date(operationDate);
    minimumDate.setDate(minimumDate.getDate() + minimumDays - 1);
    if (date < minimumDate) {
      showAlert(
        dispatch,
        'warning',
        `La fecha de vencimiento no puede ser menor a ${minimumDays}  días a partir de la fecha actual`,
      );
      return false;
    }
    for (const requestInvoice of selectedDocuments) {
      const days = calculateDays(
        new Date(requestInvoice.issuedDate),
        new Date(date),
      );
      if (days <= 0) {
        showAlert(
          dispatch,
          'warning',
          'La fecha de vencimiento no puede ser menor a la fecha de emisión',
        );
        return false;
      }
    }
    return true;
  };

  const handleChangeDateNormal = () => {
    const date = new Date(uniqueDate);
    const valid = dateValidators(date);
    if (!valid) return;

    const newSelectedDocuments = selectedDocuments.map((doc: any) => {
      const subDocuments = doc.subDocuments?.map((subDoc: any) => {
        return {
          ...subDoc,
          dueDate: date?.toUTCString(),
          paymentDate: date,
          tempDueDate: formatDate(date),
          daysToCount: getRealDaysToCount(date, operationDate),
        };
      });
      return {
        ...doc,
        dueDate: date?.toUTCString(),
        paymentDate: date,
        daysToCount:
          subDocuments.length === 0
            ? getRealDaysToCount(date, operationDate)
            : null,
        tempDueDate: formatDate(date),
        subDocuments,
      };
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  const handleChangeDateExchange = () => {
    const date = new Date(uniqueDate);
    const liberationDate = getLiberationDate(date);
    const valid = dateValidators(liberationDate);
    if (!valid) return;
    const daysToCount = getRealDaysToCount(liberationDate);
    const newSelectedDocuments = selectedDocuments.map((doc: any) => {
      const subDocuments = doc.subDocuments?.map((subDoc: any) => {
        return {
          ...subDoc,
          dueDate: date?.toISOString(),
          paymentDate: liberationDate,
          tempDueDate: formatDate(date),
          daysToCount,
        };
      });
      return {
        ...doc,
        dueDate: date?.toISOString(),
        paymentDate: liberationDate,
        daysToCount:
          subDocuments.length === 0
            ? getRealDaysToCount(date, operationDate)
            : null,
        tempDueDate: formatDate(date),
        subDocuments,
      };
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  const handleApplyUniqueDate = () => {
    if (exchangeDay) {
      handleChangeDateExchange();
    } else {
      handleChangeDateNormal();
    }
    setOpen(false);
  };

  return (
    <Popper
      sx={{ zIndex: 1300 }}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition={false}
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      <Paper style={{ padding: 8 }}>
        <TextField
          value={uniqueDate}
          type="date"
          onChange={(e: any) => setUniqueDate(e.target.value)}
        />
        <DialogActions>
          <CustomButton
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </CustomButton>
          <CustomButton onClick={() => handleApplyUniqueDate()}>
            Aplicar
          </CustomButton>
        </DialogActions>
      </Paper>
    </Popper>
  );
};

export default PopperUniqueDate;
