import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  message: '',
  severity: 'error',
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (
      state,
      action: PayloadAction<{ message: string; severity: string }>,
    ) => {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    hideAlert: (state) => {
      state.open = false;
      state.message = '';
      state.severity = 'error';
    },
  },
});

export const { showAlert, hideAlert } = alertSlice.actions;
export default alertSlice.reducer;
