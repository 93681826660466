// store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import notificationReducer from './notificactionSlice'; // Import the new reducer
import clientConfigurationReducer from './clientConfigSlice'; // Import the new reducer
import alertReducer from './alertSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedAlertReducer = persistReducer(persistConfig, alertReducer);
const persistedNotificationReducer = persistReducer(
  persistConfig,
  notificationReducer,
);
const persistedClientConfigurationReducer = persistReducer(
  persistConfig,
  clientConfigurationReducer,
);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    notification: persistedNotificationReducer,
    clientConfiguration: persistedClientConfigurationReducer,
    alert: persistedAlertReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
