// LoginForm.tsx
import React, { useState } from 'react';
import CustomAlert from '../../../../../lib/designSystem/Alert';
import ShowStatements from './Components/Show';
import Assignation from './Components/Assignment';

const Statements = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openSuccessText, setOpenSuccessText] = useState('');

  const [openFailed, setOpenFailed] = useState(false);
  const [openAnalysis, setOpenAnalysis] = useState(0);
  const [editAssignment, setEditAssginment] = useState(false);
  const handleOpenSuccess = (text: string) => {
    setOpenSuccessText(text);
    setOpenSuccess(true);
  };

  return (
    <div>
      {!openAnalysis ? (
        <ShowStatements />
      ) : (
        <Assignation
          setOpenAnalysis={setOpenAnalysis}
          openAnalysis={openAnalysis}
          editAssignment={editAssignment}
          handleOpenSuccess={handleOpenSuccess}
        />
      )}
      <CustomAlert
        open={openSuccess}
        title="Éxito"
        text={openSuccessText}
        setOpen={setOpenSuccess}
      />
      <CustomAlert
        open={openFailed}
        title="Error"
        severity="error"
        text="Hubo un error en el análisis"
        setOpen={setOpenFailed}
      />
    </div>
  );
};

export default Statements;
