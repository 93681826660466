import React from 'react';
import { DialogActions } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import CreditLineStatus from '../../../../../../../../../common/creditLineStatus';

const ShoppingCartFactoringInReview = (props: any) => {
  const { setOpenShoppingCart, openRequest } = props;

  return (
    <div>
      <CreditLineStatus openRequest={openRequest} audienceType="received" />
      <DialogActions>
        <CustomButton
          onClick={() => setOpenShoppingCart(false)}
          color="secondary"
        >
          Salir
        </CustomButton>
      </DialogActions>
    </div>
  );
};

export default ShoppingCartFactoringInReview;
