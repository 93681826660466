import React, { useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../../../lib/designSystem/Button';
import DialogTitle from '../../../../../../../../../../../../../../../lib/designSystem/DialogTitle';
import { updateStatusFactoringRequest } from '../../../../../../../../../../../../../../../lib/api/factoring';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import { updateStatusTransitions } from '../../../DialogActionWrapper';
import { showAlert } from '../../../../../../../../../../../../../../../services/redux/alertSlice';

type MessageType = {
  warning: string;
  warningArray: string[];
  acceptance: string;
};

type MessagesHash = {
  [key: string]: {
    [key: string]: MessageType;
  };
};

const messagesHash: MessagesHash = {
  Aceptada: {
    IN_REVIEW: {
      warning: `Al actualizar el estado de la solicitud a 'En revisión', se eliminarán:`,
      warningArray: [
        'Las aprobaciones de esta solicitud',
        'La nómina realizada',
      ],
      acceptance: 'Estoy seguro de actualizar el estado de la solicitud',
    },
  },
};

const FactoringRequestUpdateStatusModal = (props: any) => {
  const {
    open,
    setOpen,
    requestId,
    setUp,
    setSuccessOpen,
    setFailureOpen,
    oldStatus,
    selectedId,
  } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [newStatus, setNewStatus] = useState('');

  const handleUpdateStatusFactoringRequest = async () => {
    setLoading(true);
    try {
      await updateStatusFactoringRequest(dispatch, requestId, {
        oldStatus: oldStatus,
        newStatus: newStatus,
      });
      dispatch(
        showAlert({
          message:
            'La solicitud de factoring ha sido actualizada exitosamente.',
          severity: 'success',
        }),
      );
      setOpen(false);
      if (selectedId) {
        await setUp(selectedId);
      } else {
        await setUp();
      }
    } catch (error) {
      dispatch(
        showAlert({
          message:
            'Ha ocurrido un error al intentar actualizar la solicitud de factoring.',
          severity: 'error',
        }),
      );
    }
    setLoading(false);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle setOpen={setOpen}>
        Actualizar estado de solicitud #{requestId}
      </DialogTitle>
      {loading ? (
        <DialogContent style={{ minHeight: 200 }}>
          <CenteredCircularProgress type="dialog" />
        </DialogContent>
      ) : (
        <div>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>Estado actual:</Typography>
                <Typography>
                  <b>{oldStatus}</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  style={{ marginBottom: '10px' }}
                  variant="outlined"
                >
                  <InputLabel>Nuevo estado</InputLabel>
                  <Select
                    value={newStatus}
                    onChange={(e) => {
                      setNewStatus(e.target.value);
                      setAgreeTerms(false);
                    }}
                    fullWidth
                    label="Nuevo estado"
                  >
                    {updateStatusTransitions[oldStatus].map((status) => (
                      <MenuItem key={status.key} value={status.key}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Typography>
              {messagesHash[oldStatus]?.[newStatus]?.warning}
            </Typography>
            <div style={{ padding: 8 }}>
              {messagesHash[oldStatus]?.[newStatus]?.warningArray.map(
                (text, index) => (
                  <Typography key={index} variant="body1">
                    - {text}
                  </Typography>
                ),
              )}
            </div>

            {newStatus && (
              <div style={{ padding: '8px 0px 0px' }}>
                <FormControlLabel
                  hidden={!newStatus}
                  control={
                    <Checkbox
                      onChange={() => setAgreeTerms(!agreeTerms)}
                      value={agreeTerms}
                      checked={agreeTerms}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {messagesHash[oldStatus]?.[newStatus]?.acceptance}
                    </Typography>
                  }
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <CustomButton
              onClick={() => setOpen(false)}
              variant="outlined"
              color="secondary"
            >
              Cancelar
            </CustomButton>
            <CustomButton
              onClick={() => handleUpdateStatusFactoringRequest()}
              variant="contained"
              color="primary"
              disabled={!agreeTerms}
            >
              Actualizar
            </CustomButton>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

export default FactoringRequestUpdateStatusModal;
