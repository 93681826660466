import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import { deleteCollectionFlow } from '../../../../../../../../lib/api/collectionFlow';
import { showAlert } from '../../../../../../../../lib/functions/utils';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
interface UnenrollmentProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  flow: any;
  parentSetUp: () => void;
}

const DeleteFlow = (props: UnenrollmentProps) => {
  const { open, setOpen, flow, parentSetUp } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteFlow = async () => {
    try {
      setLoading(true);
      const id = flow.id;
      await deleteCollectionFlow(dispatch, id);
      showAlert(dispatch, 'success', 'Flujo eliminado con éxito');
      setOpen(false);
      parentSetUp();
    } catch (error) {
      showAlert(dispatch, 'error', 'Ha ocurrido un error');
      setOpen(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Eliminar flujo de cobranza <strong>{flow.name}</strong>
        </DialogTitle>
        {loading && (
          <div style={{ minHeight: 200 }}>
            <CenteredCircularProgress type="dialog" />
          </div>
        )}
        {!loading && (
          <>
            <DialogContent>
              <Typography>
                ¿Estás seguro de querer eliminar este flujo de cobranza?
              </Typography>
              <Typography>
                - Se eliminarán todas las gestiones manuales no completadas
                creadas con este flujo.
              </Typography>
              <Typography>
                - Se eliminarán todas las gestiones automáticas no completadas
                creadas con este flujo.
              </Typography>
              <Typography>
                - Las gestiones completadas no serán afectadas.
              </Typography>
            </DialogContent>
            <DialogActions>
              <CustomButton color="secondary" onClick={() => setOpen(false)}>
                Cancelar
              </CustomButton>
              <CustomButton color="error" onClick={handleDeleteFlow}>
                Eliminar
              </CustomButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default DeleteFlow;
