// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import AddStatement from './Components/AddStatement';
import {
  getFinancialStatements,
  getUploadedSignedUrl,
} from '../../../../../../../lib/api/finnacialStatements';
import { useParams } from 'react-router-dom';
import CustomAlert from '../../../../../../../lib/designSystem/Alert';
import EditFSDialog from './Components/EditDialog';
import DeleteFSDialog from './Components/DeleteDialog';

const ShowStatements = () => {
  const [addOpen, setAddOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [openAlertFailed, setOpenAlertFailed] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [editId, setEditId] = useState<number | null>(null);
  const [financialStatements, setFinancialStatements] = useState<any[]>([]);
  const [observation, setObservation] = useState<string>('');

  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const setUp = async () => {
    setLoading(true);
    if (!id) return;

    const financialStatementsResponse = await getFinancialStatements(
      dispatch,
      id,
    );
    setFinancialStatements(financialStatementsResponse);
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => setAddOpen(true),
      name: 'Agregar Balance',
      color: 'primary',
    },
  ];
  if (loading) return <CenteredCircularProgress />;

  const handleShowReal = async (financialStatementId: number) => {
    if (!id) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    try {
      setLoading(true);
      const response = await getUploadedSignedUrl(dispatch, id, {
        financialStatementId,
      });
      window.open(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleOpenDeleteDialog = (id: number) => {
    setDeleteId(id);
    setConfirmDeleteDialog(true);
  };

  const handleEdit = (id: number) => {
    setEditId(id);
    setObservation(
      financialStatements.find((x) => x.id === id)?.observation || '',
    );
  };

  const actions: any = [
    { action: handleShowReal, actionLabel: 'Descargar' },
    { action: handleEdit, actionLabel: 'Editar' },
    { action: handleOpenDeleteDialog, actionLabel: 'Borrar' },
  ];

  return (
    <div>
      <HeaderWithActions buttons={buttonsData} title="Balances" />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EnhancedTable
            dataHeaders={[
              { key: 'year', label: 'Año' },
              { key: 'fsType', label: 'Tipo' },
              { key: 'observation', label: 'Observaciones' },
              {
                key: 'createdAt',
                label: 'Fecha de creación',
                type: 'date-large',
              },
              {
                key: 'updatedAt',
                label: 'Última actualización',
                type: 'date-large',
              },
              { key: 'status', label: 'Estado', type: 'fs-status' },
            ]}
            data={financialStatements}
            rowsPerPageDefault={10}
            actions={actions}
            showActions
          />
          <AddStatement
            openDialog={addOpen}
            setOpenDialog={setAddOpen}
            financialStatements={financialStatements}
            reloadIndex={setUp}
            setOpenAlertSuccess={setOpenAlertSuccess}
            setOpenAlertFailed={setOpenAlertFailed}
          />
        </Grid>
      </Grid>
      <EditFSDialog
        id={id}
        setEditId={setEditId}
        editId={editId}
        observation={observation}
        setObservation={setObservation}
        setUp={setUp}
      />
      <DeleteFSDialog
        id={id}
        deleteId={deleteId}
        confirmDeleteDialog={confirmDeleteDialog}
        setConfirmDeleteDialog={setConfirmDeleteDialog}
        setUp={setUp}
      />

      <CustomAlert
        open={openAlertSuccess}
        title="Éxito"
        text="Se ha subido su balance con éxito"
        setOpen={setOpenAlertSuccess}
      />
      <CustomAlert
        open={openAlertFailed}
        title="Error"
        text="No se logró subir su balance"
        setOpen={setOpenAlertFailed}
      />
    </div>
  );
};

export default ShowStatements;
