import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../../lib/designSystem/HeaderWithActions';
import { useDispatch, useSelector } from 'react-redux';
import CustomAlert from '../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import { AuthState } from '../../../../../services/redux/authSlice';
import { fetchAllVerifications } from '../../../../../lib/api/documentConfirmation';
import TableWithPagination from '../../../../../lib/designSystem/TableWithPagination';
import DocumentSearcher from '../../../../../lib/common/DocumentSearcher';
import ConfirmationModule from './components/ConfirmationModule';
import DocumentConfirmationDetailsModule from './components/DocumentDetail';

const VerificationModule: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [failure, setFailure] = useState('');
  const [documents, setDocuments] = useState<any>([]);
  const [documentsToVerify, setDocumentsToVerify] = useState<any>(null);
  const [openNewVerification, setOpenNewVerification] = useState(false);
  const [confirmationType, setConfirmationType] = useState<any>('none');
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [selectedDocumentsIds, setSelectedDocumentsIds] = useState<any>([]);
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState<any>('');
  const [stakeholderNameToVerify, setStakeholderNameToVerify] =
    useState<any>('');
  const [stakeholderIdentifierToVerify, setStakeholderIdentifierToVerify] =
    useState<any>('');
  const [documentToShow, setDocumentToShow] = useState<any>(null);
  const [openDocumentDetails, setOpenDocumentDetails] = useState(false);
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState<any>({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (startDate) query += `startDate=${startDate}&`;
    if (endDate) query += `endDate=${endDate}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (confirmationType) query += `confirmationType=${confirmationType}&`;
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const searchDocumentsWithVerifications = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    try {
      const query = generateQuery({ page, pageSize });
      const response = await fetchAllVerifications(dispatch, query);
      setDocuments(response.data);
      setPagination(response.pagination);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const cantSelectRow = (row: any) => {
    if (selectedDocumentsIds.length) {
      const document = documents.find(
        (document: any) => document.id === selectedDocumentsIds[0],
      );
      if (document) {
        return document.stakeholderIdentifier !== row.stakeholderIdentifier;
      } else {
        setSelectedDocumentsIds([]);
      }
    }
    return false;
  };

  const handleVerify = async () => {
    const selectedDocuments = documents.filter((document: any) =>
      selectedDocumentsIds.includes(document.id),
    );
    if (!selectedDocuments.length) return;

    setStakeholderNameToVerify(selectedDocuments[0].stakeholderName);
    setStakeholderIdentifierToVerify(
      selectedDocuments[0].stakeholderIdentifier,
    );

    setDocumentsToVerify(selectedDocuments);
    setOpenNewVerification(true);
  };

  const handleDetails = (id: string) => {
    const documentToShowSearch = documents.find((doc: any) => doc.id === id);
    if (!documentToShowSearch) return;
    setDocumentToShow(documentToShowSearch);
    setOpenDocumentDetails(true);
  };

  const actions = [
    {
      action: handleDetails,
      actionLabel: 'Detalles',
    },
  ];

  useEffect(() => {
    searchDocumentsWithVerifications({ page: 0, pageSize: PAGE_SIZE });
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        buttons={[
          {
            name: 'Filtro',
            action: () => {},
            color: 'primary',
            customButton: (
              <DocumentSearcher
                searchData={searchDocumentsWithVerifications}
                setData={setDocuments}
                setConfirmationType={setConfirmationType}
                confirmationType={confirmationType}
                setLoading={setLoading}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                stakeholderIdentifier={stakeholderIdentifier}
                setStakeholderIdentifier={setStakeholderIdentifier}
                pagination={pagination}
              />
            ),
          },
          {
            name: 'Verificar',
            action: handleVerify,
            color: 'primary',
          },
        ]}
        title="Aquí verás los documentos junto a sus verificaciones."
        variant="body1"
      />
      <TableWithPagination
        tableSize="small"
        exportToFile="xlsx"
        dataHeaders={[
          { key: 'folio', label: 'Folio' },
          { key: 'businessName', label: 'Emisor' },
          { key: 'stakeholderName', label: 'Receptor' },
          { key: 'stakeholderIdentifier', label: 'Rut Receptor' },
          { key: 'amount', label: 'Monto', type: 'money' },
          { key: 'confirmDate', label: 'Fecha verificación', type: 'date' },
          { key: 'documentConfirmation', label: 'Tipo' },
          { key: 'issuedDate', label: 'Fecha emisión', type: 'date' },
        ]}
        data={documents}
        loading={loading}
        selected={selectedDocumentsIds}
        setSelected={setSelectedDocumentsIds}
        setData={setDocuments}
        searchData={searchDocumentsWithVerifications}
        setPagination={setPagination}
        pagination={pagination}
        cantSelectRow={cantSelectRow}
        actions={actions}
        lastElement
      />
      {openNewVerification && (
        <ConfirmationModule
          documents={documentsToVerify}
          stakeholderName={stakeholderNameToVerify}
          stakeholderIdentifier={stakeholderIdentifierToVerify}
          setSuccessOpen={setSuccess}
          setFailureOpen={setFailure}
          open={openNewVerification}
          setOpen={setOpenNewVerification}
          setUp={searchDocumentsWithVerifications}
          pagination={pagination}
        />
      )}
      {openDocumentDetails && (
        <DocumentConfirmationDetailsModule
          document={documentToShow}
          open={openDocumentDetails}
          setOpen={setOpenDocumentDetails}
        />
      )}
      <CustomAlert
        open={success.length}
        title={success}
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={failure.length}
        title={failure}
        onClose={() => {
          setFailure('');
        }}
        setOpen={setFailure}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default VerificationModule;
