import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

interface AutomaticGridProps {
  missingFields: boolean;
  automaticStepTemplateId: string;
  setAutomaticStepTemplateId: (value: string) => void;
  setStepName: (name: string) => void;
  setStepDescription: (description: string) => void;
}

const NOTIFICATION_TYPES = {
  issuedInvoices: 'Notificación de facturas emitidas',
  documentCession: 'Notificación de cesión',
  documentCessionReminder: 'Notificación próximo vencimiento',
  documentCessionDue: 'Notificación de vencimiento',
};

const AutomaticGrid: React.FC<AutomaticGridProps> = (props) => {
  const {
    missingFields,
    automaticStepTemplateId,
    setAutomaticStepTemplateId,
    setStepName,
    setStepDescription,
  } = props;
  return (
    <div style={{ paddingTop: '12px' }}>
      <FormControl fullWidth>
        <InputLabel id="template-label">Template</InputLabel>
        <Select
          error={missingFields && !automaticStepTemplateId}
          labelId="template-label"
          id="template-select"
          value={automaticStepTemplateId}
          onChange={(e) => {
            setAutomaticStepTemplateId(e.target.value);
            setStepName(
              NOTIFICATION_TYPES[
                e.target.value as keyof typeof NOTIFICATION_TYPES
              ],
            );
            setStepDescription(
              NOTIFICATION_TYPES[
                e.target.value as keyof typeof NOTIFICATION_TYPES
              ],
            );
          }}
          label="Template"
        >
          {Object.keys(NOTIFICATION_TYPES).map((element) => {
            return (
              <MenuItem key={element} value={element}>
                {NOTIFICATION_TYPES[element as keyof typeof NOTIFICATION_TYPES]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default AutomaticGrid;
