import React, { useEffect, useState } from 'react';

import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import { useDispatch } from 'react-redux';
import { resumeBusinessCompanyRelation } from '../../../../lib/api/enrollment';
import { showAlert } from '../../../../lib/functions/utils';
import { getPausedBusinessRelations } from '../../../../lib/api';
import EnhancedTable from '../../../../lib/designSystem/Table';
import { Box, Checkbox, DialogTitle, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CustomButton from '../../../../lib/designSystem/Button';
import FilterComponent from '../../../../lib/common/FilterNonSearch';
import { Info } from '@mui/icons-material';

const headers = [
  { key: 'businessName', label: 'Nombre' },
  { key: 'identifier', label: 'Rut' },
  { key: 'createdAt', label: 'Creado', type: 'date-large' },
  { key: 'pausedAt', label: 'Pausado', type: 'date-large' },
];

const PausedAccounts = () => {
  const [loading, setLoading] = useState(false);
  const [pausedBusinesses, setPausedBusinesses] = useState([]);
  const [openReactivateDialog, setOpenReactivateDialog] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState<any>(null);
  const [filteredBusinesses, setFilteredBusinesses] = useState<any>([]);
  const [checkBoxConfirm, setCheckBoxConfirm] = useState(false);
  const dispatch = useDispatch();

  const handleReactivateAction = (id: number) => {
    setOpenReactivateDialog(true);
    const business = pausedBusinesses.find(
      (business: any) => business.id === id,
    );
    setSelectedBusiness(business);
  };

  const reactivateAccount = async () => {
    setLoading(true);
    try {
      await resumeBusinessCompanyRelation(dispatch, {
        businessIdentifier: selectedBusiness.identifier,
      });
      showAlert(dispatch, 'success', 'Cuenta reactivada correctamente');
      await setUp();
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al reactivar la cuenta');
    }
    setLoading(false);
  };

  const actions: any[] = [
    {
      action: handleReactivateAction,
      actionLabel: 'Reactivar cuenta',
      canShow: () => true,
    },
  ];

  const setUp = async () => {
    setLoading(true);
    try {
      const response = await getPausedBusinessRelations(dispatch);
      setPausedBusinesses(response);
      setFilteredBusinesses(response);
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al obtener las cuentas pausadas');
    }
    setLoading(false);
  };

  const buttonsData: CustomButtonProps[] = [];

  buttonsData.push({
    name: 'Filtro',
    action: () => {},
    color: 'primary',
    customButton: (
      <FilterComponent
        data={pausedBusinesses}
        filterFields={[
          { key: 'businessName', filterName: 'Nombre' },
          { key: 'identifier', filterName: 'Rut' },
        ]}
        setFilteredData={setFilteredBusinesses}
      />
    ),
  });

  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás todas las cuentas que hayas marcado como eliminada."
        variant="body1"
        buttons={buttonsData}
      />
      <EnhancedTable
        data={filteredBusinesses}
        dataHeaders={headers}
        showActions
        actions={actions}
      />
      <Dialog
        open={openReactivateDialog}
        onClose={() => setOpenReactivateDialog(false)}
      >
        <DialogTitle>Reactivar cuenta</DialogTitle>
        <DialogContent>
          {/* Mensaje de información */}
          <Box display="flex" alignItems="center" gap={1} py={2}>
            <Info color="info" />
            <Typography variant="body1">
              Si reactivas a <strong>{selectedBusiness?.businessName}</strong>,
              se cobrará la cuenta por el mes completo.
            </Typography>
          </Box>

          {/* Confirmación del usuario */}
          <Box display="flex" alignItems="center" mb={2}>
            <Checkbox
              checked={checkBoxConfirm}
              onChange={() => setCheckBoxConfirm(!checkBoxConfirm)}
              id="confirm-checkbox"
            />
            <Typography
              variant="body2"
              component="label"
              htmlFor="confirm-checkbox"
            >
              Confirmo que estoy de acuerdo con el cobro del mes completo.
            </Typography>
          </Box>

          {/* Botón de acción */}
          <DialogActions>
            <CustomButton
              color="success"
              disabled={!checkBoxConfirm}
              onClick={() => {
                setOpenReactivateDialog(false);
                reactivateAccount();
              }}
            >
              Reactivar cuenta
            </CustomButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PausedAccounts;
