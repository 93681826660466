import {
  Popper,
  IconButton,
  Typography,
  Paper,
  TextField,
} from '@mui/material';
import React from 'react';
import { Close } from '@mui/icons-material';

const GuaranteeObservationPopper = (props: any) => {
  const {
    openGuaranteePopper,
    setOpenGuaranteePopper,
    anchorEl,
    formData,
    handleChange,
    onEditing,
  } = props;
  return (
    <Popper
      sx={{ zIndex: 1300 }}
      open={openGuaranteePopper}
      anchorEl={anchorEl}
      placement={'bottom'}
      transition={false}
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      <Paper
        style={{
          padding: 20,
          minWidth: 300,
          maxHeight: 400,
          overflow: 'scroll',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body1">Observación monto en garantía</Typography>
          <IconButton onClick={() => setOpenGuaranteePopper(false)}>
            <Close />
          </IconButton>
        </div>
        <TextField
          value={formData.guaranteeObservation}
          type="text"
          fullWidth
          disabled={
            !onEditing ||
            !formData.guaranteeAmount ||
            formData.guaranteeAmount <= 0
          }
          multiline
          rows={3}
          placeholder="Observación"
          onChange={(e) => handleChange('guaranteeObservation', e.target.value)}
        />
      </Paper>
    </Popper>
  );
};

export default GuaranteeObservationPopper;
