import React, { useEffect, useState } from 'react';
import { DialogActions, Typography } from '@mui/material';
import EnhancedTable from '../../../../../../../../../../../lib/designSystem/Table';
import { validatePermissions } from '../../../../../../../../../../../lib/functions/utils';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';
import {
  getFactoringRequestBankAccounts,
  savePayments,
} from '../../../../../../../../../../../lib/api';
import { getCompanyPayoutGroup } from '../../../../../../../../../../../lib/api/payments';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
import { AuthState } from '../../../../../../../../../../../services/redux/authSlice';
import GenerateNominee from './components/generateNominee';
import NomineeExporter from '../../../../../../../../../../../lib/common/NomineeExporter';

const nomineeHedearsForDb = [
  { key: 'bankAccountOriginName', label: 'Banco origen' },
  { key: 'businessDestinationName', label: 'Banco destino' },
  { key: 'bankAccountDestinationNumber', label: 'N° Cuenta' },
  { key: 'bankAccountDestinationAccountType', label: 'Tipo de cuenta' },
  { key: 'bankAccountDestinationHolderName', label: 'Titular' },
  { key: 'bankAccountDestinationHolderIdentifier', label: 'Rut' },
  { key: 'amount', label: 'Monto', type: 'money' },
];

export const paymentsCompressor = (payments: any) => {
  const hashMap: { [key: string]: { payment: any; count: number } } = {};
  for (let i = 0; i < payments.length; i++) {
    const payment = payments[i];
    const key = `${payment.bankAccountDestinationId}-${payment.bankAccountOriginId}-${payment.amount}`;
    if (!hashMap[key]) {
      hashMap[key] = { payment, count: 1 };
    } else {
      hashMap[key].count += 1;
    }
  }
  return hashMap;
};

const PaymentModule = (props: any) => {
  const {
    selectedRequest,
    setOpenShoppingCart,
    setSuccessOpen,
    setFailureOpen,
    setUp,
  } = props;
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [destinyAccounts, setDestinyAccounts] = useState<any>([]);
  const [originAccounts, setOriginAccounts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<any>([]);
  const [exisitingNominee, setExisitingNominee] = useState<any>(false);
  const [openExporter, setOpenExporter] = useState(false);
  // const [stakeholderIdentifier, setStakeholderIdentifier] = useState<string>('');

  console.log('selectedRequest----', selectedRequest);
  const dispatch = useDispatch();

  const handleCreateNominee = async () => {
    setLoading(true);
    try {
      const compressedPayments = paymentsCompressor(payments);
      await savePayments(dispatch, {
        compressedPayments,
        id: selectedRequest.id,
      });
      setSuccessOpen('Nómina guardada correctamente');
      await setUpPayments();
    } catch (error) {
      setFailureOpen('Error al guardar la nómina');
    }
    setLoading(false);
  };

  const setUpPayments = async () => {
    setLoading(true);
    try {
      const exisitingNominee = await getCompanyPayoutGroup(
        dispatch,
        'factoring-request',
        selectedRequest.id,
      );
      setPayments(exisitingNominee.payments);
      if (exisitingNominee.payments.length) setExisitingNominee(true);
    } catch (error) {
      setFailureOpen('Error al obtener la nómina');
    }
    setLoading(false);
  };

  const handleExport = () => {
    setOpenExporter(true);
  };

  const setUpExistingBankAccounts = async () => {
    setLoading(true);
    try {
      const accounts = await getFactoringRequestBankAccounts(dispatch, {
        id: selectedRequest.id,
      });
      if (accounts) {
        setDestinyAccounts(accounts.businessBankAccounts);
        setOriginAccounts(accounts.ownBankAccounts);
      }
    } catch (error) {
      setFailureOpen('Error al obtener las cuentas');
    }
    setLoading(false);
  };

  const setUpDialog = async () => {
    setLoading(true);
    await setUpPayments();
    await setUpExistingBankAccounts();
    setLoading(false);
  };

  useEffect(() => {
    setUpDialog();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      {!payments.length ? (
        <div>
          {destinyAccounts.length && originAccounts.length ? (
            <GenerateNominee
              availableAccounts={destinyAccounts}
              originAccounts={originAccounts}
              payments={payments}
              setUp={setUp}
              setPayments={setPayments}
              selectedRequest={selectedRequest}
              handleExit={() => setOpenShoppingCart(false)}
              setSuccessOpen={setSuccessOpen}
            />
          ) : (
            <div>
              {!destinyAccounts.length && (
                <Typography variant="body1" padding="20px 0px">
                  No tienes cuentas registradas para este cliente. Crea una para
                  poder generar una nómina.{' '}
                  <a
                    href={`/companies/${selectedRequest.businessIdentifier}/profile/bankAccounts`}
                  >
                    Acá
                  </a>
                </Typography>
              )}
              {!originAccounts.length && (
                <Typography variant="body1" padding="20px 0px">
                  No tienes cuentas registradas para tu empresa. Crea una para
                  poder generar una nómina.
                </Typography>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px 0px',
            }}
          >
            <Typography fontWeight={600} variant="h6">
              {'Nómina'}
            </Typography>
            {!exisitingNominee && (
              <CustomButton color="secondary" onClick={() => setPayments([])}>
                volver
              </CustomButton>
            )}
            {exisitingNominee && (
              <div>
                <CustomButton color="primary" onClick={() => handleExport()}>
                  Exportar
                </CustomButton>
              </div>
            )}
          </div>
          <Typography variant="body1" padding="20px 0px">
            Acá podrás ver todas las transferencias que se realizarán en la
            nómina.
          </Typography>
          <EnhancedTable
            dataHeaders={nomineeHedearsForDb}
            data={payments}
            rowsPerPageDefault={10}
            finalSumRow
          />
          {exisitingNominee &&
            payments.every((x: any) => !x.executedAt) &&
            validatePermissions(user, 'financing_payout_view') && (
              <Typography variant="body1" padding="20px 0px">
                Una vez que de marquen todas como ejecutadas, la nómina se
                cerrará y la solicitud pasará a estado de activa. Para ejecutar
                los pagos, dirígete a{' '}
                <a href="/finance/payments/out">Pagos enviados</a>
              </Typography>
            )}
        </div>
      )}
      <DialogActions>
        <CustomButton
          color="secondary"
          onClick={() => setOpenShoppingCart(false)}
        >
          Salir
        </CustomButton>
        {exisitingNominee && null}
        {!exisitingNominee && payments.length ? (
          <CustomButton color="primary" onClick={() => handleCreateNominee()}>
            Guardar Nómina
          </CustomButton>
        ) : null}
      </DialogActions>
      <NomineeExporter
        open={openExporter}
        setOpen={setOpenExporter}
        payments={payments}
        id={selectedRequest.id}
      />
    </div>
  );
};

export default PaymentModule;
