import {
  FormControl,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  DialogContentText,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React from 'react';

interface ContactsProps {
  invoicingContacts: any[];
  selectedExecutive: any;
  setSelectedExecutive: (executive: any) => void;
  selectedDate: any;
  handleSetSelectedDate: (date: any) => void;
  executivesOptions: any[];
  createExpiredSteps: boolean;
  setCreateExpiredSteps: (createExpiredSteps: boolean) => void;
}

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const FlowDetails = (props: ContactsProps) => {
  const {
    invoicingContacts,
    selectedExecutive,
    setSelectedExecutive,
    selectedDate,
    handleSetSelectedDate,
    executivesOptions,
    createExpiredSteps,
    setCreateExpiredSteps,
  } = props;
  return (
    <DialogContentText>
      <p>
        Contactos a los que se enviaran correos de cobranza:{' '}
        {invoicingContacts.map((element: any) => element.email).toString()}
      </p>
      <p>
        Confirma la siguiente información para enrolar el documento en el flujo
        de cobranza.
      </p>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="flow-label">
              Ejecutivo gestiones manuales
            </InputLabel>
            <Select
              labelId="flow-label"
              id="flow-select"
              value={selectedExecutive}
              onChange={(e) => setSelectedExecutive(e.target.value)}
              label="Ejecutivo gestiones manuales"
            >
              {executivesOptions.map((executive: any) => (
                <MenuItem key={executive.id} value={executive.id}>
                  {executive.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                label="Fecha de pago de factura"
                value={selectedDate}
                onChange={(e) => handleSetSelectedDate(e)}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            checked={createExpiredSteps}
            onChange={(e) => setCreateExpiredSteps(e.target.checked)}
          />
        }
        label="Crear gestiones cuya fecha de vencimiento es anterior a la fecha actual?"
      />
    </DialogContentText>
  );
};

export default FlowDetails;
