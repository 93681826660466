import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { useDispatch, useSelector } from 'react-redux';
// import Management from './components/Management';
// import NewManagement from './components/NewManagement';
import CustomAlert from '../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import { getAllFactoringExtensions } from '../../../../../lib/api/extension/factoring';
import {
  formatDate,
  validatePermissions,
} from '../../../../../lib/functions/utils';
import ExtensionDetails from './components/ExtensionDetails';
import { AuthState } from '../../../../../services/redux/authSlice';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'id', label: 'Folio' },
  { key: 'folio', label: 'Folio documento' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'documentAmount', label: 'Monto documento', type: 'money' },
  { key: 'dueDate', label: 'Fecha vencimiento', type: 'date' },
  { key: 'status', label: 'Estado', type: 'status' },
];

const Extensions: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [failure, setFailure] = useState('');
  const [extensions, setExtensions] = useState<any>([]);
  const [openNewExtension, setOpenNewExtension] = useState(false);
  const [edit, setEdit] = useState(false);
  const newExtension = {
    rate: '',
    operationCostFixed: 0,
    operationCostVariable: 0,
    rateCost: 0,
    operationCostRateVariable: '',
    dueDate: formatDate(new Date()),
    documentId: null,
    observation: '',
  };
  const [extension, setExtension] = useState(newExtension);
  const fetchExtensions = async () => {
    setLoading(true);
    try {
      const response = await getAllFactoringExtensions(dispatch);
      setExtensions(response);
    } catch (error) {
      alert('Error al cargar los pagos');
    }
    setLoading(false);
  };

  const handleNewExtension = () => {
    setEdit(false);
    setExtension(newExtension);
    setOpenNewExtension(true);
  };

  const handleDetails = async (id: string) => {
    const extension = extensions.find((extension: any) => extension.id === id);
    if (!extension) return;
    setExtension(extension);
    setOpenNewExtension(true);
    setEdit(true);
  };

  const actions: any = [
    {
      actionLabel: 'Ver',
      action: handleDetails,
    },
  ];

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => handleNewExtension(),
      name: 'Nueva',
      color: 'primary',
      disabled: !validatePermissions(user, 'financing_extension_new'),
    },
  ];

  useEffect(() => {
    fetchExtensions();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás todas las prórogas."
        variant="body1"
        buttons={buttonsData}
      />
      <EnhancedTable
        dataHeaders={headers}
        data={extensions}
        rowsPerPageDefault={50}
        actions={actions}
        showActions
        exportToFile="xlsx"
        lastElement
      />
      {openNewExtension && (
        <ExtensionDetails
          extension={extension}
          setExtension={setExtension}
          open={openNewExtension}
          setOpen={setOpenNewExtension}
          setSuccess={setSuccess}
          setFailure={setFailure}
          setUp={fetchExtensions}
          edit={edit}
        />
      )}

      <CustomAlert
        open={success.length}
        title={success}
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={failure.length}
        title={failure}
        onClose={() => {
          setFailure('');
        }}
        setOpen={setFailure}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default Extensions;
