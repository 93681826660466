import React, { useEffect, useState } from 'react';
import { CircularProgress, DialogActions, Typography } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import {
  getRequirementsForProduct,
  requestCreditLine,
} from '../../../../../../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import { Check } from '@mui/icons-material';
import UploaderInLine from '../../../../../../../../../../../../../lib/designSystem/UploaderInLine';
import { uploadFilesFromRequest } from '../../../../../../../../../../../../../lib/api/files';

export const RequirementItem = (props: any) => {
  const { name, status, openRequest, setUp } = props;
  const [file, setFile] = useState<any>(null);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [miniLoading, setMiniLoading] = useState(false);

  const dispatch = useDispatch();

  const handleFileChange = (event: any) => {
    const uploadedFile = event.target.files[0];
    setUploadedFileName(uploadedFile.name);
    setFile(uploadedFile);
  };

  const uploadFiles = async () => {
    setMiniLoading(true);
    try {
      const formData = new FormData();
      // Change the file Name to name prop
      const newName = `${name}.${file.name.split('.').pop()}`;
      const fileToUpload = new File([file], newName, { type: file.type });
      formData.append('files', fileToUpload);
      console.log('fileToUpload', fileToUpload);
      await uploadFilesFromRequest(dispatch, formData, {
        requestId: openRequest.id,
        requestType: 'creditLine',
      });
      await setUp();
      // setSuccessOpen('Se han subido tus archivos con éxito');
    } catch (error) {
      console.log(error);
    }
    setMiniLoading(false);
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="body1">- {name}</Typography>

      {status === 'Faltante' ? (
        <div>
          {miniLoading ? (
            <CircularProgress />
          ) : (
            <UploaderInLine
              handleFileChange={handleFileChange}
              uploadedFileName={uploadedFileName}
              handleSave={uploadFiles}
            />
          )}
        </div>
      ) : (
        <Typography variant="body1" style={{ color: 'green' }}>
          <Check color="success" />
        </Typography>
      )}
    </div>
  );
};

const ShoppingCartFactoringNotRequested = (props: any) => {
  const { setOpenShoppingCart, openRequest, setUp, setSuccessOpen } = props;
  const [loading, setLoading] = useState(false);
  const [requirements, setRequirements] = useState([]);

  const dispatch = useDispatch();

  const handleCreditLineRequest = async () => {
    setLoading(true);
    try {
      await requestCreditLine(dispatch, {
        id: openRequest.id,
      });
      setOpenShoppingCart(false);
      setSuccessOpen(
        'Hemos subido los archivos, ahora necesitaremos validarlos.',
      );
      await setUp(openRequest.id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getRequirementsDone = async () => {
    setLoading(true);
    try {
      console.log(openRequest.productId);
      const response = await getRequirementsForProduct(dispatch, {
        creditLineId: openRequest.id,
      });
      setRequirements(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRequirementsDone();
  }, []);

  const canRequest = () => {
    return requirements.every(
      (requirement: any) => requirement.status === 'Completado',
    );
  };

  if (loading) {
    return <CenteredCircularProgress type="dialog" />;
  }

  return (
    <div>
      <div>
        <div>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            {openRequest.productName}
          </Typography>
          <Typography variant="body1">
            {openRequest.productDescription}
          </Typography>
        </div>
        <div style={{ paddingTop: 10 }}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Requerimientos
          </Typography>
          <Typography variant="body1">
            Para solicitar la línea de crédito debe subir los siguientes
            requerimientos:
          </Typography>
        </div>
        <div style={{ paddingTop: 20 }}>
          {requirements.map((requirement: any, index: number) => (
            <RequirementItem
              key={index}
              name={requirement.name}
              status={requirement.status}
              openRequest={openRequest}
              setUp={getRequirementsDone}
            />
          ))}
        </div>
        <div style={{ paddingTop: 20 }}>
          <Typography variant="body1">
            Cuando hayas ingresado <b>todos los requerimientos</b>, podrás
            solicitar la línea de crédito.
          </Typography>
        </div>

        <DialogActions>
          <CustomButton
            onClick={() => setOpenShoppingCart(false)}
            color="secondary"
          >
            Salir
          </CustomButton>
          <CustomButton
            disabled={!canRequest()}
            onClick={() => handleCreditLineRequest()}
            color="primary"
          >
            Solicitar
          </CustomButton>
        </DialogActions>
      </div>
    </div>
  );
};

export default ShoppingCartFactoringNotRequested;
