import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { shutdown } from '@intercom/messenger-js-sdk';

type User = {
  email: string;
  name: string;
  companyName: string;
  permissionPolicy?: number;
  id?: number;
  companyTypes?: string[];
  express?: boolean;
  clientId?: string;
  autoEnrrolled?: boolean;
  businessId?: string;
  companyId: number;
  dataVersion?: string;
  businessIdentifier?: string;
  roles?: { name: string }[];
  intercomHash?: string;
};

export type AuthState = {
  isAuthenticated: boolean;
  user?: User; // This represents the logged-in user's details
};

const initialState: AuthState = {
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate: (state, action: PayloadAction<User>) => {
      state.isAuthenticated = true;
      state.user = action.payload; // Store the user's details
    },
    logout: (state) => {
      shutdown();
      state.isAuthenticated = false;
      state.user = undefined; // Clear the user's details on logout
    },
    updateUserEnrollment: (
      state,
      action: PayloadAction<{
        autoEnrrolled: boolean;
        businessId: string;
        businessIdentifier: string;
      }>,
    ) => {
      if (state.user) {
        state.user.autoEnrrolled = action.payload.autoEnrrolled;
        state.user.businessId = action.payload.businessId;
        state.user.businessIdentifier = action.payload.businessIdentifier;
      }
    },
  },
});

export const { authenticate, logout, updateUserEnrollment } = authSlice.actions;

export default authSlice.reducer;
