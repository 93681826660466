import React, { CSSProperties, ReactNode } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { ClientConfigState } from '../../../services/redux/clientConfigSlice';

type ButtonColor =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'transparent'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

interface CustomButtonProps {
  label?: string;
  onClick?: (e?: any) => void | Promise<void>;
  color?: ButtonColor;
  style?: CSSProperties;
  size?: 'small' | 'medium' | 'large';
  key?: string | number;
  children?: ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  startIcon?: ReactNode;
  component?: React.ElementType;
  expressConfig?: any;
}

const disabledButtonStyles: CSSProperties = {
  opacity: 0.5,
  filter: 'grayscale(100%)',
  cursor: 'not-allowed',
};

const baseButtonStyles: CSSProperties = {
  display: 'inline-flex',
  padding: '6px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  flexShrink: 0,
  borderRadius: '20px',
};

const getButtonColors = (customPrimaryColor: string | undefined) => {
  const primaryColor = customPrimaryColor || '#0F23DF'; // #0F23DF is Pruff color
  return {
    primary: {
      color: 'white',
      border: `1px solid ${primaryColor}`,
      background: `linear-gradient(60deg, ${primaryColor} 0.2%, ${primaryColor} 99.99%)`,
      '&:hover': {
        background: '#0f23df0a',
        color: primaryColor,
      },
    },
    secondary: {
      color: primaryColor,
      border: `1px solid ${primaryColor}`,
      background: '#ffffff',
      '&:hover': {
        background: primaryColor,
        color: 'white',
      },
    },
    error: {
      color: '#ffffff',
      border: '1px solid #D62839',
      background: '#D62839',
      '&:hover': {
        background: '#0f23df0a',
        color: '#D62839',
      },
    },
    warning: {
      color: '#ffffff',
      border: '1px solid #E8C547',
      background: '#E8C547',
      '&:hover': {
        background: '#0f23df0a',
        color: '#E8C547',
      },
    },
    success: {
      color: '#ffffff',
      border: '1px solid #2ECC71',
      background: '#2ECC71',
      '&:hover': {
        background: '#0f23df0a',
        color: '#2ECC71',
      },
    },
    transparent: {
      color: '#0F23DF',
      border: '1px solid #0F23DF',
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
        color: '#0F23DF',
      },
    },
  };
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'btnColor',
})<{ btnColor: any; customPrimaryColor?: string }>(({
  btnColor,
  customPrimaryColor,
}) => {
  const buttonColors: any = getButtonColors(customPrimaryColor);
  if (!btnColor || !buttonColors[btnColor]) {
    btnColor = 'primary';
  }
  return {
    ...baseButtonStyles,
    ...buttonColors[btnColor],

    '&:disabled': {
      ...disabledButtonStyles,
      color:
        btnColor === 'secondary'
          ? customPrimaryColor
          : buttonColors[btnColor].color,
    },
  };
});

const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const { color = 'primary', children, expressConfig, ...otherProps } = props;

  // Get expressConfig from Redux
  const expressConfigObject = expressConfig
    ? expressConfig
    : useSelector(
        (state: { clientConfiguration: ClientConfigState }) =>
          state.clientConfiguration.configs,
      );

  // Get custom primary color from expressConfig if available
  const customPrimaryColor = expressConfigObject?.pruffConnect_styles_mainColor;

  return (
    <StyledButton
      btnColor={color === 'inherit' || color === 'info' ? 'primary' : color}
      customPrimaryColor={
        color === 'primary' || color === 'secondary'
          ? customPrimaryColor
          : undefined
      }
      {...otherProps}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
