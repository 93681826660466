import { getDueWorkingDay } from '../../../../../../../../../../../../lib/functions/dates';

export const calculateAmortization = ({
  creditDetails,
  setAmortizationSchedule,
  workingDayActive,
  documents,
}: {
  creditDetails: any;
  setAmortizationSchedule: any;
  workingDayActive: 'active' | 'inactive';
  documents?: any;
}) => {
  const creditAnnualRate = creditDetails.anualRate || creditDetails.rate * 12;
  const annualRate = Number(creditAnnualRate) / 100;
  const totalMonths = Number(creditDetails.months);
  const operationCost = creditDetails.operationCost;
  const otherExpenses = creditDetails.otherExpenses;
  const initialBalance = creditDetails.amount + operationCost + otherExpenses;
  console.log('initialBalance', initialBalance);

  // --- 1. Calcular las fechas de pago y la diferencia de días entre ellas ---
  const operationDate = new Date(creditDetails.operationDate);
  const firstPaymentDate = new Date(
    creditDetails.firstPaymentDate || documents[0].paymentDate,
  );

  if (firstPaymentDate <= operationDate) {
    console.error(
      'La fecha de primer pago debe ser al menos un mes después de la fecha de operación.',
    );
    return;
  }

  let paymentDates: any[] = [];
  if (documents) {
    paymentDates = calculatePaymentDatesFromDocuments(documents, creditDetails);
  } else {
    paymentDates = calculatePaymentDates(
      creditDetails,
      firstPaymentDate,
      workingDayActive,
      operationDate,
      totalMonths,
    );
  }

  console.log('paymentDates', paymentDates);

  // --- 2. Definir una función que simule el cronograma dado un valor de cuota ---
  // Aquí usamos la aproximación lineal para la tasa en cada periodo:
  // periodRate = annualRate * (diffDays / 365)
  const simulateSchedule = (cuota: number) => {
    let balance = initialBalance;
    // Para cada período, calculamos interés, principal y actualizamos el balance
    paymentDates.forEach((payment: any) => {
      const periodRate = annualRate * (payment.diffDays / 365);
      const interest = balance * periodRate;
      const principal = cuota - interest;
      balance -= principal;
    });
    return balance;
  };

  // --- 3. Resolver (solver) para encontrar la cuota que deje el saldo final en 0 ---
  // Usamos búsqueda binaria
  const tolerance = 0.01; // tolerancia en saldo (puede ajustarse)
  const maxIterations = 100;

  // Como cota inicial usaremos la cuota calculada con la fórmula clásica usando 30 días como estándar
  const standardDays = 30;
  const monthlyRate = Math.pow(1 + annualRate, standardDays / 365) - 1;
  const cuotaFormula =
    (initialBalance * (monthlyRate * Math.pow(1 + monthlyRate, totalMonths))) /
    (Math.pow(1 + monthlyRate, totalMonths) - 1);

  // Establecemos límites iniciales para la búsqueda
  let cuotaLower = 0;
  let cuotaUpper = cuotaFormula * 2; // por ejemplo, un límite superior arbitrario
  let cuotaGuess = (cuotaLower + cuotaUpper) / 2;

  let iteration = 0;
  let finalBalance = simulateSchedule(cuotaGuess);
  while (Math.abs(finalBalance) > tolerance && iteration < maxIterations) {
    // Si el saldo final es positivo, significa que la cuota es muy baja (no se amortiza lo suficiente)
    if (finalBalance > 0) {
      cuotaLower = cuotaGuess;
    } else {
      cuotaUpper = cuotaGuess;
    }
    cuotaGuess = (cuotaLower + cuotaUpper) / 2;
    finalBalance = simulateSchedule(cuotaGuess);
    iteration++;
  }

  // En este punto, cuotaGuess es la cuota que deja el saldo final casi en 0.
  const cuotaFija = cuotaGuess;

  // --- 4. Generar el cronograma real usando la cuota encontrada ---
  const schedule: any[] = [];
  let balance = initialBalance;
  let totalAmortization = 0;
  paymentDates.forEach((payment: any, index: number) => {
    const periodRate = annualRate * (payment.diffDays / 365);
    const interest = balance * periodRate;
    const principal = cuotaFija - interest;
    balance -= principal;
    totalAmortization += principal;

    schedule.push({
      month: index + 1,
      paymentDate: payment.date,
      amount: cuotaFija,
      interest: interest,
      principal: principal,
      balance: balance < 0 ? 0 : balance, // para evitar negativos en la última cuota
      operationCost: operationCost,
      otherExpenses: otherExpenses,
      totalAmortization: totalAmortization,
    });
  });

  setAmortizationSchedule(schedule);
};

const calculatePaymentDates = (
  creditDetails: any,
  firstPaymentDate: Date,
  workingDayActive: 'active' | 'inactive',
  operationDate: Date,
  totalMonths: number,
) => {
  const paymentDates: any[] = [];

  let currentDate = new Date(firstPaymentDate);
  const dateFirstPayment = currentDate.getUTCDate();
  if (workingDayActive === 'active') {
    currentDate = getDueWorkingDay(currentDate);
  }
  let diffDays = Math.floor(
    (currentDate.getTime() - operationDate.getTime()) / (1000 * 60 * 60 * 24),
  );
  paymentDates.push({ date: new Date(currentDate), diffDays: diffDays });

  for (let i = 1; i < totalMonths; i++) {
    currentDate.setMonth(currentDate.getMonth() + 1);
    currentDate.setDate(dateFirstPayment);
    if (workingDayActive === 'active') {
      currentDate = getDueWorkingDay(currentDate);
    }
    const lastEntry = paymentDates[i - 1];
    diffDays =
      (currentDate.getTime() - lastEntry.date.getTime()) /
      (1000 * 60 * 60 * 24);
    paymentDates.push({ date: new Date(currentDate), diffDays: diffDays });
  }

  return paymentDates;
};

const calculatePaymentDatesFromDocuments = (
  documents: any,
  creditDetails: any,
) => {
  const firstPaymentDate = new Date(documents[0].paymentDate);
  const paymentDates: any[] = [];
  const firstDiff = Math.floor(
    (firstPaymentDate.getTime() -
      new Date(creditDetails.operationDate).getTime()) /
      (1000 * 60 * 60 * 24),
  );
  paymentDates.push({
    date: new Date(creditDetails.firstPaymentDate),
    diffDays: firstDiff,
  });
  for (let i = 1; i < documents.length; i++) {
    const lastEntry = paymentDates[i - 1];
    const diffDays = Math.floor(
      (new Date(documents[i].paymentDate).getTime() -
        lastEntry.date.getTime()) /
        (1000 * 60 * 60 * 24),
    );
    paymentDates.push({ date: new Date(documents[i].paymentDate), diffDays });
  }
  return paymentDates;
};
