import React, { useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import { useParams } from 'react-router-dom';
import RangesAssign from './RangesAssignation';

const NewMatrixAssignation = (props: any) => {
  const {
    setDecisionNodesAssignation,
    decisionNodesAssignation,
    decisionNodes,
    categorizedNodes,
    openAddNode,
    setOpenAddNode,
    newNode,
    setNewNode,
  } = props;
  const [selectedCategory, setSelectedCategory] = useState('');
  const [counter, setCounter] = useState(
    decisionNodesAssignation.length
      ? decisionNodesAssignation[decisionNodesAssignation.length - 1].id + 1
      : 1,
  );
  const { id } = useParams();
  const [operation, setOperation] = useState('gt');

  const validateRanges = () => {
    if (newNode.ranges.length < 2) {
      return alert('Debes agregar al menos dos rangos');
    }
    const ranges = newNode.ranges;
    for (let i = 0; i < ranges.length; i++) {
      if (ranges[i].decisionNodeId === '' || ranges[i].value === null) {
        console.log('i', i, ranges.length);
        if (i === ranges.length - 1) {
          return true;
        }
        return alert('Debes completar todos los rangos');
      }
    }
    return true;
  };
  const addNode = () => {
    console.log('newNode', newNode);
    if (selectedCategory !== 'decisionEngine') {
      if (!validateRanges()) {
        return;
      }
    }
    if (newNode.originNodeId === '') {
      return alert('Debes completar todos los camposa');
    }
    const nodes = [...decisionNodesAssignation];
    nodes.push({ ...newNode });
    const nextCounter = counter;
    setCounter(nextCounter + 1);
    setDecisionNodesAssignation(nodes);
    setOpenAddNode(false);
    setNewNode({
      id: 0,
      sonIncorrectNodeId: null,
      sonCorrectNodeId: null,
      originNodeId: '',
      value: '',
      type: '',
      description: '',
      name: '',
      fatherNodeDecision: null,
      ranges: [],
    });
  };

  const addRange = () => {
    const range = {
      value: '',
      decisionNodeId: newNode.id,
    };
    setNewNode({ ...newNode, ranges: [...newNode.ranges, range] });
  };

  const setUpRangeForBoolean = () => {
    const ranges = [
      {
        value: 0,
        decisionNodeId: newNode.id,
        nodeId: '',
      },
      {
        value: 1,
        decisionNodeId: newNode.id,
        nodeId: '',
      },
    ];
    return ranges;
  };

  const handleChangeRange = (e: any, index: any) => {
    const { value } = e.target;
    const { name } = e.target;
    console.log(name, value);
    const ranges = newNode.ranges.map((x: any, i: any) => {
      if (i === index) {
        return { ...x, [name]: value };
      }
      return x;
    });
    setNewNode({ ...newNode, ranges });
  };

  const handleChangeOrigin = (e: any) => {
    const { value } = e.target;
    const { name } = e.target;
    console.log(name, value);
    if (name === 'originNodeId') {
      const node = decisionNodes.find((x: any) => x.id === value);
      console.log('node', node);

      return setNewNode({
        ...newNode,
        [name]: value,
        value: node.value,
        type: node.type,
        operation: node.operation || 'gt',
        description: node.description,
        name: node.name,
        id: counter,
        ranges: node.type === 'boolean' ? setUpRangeForBoolean() : [],
      });
    }
    setNewNode({ ...newNode, [name]: value });
  };

  const deleteRange = (index: any) => {
    const ranges = newNode.ranges.filter((x: any, i: any) => i !== index);
    ranges[ranges.length - 1].max = null;
    setNewNode({ ...newNode, ranges });
  };

  const availableNodeForSelection = () => {
    const selectedIds = decisionNodesAssignation.map(
      (x: any) => x.originNodeId,
    );
    console.log('id', id);
    console.log('decisionNodes', decisionNodes);
    return decisionNodes.filter(
      (x: any) =>
        x.type !== 'final' &&
        !selectedIds.includes(x.id) &&
        x.category === selectedCategory &&
        Number(x.subDecisionEngineId) !== Number(id),
    );
  };
  const availableFinalNodeForSelection = () => {
    return decisionNodes.filter((x: any) => x.type === 'final');
  };

  return (
    <Dialog
      maxWidth="md"
      open={openAddNode}
      onClose={() => setOpenAddNode(false)}
    >
      <DialogTitle>Agregar evaluación</DialogTitle>
      <DialogContent style={{ width: '500px' }}>
        <Typography variant="body1" gutterBottom>
          Acá podrás definir una nueva evaluación dentro de la matriz.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Evaluación a agregar</b>
        </Typography>
        <div style={{ padding: '10px 0px' }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="filter-label">Categorías</InputLabel>
            <Select
              labelId="filter-label"
              value={selectedCategory}
              onChange={(e: any) => {
                setSelectedCategory(e.target.value);
              }}
              label="Categorías"
            >
              {Object.keys(categorizedNodes).map((x: any, index: any) => (
                <MenuItem key={index} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ padding: '10px 0px' }}>
          <FormControl
            fullWidth
            variant="outlined"
            disabled={selectedCategory === ''}
          >
            <InputLabel id="filter-label">Evaluaciones disponibles</InputLabel>
            <Select
              labelId="filter-label"
              id="Filtrar por estado"
              name="originNodeId"
              value={newNode.originNodeId}
              onChange={(e: any) => handleChangeOrigin(e)}
              label="Evaluaciones disponibles"
            >
              {availableNodeForSelection().map((x: any, index: any) => (
                <MenuItem key={index} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {newNode.originNodeId !== '' && (
          <div style={{ padding: '20ox' }}>
            <Typography variant="body1" gutterBottom>
              {newNode.description}
            </Typography>
          </div>
        )}
        {newNode.type !== 'decisionEngine' && newNode.originNodeId !== '' && (
          <RangesAssign
            newNode={newNode}
            setNewNode={setNewNode}
            addRange={addRange}
            handleChangeRange={handleChangeRange}
            deleteRange={deleteRange}
            availableFinalNodeForSelection={availableFinalNodeForSelection}
            operation={operation}
            setOperation={setOperation}
          />
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={() => setOpenAddNode(false)}
          color="secondary"
          variant="contained"
        >
          Cancelar
        </CustomButton>
        <CustomButton
          onClick={() => addNode()}
          color="primary"
          variant="contained"
        >
          Agregar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewMatrixAssignation;
