import { requestHandler } from '../../../api';

export const expressGetPendingAccountsReceivable = async (
  dispatch: any,
  companyRecieverId: string,
  query: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/pendingAccounts/receivable?${query}`,
    dispatch,
  });
  if (response) return response.data;
};

export const expressGetPendingAccountsPayable = async (
  dispatch: any,
  companyRecieverId: string,
  query: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/pendingAccounts/payable?${query}`,
    dispatch,
  });
  if (response) return response.data;
};
