import { requestHandler } from '../../api';

export const getCollectionsDocumentIndicators = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/indicators/documents`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCollectionsManagementsIndicators = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/indicators/management`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCollectionsRecoveryIndicators = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/indicators/recovery`,
    dispatch,
  });
  if (response) return response.data;
};

export const completeCollectionManagement = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/collection/management/${id}/complete`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const unDoCompleteCollectionManagement = async (
  dispatch: any,
  id: number,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/collection/management/${id}/uncomplete`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCollectionManagementById = async (
  dispatch: any,
  id: number,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/management/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentsForCollection = async (
  dispatch: any,
  query: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/documents?${query}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAvailableDocumentsForCollection = async (
  dispatch: any,
  query: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/documents/available?${query}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentDetailedForCollection = async (
  dispatch: any,
  id: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/documents/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getExecutivesForDocumentCollection = async (
  dispatch: any,
  query: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/documents/available/collectors?${query}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentsChartsData = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/documents/charts`,
    dispatch,
  });
  if (response) return response.data;
};
