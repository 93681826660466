import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { formatMoney } from '../../../../../../../../../../../../../lib/functions/utils';
import WarrantyPopper from './WarrantyDialog';

const Params = (props: any) => {
  const {
    creditDetails,
    setCreditDetails,
    editing,
    workingDayActive,
    setWorkingDayActive,
  } = props;
  const handleChangeMoney = (value: string, key: string) => {
    const newValue = value.split('$').join('').split('.').join('');
    setCreditDetails({ ...creditDetails, [key]: Number(newValue) });
  };

  const handleChange = (value: string, key: string) => {
    setCreditDetails({ ...creditDetails, [key]: value });
  };

  const handleChangeRates = (value: string, key: string) => {
    // Solo puede ser numeros y puntos
    if (isNaN(Number(value.replace('.', '')))) return;

    if (key === 'rate') {
      const anualRate = Number((Number(value) * 12).toFixed(4));
      setCreditDetails({ ...creditDetails, anualRate, rate: value });
    } else if (key === 'anualRate') {
      const rate = Number((Number(value) / 12).toFixed(4));
      setCreditDetails({ ...creditDetails, rate, anualRate: value });
    } else if (key === 'arrearRate') {
      const anualArrearRate = Number((Number(value) * 12).toFixed(4));
      setCreditDetails({
        ...creditDetails,
        anualArrearRate,
        arrearRate: value,
      });
    } else {
      const arrearRate = Number((Number(value) / 12).toFixed(4));
      setCreditDetails({
        ...creditDetails,
        arrearRate,
        anualArrearRate: value,
      });
    }
  };

  const minFirstPaymentDate = (operationDate: Date) => {
    const firstPaymentDate = new Date(operationDate);
    firstPaymentDate.setDate(firstPaymentDate.getDate() + 30);
    return firstPaymentDate.toISOString().split('T')[0];
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <FormControl fullWidth margin="dense" size="small">
            <InputLabel>Tipo de Crédito</InputLabel>
            <Select
              value={creditDetails.type}
              disabled={!editing}
              onChange={(e) =>
                handleChange(
                  e.target.value as 'WORKING_CAPITAL' | 'CONSUMER',
                  'type',
                )
              }
              label="Tipo de Crédito"
            >
              <MenuItem value="WORKING_CAPITAL">Capital de Trabajo</MenuItem>
              <MenuItem value="CONSUMER">Consumo</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth margin="dense" size="small">
            <InputLabel>Método de Cálculo de Interés</InputLabel>
            <Select
              value={creditDetails.interestType}
              disabled={!editing}
              onChange={(e) =>
                handleChange(
                  e.target.value as 'compound' | 'simple',
                  'interestType',
                )
              }
              label="Método de Cálculo de Interés"
            >
              <MenuItem value="compound">Interés Compuesto</MenuItem>
              {/* <MenuItem value="simple">Interés Simple</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Fecha de Inicio"
            disabled={!editing}
            type="date"
            value={creditDetails.operationDate}
            onChange={(e) => handleChange(e.target.value, 'operationDate')}
            margin="dense"
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Fecha primer pago"
            type="date"
            disabled={!editing}
            value={creditDetails.firstPaymentDate}
            onChange={(e) => handleChange(e.target.value, 'firstPaymentDate')}
            margin="dense"
            size="small"
            InputProps={{
              inputProps: {
                min: minFirstPaymentDate(new Date(creditDetails.operationDate)),
              },
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Monto Total"
            value={formatMoney(creditDetails.amount)}
            disabled={!editing}
            onChange={(e) => handleChangeMoney(e.target.value, 'amount')}
            margin="dense"
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Gastos Operacionales"
            value={formatMoney(creditDetails.operationCost)}
            disabled={!editing}
            onChange={(e) => handleChangeMoney(e.target.value, 'operationCost')}
            margin="dense"
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Seguros"
            value={formatMoney(creditDetails.otherExpenses)}
            disabled={!editing}
            onChange={(e) => handleChangeMoney(e.target.value, 'otherExpenses')}
            margin="dense"
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Meses"
            value={creditDetails.months}
            onChange={(e) => handleChange(e.target.value, 'months')}
            disabled={!editing}
            margin="dense"
            size="small"
          />
        </Grid>

        <Grid item xs={3}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Interés Mensual (%)"
                value={creditDetails.rate}
                onChange={(e) => handleChangeRates(e.target.value, 'rate')}
                disabled={!editing}
                margin="dense"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Interés Anual (%)"
                value={creditDetails.anualRate}
                onChange={(e) => handleChangeRates(e.target.value, 'anualRate')}
                disabled={!editing}
                margin="dense"
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Mora Mensual (%)"
                value={creditDetails.arrearRate}
                onChange={(e) =>
                  handleChangeRates(e.target.value, 'arrearRate')
                }
                disabled={!editing}
                margin="dense"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Mora Anual (%)"
                value={creditDetails.anualArrearRate}
                onChange={(e) =>
                  handleChangeRates(e.target.value, 'anualArrearRate')
                }
                disabled={!editing}
                margin="dense"
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth margin="dense" size="small">
            <InputLabel>Día Hábil</InputLabel>
            <Select
              value={workingDayActive}
              onChange={(e) =>
                setWorkingDayActive(e.target.value as 'active' | 'inactive')
              }
              disabled={!editing}
              label="Día Hábil"
            >
              <MenuItem value="active">Activar </MenuItem>
              <MenuItem value="inactive">Desactivado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <WarrantyPopper
            index={0}
            creditRequest={creditDetails}
            setCreditRequest={setCreditDetails}
            canEdit={editing}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Params;
