import Intercom from '@intercom/messenger-js-sdk';
import { AuthState } from '../../services/redux/authSlice';
import { useSelector } from 'react-redux';

export default function IntercomChat() {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  Intercom({
    app_id: 'iimj7oev',
    user_id: user?.email,
    user_hash: user?.intercomHash,
    name: user?.name,
    email: user?.email,
    company: {
      id: user?.companyId,
      name: user?.companyName,
    },
  });
  return null;
}
