import React from 'react';
import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { formatMoney } from '../../../../../../../../../../lib/functions/utils';
import { Typography } from '@mui/material';

export const CustomBarTooltip = ({
  active,
  payload,
  label,
  money,
}: TooltipProps<ValueType, NameType> & { money: boolean }) => {
  if (active && payload && payload.length) {
    const reversedPayload = payload.reverse();
    console.log('MONEY', money);
    return (
      <div
        style={{
          backgroundColor: 'rgba(255, 255, 255, 1)',
          border: '1px solid black',
          borderRadius: '4px',
          padding: '8px',
        }}
      >
        <Typography variant="h6">
          <strong>{`${label}`}</strong>
        </Typography>
        <div>
          {reversedPayload.map((pld) => (
            <div
              style={{ padding: 5, display: 'flex', gap: 10 }}
              key={pld.dataKey}
            >
              <div style={{ color: pld.color }}>
                <strong>
                  {money ? formatMoney(Number(pld.value)) : pld.value}
                </strong>
              </div>
              <div>{pld.name}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default CustomBarTooltip;
