import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import CustomTimeline, {
  Variant,
} from '../../../../../../../../lib/designSystem/CustomTimeline';

interface CreateStateProps {
  open: boolean;
  setOpen: any;
  flow: any;
}

const SeeFlow: React.FC<CreateStateProps> = (props) => {
  const { open, setOpen, flow } = props;

  // For flow
  const flowName = flow.name;

  // For timeline
  const [timelineItemsCreation, setTimelineItemsCreation] = useState<any[]>([]);
  const [timelineItemsDue, setTimelineItemsDue] = useState<any[]>([]);

  const setExistingTimelines = () => {
    const itemsCreation = [
      {
        left: {
          variantPrimary: 'body1' as Variant,
          textPrimary: 'Fecha de creación',
        },
        right: {
          textPrimary: '',
        },
        icon: <TodayIcon />,
        days: -Infinity,
        iconVariant: 'filled',
      },
    ];
    const itemsDue = [
      {
        left: {
          variantPrimary: 'body1' as Variant,
          textPrimary: 'Fecha de vencimiento',
        },
        right: {
          textPrimary: '',
        },
        icon: <EventIcon />,
        days: 0,
        iconVariant: 'filled',
      },
    ];
    for (const step of flow.steps) {
      const item = {
        left: {
          variantPrimary: 'body1' as Variant,
          textPrimary: step.days
            ? step.days > 0
              ? `+${step.days} días`
              : `${step.days} días`
            : '0 días',
        },
        right: {
          textPrimary: step.name,
        },
        icon: step.manualStep ? <PersonIcon /> : <SmartToyOutlinedIcon />,
        iconVariant: 'outlined',
        days: step.days,
      };
      if (step.regard === 'CREATIONDATE') {
        itemsCreation.push(item);
      } else {
        itemsDue.push(item);
      }
    }
    setTimelineItemsCreation(itemsCreation.sort((a, b) => a.days - b.days));
    setTimelineItemsDue(itemsDue.sort((a, b) => a.days - b.days));
  };

  const setUp = async () => {
    setExistingTimelines();
  };

  useEffect(() => {
    setUp();
  }, []);

  const showCreationTimeline = timelineItemsCreation.length > 1;
  const showDueTimeline = timelineItemsDue.length > 1;
  const creationXs = showCreationTimeline && showDueTimeline ? 6 : 12;
  const dueXs = showCreationTimeline && showDueTimeline ? 6 : 12;

  return (
    <div>
      <Dialog open={open} maxWidth="xl">
        <DialogTitle>Flujo de cobranza {flowName}</DialogTitle>
        <DialogContent style={{ minWidth: '80vw' }}>
          <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
            <Grid container>
              <Grid item xs={creationXs}>
                {showCreationTimeline && (
                  <CustomTimeline items={timelineItemsCreation} />
                )}
              </Grid>
              <Grid item xs={dueXs}>
                {showDueTimeline && <CustomTimeline items={timelineItemsDue} />}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton color="secondary" onClick={() => setOpen(false)}>
            Cerrar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SeeFlow;
