import React, { useEffect, useState } from 'react';
import { getDocumentsConciliationSuggestions } from '../../../../../../lib/api/conciliations';
import { useDispatch } from 'react-redux';
import {
  CircularProgress,
  Fade,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Cancel } from '@mui/icons-material';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { formatMoney } from '../../../../../../lib/functions/utils';
import FilterComponent from '../../../../../../lib/common/FilterNonSearch';
import { loggerDev } from '../../../../../../lib/functions/utils';

const DocumentsSuggestedContainer = (props: any) => {
  const {
    businessIdentifier,
    amountToApply,
    setAmountToApply,
    selected,
    setSelected,
    maxAmount,
    documents,
    setDocuments,
    setDocumentsToApply,
    addIconPlacement,
    disabled,
    documentsToApply,
    operationDate,
  } = props;
  props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [openPopper, setOpenPopper] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [placement, setPlacement] =
    React.useState<PopperPlacementType>('right');
  const [filteredDocuments, setFilteredDocuments] = useState<any[]>([]);

  const handleClickPopper =
    (newPlacement: PopperPlacementType) =>
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      loggerDev('click', placement, newPlacement);
      setAnchorEl(event.currentTarget);
      setOpenPopper((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
      await setUp();
    };

  const handleClose = () => {
    setOpenPopper(false);
    setFilteredDocuments([]);
  };
  const handleChangeAmountToConciliate = (event: any) => {
    const amount = event.target.value.split('.').join('').split('$').join('');

    loggerDev('changing amount', amount, maxAmount);
    if (isNaN(amount)) return;

    if (amount > maxAmount) {
      alert('El monto a conciliar no puede ser mayor al monto a depositar');
      return;
    }

    const amountToApply = Number(amount);
    const document = documents.find((inv: any) => inv.id === event.target.name);
    if (
      amountToApply < 0 ||
      Math.ceil(amountToApply) > Math.ceil(document.totalRemaining)
    ) {
      return alert('El monto a conciliar no puede ser mayor al saldo restante');
    }
    document.amountToConciliate = amountToApply;
    setDocuments([...documents]);
    handleSelectChange(documents);
  };

  const cantSelectRow = (row: any) => {
    if (!maxAmount) return false;
    if (selected.includes(row.id)) return false;
    return Number(amountToApply) + Number(row.amountToConciliate) > maxAmount;
  };

  const documentsHeaders = [
    { key: 'folio', label: 'Folio' },
    { key: 'type', label: 'Tipo' },
    { key: 'businessName', label: 'Emisor' },
    // { key: 'subType', label: 'Subtipo' },
    { key: 'stakeholderName', label: 'Pagador' },
    { key: 'amountNet', label: 'Monto neto', type: 'money' },
    { key: 'totalRemainingNet', label: 'Saldo', type: 'money' },
    {
      key: 'amountToConciliate',
      label: 'Monto a conciliar',
      handleChange: handleChangeAmountToConciliate,
      type: 'money',
    },
  ];

  const prepareDocuments = (documents: any) => {
    loggerDev('documents to apply', documentsToApply);
    return documents.map((document: any) => {
      const documentToApply = documentsToApply.find(
        (doc: any) => doc.id === document.id,
      );
      return {
        ...document,
        amountToConciliate: documentToApply
          ? documentToApply.amountToConciliate
          : maxAmount
            ? Math.min(maxAmount, document.totalRemainingNet)
            : document.totalRemainingNet,
      };
    });
  };

  const setUp = async () => {
    setLoading(true);
    try {
      const queryParams = `operationDate=${operationDate}`;
      const response = await getDocumentsConciliationSuggestions(
        dispatch,
        {
          businessIdentifier,
        },
        queryParams,
      );
      loggerDev('response', response);
      const documentsPrepared = prepareDocuments(response.documentsProcessed);
      setDocuments(documentsPrepared);
      setFilteredDocuments(documentsPrepared);
    } catch (error) {
      loggerDev(error);
    }
    setLoading(false);
  };

  const handleSelectChange = (newDocuments?: any) => {
    if (!documents.length || (newDocuments && !newDocuments.length)) return;
    const selectedDocuments = (newDocuments || documents).filter((inv: any) =>
      selected.includes(inv.id),
    );
    loggerDev('selectedDocuments', selectedDocuments);
    const amountToApply = selectedDocuments.reduce(
      (acc: number, inv: any) => acc + inv.amountToConciliate,
      0,
    );
    loggerDev(
      'amountToApplyaaa',
      amountToApply,
      maxAmount,
      amountToApply > maxAmount,
    );
    if (maxAmount && amountToApply > maxAmount) {
      alert('El monto a conciliar no puede ser mayor al monto a depositar');
      return;
    }
    setAmountToApply(String(amountToApply.toFixed(0)));
    setDocumentsToApply(selectedDocuments);
  };

  useEffect(() => {
    handleSelectChange();
  }, [selected]);

  const handleSetFitleredData = (filteredData: any) => {
    setFilteredDocuments(filteredData);
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {addIconPlacement === 'end' && (
          <TextField
            value={formatMoney(amountToApply)}
            fullWidth
            placeholder="0"
            disabled
          />
        )}
        <IconButton
          onClick={handleClickPopper(
            // addIconPlacement === 'end' ? 'right' : 'left-end',
            'right',
          )}
          disabled={!businessIdentifier || disabled}
        >
          <Add />
          {selected.length !== 0 ? (
            <Typography
              style={{
                position: 'absolute',
                bottom: -5,
                right: 0,
                color: 'white',
                fontSize: 10,
                padding: '2px 6px',
                borderRadius: 50,
                fontWeight: '400',
                margin: '1px 2px',
                backgroundColor: '#297dff',
              }}
            >
              {selected.length}
            </Typography>
          ) : null}
        </IconButton>
        {addIconPlacement !== 'end' && (
          <TextField
            value={formatMoney(amountToApply)}
            fullWidth
            placeholder="0"
            disabled
          />
        )}
      </div>
      <Popper
        sx={{ zIndex: 1300 }}
        open={openPopper}
        anchorEl={anchorEl}
        placement={'bottom'}
        transition={false}
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
      >
        <Paper
          style={{
            padding: 20,
            maxHeight: 400,
            overflow: 'scroll',
          }}
        >
          {loading ? (
            <div style={{ textAlign: 'center' }}>
              <Fade in={loading}>
                <CircularProgress />
              </Fade>
            </div>
          ) : (
            <div>
              {' '}
              <div
                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                    padding: '10px 0px',
                  }}
                >
                  <Typography variant="h6">Documentos</Typography>
                  <FilterComponent
                    data={documents}
                    filterFields={[
                      { key: 'type', filterName: 'Tipo' },
                      { key: 'folio', filterName: 'Folio' },
                      { key: 'businessName', filterName: 'Emisor' },
                      { key: 'stakeholderName', filterName: 'Pagador' },
                    ]}
                    setFilteredData={handleSetFitleredData}
                  />
                </div>
                <IconButton
                  onClick={() => handleClose()}
                  style={{ marginLeft: 'auto' }}
                >
                  <Cancel />
                </IconButton>
              </div>
              <EnhancedTable
                dataHeaders={documentsHeaders}
                data={filteredDocuments}
                selected={selected}
                setSelected={setSelected}
                cantSelectRow={cantSelectRow}
              />
            </div>
          )}
        </Paper>
      </Popper>
    </div>
  );
};

export default DocumentsSuggestedContainer;
