import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  TableSortLabel,
  Box,
  TableContainer,
  Tooltip,
  Checkbox,
  Typography,
  TextField,
  Autocomplete,
  Badge,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { formatMoney } from '../../functions/utils';
import { visuallyHidden } from '@mui/utils';
import { cellFormat } from './cellFormat';
import { CancelOutlined, FilterList } from '@mui/icons-material';
import CustomButton from '../Button';
import MenuDialog from '../MenuDialog';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DataExporter from '../DataExporter';

type RowData = {
  [key: string]: any;
};

type Action = {
  action: (number: any) => void;
  actionLabel: string;
  canShow?: any;
  notifications?: boolean;
};

export type Header = {
  label: string;
  key: string;
  type?: string;
  canNull?: boolean;
  handleChange?: any;
  handleAction?: any;
  icon?: any;
  onClick?: any;
  keyDisabled?: string;
  customComponent?: any;
  nullValue?: string;
};

interface EnhancedTableProps {
  data: RowData[];
  actions?: Action[];
  caption?: string;
  dataHeaders: Header[];
  tableSize?: 'small' | 'medium';
  showActions?: boolean;
  finalSumRow?: boolean;
  filters?: {
    key: string;
    label: string;
    options?: string[];
    startDate?: any;
    endDate?: any;
    setStartDate?: any;
    setEndDate?: any;
    type?: string;
  }[];
  rowsPerPageDefault?: number;
  openFilters?: any;
  setOpenFilters?: any;
  selected?: any;
  setSelected?: any;
  cantSelectRow?: any;
  updateValues?: any;
  completeTable?: boolean;
  handleClickRow?: any;
  canSelectAll?: boolean;
  filterOptions?: any;
  buttons?: any;
  filterButton?: boolean;
  exportToFile?: 'csv' | 'xlsx';
  hideTablePagination?: boolean;
  lastElement?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '100%', // ensure the table fits its container
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
      maxWidth: '100%',
      tableLayout: 'fixed',
      overflowX: 'auto', // Permite el desplazamiento horizontal si es necesario
      display: 'block', // Ayuda a contener la tabla dentro del contenedor
    },
    tableBody: {
      tableLayout: 'fixed',
      backgroundColor: '#ffffff',
      maxWidth: '100%',
      overflowX: 'auto',
    },
  }),
);

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  // Check if a[orderBy] is null and b[orderBy] is not
  if (a[orderBy] === null && b[orderBy] !== null) {
    return 1; // a is less than b
  }

  // Check if b[orderBy] is null and a[orderBy] is not
  if (b[orderBy] === null && a[orderBy] !== null) {
    return -1; // a is greater than b
  }

  // If both are null or both are non-null, perform the usual comparison
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function parseArrayToNumberSort<T>(array: T[], orderBy: keyof T): T[] {
  const parsedArray = array.map((element) => {
    const value = element[orderBy];
    if (typeof value === 'string' && value !== '' && !isNaN(Number(value))) {
      return { ...element, [orderBy]: Number(value) };
    }
    return element;
  });
  if (parsedArray.every((el) => typeof el[orderBy] === 'number')) {
    return parsedArray;
  }
  return array;
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
  orderBy: string,
) {
  const parsedArray = parseArrayToNumberSort([...array], orderBy as keyof T);
  const stabilizedThis = parsedArray.map(
    (el, index) => [el, index] as [T, number],
  );
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableHead {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: any;
  showActions: any;
  selected?: any;
  handleSelectAllClick?: any;
  selectedAll?: any;
  setSelectedAll?: any;
}

function EnhancedTableHead(props: EnhancedTableHead) {
  const {
    headCells,
    order,
    orderBy,
    rowCount,
    onRequestSort,
    showActions,
    selected,
    handleSelectAllClick,
    selectedAll,
    setSelectedAll,
  } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead style={{ background: '#efefef' }}>
      <TableRow>
        {selected && !handleSelectAllClick && (
          <TableCell align="right"></TableCell>
        )}
        {selected && handleSelectAllClick && (
          <TableCell align="left">
            <Checkbox
              style={{ padding: 0, marginLeft: '-3px' }}
              color="primary"
              checked={selectedAll}
              onClick={(e) => handleSelectAllClick(e)}
            />
          </TableCell>
        )}
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.key}
            align={headCell.numeric ? 'right' : 'left'}
            style={{ padding: '8px 12px' }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : 'asc'}
              onClick={createSortHandler(headCell.key)}
            >
              {headCell.label}
              {orderBy === headCell.key ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {showActions ? <TableCell align="right"></TableCell> : null}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTable: React.FC<EnhancedTableProps> = ({
  data,
  caption,
  tableSize = 'medium',
  dataHeaders = [],
  actions = [],
  showActions = false,
  finalSumRow = false,
  filters = [],
  rowsPerPageDefault = 5,
  openFilters,
  selected,
  setSelected,
  cantSelectRow,
  // setOpenFilters,
  updateValues,
  completeTable,
  handleClickRow,
  canSelectAll,
  buttons,
  filterButton = false,
  exportToFile,
  hideTablePagination = false,
  lastElement = false,
  // filterOptions,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageDefault);
  const [openMenus, setOpenMenus] = React.useState(data.map(() => false));
  const [anchorEls, setAnchorEls] = React.useState<(HTMLElement | null)[]>(
    data.map(() => null),
  );
  const [filter, setFilter] = useState('Todos');
  const [dataAvailable, setDataAvailable] = useState(data);
  const classes = useStyles();
  const [order, setOrder] = React.useState<any>('');
  const [orderBy, setOrderBy] = React.useState<any>('');
  const [openTooltipIndex, setOpenTooltipIndex] = useState<string | null>(null);
  const [selectedAll, setSelectedAll] = useState(false);
  const [filterValue, setFilterValue] = useState<any>(null);

  const firstCellStyle: any = {
    maxWidth: '150px',
    overflow: 'hidden', // Ensure the content doesn't overflow.
    textOverflow: 'ellipsis', // If the text overflows, it will end with "..."
    whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
    padding: '4px 12px',
    margin: '0px',
  };

  const handleFilterChange = (event: any, newValue: any, key: string) => {
    console.log(event, newValue, key);
    if (!newValue) {
      setFilterValue(null);
      return setDataAvailable(data);
    }
    setFilter(newValue as string);
    if (canSelectAll) {
      setSelected([]);
      setSelectedAll(false);
    }
    setDataAvailable(data.filter((x: any) => x[key] === newValue));
    setFilterValue(newValue);
  };

  const handleDateFilterChange = (startDate: any, endDate: any) => {
    if (startDate && endDate) {
      setDataAvailable(
        data.filter((x: any) => {
          return (
            new Date(x.issuedDate) >= startDate &&
            new Date(x.issuedDate) <= endDate
          );
        }),
      );
    } else {
      setDataAvailable(data);
    }
  };

  const handleClick = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    // Create a copy of the openMenus array and set the clicked menu to open
    const newOpenMenus = [...openMenus];
    newOpenMenus[index] = true;

    // Use optional chaining to safely access event.currentTarget
    const anchorEl = event.currentTarget;

    setOpenMenus(newOpenMenus);
    setAnchorEls((prevAnchorEls: any) => {
      // Create a copy of the anchorEls array and set the anchorEl for the clicked menu
      const newAnchorEls: HTMLElement[] | null[] = [...prevAnchorEls];
      newAnchorEls[index] = anchorEl;
      return newAnchorEls;
    });
  };

  const handleClose = (index: number) => {
    // Create a copy of the openMenus array and set the clicked menu to close
    const newOpenMenus = [...openMenus];
    newOpenMenus[index] = false;

    setOpenMenus(newOpenMenus);
    setAnchorEls(anchorEls.map(() => null)); // Reset all anchorEls to null
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function calculateSums(
    data: RowData[],
    headers: Header[],
  ): { [key: string]: any } {
    const sums: { [key: string]: any } = {};

    headers.forEach((header, index) => {
      sums[header.key] = data.reduce((acc, row) => {
        if (typeof row[header.key] === 'number') {
          return acc + row[header.key];
        }
        return acc;
      }, 0);
    });

    return sums;
  }

  const sums = calculateSums(data, dataHeaders);

  const visibleRowsCache = React.useMemo(
    () =>
      stableSort(dataAvailable, getComparator(order, orderBy), orderBy).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [dataAvailable, order, orderBy, page, rowsPerPage],
  );

  const visibleRows = () =>
    stableSort(dataAvailable, getComparator(order, orderBy), orderBy).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  useEffect(() => {
    setDataAvailable(data);
  }, [data]);

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      setSelected(newSelected);
      setSelectedAll(true);
      return;
    }
    setSelected([]);
    setSelectedAll(false);
  };

  const handleClickSelected = (id: number, row: any) => {
    if (cantSelectRow && cantSelectRow(row)) return;

    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    if (updateValues) {
      updateValues(newSelected);
    }
    setSelected(newSelected);
  };
  const isSelected = (id: number) => selected?.indexOf(id) !== -1;
  const [selectedRow, setSelectedRow] = useState<any>([]);

  const handleSelectRow = (id: number | string) => {
    if (selectedRow === id) {
      return setSelectedRow(null);
    }
    setSelectedRow(id);
  };

  return (
    <div style={{ padding: lastElement ? '0px 0px 100px 0px' : '0px' }}>
      <div
        style={
          caption
            ? {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: filters.length ? '0px 0px' : '20px 0px',
              }
            : {
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '0px',
              }
        }
      >
        {caption && <Typography variant="body1">{caption}</Typography>}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: buttons?.length ? 20 : 0,
          }}
        >
          {buttons?.map((x: any, index: any) => (
            <>
              {x.customButton && <>{x.customButton}</>}
              {!x.customButton && (
                <div key={index} style={{ paddingLeft: '10px' }}>
                  <CustomButton
                    variant="contained"
                    color={x.color}
                    onClick={x.action}
                  >
                    {x.name}
                  </CustomButton>
                </div>
              )}
            </>
          ))}
        </div>
        {openFilters && !filterButton && (
          <div
            style={{
              display: 'flex',
              paddingBottom: '20x',
              alignItems: 'center',
            }}
          >
            {filters.map((x: any, index: any) => (
              <div key={index} style={{ paddingLeft: '10px' }}>
                {x.options.length ? (
                  <Autocomplete
                    style={{ margin: '8px 0px', minWidth: '250px' }}
                    options={x.options}
                    getOptionLabel={(option: any) => `${option}`}
                    renderInput={(params: any) => (
                      <TextField {...params} label={x.label} />
                    )}
                    onChange={(e: any, newValue: any) =>
                      handleFilterChange(e, newValue, x.key)
                    }
                    fullWidth
                  />
                ) : null}
              </div>
            ))}
          </div>
        )}
        {filters.length > 0 && filterButton && (
          <div>
            <MenuDialog
              buttonColor="secondary"
              buttonLabel="Filtros"
              startIcon={<FilterList />}
            >
              {filters.map((x: any, index: any) => (
                <div key={index} style={{ marginLeft: '26px' }}>
                  {x.options.length && x.type !== 'betweenDates' ? (
                    <Autocomplete
                      style={{
                        margin: '8px 0px',
                        minWidth: '250px',
                      }}
                      options={x.options}
                      getOptionLabel={(option: any) => `${option}`}
                      renderInput={(params: any) => (
                        <TextField {...params} label={x.label} />
                      )}
                      onChange={(e: any, newValue: any) =>
                        handleFilterChange(e, newValue, x.key)
                      }
                      fullWidth
                    />
                  ) : null}
                  {x.type === 'betweenDates' ? (
                    <div>
                      <div style={{ padding: '8px 0px' }}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es-mx"
                        >
                          <DatePicker
                            label="Fecha inicio búsqueda"
                            value={x.startDate}
                            onChange={(e) => {
                              handleDateFilterChange(x.startDate, x.endDate);
                              x.setStartDate(dayjs(e));
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                      <div style={{ padding: '8px 0px', maxWidth: '180%' }}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es-mx"
                        >
                          <DatePicker
                            label="Fecha fin búsqueda"
                            value={x.endDate}
                            onChange={(e) => {
                              handleDateFilterChange(x.startDate, x.endDate);
                              x.setEndDate(dayjs(e));
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </MenuDialog>
          </div>
        )}
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} style={{ borderRadius: '10px' }}>
          <EnhancedTableHead
            headCells={dataHeaders}
            selected={selected}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={dataAvailable.length}
            showActions={actions.length}
            handleSelectAllClick={canSelectAll ? handleSelectAllClick : null}
            selectedAll={selectedAll}
            setSelectedAll={setSelectedAll}
          />
          <TableBody className={classes.tableBody}>
            {visibleRows().map((row, index) => {
              const isItemSelected = isSelected(row.id as number);
              const haveHandleChange = dataHeaders.some(
                (x: any) => x.handleChange,
              );
              return (
                <TableRow
                  key={index}
                  style={{
                    ...(handleClickRow ? { cursor: 'pointer' } : {}),
                    ...(selectedRow === row.id
                      ? { backgroundColor: '#808080' }
                      : {}),
                  }}
                  onClick={() => {
                    if (selected && !haveHandleChange) {
                      handleClickSelected(row.id as number, row);
                    }
                    if (handleClickRow) {
                      handleClickRow(row.id);
                      handleSelectRow(row.id);
                    }
                  }}
                >
                  {selected && (
                    <TableCell padding="checkbox">
                      {cantSelectRow && cantSelectRow(row) ? (
                        <CancelOutlined
                          color="disabled"
                          style={{ padding: '6px 10px' }}
                        />
                      ) : (
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={() =>
                            handleClickSelected(row.id as number, row)
                          }
                        />
                      )}
                    </TableCell>
                  )}
                  {dataHeaders.map((x, rowIndex) => {
                    if (x.customComponent) {
                      return (
                        <TableCell
                          key={rowIndex}
                          style={{
                            ...firstCellStyle,
                            ...(x.onClick ? { cursor: 'pointer' } : {}),
                          }}
                        >
                          {x.customComponent(row[x.key])}
                        </TableCell>
                      );
                    }
                    return (
                      <Tooltip
                        key={rowIndex}
                        title={!x.type ? row[x.key] : ''}
                        open={openTooltipIndex === `${index}-${rowIndex}`}
                        onClose={() => setOpenTooltipIndex(null)}
                        PopperProps={{
                          style: { maxWidth: '1000px', whiteSpace: 'normal' }, // This allows the tooltip to expand as needed
                        }}
                      >
                        <TableCell
                          style={{
                            ...firstCellStyle,
                            ...(x.onClick ? { cursor: 'pointer' } : {}),
                          }}
                          onMouseEnter={() => {
                            setOpenTooltipIndex(`${index}-${rowIndex}`);
                          }}
                          onMouseLeave={() => setOpenTooltipIndex(null)} // Close the tooltip when the mouse leaves
                          onClick={(e) =>
                            x.onClick && x.onClick(x.key, row.id, e)
                          }
                        >
                          {x.handleAction ? (
                            <IconButton
                              disabled={
                                x.keyDisabled
                                  ? row[x.keyDisabled]
                                    ? false
                                    : true
                                  : false
                              }
                              onClick={() => x.handleAction(row.id)}
                            >
                              {x.icon}
                            </IconButton>
                          ) : null}
                          {x.handleChange ? (
                            <TextField
                              value={
                                x.type === 'money'
                                  ? formatMoney(row[x.key] as number)
                                  : row[x.key]
                              }
                              id={String(row.id)}
                              name={String(row.id)}
                              onChange={(e) => x.handleChange(e)}
                            />
                          ) : (
                            cellFormat(
                              row[x.key],
                              row.rowType ? row.rowType : x.type,
                              x.canNull,
                              x.nullValue,
                            )
                          )}
                        </TableCell>
                      </Tooltip>
                    );
                  })}
                  {actions.length ? (
                    <TableCell
                      style={firstCellStyle}
                      key={index}
                      size={tableSize}
                      align="right"
                    >
                      <IconButton
                        onClick={(event) => handleClick(index, event)}
                      >
                        <Badge badgeContent={row.notifications} color="error">
                          <MoreVertIcon style={{ color: 'black' }} />
                        </Badge>
                      </IconButton>
                      <Menu
                        anchorEl={anchorEls[index]}
                        open={openMenus[index]}
                        onClose={() => handleClose(index)}
                        onClick={() => handleClose(index)}
                      >
                        {actions.map((x, index) => {
                          if (x.canShow) {
                            if (x.canShow(row.id)) {
                              return (
                                <MenuItem
                                  key={index}
                                  onClick={() => {
                                    x.action(row.id as number);
                                  }}
                                >
                                  <Badge
                                    badgeContent={
                                      x.notifications ? row.notifications : null
                                    }
                                    color="error"
                                  >
                                    {x.actionLabel}{' '}
                                  </Badge>
                                </MenuItem>
                              );
                            }
                          } else {
                            return (
                              <MenuItem
                                key={index}
                                onClick={() => x.action(row.id as number)}
                              >
                                {x.actionLabel}{' '}
                              </MenuItem>
                            );
                          }
                        })}
                      </Menu>
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
            {completeTable &&
              Array.from({
                length:
                  rowsPerPage -
                  (filters.length
                    ? visibleRows().length
                    : visibleRowsCache.length),
              }).map((_, index) => (
                <TableRow key={`empty-${index}`}>
                  {selected && <TableCell />}{' '}
                  {/* Include this if you have a checkbox column */}
                  {dataHeaders.map((_, cellIndex) => (
                    <TableCell
                      key={`empty-cell-${cellIndex}`}
                      style={{ border: 0 }}
                    />
                  ))}
                  {actions.length ? <TableCell style={{ border: 0 }} /> : null}{' '}
                  {/* Include this if you have an actions column */}
                </TableRow>
              ))}
            {finalSumRow && (
              <TableRow style={{ borderTop: '1.5px solid' }}>
                <TableCell size={tableSize}>
                  <b>Total</b>
                </TableCell>
                {selected && <TableCell size={tableSize}>-</TableCell>}
                {dataHeaders.slice(1).map((header, index) => (
                  <TableCell key={index} size={tableSize}>
                    {typeof sums[header.key] === 'number' &&
                    header.type === 'money'
                      ? formatMoney(sums[header.key])
                      : ''}
                  </TableCell>
                ))}
                {actions.length ? <TableCell align="right"></TableCell> : null}{' '}
                {/* empty cell if there are actions */}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: '#efefef',
          }}
        >
          <div style={{ paddingLeft: '10px' }}>
            {exportToFile && (
              <DataExporter
                buttonColor="transparent"
                data={data}
                fileType={exportToFile}
                headers={dataHeaders}
              />
            )}
          </div>

          {!hideTablePagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={dataAvailable.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Filas por página:" // This sets the label to Spanish
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
              }
            />
          )}
        </div>
      </TableContainer>
    </div>
  );
};

export default EnhancedTable;
