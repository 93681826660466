import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import {
  getBankAccountsForNominee,
  getDocumentAvailablesForPaymentRequest,
} from '../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import GenerateNominee from '../../../../../Financing/Factoring/components/Recieved/components/ShoppingDialog/components/PaymentModule/components/generateNominee';
import {
  createPaymentRequest,
  getExpensesFinancialNodes,
  markDocumentsAsPaid,
} from '../../../../../../../../lib/api/payments';
import { ExpandMore } from '@mui/icons-material';

const nomineeHeaders = [
  { key: 'bankAccountOriginName', label: 'Banco origen' },
  {
    key: 'bankAccountDestinationName',
    label: 'Banco destino',
  },
  { key: 'bankAccountDestinationNumber', label: 'N° Cuenta' },
  { key: 'bankAccountDestinationAccountType', label: 'Tipo de cuenta' },
  { key: 'bankAccountDestinationHolderName', label: 'Titular' },
  { key: 'bankAccountDestinationHolderIdentifier', label: 'Rut' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'executedAt', label: 'Ejecutada', type: 'date' },
];

const PayoutDocuments = (props: any) => {
  const { setOpen, setSuccessOpen, setFailureOpen, setUp } = props;
  const [loading, setLoading] = useState(false);
  const [openNonNominee, setOpenNonNominee] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);
  const [documents, setDocuments] = useState([]);
  const [documentsConfirmed, setDocumentsConfirmed] = useState(false);
  const [destinyAccounts, setDestinyAccounts] = useState([]);
  const [originAccounts, setOriginAccounts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [collapse, setCollapse] = useState(false);
  const [step, setStep] = useState(1);
  const [financialNodes, setFinancialNodes] = useState([]);
  const [selectedFinancialNode, setSelectedFinancialNode] = useState<any>(null);

  const dispatch = useDispatch();

  const setUpDocuments = async () => {
    setLoading(true);
    try {
      const response = await getDocumentAvailablesForPaymentRequest(dispatch);
      const financialNodes = await getExpensesFinancialNodes(dispatch);
      setDocuments(response);
      setFinancialNodes(financialNodes);
    } catch (error) {
      alert('Error al cargar los documentos');
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpDocuments();
  }, []);

  const getStakeholderData = () => {
    if (selectedDocuments.length) {
      const document: any = documents.find(
        (invoice: any) => invoice.id === selectedDocuments[0],
      );
      if (document) {
        return {
          businessName: document.businessName,
          businessIdentifier: document.businessIdentifier,
        };
      }
    }
    return { businessName: '', businessIdentifier: '' };
  };

  const { businessIdentifier, businessName } = selectedDocuments.length
    ? getStakeholderData()
    : {
        businessIdentifier: '',
        businessName: '',
      };

  const configurePaymentRequest = () => {
    const documentsFiltered = documents.filter((doc: any) => {
      return selectedDocuments.includes(doc.id);
    });
    return {
      businessName,
      amount: documentsFiltered.reduce(
        (acc: number, doc: any) => acc + doc.amount,
        0,
      ),
      documents: documentsFiltered,
    };
  };
  const setUpConfigurePayment = async () => {
    setLoading(true);
    const request = configurePaymentRequest();
    setSelectedRequest(request);
    try {
      const accounts = await getBankAccountsForNominee(
        dispatch,
        businessIdentifier,
      );
      console.log(accounts);
      if (accounts) {
        setDestinyAccounts(accounts.businessBankAccounts);
        setOriginAccounts(accounts.ownBankAccounts);
        setDocumentsConfirmed(true);
      }
    } catch (error) {
      setFailureOpen('Error al obtener las cuentas');
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const financialNode: any = financialNodes.find(
        (node: any) => node.id === selectedFinancialNode,
      );
      if (!financialNode) {
        setFailureOpen('Error al obtener el nodo financiero');
        return;
      }
      await createPaymentRequest(dispatch, {
        payments,
        documentsToPay: selectedDocuments,
        financialNodeId: financialNode.id,
        financialNodeInternalKey: financialNode.internalKey,
      });
      await setUp();
      setSuccessOpen('Pago configurado con éxito');
    } catch (error) {
      setFailureOpen('Error al configurar el pago');
    }
    setLoading(false);
    setOpen(false);
  };

  const handleMarkAsPaid = async () => {
    setLoading(true);
    try {
      await markDocumentsAsPaid(dispatch, {
        documentsToPay: selectedDocuments,
      });
      await setUp();
      setSuccessOpen('Documentos marcados como pagados');
    } catch (error) {
      setFailureOpen('Error al marcar como pagado');
    }
    setLoading(false);
    setOpenNonNominee(false);
    setOpen(false);
  };

  const stepChecker = async () => {
    switch (step) {
      case 1:
        await setUpConfigurePayment();
        return setStep(2);
      case 2:
        if (!payments.length) {
          return setStep(3);
        }
        return await handleSubmit();
    }
  };

  const disabledCheck = () => {
    if (selectedDocuments.length === 0) return true;

    if (selectedFinancialNode === null) return true;

    if (payments.length === 0 && documentsConfirmed) return true;

    return false;
  };

  const cantSelectRow = (row: any) => {
    if (businessIdentifier && row.businessIdentifier !== businessIdentifier)
      return true;
    return false;
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <DialogContent>
        {step === 1 && (
          <div>
            <EnhancedTable
              data={documents}
              dataHeaders={[
                { key: 'folio', label: 'folio' },
                { key: 'businessName', label: 'Emisor' },
                { key: 'amount', label: 'monto', type: 'money' },
                { key: 'issuedDate', label: 'Fecha emisión', type: 'date' },
              ]}
              selected={selectedDocuments}
              setSelected={setSelectedDocuments}
              rowsPerPageDefault={5}
              cantSelectRow={cantSelectRow}
            />
            <Grid
              container
              spacing={2}
              sx={{ padding: '20px 0px' }}
              alignItems="center"
            >
              <Grid item xs={4}>
                <Typography variant="body1">
                  A qué cuenta pertence este pago?
                </Typography>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="account-type">Tipo de cuenta</InputLabel>
                  <Select
                    labelId="account-type"
                    value={selectedFinancialNode}
                    onChange={(e) =>
                      setSelectedFinancialNode(e.target.value as string)
                    }
                    label="Tipo de cuenta"
                  >
                    {financialNodes.map((node: any) => (
                      <MenuItem value={node.id}>{node.key}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}
        {step === 2 && (
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography fontWeight={600} variant="h6">
                Documentos seleccionados
              </Typography>
              <IconButton onClick={() => setCollapse(!collapse)}>
                <ExpandMore />
              </IconButton>
            </div>
            <Collapse in={collapse}>
              <EnhancedTable
                data={documents.filter((doc: any) =>
                  selectedDocuments.includes(doc.id),
                )}
                dataHeaders={[
                  { key: 'folio', label: 'folio' },
                  { key: 'businessName', label: 'Emisor' },
                  { key: 'amount', label: 'monto', type: 'money' },
                  { key: 'issuedDate', label: 'Fecha emisión', type: 'date' },
                ]}
                rowsPerPageDefault={5}
              />
            </Collapse>
            <Divider />
          </div>
        )}
        {step === 2 && !payments.length ? (
          <div>
            <GenerateNominee
              availableAccounts={destinyAccounts}
              originAccounts={originAccounts}
              selectedRequest={selectedRequest}
              setPayments={setPayments}
              amount={selectedRequest.amount}
            />
          </div>
        ) : null}
        {step === 2 && payments.length ? (
          <div>
            <Typography variant="body1" margin={'4px'}>
              Seleccione la configuración de pago
            </Typography>
            <EnhancedTable dataHeaders={nomineeHeaders} data={payments} />
          </div>
        ) : null}
      </DialogContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ padding: '0px 10px' }}>
          {step === 2 && (
            <CustomButton
              color="success"
              onClick={() => setOpenNonNominee(true)}
            >
              {' '}
              Sin nómina{' '}
            </CustomButton>
          )}
        </div>
        <DialogActions>
          <CustomButton
            color="secondary"
            onClick={() =>
              documentsConfirmed ? setDocumentsConfirmed(false) : setOpen(false)
            }
          >
            {' '}
            Cancelar{' '}
          </CustomButton>
          <CustomButton
            color="primary"
            onClick={() => stepChecker()}
            disabled={disabledCheck()}
          >
            {' '}
            {step === 1 ? 'Siguiente' : 'Finalizar'}{' '}
          </CustomButton>
        </DialogActions>
      </div>
      <Dialog open={openNonNominee} onClose={() => setOpenNonNominee(false)}>
        <DialogTitle>Pago sin nómina</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Esto se hace para facturas que ya fueron pagadas sin un pago
            directo. Normalmente utilizado en prestamos con descuentos o ventas
            con descuentos.
          </Typography>
          <Typography variant="body1">
            Estás seguro de querer continuar?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton
            color="secondary"
            onClick={() => setOpenNonNominee(false)}
          >
            Cancelar
          </CustomButton>
          <CustomButton color="primary" onClick={() => handleMarkAsPaid()}>
            Marcar como pagado
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PayoutDocuments;
