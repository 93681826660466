import React from 'react';
import { DialogActions } from '@mui/material';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';

export const Actions = (props: any) => {
  const {
    handleClose,
    documentHasExecutives,
    handleSend,
    loading,
    stateId,
    option,
    contactMethod,
    description,
    dueDate,
    handleAssignExecutive,
    portfolioExecutiveId,
  } = props;
  return (
    <DialogActions>
      <CustomButton color="secondary" onClick={() => handleClose()}>
        Cancelar
      </CustomButton>
      {documentHasExecutives ? (
        <CustomButton
          color="primary"
          onClick={() => handleSend()}
          disabled={
            loading ||
            !stateId ||
            !option ||
            !contactMethod ||
            !description.length ||
            !dueDate.length
          }
        >
          Crear
        </CustomButton>
      ) : (
        <CustomButton
          color="primary"
          onClick={() => handleAssignExecutive()}
          disabled={loading || !portfolioExecutiveId}
        >
          Asignar ejecutivo
        </CustomButton>
      )}
    </DialogActions>
  );
};
