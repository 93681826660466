export const communes = [
  { id: 1, name: 'LAS CONDES' },
  { id: 2, name: 'OVALLE' },
  { id: 3, name: 'COQUIMBO' },
  { id: 4, name: 'SAN MIGUEL' },
  { id: 5, name: 'SANTIAGO' },
  { id: 6, name: 'PROVIDENCIA' },
  { id: 7, name: 'INDEPENDENCIA' },
  { id: 8, name: 'ANTOFAGASTA' },
  { id: 9, name: 'LA CISTERNA' },
  { id: 10, name: 'OSORNO' },
  { id: 11, name: 'VALPARAISO' },
  { id: 12, name: 'NUNOA' },
  { id: 13, name: 'SAN ANTONIO' },
  { id: 14, name: 'CURICO' },
  { id: 15, name: 'LIMACHE' },
  { id: 16, name: 'LOS ANGELES' },
  { id: 17, name: 'CONCEPCION' },
  { id: 18, name: 'CARTAGENA' },
  { id: 19, name: 'MOLINA' },
  { id: 20, name: 'RANCAGUA' },
  { id: 21, name: 'CONCHALI' },
  { id: 22, name: 'SAN BERNARDO' },
  { id: 23, name: 'PIRQUE' },
  { id: 24, name: 'PENALOLEN' },
  { id: 25, name: 'COLINA' },
  { id: 26, name: 'MELIPILLA' },
  { id: 27, name: 'MACUL' },
  { id: 28, name: 'EL BOSQUE' },
  { id: 29, name: 'QUINTA NORMAL' },
  { id: 30, name: 'LA SERENA' },
  { id: 31, name: 'SANTA CRUZ' },
  { id: 32, name: 'CASABLANCA' },
  { id: 33, name: 'SAN FERNANDO' },
  { id: 34, name: 'LA FLORIDA' },
  { id: 35, name: 'PENCAHUE' },
  { id: 36, name: 'SAN FELIPE' },
  { id: 37, name: 'REQUINOA' },
  { id: 38, name: 'TALCAHUANO' },
  { id: 39, name: 'RECOLETA' },
  { id: 40, name: 'TALCA' },
  { id: 41, name: 'VINA DEL MAR' },
  { id: 42, name: 'LA LIGUA' },
  { id: 43, name: 'LO BARNECHEA' },
  { id: 44, name: 'CHILLAN' },
  { id: 45, name: 'ROMERAL' },
  { id: 46, name: 'PENAFLOR' },
  { id: 47, name: 'ARICA' },
  { id: 48, name: 'COPIAPO' },
  { id: 49, name: 'LA UNION' },
  { id: 50, name: 'TALAGANTE' },
  { id: 51, name: 'PLACILLA' },
  { id: 52, name: 'QUILLOTA' },
  { id: 53, name: 'PARRAL' },
  { id: 54, name: 'HIJUELAS' },
  { id: 55, name: 'PUTAENDO' },
  { id: 56, name: 'TEMUCO' },
  { id: 57, name: 'LA CALERA' },
  { id: 58, name: 'QUILPUE' },
  { id: 59, name: 'LA CRUZ' },
  { id: 60, name: 'VICUNA' },
  { id: 61, name: 'CAUQUENES' },
  { id: 62, name: 'EL QUISCO' },
  { id: 63, name: 'ALGARROBO' },
  { id: 64, name: 'LINARES' },
  { id: 65, name: 'PUERTO MONTT' },
  { id: 66, name: 'SAN JAVIER' },
  { id: 67, name: 'HUECHURABA' },
  { id: 68, name: 'BUIN' },
  { id: 69, name: 'SAN JOAQUIN' },
  { id: 70, name: 'SANTA MARIA' },
  { id: 71, name: 'MULCHEN' },
  { id: 72, name: 'GALVARINO' },
  { id: 73, name: 'SAN VICENTE T-T' },
  { id: 74, name: 'VILLARRICA' },
  { id: 75, name: 'FREIRE' },
  { id: 76, name: 'VITACURA' },
  { id: 77, name: 'VILLA ALEMANA' },
  { id: 78, name: 'PUENTE ALTO' },
  { id: 79, name: 'PUNTA ARENAS' },
  { id: 80, name: 'PADRE LAS CASAS' },
  { id: 81, name: 'PAREDONES' },
  { id: 82, name: 'SALAMANCA' },
  { id: 83, name: 'ANGOL' },
  { id: 84, name: 'EL CARMEN' },
  { id: 85, name: 'LOS ANDES' },
  { id: 86, name: 'SAN PEDRO DE LA PAZ' },
  { id: 87, name: 'LA REINA' },
  { id: 88, name: 'CERRILLOS' },
  { id: 89, name: 'TRAIGUEN' },
  { id: 90, name: 'OLIVAR' },
  { id: 91, name: 'ANDACOLLO' },
  { id: 92, name: 'PETORCA' },
  { id: 93, name: 'PUDAHUEL' },
  { id: 94, name: 'CERRO NAVIA' },
  { id: 95, name: 'LA GRANJA' },
  { id: 96, name: 'CURANILAHUE' },
  { id: 97, name: 'CABILDO' },
  { id: 98, name: 'EST CENTRAL' },
  { id: 99, name: 'VICTORIA' },
  { id: 100, name: 'EL TABO' },
  { id: 101, name: 'RENCA' },
  { id: 102, name: 'LO PRADO' },
  { id: 103, name: 'TEODORO SCHMIDT' },
  { id: 104, name: 'FLORIDA' },
  { id: 105, name: 'CON CON' },
  { id: 106, name: 'CALLE LARGA' },
  { id: 107, name: 'NANCAGUA' },
  { id: 108, name: 'NIQUEN' },
  { id: 109, name: 'LAMPA' },
  { id: 110, name: 'RIO BUENO' },
  { id: 111, name: 'VALDIVIA' },
  { id: 112, name: 'FRUTILLAR' },
  { id: 113, name: 'CONSTITUCION' },
  { id: 114, name: 'LONQUIMAY' },
  { id: 115, name: 'CALERA DE TANGO' },
  { id: 116, name: 'SAN RAMON' },
  { id: 117, name: 'FUTRONO' },
  { id: 118, name: 'PUMANQUE' },
  { id: 119, name: 'ALHUE' },
  { id: 120, name: 'RINCONADA' },
  { id: 121, name: 'CODEGUA' },
  { id: 122, name: 'PAINE' },
  { id: 123, name: 'PADRE HURTADO' },
  { id: 124, name: 'CHEPICA' },
  { id: 125, name: 'NOGALES' },
  { id: 126, name: 'MALLOA' },
  { id: 127, name: 'DONIHUE' },
  { id: 128, name: 'LONCOCHE' },
  { id: 129, name: 'PERALILLO' },
  { id: 130, name: 'QUINTA TILCOCO' },
  { id: 131, name: 'YUMBEL' },
  { id: 132, name: 'MAIPU' },
  { id: 133, name: 'PUERTO VARAS' },
  { id: 134, name: 'LLAY-LLAY' },
  { id: 135, name: 'SAN CARLOS' },
  { id: 136, name: 'SAN CLEMENTE' },
  { id: 137, name: 'TOME' },
  { id: 138, name: 'SAGRADA FAMILIA' },
  { id: 139, name: 'IQUIQUE' },
  { id: 140, name: 'CURACAVI' },
  { id: 141, name: 'SAN NICOLAS' },
  { id: 142, name: 'CALAMA' },
  { id: 143, name: 'RIO NEGRO' },
  { id: 144, name: 'MAULE' },
  { id: 145, name: 'CURACAUTIN' },
  { id: 146, name: 'CHIGUAYANTE' },
  { id: 147, name: 'LA PINTANA' },
  { id: 148, name: 'CATEMU' },
  { id: 149, name: 'HUALQUI' },
  { id: 150, name: 'CASTRO' },
  { id: 151, name: 'LO ESPEJO' },
  { id: 152, name: 'PUCON' },
  { id: 153, name: 'LEBU' },
  { id: 154, name: 'PUNITAQUI' },
  { id: 155, name: 'PICHILEMU' },
  { id: 156, name: 'VILCUN' },
  { id: 157, name: 'GRANEROS' },
  { id: 158, name: 'YUNGAY' },
  { id: 159, name: 'SAN RAFAEL' },
  { id: 160, name: 'ILLAPEL' },
  { id: 161, name: 'VILLA ALEGRE' },
  { id: 162, name: 'ZAPALLAR' },
  { id: 163, name: 'CANELA' },
  { id: 164, name: 'SAN PEDRO DE MELIPILLA' },
  { id: 165, name: 'MARCHIGUE' },
  { id: 166, name: 'PUREN' },
  { id: 167, name: 'SAN FCO DE MOSTAZAL' },
  { id: 168, name: 'CARAHUE' },
  { id: 169, name: 'CHAITEN' },
  { id: 170, name: 'COIHUECO' },
  { id: 171, name: 'CANETE' },
  { id: 172, name: 'SANTO DOMINGO' },
  { id: 173, name: 'EL MONTE' },
  { id: 174, name: 'MACHALI' },
  { id: 175, name: 'ANCUD' },
  { id: 176, name: 'CORONEL' },
  { id: 177, name: 'MEJILLONES' },
  { id: 178, name: 'SAN ESTEBAN' },
  { id: 179, name: 'VICHUQUEN' },
  { id: 180, name: 'MONTE PATRIA' },
  { id: 181, name: 'LAUTARO' },
  { id: 182, name: 'MARIA PINTO' },
  { id: 183, name: 'PUCHUNCAVI' },
  { id: 184, name: 'PICHIDEGUA' },
  { id: 185, name: 'PUERTO NATALES' },
  { id: 186, name: 'RENGO' },
  { id: 187, name: 'PALMILLA' },
  { id: 188, name: 'ARAUCO' },
  { id: 189, name: 'NUEVA IMPERIAL' },
  { id: 190, name: 'LUMACO' },
  { id: 191, name: 'LOS MUERMOS' },
  { id: 192, name: 'QUIRIHUE' },
  { id: 193, name: 'OLMUE' },
  { id: 194, name: 'CHONCHI' },
  { id: 195, name: 'COLLIPULLI' },
  { id: 196, name: 'TIL-TIL' },
  { id: 197, name: 'LAJA' },
  { id: 198, name: 'RAUCO' },
  { id: 199, name: 'ALTO HOSPICIO' },
  { id: 200, name: 'RANQUIL' },
  { id: 201, name: 'SAN PABLO' },
  { id: 202, name: 'RIO CLARO' },
  { id: 203, name: 'QUILLON' },
  { id: 204, name: 'TOCOPILLA' },
  { id: 205, name: 'SANTA BARBARA' },
  { id: 206, name: 'COCHRANE' },
  { id: 207, name: 'SAN JOSE MAIPO' },
  { id: 208, name: 'CHIMBARONGO' },
  { id: 209, name: 'QUILICURA' },
  { id: 210, name: 'PAPUDO' },
  { id: 211, name: 'PENCO' },
  { id: 212, name: 'LOS LAGOS' },
  { id: 213, name: 'LOS VILOS' },
  { id: 214, name: 'HUASCO' },
  { id: 215, name: 'PORVENIR' },
  { id: 216, name: 'COYHAIQUE' },
  { id: 217, name: 'PANGUIPULLI' },
  { id: 218, name: 'ISLA DE MAIPO' },
  { id: 219, name: 'EMPEDRADO' },
  { id: 220, name: 'PELLUHUE' },
  { id: 221, name: 'CUREPTO' },
  { id: 222, name: 'VALLENAR' },
  { id: 223, name: 'YERBAS BUENAS' },
  { id: 224, name: 'LOTA' },
  { id: 225, name: 'LAS CABRAS' },
  { id: 226, name: 'MARIQUINA' },
  { id: 227, name: 'QUILLECO' },
  { id: 228, name: 'COMBARBALA' },
  { id: 229, name: 'COLBUN' },
  { id: 230, name: 'PUTRE' },
  { id: 231, name: 'COLTAUCO' },
  { id: 232, name: 'PICA' },
  { id: 233, name: 'PEUMO' },
  { id: 234, name: 'ANTUCO' },
  { id: 235, name: 'RETIRO' },
  { id: 236, name: 'PANQUEHUE' },
  { id: 237, name: 'CISNES' },
  { id: 238, name: 'SAN IGNACIO' },
  { id: 239, name: 'HUALPEN' },
  { id: 240, name: 'QUINTERO' },
  { id: 241, name: 'RENAICO' },
  { id: 242, name: 'FREIRINA' },
  { id: 243, name: 'JUAN FERNANDEZ' },
  { id: 244, name: 'CHILLAN VIEJO' },
  { id: 245, name: 'HUALANE' },
  { id: 246, name: 'CUNCO' },
  { id: 247, name: 'PURRANQUE' },
  { id: 248, name: 'PUYEHUE' },
  { id: 249, name: 'LAGO RANCO' },
  { id: 250, name: 'FUTALEUFU' },
  { id: 251, name: 'COBQUECURA' },
  { id: 252, name: 'PUERTO OCTAY' },
  { id: 253, name: 'DALCAHUE' },
  { id: 254, name: 'CHANARAL' },
  { id: 255, name: 'LLANQUIHUE' },
  { id: 256, name: 'CALDERA' },
  { id: 257, name: 'DIEGO DE ALMAGRO' },
  { id: 258, name: 'CONTULMO' },
  { id: 259, name: 'TENO' },
  { id: 260, name: 'COELEMU' },
  { id: 261, name: 'BULNES' },
  { id: 262, name: 'RIO IBANEZ' },
  { id: 263, name: 'NACIMIENTO' },
  { id: 264, name: 'SAN GREGORIO' },
  { id: 265, name: 'PEMUCO' },
  { id: 266, name: 'LOS ALAMOS' },
  { id: 267, name: 'FRESIA' },
  { id: 268, name: 'TIMAUKEL' },
  { id: 269, name: 'LOLOL' },
  { id: 270, name: 'LA ESTRELLA' },
  { id: 271, name: 'PITRUFQUEN' },
  { id: 272, name: 'COINCO' },
  { id: 273, name: 'RIO HURTADO' },
  { id: 274, name: 'PAIHUANO' },
  { id: 275, name: 'GORBEA' },
  { id: 276, name: 'CHANCO' },
  { id: 277, name: 'PRIMAVERA' },
  { id: 278, name: 'NINHUE' },
  { id: 279, name: 'PELARCO' },
  { id: 280, name: 'AYSEN' },
  { id: 281, name: 'NEGRETE' },
  { id: 282, name: 'LANCO' },
  { id: 283, name: 'PAILLACO' },
  { id: 284, name: 'PORTEZUELO' },
  { id: 285, name: 'LONGAVI' },
  { id: 286, name: 'ALTO DEL CARMEN' },
  { id: 287, name: 'CABRERO' },
  { id: 288, name: 'TIRUA' },
  { id: 289, name: 'PINTO' },
  { id: 290, name: 'MAULLIN' },
  { id: 291, name: 'MAFIL' },
  { id: 292, name: 'TORRES DE PAINE' },
  { id: 293, name: 'SAN JUAN DE LA COSTA' },
  { id: 294, name: 'LITUECHE' },
  { id: 295, name: 'CALBUCO' },
  { id: 296, name: 'COCHAMO' },
  { id: 297, name: 'RIO VERDE' },
  { id: 298, name: 'SANTA JUANA' },
  { id: 299, name: 'SAN PEDRO DE ATACAMA' },
  { id: 300, name: 'HUALAIHUE' },
  { id: 301, name: 'NAVIDAD' },
  { id: 302, name: 'LOS SAUCES' },
  { id: 303, name: 'TOLTEN' },
  { id: 304, name: 'PERQUENCO' },
  { id: 305, name: 'PUQUELDON' },
  { id: 306, name: 'POZO ALMONTE' },
  { id: 307, name: 'QUILACO' },
  { id: 308, name: 'TIERRA AMARILLA' },
  { id: 309, name: 'QUELLON' },
  { id: 310, name: 'SIERRA GORDA' },
  { id: 311, name: 'SAN FABIAN' },
  { id: 312, name: 'LICANTEN' },
  { id: 313, name: 'QUINCHAO' },
  { id: 314, name: 'Sin Comuna' },
  { id: 315, name: 'TALTAL' },
  { id: 316, name: 'TREHUACO' },
  { id: 317, name: 'OLLAGUE' },
  { id: 318, name: 'LAGUNA BLANCA' },
  { id: 319, name: 'CAMINA' },
  { id: 320, name: 'ISLA DE PASCUA' },
  { id: 321, name: 'CORRAL' },
  { id: 322, name: 'CHILE CHICO' },
  { id: 323, name: 'CABO DE HORNOS' },
  { id: 324, name: 'SAAVEDRA' },
  { id: 325, name: 'PALENA' },
  { id: 326, name: 'TUCAPEL' },
  { id: 327, name: 'ALTO BIO BIO' },
  { id: 328, name: 'QUEILEN' },
  { id: 329, name: 'CAMARONES' },
  { id: 330, name: 'QUEMCHI' },
  { id: 331, name: 'CHOLCHOL' },
  { id: 332, name: 'MARIA ELENA' },
  { id: 333, name: 'MELIPEUCO' },
  { id: 334, name: 'HUARA' },
  { id: 335, name: 'CURARREHUE' },
  { id: 336, name: 'CURACO DE VELEZ' },
  { id: 337, name: 'OHIGGINS' },
  { id: 338, name: 'LA HIGUERA' },
  { id: 339, name: 'ERCILLA' },
  { id: 340, name: 'TORTEL' },
  { id: 341, name: 'GENERAL LAGOS' },
  { id: 342, name: 'LAGO VERDE' },
  { id: 343, name: 'GUAITECAS' },
  { id: 344, name: 'SAN ROSENDO' },
  { id: 345, name: 'COLCHANE' },
  { id: 346, name: 'ANTARTIDA' },
];

export const regions = [
  { id: 1, name: 'XIII REGION METROPOLITANA' },
  { id: 2, name: 'IV REGION COQUIMBO' },
  { id: 3, name: 'II REGION DE ANTOFAGASTA' },
  { id: 4, name: 'X REGION LOS LAGOS' },
  { id: 5, name: 'V REGION VALPARAISO' },
  { id: 6, name: 'VII REGION DEL MAULE' },
  { id: 7, name: 'VIII REGION DEL BIO BIO' },
  { id: 8, name: `VI REGION DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS` },
  { id: 9, name: 'XVI REGION DE ÑUBLE' },
  { id: 10, name: 'XV REGION ARICA Y PARINACOTA' },
  { id: 11, name: 'III REGION DE ATACAMA' },
  { id: 12, name: 'XIV REGION DE LOS RIOS' },
  { id: 13, name: 'IX REGION DE LA ARAUCANIA' },
  { id: 14, name: 'XII REGION DE MAGALLANES Y LA ANTARTICA CHILENA' },
  { id: 15, name: 'I REGION DE TARAPACA' },
  { id: 16, name: 'XI REGION AYSEN DEL GENERAL CARLOS IBAÑEZ DEL CAMPO' },
  { id: 17, name: 'Sin Información' },
];

export const provinces = [
  { id: 1, name: 'Arica' },
  { id: 2, name: 'Parinacota' },
  { id: 3, name: 'Iquique' },
  { id: 4, name: 'Tamarugal' },
  { id: 7, name: 'Tocopilla' },
  { id: 6, name: 'El Loa' },
  { id: 5, name: 'Antofagasta' },
  { id: 9, name: 'Chañaral' },
  { id: 8, name: 'Copiapó' },
  { id: 10, name: 'Huasco' },
  { id: 11, name: 'Elqui' },
  { id: 12, name: 'Limarí' },
  { id: 13, name: 'Choapa' },
  { id: 14, name: 'Petorca' },
  { id: 15, name: 'Los Andes' },
  { id: 16, name: 'San Felipe de Aconcagua' },
  { id: 17, name: 'Quillota' },
  { id: 18, name: 'Valparaíso' },
  { id: 19, name: 'San Antonio' },
  { id: 20, name: 'Isla de Pascua' },
  { id: 21, name: 'Marga Marga' },
  { id: 22, name: 'Chacabuco' },
  { id: 23, name: 'Santiago' },
  { id: 24, name: 'Cordillera' },
  { id: 25, name: 'Maipo' },
  { id: 26, name: 'Melipilla' },
  { id: 27, name: 'Talagante' },
  { id: 28, name: 'Cachapoal' },
  { id: 29, name: 'Colchagua' },
  { id: 30, name: 'Cardenal Caro' },
  { id: 31, name: 'Curicó' },
  { id: 32, name: 'Talca' },
  { id: 33, name: 'Linares' },
  { id: 34, name: 'Cauquenes' },
  { id: 35, name: 'Diguillín' },
  { id: 36, name: 'Itata' },
  { id: 37, name: 'Punilla' },
  { id: 38, name: 'Biobío' },
  { id: 39, name: 'Concepción' },
  { id: 40, name: 'Arauco' },
  { id: 41, name: 'Malleco' },
  { id: 42, name: 'Cautín' },
  { id: 43, name: 'Valdivia' },
  { id: 44, name: 'Ranco' },
  { id: 45, name: 'Osorno' },
  { id: 46, name: 'Llanquihue' },
  { id: 47, name: 'Chiloe' },
  { id: 48, name: 'Palena' },
  { id: 49, name: 'Coyhaique' },
  { id: 50, name: 'Aysén' },
  { id: 51, name: 'General Carrera' },
  { id: 52, name: 'General Prat' },
  { id: 53, name: 'Última Esperanza' },
  { id: 54, name: 'Magallanes' },
  { id: 55, name: 'Tierra del Fuego' },
  { id: 56, name: 'Antártica Chilena' },
];

export const companySizes = [
  { id: 1, name: 'Sin información' },
  { id: 2, name: 'Micro Empresa I' },
  { id: 3, name: 'Micro Empresa II' },
  { id: 4, name: 'Micro Empresa III' },
  { id: 5, name: 'Pequeña Empresa I' },
  { id: 6, name: 'Pequeña Empresa II' },
  { id: 7, name: 'Pequeña Empresa III' },
  { id: 8, name: 'Mediana Empresa I' },
  { id: 9, name: 'Mediana Empresa II' },
  { id: 10, name: 'Gran Empresa I' },
  { id: 11, name: 'Gran Empresa II' },
  { id: 12, name: 'Gran Empresa III' },
  { id: 13, name: 'Gran Empresa IV' },
];

export const economicSectors = [
  {
    id: 1,
    name: 'ACTIVIDADES DE ATENCION DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL',
  },
  { id: 2, name: 'TRANSPORTE Y ALMACENAMIENTO' },
  { id: 3, name: 'INDUSTRIA MANUFACTURERA' },
  { id: 4, name: 'ACTIVIDADES DE ALOJAMIENTO Y DE SERVICIO DE COMIDAS' },
  { id: 5, name: 'OTRAS ACTIVIDADES DE SERVICIOS' },
  {
    id: 6,
    name: 'COMERCIO AL POR MAYOR Y AL POR MENOR; REPARACION DE VEHICULOS AUTOMOTORES Y MOTOCICLETAS',
  },
  { id: 7, name: 'ACTIVIDADES INMOBILIARIAS' },
  { id: 8, name: 'ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO' },
  { id: 9, name: 'ACTIVIDADES PROFESIONALES, CIENTIFICAS Y TECNICAS' },
  { id: 10, name: 'INFORMACION Y COMUNICACIONES' },
  { id: 11, name: 'AGRICULTURA, GANADERIA, SILVICULTURA Y PESCA' },
  { id: 12, name: 'ENSEÑANZA' },
  { id: 13, name: 'ACTIVIDADES FINANCIERAS Y DE SEGUROS' },
  { id: 14, name: 'CONSTRUCCION' },
  { id: 15, name: 'ACTIVIDADES ARTISTICAS, DE ENTRETENIMIENTO Y RECREATIVAS' },
  { id: 16, name: 'EXPLOTACION DE MINAS Y CANTERAS' },
  {
    id: 17,
    name: 'SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO',
  },
  {
    id: 18,
    name: 'SUMINISTRO DE AGUA; EVACUACION DE AGUAS RESIDUALES, GESTION DE DESECHOS Y DESCONTAMINACION',
  },
  {
    id: 19,
    name: 'ACTIVIDADES DE ORGANIZACIONES Y ORGANOS EXTRATERRITORIALES',
  },
  {
    id: 20,
    name: 'ADMINISTRACION PUBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACION OBLIGATORIA',
  },
  {
    id: 21,
    name: 'ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES; ACTIVIDADES NO DIFERENCIADAS DE LOS HOGARES',
  },
];

export const economicSubSectors = [
  { id: 1, name: 'OTRAS ACTIVIDADES DE ATENCION EN INSTITUCIONES' },
  { id: 2, name: 'OTRAS ACTIVIDADES DE TRANSPORTE POR VIA TERRESTRE' },
  {
    id: 3,
    name: 'FABRICACION DE PRODUCTOS METALICOS PARA USO ESTRUCTURAL, TANQUES, DEPOSITOS, RECIPIENTES DE METAL',
  },
  { id: 4, name: 'ACTIVIDADES DE RESTAURANTES Y DE SERVICIO MOVIL DE COMIDAS' },
  { id: 5, name: 'ACTIVIDADES DE OTRAS ASOCIACIONES' },
  {
    id: 6,
    name: 'VENTA AL POR MENOR DE ALIMENTOS, BEBIDAS Y TABACO EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 7,
    name: 'VENTA AL POR MENOR DE OTROS PRODUCTOS EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 8, name: 'FABRICACION DE MUEBLES' },
  { id: 9, name: 'OTRAS ACTIVIDADES DE SERVICIOS PERSONALES' },
  {
    id: 10,
    name: 'ACTIVIDADES INMOBILIARIAS REALIZADAS CON BIENES PROPIOS O ARRENDADOS',
  },
  { id: 11, name: 'ACTIVIDADES DE SEGURIDAD PRIVADA' },
  { id: 12, name: 'MANTENIMIENTO Y REPARACION DE VEHICULOS AUTOMOTORES' },
  {
    id: 13,
    name: 'ACTIVIDADES DE ARQUITECTURA E INGENIERIA Y ACTIVIDADES CONEXAS DE CONSULTORIA TECNICA',
  },
  {
    id: 14,
    name: 'ACTIVIDADES DE PRODUCCION DE PELICULAS CINEMATOGRAFICAS, VIDEOS Y PROGRAMAS DE TELEVISION',
  },
  { id: 15, name: 'VENTA AL POR MENOR EN COMERCIOS NO ESPECIALIZADOS' },
  { id: 16, name: 'ACTIVIDADES DE SERVICIOS DE APOYO A LAS EMPRESAS N.C.P.' },
  { id: 17, name: 'GANADERIA' },
  {
    id: 18,
    name: 'VENTA AL POR MENOR DE PRODUCTOS CULTURALES Y RECREATIVOS EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 19, name: 'OTRAS ACTIVIDADES DE ALOJAMIENTO' },
  { id: 20, name: 'FABRICACION DE VIDRIO Y PRODUCTOS DE VIDRIO' },
  {
    id: 21,
    name: 'EDICION DE LIBROS Y PUBLICACIONES PERIODICAS Y OTRAS ACTIVIDADES DE EDICION',
  },
  {
    id: 22,
    name: 'ACTIVIDADES DE PROGRAMACION INFORMATICA, CONSULTORIA INFORMATICA Y ACTIVIDADES CONEXAS',
  },
  {
    id: 23,
    name: 'VENTA AL POR MENOR DE OTROS ENSERES DOMESTICOS EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 24,
    name: 'ACTIVIDADES DE CONTABILIDAD, TENEDURIA DE LIBROS Y AUDITORIA; CONSULTORIA FISCAL',
  },
  { id: 25, name: 'CULTIVO DE PLANTAS PERENNES' },
  { id: 26, name: 'ACTIVIDADES DE MEDICOS Y ODONTOLOGOS' },
  { id: 27, name: 'FABRICACION DE PRODUCTOS DE PLASTICO' },
  { id: 28, name: 'VENTA AL POR MAYOR NO ESPECIALIZADA' },
  { id: 29, name: 'FABRICACION DE PRODUCTOS DE CAUCHO' },
  {
    id: 30,
    name: 'FABRICACION DE OTROS PRODUCTOS ELABORADOS DE METAL; ACTIVIDADES DE SERVICIOS DE TRABAJO DE METALES',
  },
  {
    id: 31,
    name: 'ENSEÑANZA PREESCOLAR, PRIMARIA, SECUNDARIA CIENTIFICO HUMANISTA Y TECNICO PROFESIONAL',
  },
  { id: 32, name: 'FABRICACION DE EQUIPO DE TRANSPORTE N.C.P.' },
  {
    id: 33,
    name: 'SUMINISTRO DE COMIDAS POR ENCARGO Y OTRAS ACTIVIDADES DE SERVICIO DE COMIDAS',
  },
  { id: 34, name: 'VENTA AL POR MAYOR DE MAQUINARIA, EQUIPO Y MATERIALES' },
  {
    id: 35,
    name: 'FONDOS Y SOCIEDADES DE INVERSION Y ENTIDADES FINANCIERAS SIMILARES',
  },
  {
    id: 36,
    name: 'CULTIVO DE PRODUCTOS AGRICOLAS EN COMBINACION CON LA CRIA DE ANIMALES',
  },
  {
    id: 37,
    name: 'ALQUILER Y ARRENDAMIENTO DE OTROS TIPOS DE MAQUINARIA, EQUIPO Y BIENES TANGIBLES',
  },
  { id: 38, name: 'EXTRACCION DE MADERA' },
  { id: 39, name: 'FABRICACION DE PRENDAS DE VESTIR, EXCEPTO PRENDAS DE PIEL' },
  { id: 40, name: 'REPARACION DE EFECTOS PERSONALES Y ENSERES DOMESTICOS' },
  { id: 41, name: 'CULTIVO DE PLANTAS NO PERENNES' },
  {
    id: 42,
    name: 'OTRAS ACTIVIDADES PROFESIONALES, CIENTIFICAS Y TECNICAS N.C.P.',
  },
  {
    id: 43,
    name: 'IMPRESION Y ACTIVIDADES DE SERVICIOS RELACIONADAS CON LA IMPRESION',
  },
  { id: 44, name: 'PUBLICIDAD' },
  { id: 45, name: 'VENTA AL POR MAYOR DE ALIMENTOS, BEBIDAS Y TABACO' },
  {
    id: 46,
    name: 'VENTA AL POR MENOR NO REALIZADA EN COMERCIOS, PUESTOS DE VENTA O MERCADOS',
  },
  {
    id: 47,
    name: 'REPARACION DE PRODUCTOS ELABORADOS DE METAL, MAQUINARIA Y EQUIPO',
  },
  { id: 48, name: 'ELABORACION DE OTROS PRODUCTOS ALIMENTICIOS' },
  { id: 49, name: 'ELABORACION Y CONSERVACION DE CARNE' },
  { id: 50, name: 'ACTIVIDADES DE ALOJAMIENTO PARA ESTANCIAS CORTAS' },
  { id: 51, name: 'VENTA DE VEHICULOS AUTOMOTORES' },
  {
    id: 52,
    name: 'VENTA DE PARTES, PIEZAS Y ACCESORIOS PARA VEHICULOS AUTOMOTORES',
  },
  { id: 53, name: 'FABRICACION DE PRODUCTOS MINERALES NO METALICOS N.C.P.' },
  { id: 54, name: 'FABRICACION DE OTROS PRODUCTOS TEXTILES' },
  { id: 55, name: 'OTRAS ACTIVIDADES DE ATENCION DE LA SALUD HUMANA' },
  { id: 56, name: 'ACTIVIDADES DE CONSULTORIA DE GESTION' },
  { id: 57, name: 'ALMACENAMIENTO Y DEPOSITO' },
  { id: 58, name: 'VENTA AL POR MAYOR DE ENSERES DOMESTICOS' },
  { id: 59, name: 'ACTIVIDADES JURIDICAS' },
  {
    id: 60,
    name: 'ACTIVIDADES DE APOYO A LA AGRICULTURA Y LA GANADERIA Y ACTIVIDADES POSCOSECHA',
  },
  {
    id: 61,
    name: 'ACTIVIDADES INMOBILIARIAS REALIZADAS A CAMBIO DE UNA RETRIBUCION O POR CONTRATA',
  },
  { id: 62, name: 'OTRAS ACTIVIDADES ESPECIALIZADAS DE CONSTRUCCION' },
  { id: 63, name: 'FABRICACION DE CALZADO' },
  { id: 64, name: 'SERVICIOS DE APOYO A LA SILVICULTURA' },
  { id: 65, name: 'PROPAGACION DE PLANTAS' },
  { id: 66, name: 'ENSEÑANZA SUPERIOR' },
  { id: 67, name: 'FABRICACION DE COMPUTADORES Y EQUIPO PERIFERICO' },
  {
    id: 68,
    name: 'ACTIVIDADES DE ASOCIACIONES EMPRESARIALES, PROFESIONALES Y DE EMPLEADORES',
  },
  { id: 69, name: 'VENTA AL POR MENOR EN PUESTOS DE VENTA Y MERCADOS' },
  { id: 70, name: 'ACTIVIDADES DEPORTIVAS' },
  { id: 71, name: 'OTRAS ACTIVIDADES DE VENTA AL POR MAYOR ESPECIALIZADA' },
  { id: 72, name: 'FABRICACION DE OTROS PRODUCTOS QUIMICOS' },
  {
    id: 73,
    name: 'VENTA AL POR MAYOR DE MATERIAS PRIMAS AGROPECUARIAS Y ANIMALES VIVOS',
  },
  { id: 74, name: 'EXTRACCION DE PIEDRA, ARENA Y ARCILLA' },
  { id: 75, name: 'ACTIVIDADES DE APOYO AL TRANSPORTE' },
  { id: 76, name: 'TERMINACION Y ACABADO DE EDIFICIOS' },
  { id: 77, name: 'ACTIVIDADES DE LIMPIEZA' },
  {
    id: 78,
    name: 'FABRICACION DE PARTES, PIEZAS Y ACCESORIOS PARA VEHICULOS AUTOMOTORES',
  },
  { id: 79, name: 'ELABORACION DE BEBIDAS ALCOHOLICAS Y NO ALCOHOLICAS' },
  {
    id: 80,
    name: 'ELABORACION DE PRODUCTOS DE MOLINERIA, ALMIDONES Y PRODUCTOS DERIVADOS DEL ALMIDON',
  },
  {
    id: 81,
    name: 'FABRICACION DE MOTORES, GENERADORES Y TRANSFORMADORES ELECTRICOS, APARATOS DE DISTRIBUCION Y CONTROL',
  },
  {
    id: 82,
    name: 'CURTIDO Y ADOBO DE CUEROS; FABRICACION PRODUCTOS DE CUERO; ADOBO Y TEÑIDO DE PIELES',
  },
  { id: 83, name: 'ALQUILER Y ARRENDAMIENTO DE VEHICULOS AUTOMOTORES' },
  { id: 84, name: 'CONSTRUCCION DE PROYECTOS DE SERVICIO PUBLICO' },
  { id: 85, name: 'OTRAS ACTIVIDADES DE ESPARCIMIENTO Y RECREATIVAS' },
  {
    id: 86,
    name: 'INSTALACIONES ELECTRICAS, DE GASFITERIA Y OTRAS INSTALACIONES PARA OBRAS DE CONSTRUCCION',
  },
  {
    id: 87,
    name: 'FABRICACION DE GAS; DISTRIBUCION DE COMBUSTIBLES GASEOSOS POR TUBERIAS',
  },
  {
    id: 88,
    name: 'VENTA AL POR MENOR DE COMBUSTIBLES PARA VEHICULOS AUTOMOTORES EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 89,
    name: 'ACTIVIDADES AUXILIARES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES',
  },
  { id: 90, name: 'SILVICULTURA Y OTRAS ACTIVIDADES FORESTALES' },
  { id: 91, name: 'FABRICACION DE MAQUINARIA DE USO ESPECIAL' },
  { id: 92, name: 'FABRICACION DE MAQUINARIA DE USO GENERAL' },
  { id: 93, name: 'DEMOLICION Y PREPARACION DEL TERRENO' },
  {
    id: 94,
    name: 'FABRICACION DE PRODUCTOS DE MADERA, CORCHO, PAJA Y MATERIALES TRENZABLES',
  },
  { id: 95, name: 'ACTIVIDADES ESPECIALIZADAS DE DISEÑO' },
  { id: 96, name: 'PROGRAMACION Y TRANSMISIONES DE TELEVISION' },
  { id: 97, name: 'FABRICACION DE COMPONENTES Y TABLEROS ELECTRONICOS' },
  { id: 98, name: 'ELABORACION DE PRODUCTOS LACTEOS' },
  {
    id: 99,
    name: 'VENTA, MANTENIMIENTO Y REPARACION DE MOTOCICLETAS Y SUS PARTES, PIEZAS Y ACCESORIOS',
  },
  {
    id: 100,
    name: 'PROCESAMIENTO DE DATOS, HOSPEDAJE Y ACTIVIDADES CONEXAS; PORTALES WEB',
  },
  { id: 101, name: 'PESCA' },
  {
    id: 102,
    name: 'FABRICACION SUSTANCIAS QUIMICAS BASICAS, ABONOS Y COMPUESTOS DE NITROGENO, PLASTICOS Y CAUCHO SINT.',
  },
  { id: 103, name: 'CONSTRUCCION DE EDIFICIOS' },
  { id: 104, name: 'OTROS TIPOS DE ENSEÑANZA' },
  {
    id: 105,
    name: 'FABRICACION DE INSTRUMENTOS Y MATERIALES MEDICOS Y ODONTOLOGICOS',
  },
  { id: 106, name: 'ELABORACION DE PIENSOS PREPARADOS PARA ANIMALES' },
  { id: 107, name: 'FABRICACION DE ARTICULOS DE DEPORTE' },
  {
    id: 108,
    name: 'VENTA AL POR MAYOR A CAMBIO DE UNA RETRIBUCION O POR CONTRATA',
  },
  {
    id: 109,
    name: 'VENTA AL POR MENOR DE EQUIPO DE INFORMACION Y DE COMUNICACIONES EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 110,
    name: 'ALQUILER Y ARRENDAMIENTO DE EFECTOS PERSONALES Y ENSERES DOMESTICOS',
  },
  { id: 111, name: 'ASERRADO Y ACEPILLADURA DE MADERA' },
  { id: 112, name: 'ACTIVIDADES DE AGENCIAS DE EMPLEO' },
  { id: 113, name: 'ACTIVIDADES ADMINISTRATIVAS Y DE APOYO DE OFICINA' },
  { id: 114, name: 'OTRAS INDUSTRIAS MANUFACTURERAS N.C.P.' },
  { id: 115, name: 'CAPTACION, TRATAMIENTO Y DISTRIBUCION DE AGUA' },
  {
    id: 116,
    name: 'ACTIVIDADES DE APOYO PARA LA EXPLOTACION DE OTRAS MINAS Y CANTERAS',
  },
  { id: 117, name: 'HILATURA, TEJEDURA Y ACABADO DE PRODUCTOS TEXTILES' },
  { id: 118, name: 'OTRAS ACTIVIDADES DE TELECOMUNICACIONES' },
  { id: 119, name: 'ACTIVIDADES VETERINARIAS' },
  { id: 120, name: 'REPRODUCCION DE GRABACIONES' },
  {
    id: 121,
    name: 'FABRICACION DE PRODUCTOS PRIMARIOS DE METALES PRECIOSOS Y OTROS METALES NO FERROSOS',
  },
  { id: 122, name: 'ACTIVIDADES DE SERVICIO DE BEBIDAS' },
  { id: 123, name: 'REPARACION DE COMPUTADORES Y EQUIPO DE COMUNICACIONES' },
  { id: 124, name: 'EXTRACCION Y PROCESAMIENTO DE COBRE' },
  { id: 125, name: 'FABRICACION DE JOYAS, BISUTERIA Y ARTICULOS CONEXOS' },
  { id: 126, name: 'EXTRACCION DE CARBON DE PIEDRA' },
  { id: 127, name: 'FABRICACION DE APARATOS DE USO DOMESTICO' },
  {
    id: 128,
    name: 'ACTIVIDADES DE AGENCIAS DE VIAJES Y OPERADORES TURISTICOS',
  },
  {
    id: 129,
    name: 'ACTIVIDADES DE ATENCION EN INSTITUCIONES PARA PERSONAS DE EDAD Y PERSONAS CON DISCAPACIDAD',
  },
  { id: 130, name: 'ACTIVIDADES DE JUEGOS DE AZAR Y APUESTAS' },
  { id: 131, name: 'ACTIVIDADES DE TELECOMUNICACIONES ALAMBRICAS' },
  { id: 132, name: 'OTRAS ACTIVIDADES DE SERVICIOS DE INFORMACION' },
  { id: 133, name: 'EXPLOTACION DE MINAS Y CANTERAS N.C.P.' },
  { id: 134, name: 'CONSTRUCCION DE BUQUES Y OTRAS EMBARCACIONES' },
  { id: 135, name: 'OTRAS ACTIVIDADES DE DOTACION DE RECURSOS HUMANOS' },
  { id: 136, name: 'ACTIVIDADES DE HOSPITALES PUBLICOS Y PRIVADOS' },
  { id: 137, name: 'CONSTRUCCION DE OTRAS OBRAS DE INGENIERIA CIVIL' },
  {
    id: 138,
    name: 'EXTRACCION DE MINERALES METALIFEROS NO FERROSOS, EXCEPTO COBRE',
  },
  { id: 139, name: 'FABRICACION DE OTROS TIPOS DE EQUIPO ELECTRICO' },
  { id: 140, name: 'CONSTRUCCION DE CARRETERAS Y LINEAS DE FERROCARRIL' },
  { id: 141, name: 'TRANSMISIONES DE RADIO' },
  { id: 142, name: 'ESTUDIOS DE MERCADO Y ENCUESTAS DE OPINION PUBLICA' },
  { id: 143, name: 'ENSAYOS Y ANALISIS TECNICOS' },
  { id: 144, name: 'ACTIVIDADES CREATIVAS, ARTISTICAS Y DE ENTRETENIMIENTO' },
  { id: 145, name: 'ACTIVIDADES POSTALES' },
  { id: 146, name: 'RECUPERACION DE MATERIALES' },
  { id: 147, name: 'FABRICACION DE PAPEL Y DE PRODUCTOS DE PAPEL' },
  { id: 148, name: 'ACTIVIDADES DE INVESTIGACION' },
  {
    id: 149,
    name: 'ELABORACION DE ACEITES Y GRASAS DE ORIGEN VEGETAL Y ANIMAL',
  },
  {
    id: 150,
    name: 'OTRAS ACTIVIDADES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES',
  },
  {
    id: 151,
    name: 'FABRICACION DE PRODUCTOS FARMACEUTICOS, SUSTANCIAS QUIMICAS MEDICINALES Y PRODUCTOS BOTANICOS',
  },
  { id: 152, name: 'ACUICULTURA' },
  { id: 153, name: 'ACTIVIDADES DE TELECOMUNICACIONES POR SATELITE' },
  {
    id: 154,
    name: 'ELABORACION Y CONSERVACION DE FRUTAS, LEGUMBRES Y HORTALIZAS',
  },
  { id: 155, name: 'ACTIVIDADES DE APOYO A LA ENSEÑANZA' },
  { id: 156, name: 'ORGANIZACION DE CONVENCIONES Y EXPOSICIONES COMERCIALES' },
  {
    id: 157,
    name: 'ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SEGUROS Y FONDOS DE PENSIONES',
  },
  { id: 158, name: 'TRANSPORTE DE PASAJEROS POR VIA AEREA' },
  {
    id: 159,
    name: 'FABRICACION DE CARROCERIAS PARA VEHICULOS AUTOMOTORES; FABRICACION DE REMOLQUES Y SEMIRREMOLQUES',
  },
  {
    id: 160,
    name: 'INVESTIGACIONES Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS NATURALES Y LA INGENIERIA',
  },
  { id: 161, name: 'FABRICACION DE EQUIPO ELECTRICO DE ILUMINACION' },
  { id: 162, name: 'OTROS SERVICIOS DE RESERVAS Y ACTIVIDADES CONEXAS' },
  {
    id: 163,
    name: 'ACTIVIDADES DE DESCONTAMINACION Y OTROS SERVICIOS DE GESTION DE DESECHOS',
  },
  { id: 164, name: 'TRANSPORTE MARITIMO Y DE CABOTAJE' },
  { id: 165, name: 'EVACUACION DE AGUAS RESIDUALES' },
  { id: 166, name: 'ACTIVIDADES DE AGENCIAS DE EMPLEO TEMPORAL' },
  { id: 167, name: 'RECOGIDA DE DESECHOS' },
  { id: 168, name: 'TRANSPORTE POR VIAS DE NAVEGACION INTERIORES' },
  {
    id: 169,
    name: 'ELABORACION Y CONSERVACION DE PESCADO, CRUSTACEOS Y MOLUSCOS',
  },
  { id: 170, name: 'INSTALACION DE MAQUINARIA Y EQUIPOS INDUSTRIALES' },
  {
    id: 171,
    name: 'ACTIVIDADES DE ORGANIZACIONES Y ORGANOS EXTRATERRITORIALES',
  },
  { id: 172, name: 'SUMINISTRO DE VAPOR Y DE AIRE ACONDICIONADO' },
  {
    id: 173,
    name: 'ACTIVIDADES DE BIBLIOTECAS, ARCHIVOS Y MUSEOS Y OTRAS ACTIVIDADES CULTURALES',
  },
  {
    id: 174,
    name: 'RECOLECCION DE PRODUCTOS FORESTALES DISTINTOS DE LA MADERA',
  },
  { id: 175, name: 'FONDOS DE PENSIONES' },
  {
    id: 176,
    name: 'ACTIVIDADES DE CAMPAMENTOS, PARQUES DE VEHICULOS DE RECREO Y PARQUES DE CARAVANAS',
  },
  {
    id: 177,
    name: 'ACTIVIDADES DE ATENCION EN INSTITUCIONES PARA PERSONAS CON DISCAPACIDAD MENTAL Y TOXICOMANOS',
  },
  { id: 178, name: 'FUNDICION DE METALES' },
  { id: 179, name: 'ACTIVIDADES DE SINDICATOS' },
  { id: 180, name: 'ACTIVIDADES DE GESTION DE FONDOS' },
  { id: 181, name: 'ACTIVIDADES DE TELECOMUNICACIONES INALAMBRICAS' },
  {
    id: 182,
    name: 'ADMINISTRACION DEL ESTADO Y APLICACION DE LA POLITICA ECONOMICA Y SOCIAL DE LA COMUNIDAD',
  },
  { id: 183, name: 'PRESTACION DE SERVICIOS A LA COMUNIDAD EN GENERAL' },
  { id: 184, name: 'ACTIVIDADES DE SERVICIOS DE SISTEMAS DE SEGURIDAD' },
  { id: 185, name: 'OTRAS ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO' },
  { id: 186, name: 'ACTIVIDADES DE SOCIEDADES DE CARTERA' },
  { id: 187, name: 'ACTIVIDADES DE OFICINAS PRINCIPALES' },
  {
    id: 188,
    name: 'GENERACION, TRANSMISION Y DISTRIBUCION DE ENERGIA ELECTRICA',
  },
  {
    id: 189,
    name: 'INVESTIGACIONES Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS SOCIALES Y LAS HUMANIDADES',
  },
  {
    id: 190,
    name: 'ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO PARA PERSONAS DE EDAD Y PERSONAS CON DISCAPACIDAD',
  },
  {
    id: 191,
    name: 'ACTIVIDADES DE PAISAJISMO Y SERVICIOS DE MANTENIMIENTO CONEXOS',
  },
  {
    id: 192,
    name: 'ARRENDAMIENTO DE PROPIEDAD INTELECTUAL Y SIMILARES, EXCEPTO OBRAS PROTEGIDAS POR DERECHOS DE AUTOR',
  },
  {
    id: 193,
    name: 'ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES DE PERSONAL DOMESTICO',
  },
  { id: 194, name: 'INTERMEDIACION MONETARIA' },
  { id: 195, name: 'TRANSPORTE DE CARGA POR VIA AEREA' },
  { id: 196, name: 'TRANSPORTE POR TUBERIAS' },
  {
    id: 197,
    name: 'ACTIVIDADES DE APOYO PARA LA EXTRACCION DE PETROLEO Y GAS NATURAL',
  },
  { id: 198, name: 'EXTRACCION DE MINERALES DE HIERRO' },
  {
    id: 199,
    name: 'FABRICACION DE EQUIPO DE MEDICION, PRUEBA, NAVEGACION Y CONTROL Y DE RELOJES',
  },
  { id: 200, name: 'ACTIVIDADES COMBINADAS DE APOYO A INSTALACIONES' },
  { id: 201, name: 'FABRICACION DE ARTICULOS DE PUNTO Y GANCHILLO' },
  { id: 202, name: 'ACTIVIDADES DE CALL-CENTER' },
  { id: 203, name: 'FABRICACION DE ARTICULOS DE PIEL' },
  { id: 204, name: 'FABRICACION DE JUEGOS Y JUGUETES' },
  { id: 205, name: 'ACTIVIDADES DE GRABACION DE SONIDO Y EDICION DE MUSICA' },
  {
    id: 206,
    name: 'FABRICACION DE EQUIPO DE IRRADIACION Y EQUIPO ELECTRONICO DE USO MEDICO Y TERAPEUTICO',
  },
  { id: 207, name: 'ACTIVIDADES DE FOTOGRAFIA' },
  {
    id: 208,
    name: 'ACTIVIDADES DE PLANES DE SEGURIDAD SOCIAL DE AFILIACION OBLIGATORIA',
  },
  { id: 209, name: 'TRATAMIENTO Y ELIMINACION DE DESECHOS' },
  { id: 210, name: 'FABRICACION DE INSTRUMENTOS OPTICOS Y EQUIPO FOTOGRAFICO' },
  { id: 211, name: 'ACTIVIDADES DE MENSAJERIA' },
  { id: 212, name: 'ACTIVIDADES DE ATENCION DE ENFERMERIA EN INSTITUCIONES' },
  { id: 213, name: 'EDICION DE PROGRAMAS INFORMATICOS' },
  { id: 214, name: 'SEGUROS' },
  { id: 215, name: 'INDUSTRIAS BASICAS DE HIERRO Y ACERO' },
  { id: 216, name: 'FABRICACION DE LOCOMOTORAS Y MATERIAL RODANTE' },
  { id: 217, name: 'FABRICACION DE PRODUCTOS DE HORNOS DE COQUE' },
  {
    id: 218,
    name: 'CAZA ORDINARIA Y MEDIANTE TRAMPAS Y ACTIVIDADES DE SERVICIOS CONEXAS',
  },
  { id: 219, name: 'REASEGUROS' },
  { id: 220, name: 'EXTRACCION DE PETROLEO CRUDO' },
  { id: 221, name: 'FABRICACION DE FIBRAS ARTIFICIALES' },
  { id: 222, name: 'FABRICACION DE APARATOS ELECTRONICOS DE CONSUMO' },
  {
    id: 223,
    name: 'FABRICACION DE AERONAVES, NAVES ESPACIALES Y MAQUINARIA CONEXA',
  },
  { id: 224, name: 'FABRICACION DE EQUIPO DE COMUNICACIONES' },
  { id: 225, name: 'FABRICACION DE PRODUCTOS DE LA REFINACION DEL PETROLEO' },
  { id: 226, name: 'FABRICACION DE PILAS, BATERIAS Y ACUMULADORES' },
  { id: 227, name: 'TRANSPORTE POR FERROCARRIL' },
  { id: 228, name: 'FABRICACION DE INSTRUMENTOS MUSICALES' },
  { id: 229, name: 'FABRICACION DE CABLES Y DISPOSITIVOS DE CABLEADO' },
  { id: 230, name: 'ELABORACION DE PRODUCTOS DE TABACO' },
  { id: 231, name: 'FABRICACION DE VEHICULOS AUTOMOTORES' },
  { id: 232, name: 'EXTRACCION DE GAS NATURAL' },
  { id: 233, name: 'FABRICACION DE SOPORTES MAGNETICOS Y OPTICOS' },
];

export const economicActivities = [
  { id: 1, name: 'OTRAS ACTIVIDADES DE ATENCION EN INSTITUCIONES' },
  { id: 2, name: 'TRANSPORTE DE CARGA POR CARRETERA' },
  { id: 3, name: 'FABRICACION DE PRODUCTOS METALICOS PARA USO ESTRUCTURAL' },
  { id: 4, name: 'ACTIVIDADES DE RESTAURANTES Y DE SERVICIO MOVIL DE COMIDAS' },
  { id: 5, name: 'CONSEJO DE ADMINISTRACION DE EDIFICIOS Y CONDOMINIOS' },
  { id: 6, name: 'ACTIVIDADES DE OTRAS ASOCIACIONES N.C.P.' },
  {
    id: 7,
    name: 'VENTA AL POR MENOR DE BEBIDAS ALCOHOLICAS Y NO ALCOHOLICAS EN COMERCIOS ESPECIALIZADOS (BOTILLERIAS)',
  },
  {
    id: 8,
    name: 'VENTA AL POR MENOR DE PRENDAS Y ACCESORIOS DE VESTIR EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 9, name: 'FABRICACION DE MUEBLES PRINCIPALMENTE DE MADERA' },
  { id: 10, name: 'OTRAS ACTIVIDADES DE SERVICIOS PERSONALES N.C.P.' },
  { id: 11, name: 'COMPRA, VENTA Y ALQUILER (EXCEPTO AMOBLADOS) DE INMUEBLES' },
  { id: 12, name: 'SERVICIOS DE SEGURIDAD PRIVADA PRESTADOS POR EMPRESAS' },
  { id: 13, name: 'MANTENIMIENTO Y REPARACION DE VEHICULOS AUTOMOTORES' },
  {
    id: 14,
    name: 'SERVICIOS DE ARQUITECTURA (DISEÑO DE EDIFICIOS, DIBUJO DE PLANOS DE CONSTRUCCION, ENTRE OTROS)',
  },
  {
    id: 15,
    name: 'ACTIVIDADES DE PRODUCCION DE PELICULAS CINEMATOGRAFICAS, VIDEOS Y PROGRAMAS DE TELEVISION',
  },
  {
    id: 16,
    name: 'LAVADO Y LIMPIEZA, INCLUIDA LA LIMPIEZA EN SECO, DE PRODUCTOS TEXTILES Y DE PIEL',
  },
  {
    id: 17,
    name: 'VENTA AL POR MENOR DE ALIMENTO Y ACCESORIOS PARA MASCOTAS EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 18,
    name: 'VENTA AL POR MENOR EN COMERCIOS DE VESTUARIO Y PRODUCTOS PARA EL HOGAR (GRANDES TIENDAS)',
  },
  { id: 19, name: 'PELUQUERIA Y OTROS TRATAMIENTOS DE BELLEZA' },
  {
    id: 20,
    name: 'OTRAS ACTIVIDADES DE SERVICIOS DE APOYO A LAS EMPRESAS N.C.P.',
  },
  { id: 21, name: 'VENTA AL POR MENOR DE CALZADO EN COMERCIOS ESPECIALIZADOS' },
  { id: 22, name: 'CRIA DE GANADO BOVINO PARA LA PRODUCCION LECHERA' },
  {
    id: 23,
    name: 'SERVICIOS DE SEGURIDAD PRIVADA PRESTADOS POR INDEPENDIENTES',
  },
  {
    id: 24,
    name: 'VENTA AL POR MENOR DE ARTICULOS DE PAPELERIA Y ESCRITORIO EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 25,
    name: 'ACTIVIDADES DE RESIDENCIALES PARA ESTUDIANTES Y TRABAJADORES',
  },
  { id: 26, name: 'FABRICACION DE VIDRIO PLANO' },
  { id: 27, name: 'EDICION DE LIBROS' },
  {
    id: 28,
    name: 'ACTIVIDADES DE CONSULTORIA DE INFORMATICA Y DE GESTION DE INSTALACIONES INFORMATICAS',
  },
  {
    id: 29,
    name: 'VENTA AL POR MENOR DE ARTICULOS DE FERRETERIA Y MATERIALES DE CONSTRUCCION',
  },
  {
    id: 30,
    name: 'VENTA AL POR MENOR DE PINTURAS, BARNICES Y LACAS EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 31,
    name: 'ACTIVIDADES DE CONTABILIDAD, TENEDURIA DE LIBROS Y AUDITORIA, CONSULTORIA FISCAL',
  },
  {
    id: 32,
    name: 'CULTIVO DE FRUTOS OLEAGINOSOS (INCLUYE EL CULTIVO DE ACEITUNAS)',
  },
  {
    id: 33,
    name: 'CENTROS MEDICOS PRIVADOS (ESTABLECIMIENTOS DE ATENCION AMBULATORIA)',
  },
  { id: 34, name: 'FABRICACION DE PRODUCTOS DE PLASTICO' },
  { id: 35, name: 'VENTA AL POR MAYOR NO ESPECIALIZADA' },
  { id: 36, name: 'FABRICACION DE OTROS PRODUCTOS DE CAUCHO' },
  { id: 37, name: 'FABRICACION DE OTROS PRODUCTOS ELABORADOS DE METAL N.C.P.' },
  {
    id: 38,
    name: 'ENSEÑANZA PRIMARIA, SECUNDARIA CIENTIFICO HUMANISTA Y TECNICO PROFESIONAL PRIVADA',
  },
  {
    id: 39,
    name: 'ENSEÑANZA PRIMARIA, SECUNDARIA CIENTIFICO HUMANISTA Y TECNICO PROFESIONAL PUBLICA',
  },
  { id: 40, name: 'FABRICACION DE OTROS TIPOS DE EQUIPO DE TRANSPORTE N.C.P.' },
  {
    id: 41,
    name: 'SUMINISTRO INDUSTRIAL DE COMIDAS POR ENCARGO, CONCESION DE SERVICIOS DE ALIMENTACION',
  },
  {
    id: 42,
    name: 'VENTA AL POR MAYOR DE OTROS TIPOS DE MAQUINARIA Y EQUIPO N.C.P.',
  },
  {
    id: 43,
    name: 'VENTA AL POR MENOR DE RECUERDOS, ARTESANIAS Y ARTICULOS RELIGIOSOS EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 44,
    name: 'FONDOS Y SOCIEDADES DE INVERSION Y ENTIDADES FINANCIERAS SIMILARES',
  },
  { id: 45, name: 'CULTIVO DE OTROS FRUTOS Y NUECES DE ARBOLES Y ARBUSTOS' },
  {
    id: 46,
    name: 'CULTIVO DE PRODUCTOS AGRICOLAS EN COMBINACION CON LA CRIA DE ANIMALES (EXPLOTACION MIXTA)',
  },
  {
    id: 47,
    name: 'VENTA AL POR MENOR DE APARATOS ELECTRICOS, TEXTILES PARA EL HOGAR Y OTROS ENSERES DOMESTICOS N.C.P.',
  },
  {
    id: 48,
    name: 'ALQUILER DE OTROS TIPOS DE MAQUINARIAS Y EQUIPOS SIN OPERARIO N.C.P.',
  },
  {
    id: 49,
    name: 'ALQUILER DE BIENES INMUEBLES AMOBLADOS O CON EQUIPOS Y MAQUINARIAS',
  },
  { id: 50, name: 'EXTRACCION DE MADERA' },
  {
    id: 51,
    name: 'FABRICACION DE PRENDAS DE VESTIR DE MATERIALES TEXTILES Y SIMILARES',
  },
  { id: 52, name: 'REPARACION DE CALZADO Y DE ARTICULOS DE CUERO' },
  {
    id: 53,
    name: 'VENTA AL POR MENOR DE JUEGOS Y JUGUETES EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 54,
    name: 'VENTA AL POR MENOR DE ALIMENTOS EN COMERCIOS ESPECIALIZADOS (ALMACENES PEQUEÑOS Y MINIMARKET)',
  },
  { id: 55, name: 'CULTIVO DE TRIGO' },
  {
    id: 56,
    name: 'OTRAS ACTIVIDADES PROFESIONALES, CIENTIFICAS Y TECNICAS N.C.P.',
  },
  { id: 57, name: 'ACTIVIDADES DE SERVICIOS RELACIONADAS CON LA IMPRESION' },
  {
    id: 58,
    name: 'VENTA AL POR MENOR DE OTROS PRODUCTOS EN COMERCIOS ESPECIALIZADOS N.C.P.',
  },
  { id: 59, name: 'TRANSPORTE DE PASAJEROS EN BUSES INTERURBANOS' },
  {
    id: 60,
    name: 'VENTA AL POR MENOR EN COMERCIOS DE ALIMENTOS, BEBIDAS O TABACO (SUPERMERCADOS E HIPERMERCADOS)',
  },
  { id: 61, name: 'CRIA DE OTROS ANIMALES N.C.P.' },
  { id: 62, name: 'CULTIVO DE POROTOS' },
  { id: 63, name: 'SERVICIOS DE PUBLICIDAD PRESTADOS POR EMPRESAS' },
  {
    id: 64,
    name: 'VENTA AL POR MAYOR DE HUEVOS, LACTEOS, ABARROTES Y DE OTROS ALIMENTOS N.C.P.',
  },
  {
    id: 65,
    name: 'VENTA AL POR MENOR POR CORREO, POR INTERNET Y VIA TELEFONICA',
  },
  { id: 66, name: 'CULTIVO DE FRUTAS DE PEPITA Y DE HUESO' },
  { id: 67, name: 'CULTIVO DE PAPAS' },
  {
    id: 68,
    name: 'REPARACION DE OTRO TIPO DE MAQUINARIA Y EQUIPOS INDUSTRIALES N.C.P.',
  },
  { id: 69, name: 'CULTIVO DE HORTALIZAS Y MELONES' },
  { id: 70, name: 'CULTIVO DE FLORES' },
  { id: 71, name: 'ELABORACION DE PRODUCTOS DE PANADERIA Y PASTELERIA' },
  { id: 72, name: 'ELABORACION Y CONSERVACION DE CARNE Y PRODUCTOS CARNICOS' },
  {
    id: 73,
    name: 'OTRAS ACTIVIDADES DE VENTA POR MENOR NO REALIZADAS EN COMERCIOS, PUESTOS DE VENTA O MERCADOS N.C.P.',
  },
  { id: 74, name: 'ENSEÑANZA PREESCOLAR PRIVADA' },
  {
    id: 75,
    name: 'CULTIVOS FORRAJEROS EN PRADERAS MEJORADAS O SEMBRADAS, CULTIVOS SUPLEMENTARIOS FORRAJEROS',
  },
  {
    id: 76,
    name: 'ELABORACION DE MACARRONES, FIDEOS, ALCUZCUZ Y PRODUCTOS FARINACEOS SIMILARES',
  },
  { id: 77, name: 'ACTIVIDADES DE HOTELES' },
  { id: 78, name: 'CULTIVO DE OTRAS PLANTAS PERENNES' },
  { id: 79, name: 'OTRAS ACTIVIDADES DE IMPRESION N.C.P.' },
  {
    id: 80,
    name: 'VENTA AL POR MENOR DE VEHICULOS AUTOMOTORES NUEVOS O USADOS (INCLUYE COMPRAVENTA)',
  },
  {
    id: 81,
    name: 'VENTA DE PARTES, PIEZAS Y ACCESORIOS PARA VEHICULOS AUTOMOTORES',
  },
  { id: 82, name: 'FABRICACION DE MATERIALES DE CONSTRUCCION DE ARCILLA' },
  { id: 83, name: 'FABRICACION DE CUERDAS, CORDELES, BRAMANTES Y REDES' },
  { id: 84, name: 'IMPRESION DE LIBROS' },
  { id: 85, name: 'VENTA AL POR MAYOR DE TABACO' },
  { id: 86, name: 'VENTA AL POR MAYOR DE FRUTAS Y VERDURAS' },
  {
    id: 87,
    name: 'SERVICIOS PRESTADOS DE FORMA INDEPENDIENTE POR OTROS PROFESIONALES DE LA SALUD',
  },
  { id: 88, name: 'ACTIVIDADES DE LABORATORIOS CLINICOS Y BANCOS DE SANGRE' },
  { id: 89, name: 'ACTIVIDADES DE CONSULTORIA DE GESTION' },
  { id: 90, name: 'CULTIVO DE UVA PARA MESA' },
  {
    id: 91,
    name: 'VENTA AL POR MENOR DE OTROS ARTICULOS DE SEGUNDA MANO EN COMERCIOS N.C.P.',
  },
  {
    id: 92,
    name: 'OTROS SERVICIOS DE ATENCION DE LA SALUD HUMANA PRESTADOS POR EMPRESAS',
  },
  { id: 93, name: 'OTROS SERVICIOS DE ALMACENAMIENTO Y DEPOSITO N.C.P.' },
  {
    id: 94,
    name: 'VENTA AL POR MAYOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO',
  },
  { id: 95, name: 'SERVICIOS DE ASESORAMIENTO Y REPRESENTACION JURIDICA' },
  { id: 96, name: 'ACTIVIDADES DE APOYO A LA AGRICULTURA' },
  {
    id: 97,
    name: 'VENTA AL POR MENOR DE GRABACIONES DE MUSICA Y DE VIDEO EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 98,
    name: 'ACTIVIDADES INMOBILIARIAS REALIZADAS A CAMBIO DE UNA RETRIBUCION O POR CONTRATA',
  },
  { id: 99, name: 'OTRAS ACTIVIDADES ESPECIALIZADAS DE CONSTRUCCION' },
  { id: 100, name: 'FABRICACION DE CALZADO' },
  {
    id: 101,
    name: 'FABRICACION DE COLCHONES, FABRICACION DE OTROS MUEBLES N.C.P.',
  },
  {
    id: 102,
    name: 'OTRAS ACTIVIDADES DE TRANSPORTE DE PASAJEROS POR VIA TERRESTRE N.C.P.',
  },
  { id: 103, name: 'OTROS SERVICIOS DE APOYO A LA SILVICULTURA N.C.P.' },
  {
    id: 104,
    name: 'CULTIVO DE PLANTAS VIVAS INCLUIDA LA PRODUCCION EN VIVEROS (EXCEPTO VIVEROS FORESTALES)',
  },
  {
    id: 105,
    name: 'SERVICIOS DE CORTA DE MADERA A CAMBIO DE UNA RETRIBUCION O POR CONTRATA',
  },
  { id: 106, name: 'ENSEÑANZA SUPERIOR EN INSTITUTOS PROFESIONALES' },
  {
    id: 107,
    name: 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE HUEVOS, CONFITES Y PRODUCTOS ALIMENTICIOS N.C.P.',
  },
  {
    id: 108,
    name: 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE CARNE Y PRODUCTOS CARNICOS',
  },
  { id: 109, name: 'FABRICACION DE COMPUTADORES Y EQUIPO PERIFERICO' },
  { id: 110, name: 'CULTIVO DE UVA DESTINADA A LA PRODUCCION DE VINO' },
  {
    id: 111,
    name: 'SERVICIOS PROFESIONALES DE INGENIERIA Y ACTIVIDADES CONEXAS DE CONSULTORIA TECNICA',
  },
  { id: 112, name: 'ACTIVIDADES DE ASOCIACIONES PROFESIONALES' },
  {
    id: 113,
    name: 'VENTA AL POR MENOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO EN PUESTOS DE VENTA Y MERCADOS',
  },
  { id: 114, name: 'OTRAS ACTIVIDADES DEPORTIVAS N.C.P.' },
  { id: 115, name: 'VENTA AL POR MAYOR DE PRODUCTOS QUIMICOS' },
  { id: 116, name: 'FABRICACION DE OTROS PRODUCTOS QUIMICOS N.C.P.' },
  { id: 117, name: 'VENTA AL POR MAYOR DE MATERIAS PRIMAS AGRICOLAS' },
  { id: 118, name: 'EXTRACCION DE PIEDRA, ARENA Y ARCILLA' },
  {
    id: 119,
    name: 'CRIA DE GANADO BOVINO PARA LA PRODUCCION DE CARNE O COMO GANADO REPRODUCTOR',
  },
  {
    id: 119,
    name: 'EXPLOTACION DE ESTACIONAMIENTOS DE VEHICULOS AUTOMOTORES Y PARQUIMETROS',
  },
  {
    id: 120,
    name: 'ALQUILER DE MAQUINARIA Y EQUIPO AGROPECUARIO, FORESTAL, DE CONSTRUCCION E ING. CIVIL, SIN OPERARIOS',
  },
  {
    id: 121,
    name: 'ALQUILER DE EQUIPOS DE TRANSPORTE SIN OPERARIO, EXCEPTO VEHICULOS AUTOMOTORES',
  },
  {
    id: 122,
    name: 'VENTA AL POR MENOR DE TELAS, LANAS, HILOS Y SIMILARES EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 123,
    name: 'VENTA AL POR MENOR DE CARTERAS, MALETAS Y OTROS ACCESORIOS DE VIAJE EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 124,
    name: 'VENTA AL POR MENOR DE CARBON, LEÑA Y OTROS COMBUSTIBLES DE USO DOMESTICO EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 125,
    name: 'FABRICACION DE JABONES Y DETERGENTES, PREPARADOS PARA LIMPIAR, PERFUMES Y PREPARADOS DE TOCADOR',
  },
  {
    id: 126,
    name: 'VENTA AL POR MAYOR DE BEBIDAS ALCOHOLICAS Y NO ALCOHOLICAS',
  },
  { id: 127, name: 'TERMINACION Y ACABADO DE EDIFICIOS' },
  {
    id: 128,
    name: 'VENTA AL POR MAYOR DE MATERIALES DE CONSTRUCCION, ARTICULOS DE FERRETERIA, GASFITERIA Y CALEFACCION',
  },
  { id: 129, name: 'LIMPIEZA GENERAL DE EDIFICIOS' },
  {
    id: 130,
    name: 'FABRICACION DE PARTES, PIEZAS Y ACCESORIOS PARA VEHICULOS AUTOMOTORES',
  },
  {
    id: 131,
    name: 'ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE TERRESTRE N.C.P.',
  },
  { id: 132, name: 'ENSEÑANZA SUPERIOR EN CENTROS DE FORMACION TECNICA' },
  {
    id: 133,
    name: 'VENTA AL POR MENOR DE FLORES, PLANTAS, ARBOLES, SEMILLAS Y ABONOS EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 134,
    name: 'DESTILACION, RECTIFICACION Y MEZCLAS DE BEBIDAS ALCOHOLICAS, EXCEPTO PISCO',
  },
  {
    id: 135,
    name: 'MOLIENDA DE TRIGO: PRODUCCION DE HARINA, SEMOLA Y GRANULOS',
  },
  { id: 136, name: 'VENTA AL POR MAYOR DE CARNE Y PRODUCTOS CARNICOS' },
  {
    id: 137,
    name: 'FABRICACION DE MOTORES, GENERADORES Y TRANSFORMADORES ELECTRICOS, APARATOS DE DISTRIBUCION Y CONTROL',
  },
  {
    id: 138,
    name: 'REPARACION DE OTROS EFECTOS PERSONALES Y ENSERES DOMESTICOS',
  },
  {
    id: 139,
    name: 'EMPRESAS DE SERVICIOS DE INGENIERIA Y ACTIVIDADES CONEXAS DE CONSULTORIA TECNICA',
  },
  {
    id: 140,
    name: 'VENTA AL POR MENOR DE INSTRUMENTOS MUSICALES EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 141, name: 'VENTA AL POR MAYOR DE OTROS ENSERES DOMESTICOS N.C.P.' },
  {
    id: 142,
    name: 'FABRICACION DE MALETAS, BOLSOS Y ARTICULOS SIMILARES, ARTICULOS DE TALABARTERIA Y GUARNICIONERIA',
  },
  { id: 143, name: 'ALQUILER DE VEHICULOS AUTOMOTORES SIN CHOFER' },
  {
    id: 144,
    name: 'SERVICIOS DE FORESTACION A CAMBIO DE UNA RETRIBUCION O POR CONTRATA',
  },
  { id: 145, name: 'CONSTRUCCION DE PROYECTOS DE SERVICIO PUBLICO' },
  {
    id: 146,
    name: 'TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS VIA LOCOMOCION COLECTIVA',
  },
  {
    id: 147,
    name: 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE PRODUCTOS DE PANADERIA Y PASTELERIA',
  },
  {
    id: 148,
    name: 'GESTION DE SALAS DE POOL, GESTION (EXPLOTACION) DE JUEGOS ELECTRONICOS',
  },
  { id: 149, name: 'INSTALACIONES ELECTRICAS' },
  { id: 150, name: 'APICULTURA' },
  {
    id: 151,
    name: 'CENTROS DE ATENCION ODONTOLOGICA PRIVADOS (ESTABLECIMIENTOS DE ATENCION AMBULATORIA)',
  },
  {
    id: 152,
    name: 'FABRICACION DE GAS, DISTRIBUCION DE COMBUSTIBLES GASEOSOS POR TUBERIA, EXCEPTO REGASIFICACION DE GNL',
  },
  {
    id: 153,
    name: 'VENTA AL POR MENOR DE COMBUSTIBLES PARA VEHICULOS AUTOMOTORES EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 154,
    name: 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE FRUTAS Y VERDURAS (VERDULERIAS)',
  },
  { id: 155, name: 'CRIA DE OVEJAS (OVINOS)' },
  {
    id: 156,
    name: 'VENTA AL POR MENOR DE MUEBLES Y COLCHONES EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 157,
    name: 'VENTA AL POR MENOR DE ARTICULOS DE JOYERIA, BISUTERIA Y RELOJERIA EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 158, name: 'VENTA AL POR MAYOR DE METALES Y MINERALES METALIFEROS' },
  {
    id: 159,
    name: 'EMPRESAS DE ASESORIA Y CONSULTORIA EN INVERSION FINANCIERA, SOCIEDADES DE APOYO AL GIRO',
  },
  {
    id: 160,
    name: 'OTRAS ACTIVIDADES DE VENTA AL POR MENOR EN COMERCIOS NO ESPECIALIZADOS N.C.P.',
  },
  { id: 161, name: 'EXPLOTACION DE VIVEROS FORESTALES' },
  { id: 162, name: 'OTRAS ACTIVIDADES DE EDICION' },
  {
    id: 163,
    name: 'CULTIVO DE UVA DESTINADA A LA PRODUCCION DE PISCO Y AGUARDIENTE',
  },
  {
    id: 164,
    name: 'FABRICACION DE MAQUINARIA PARA LA ELABORACION DE ALIMENTOS, BEBIDAS Y TABACO',
  },
  { id: 165, name: 'FABRICACION DE OTROS TIPOS DE MAQUINARIA DE USO GENERAL' },
  { id: 166, name: 'OTRAS ACTIVIDADES DE ESPARCIMIENTO Y RECREATIVAS N.C.P.' },
  { id: 167, name: 'PREPARACION DEL TERRENO' },
  { id: 168, name: 'ELABORACION DE OTROS PRODUCTOS ALIMENTICIOS N.C.P.' },
  { id: 169, name: 'ELABORACION DE OTROS PRODUCTOS DE MOLINERIA N.C.P.' },
  {
    id: 170,
    name: 'ACTIVIDADES DE PARQUES DE ATRACCIONES Y PARQUES TEMATICOS',
  },
  {
    id: 171,
    name: 'INSTALACIONES DE GASFITERIA, CALEFACCION Y AIRE ACONDICIONADO',
  },
  {
    id: 172,
    name: 'FABRICACION DE OTROS PRODUCTOS DE MADERA, DE ARTICULOS DE CORCHO, PAJA Y MATERIALES TRENZABLES',
  },
  {
    id: 173,
    name: 'VENTA AL POR MENOR DE ARTICULOS DE PERFUMERIA, DE TOCADOR Y COSMETICOS EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 174, name: 'ACTIVIDADES DE DISEÑO Y DECORACION DE INTERIORES' },
  { id: 175, name: 'PROGRAMACION Y TRANSMISIONES DE TELEVISION' },
  { id: 176, name: 'FABRICACION DE COMPONENTES Y TABLEROS ELECTRONICOS' },
  { id: 177, name: 'ELABORACION DE PRODUCTOS LACTEOS' },
  { id: 178, name: 'VENTA DE MOTOCICLETAS' },
  {
    id: 179,
    name: 'FABRICACION DE CUBIERTAS Y CAMARAS DE CAUCHO, RECAUCHUTADO Y RENOVACION DE CUBIERTAS DE CAUCHO',
  },
  { id: 180, name: 'PROCESAMIENTO DE DATOS, HOSPEDAJE Y ACTIVIDADES CONEXAS' },
  { id: 181, name: 'SERVICIOS RELACIONADOS CON LA PESCA MARITIMA' },
  {
    id: 182,
    name: 'FABRICACION DE PLASTICOS Y CAUCHO SINTETICO EN FORMAS PRIMARIAS',
  },
  {
    id: 183,
    name: 'VENTA AL POR MENOR DE PRODUCTOS FARMACEUTICOS Y MEDICINALES EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 184, name: 'ELABORACION DE VINOS' },
  { id: 185, name: 'CULTIVO DE SEMILLAS DE HORTALIZAS' },
  {
    id: 186,
    name: 'VENTA AL POR MAYOR DE MAQUINARIA PARA LA INDUSTRIA TEXTIL, DEL CUERO Y DEL CALZADO',
  },
  { id: 187, name: 'CONSTRUCCION DE EDIFICIOS PARA USO RESIDENCIAL' },
  {
    id: 188,
    name: 'FABRICACION DE PARTES Y PIEZAS DE CARPINTERIA PARA EDIFICIOS Y CONSTRUCCIONES',
  },
  { id: 189, name: 'OTROS TIPOS DE ENSEÑANZA N.C.P.' },
  {
    id: 190,
    name: 'VENTA AL POR MAYOR DE MADERA EN BRUTO Y PRODUCTOS PRIMARIOS DE LA ELABORACION DE MADERA',
  },
  { id: 191, name: 'PESCA MARITIMA INDUSTRIAL, EXCEPTO DE BARCOS FACTORIA' },
  {
    id: 192,
    name: 'VENTA AL POR MENOR DE OTROS ARTICULOS Y EQUIPOS DE DEPORTE N.C.P.',
  },
  { id: 193, name: 'ACTIVIDADES DE LABORATORIOS DENTALES' },
  { id: 194, name: 'ELABORACION DE PIENSOS PREPARADOS PARA ANIMALES' },
  { id: 195, name: 'FABRICACION DE ARTICULOS DE DEPORTE' },
  { id: 196, name: 'CORRETAJE AL POR MAYOR DE PRODUCTOS AGRICOLAS' },
  { id: 197, name: 'OTRAS ACTIVIDADES DE ALOJAMIENTO N.C.P.' },
  { id: 198, name: 'PESCA MARITIMA ARTESANAL' },
  { id: 199, name: 'REPARACION DE MAQUINARIA AGROPECUARIA Y FORESTAL' },
  { id: 200, name: 'SERVICIOS FUNERARIOS' },
  {
    id: 201,
    name: 'VENTA AL POR MENOR DE COMPUTADORES, EQUIPO PERIFERICO, PROGRAMAS INFORMATICOS Y EQUIPO DE TELECOM.',
  },
  { id: 202, name: 'SERVICIOS DE MEDICOS PRESTADOS DE FORMA INDEPENDIENTE' },
  {
    id: 203,
    name: 'OTRAS ACTIVIDADES DE TECNOLOGIA DE LA INFORMACION Y DE SERVICIOS INFORMATICOS',
  },
  {
    id: 204,
    name: 'ALQUILER Y ARRENDAMIENTO DE EQUIPO RECREATIVO Y DEPORTIVO',
  },
  { id: 205, name: 'TRATAMIENTO Y REVESTIMIENTO DE METALES, MAQUINADO' },
  { id: 206, name: 'FABRICACION DE PRODUCTOS DE VIDRIO N.C.P.' },
  {
    id: 207,
    name: 'SUMINISTRO DE COMIDAS POR ENCARGO (SERVICIOS DE BANQUETERIA)',
  },
  { id: 208, name: 'ASERRADO Y ACEPILLADURA DE MADERA' },
  { id: 209, name: 'ACTIVIDADES DE AGENCIAS DE EMPLEO' },
  {
    id: 210,
    name: 'VENTA AL POR MENOR DE GAS LICUADO EN BOMBONAS (CILINDROS) EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 211,
    name: 'FOTOCOPIADO, PREPARACION DE DOCUMENTOS Y OTRAS ACTIVIDADES ESPECIALIZADAS DE APOYO DE OFICINA',
  },
  {
    id: 212,
    name: 'REPARACION DE OTROS EQUIPOS ELECTRONICOS Y OPTICOS N.C.P.',
  },
  { id: 213, name: 'OTRAS INDUSTRIAS MANUFACTURERAS N.C.P.' },
  { id: 214, name: 'SERVICIO DE LAVADO DE VEHICULOS AUTOMOTORES' },
  { id: 215, name: 'CAPTACION, TRATAMIENTO Y DISTRIBUCION DE AGUA' },
  {
    id: 216,
    name: 'VENTA AL POR MAYOR DE ARTICULOS DE PAPELERIA Y ESCRITORIO',
  },
  { id: 217, name: 'VENTA AL POR MENOR DE ANTIGÜEDADES EN COMERCIOS' },
  {
    id: 218,
    name: 'SERVICIOS DE ODONTOLOGOS PRESTADOS DE FORMA INDEPENDIENTE',
  },
  {
    id: 219,
    name: 'VENTA AL POR MAYOR DE ARTICULOS ELECTRICOS Y ELECTRONICOS PARA EL HOGAR',
  },
  {
    id: 220,
    name: 'DESRATIZACION, DESINFECCION Y EXTERMINIO DE PLAGAS NO AGRICOLAS',
  },
  { id: 221, name: 'VENTA AL POR MAYOR DE DESECHOS METALICOS (CHATARRA)' },
  {
    id: 222,
    name: 'ACTIVIDADES DE APOYO PARA LA EXPLOTACION DE OTRAS MINAS Y CANTERAS PRESTADOS POR EMPRESAS',
  },
  { id: 223, name: 'TEJEDURA DE PRODUCTOS TEXTILES' },
  {
    id: 224,
    name: 'FABRICACION DE MAQUINARIA PARA LA EXPLOTACION DE MINAS Y CANTERAS Y PARA OBRAS DE CONSTRUCCION',
  },
  {
    id: 225,
    name: 'VENTA AL POR MENOR DE OTROS PRODUCTOS EN PUESTOS DE VENTA Y MERCADOS (INCLUYE FERIAS)',
  },
  { id: 226, name: 'OTRAS ACTIVIDADES DE TELECOMUNICACIONES N.C.P.' },
  { id: 227, name: 'ACTIVIDADES DE CLINICAS VETERINARIAS' },
  { id: 228, name: 'REPRODUCCION DE GRABACIONES' },
  {
    id: 229,
    name: 'VENTA AL POR MENOR DE BICICLETAS Y SUS REPUESTOS EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 230,
    name: 'REPARACION DE APARATOS DE USO DOMESTICO, EQUIPO DOMESTICO Y DE JARDINERIA',
  },
  {
    id: 231,
    name: 'ALQUILER DE OTROS EFECTOS PERSONALES Y ENSERES DOMESTICOS (INCLUYE MOBILIARIO PARA EVENTOS)',
  },
  {
    id: 232,
    name: 'FABRICACION DE PRODUCTOS PRIMARIOS DE METALES PRECIOSOS Y DE OTROS METALES NO FERROSOS N.C.P.',
  },
  { id: 233, name: 'CENTROS DE LLAMADOS Y CENTROS DE ACCESO A INTERNET' },
  { id: 234, name: 'VENTA AL POR MAYOR DE ANIMALES VIVOS' },
  { id: 235, name: 'AGENCIAS DE ADUANAS' },
  {
    id: 236,
    name: 'ACTIVIDADES DE DISCOTECAS Y CABARET (NIGHT CLUB), CON PREDOMINIO DEL SERVICIO DE BEBIDAS',
  },
  { id: 237, name: 'VENTA AL POR MAYOR DE LIBROS' },
  { id: 238, name: 'VENTA AL POR MENOR DE ROPA USADA EN COMERCIOS' },
  {
    id: 239,
    name: 'REPARACION DE EQUIPO ELECTRICO (EXCEPTO REPARACION DE EQUIPO Y ENSERES DOMESTICOS)',
  },
  {
    id: 240,
    name: 'VENTA AL POR MENOR DE TABACO Y PRODUCTOS DE TABACO EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 241, name: 'ACTIVIDADES DE DISEÑO DE VESTUARIO' },
  {
    id: 242,
    name: 'FABRICACION DE ARTICULOS CONFECCIONADOS DE MATERIALES TEXTILES, EXCEPTO PRENDAS DE VESTIR',
  },
  {
    id: 243,
    name: 'ELABORACION DE VINAGRES, MOSTAZAS, MAYONESAS Y CONDIMENTOS EN GENERAL',
  },
  { id: 244, name: 'REPARACION DE COMPUTADORES Y EQUIPO PERIFERICO' },
  {
    id: 245,
    name: 'FABRICACION DE ARTICULOS DE CUCHILLERIA, HERRAMIENTAS DE MANO Y ARTICULOS DE FERRETERIA',
  },
  { id: 246, name: 'FABRICACION DE RECIPIENTES DE MADERA' },
  { id: 247, name: 'CRIA DE CABALLOS Y OTROS EQUINOS' },
  {
    id: 248,
    name: 'VENTA AL POR MENOR DE ALIMENTOS, BEBIDAS Y TABACO EN PUESTOS DE VENTA Y MERCADOS (INCLUYE FERIAS)',
  },
  {
    id: 249,
    name: 'VENTA AL POR MAYOR DE DESPERDICIOS, DESECHOS Y OTROS PRODUCTOS N.C.P.',
  },
  { id: 250, name: 'EXTRACCION Y PROCESAMIENTO DE COBRE' },
  { id: 251, name: 'OTRAS ACTIVIDADES ESPECIALIZADAS DE DISEÑO N.C.P.' },
  { id: 252, name: 'FABRICACION DE JOYAS Y ARTICULOS CONEXOS' },
  {
    id: 253,
    name: 'VENTA AL POR MENOR EN COMERCIOS ESPECIALIZADOS DE PESCADO, MARISCOS Y PRODUCTOS CONEXOS',
  },
  {
    id: 254,
    name: 'FUNDACIONES Y CORPORACIONES, ASOCIACIONES QUE PROMUEVEN ACTIVIDADES CULTURALES O RECREATIVAS',
  },
  { id: 255, name: 'CORRETAJE AL POR MAYOR DE GANADO' },
  {
    id: 256,
    name: 'VENTA AL POR MAYOR DE PRODUCTOS DEL MAR (PESCADOS, MARISCOS Y ALGAS)',
  },
  { id: 257, name: 'EXTRACCION DE CARBON DE PIEDRA' },
  { id: 258, name: 'FABRICACION DE APARATOS DE USO DOMESTICO' },
  { id: 259, name: 'ACTIVIDADES DE OTROS CLUBES DEPORTIVOS N.C.P.' },
  { id: 260, name: 'ACTIVIDADES DE AGENCIAS DE VIAJES' },
  {
    id: 261,
    name: 'CULTIVO DE PLANTAS CON LAS QUE SE PREPARAN BEBIDAS (INCLUYE EL CULTIVO DE CAFE, TE Y MATE)',
  },
  {
    id: 262,
    name: 'ACTIVIDADES DE ATENCION EN INSTITUCIONES PARA PERSONAS DE EDAD Y PERSONAS CON DISCAPACIDAD FISICA',
  },
  { id: 263, name: 'CULTIVO DE ARROZ' },
  { id: 264, name: 'OTRAS INSTALACIONES PARA OBRAS DE CONSTRUCCION' },
  { id: 265, name: 'SERVICIOS DE TRANSPORTE A TURISTAS' },
  { id: 266, name: 'ELABORACION DE TE, CAFE, MATE E INFUSIONES DE HIERBAS' },
  { id: 267, name: 'OTRAS ACTIVIDADES DE JUEGOS DE AZAR Y APUESTAS N.C.P.' },
  { id: 268, name: 'ACTIVIDADES DE MOTELES' },
  {
    id: 269,
    name: 'VENTA AL POR MENOR DE ARTICULOS ORTOPEDICOS EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 270, name: 'TELEFONIA FIJA' },
  { id: 271, name: 'ACTIVIDADES DE AGENCIAS DE NOTICIAS' },
  { id: 272, name: 'ACTIVIDADES POSCOSECHA' },
  {
    id: 273,
    name: 'VENTA AL POR MAYOR DE ARTICULOS DE PERFUMERIA, DE TOCADOR Y COSMETICOS',
  },
  { id: 274, name: 'EXPLOTACION DE OTRAS MINAS Y CANTERAS N.C.P.' },
  {
    id: 275,
    name: 'VENTA AL POR MENOR DE ARTICULOS OPTICOS EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 276, name: 'PRODUCCION DE AGUAS MINERALES Y OTRAS AGUAS EMBOTELLADAS' },
  { id: 277, name: 'CRIA DE AVES DE CORRAL PARA LA PRODUCCION DE HUEVOS' },
  {
    id: 278,
    name: 'CONSTRUCCION DE BUQUES, EMBARCACIONES MENORES Y ESTRUCTURAS FLOTANTES',
  },
  { id: 279, name: 'ACTIVIDADES DE PROGRAMACION INFORMATICA' },
  {
    id: 280,
    name: 'EXPLOTACION DE FRIGORIFICOS PARA ALMACENAMIENTO Y DEPOSITO',
  },
  { id: 281, name: 'ACTIVIDADES DE ENVASADO Y EMPAQUETADO' },
  {
    id: 282,
    name: 'OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SERVICIOS FINANCIEROS N.C.P.',
  },
  { id: 283, name: 'OTRAS ACTIVIDADES DE DOTACION DE RECURSOS HUMANOS' },
  { id: 284, name: 'VENTA AL POR MAYOR DE VEHICULOS AUTOMOTORES' },
  { id: 285, name: 'CRIA DE AVES DE CORRAL PARA LA PRODUCCION DE CARNE' },
  { id: 286, name: 'OTROS TIPOS DE CORRETAJES O REMATES AL POR MAYOR N.C.P.' },
  { id: 287, name: 'MANIPULACION DE LA CARGA' },
  {
    id: 288,
    name: 'SERVICIOS DE TRANSPORTE DE PASAJEROS EN TAXIS LIBRES Y RADIOTAXIS',
  },
  { id: 289, name: 'ACTIVIDADES DE HOSPITALES Y CLINICAS PRIVADAS' },
  { id: 290, name: 'CONSTRUCCION DE OTRAS OBRAS DE INGENIERIA CIVIL' },
  { id: 291, name: 'OTRAS ACTIVIDADES DE APOYO AL TRANSPORTE N.C.P.' },
  { id: 292, name: 'EXTRACCION DE ZINC Y PLOMO' },
  { id: 293, name: 'EXTRACCION DE ORO Y PLATA' },
  { id: 294, name: 'ACTIVIDADES DE CASAS DE CAMBIO Y OPERADORES DE DIVISA' },
  { id: 295, name: 'FABRICACION DE OTROS TIPOS DE EQUIPO ELECTRICO' },
  {
    id: 296,
    name: 'ACTIVIDADES DE SALONES DE MASAJES, BAÑOS TURCOS, SAUNAS, SERVICIO DE BAÑOS PUBLICOS',
  },
  {
    id: 297,
    name: 'FABRICACION DE OTRAS BOMBAS, COMPRESORES, GRIFOS Y VALVULAS',
  },
  { id: 298, name: 'CONSTRUCCION DE CARRETERAS Y LINEAS DE FERROCARRIL' },
  { id: 299, name: 'TRANSMISIONES DE RADIO' },
  { id: 300, name: 'ESTUDIOS DE MERCADO Y ENCUESTAS DE OPINION PUBLICA' },
  {
    id: 301,
    name: 'VENTA AL POR MAYOR DE COMBUSTIBLES SOLIDOS, LIQUIDOS Y GASEOSOS Y PRODUCTOS CONEXOS',
  },
  {
    id: 302,
    name: 'OTROS SERVICIOS DE ENSAYOS Y ANALISIS TECNICOS (EXCEPTO ACTIVIDADES DE PLANTAS DE REVISION TECNICA)',
  },
  {
    id: 303,
    name: 'OTRAS ACTIVIDADES DE TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS POR VIA TERRESTRE N.C.P.',
  },
  {
    id: 304,
    name: 'SERVICIOS DE PRODUCCION DE OBRAS DE TEATRO, CONCIERTOS, ESPECTACULOS DE DANZA, OTRAS PROD. ESCENICAS',
  },
  { id: 305, name: 'VENTA AL POR MENOR DE LIBROS EN COMERCIOS ESPECIALIZADOS' },
  {
    id: 306,
    name: 'VENTA AL POR MAYOR DE MAQUINARIA PARA LA ELABORACION DE ALIMENTOS, BEBIDAS Y TABACO',
  },
  { id: 307, name: 'ACTIVIDADES POSTALES' },
  {
    id: 308,
    name: 'RECUPERACION Y RECICLAMIENTO DE DESPERDICIOS Y DESECHOS METALICOS',
  },
  {
    id: 309,
    name: 'OTRAS ACTIVIDADES CREATIVAS, ARTISTICAS Y DE ENTRETENIMIENTO N.C.P.',
  },
  {
    id: 310,
    name: 'EXTRACCION DE OTROS MINERALES METALIFEROS NO FERROSOS N.C.P. (EXCEPTO ZINC, PLOMO Y MANGANESO)',
  },
  {
    id: 311,
    name: 'FABRICACION DE PAPEL Y CARTON ONDULADO Y DE ENVASES DE PAPEL Y CARTON',
  },
  { id: 312, name: 'DEMOLICION' },
  {
    id: 313,
    name: 'ACTIVIDADES DE INVESTIGACION (INCLUYE ACTIVIDADES DE INVESTIGADORES Y DETECTIVES PRIVADOS)',
  },
  {
    id: 314,
    name: 'ELABORACION DE ACEITES Y GRASAS DE ORIGEN VEGETAL Y ANIMAL (EXCEPTO ELABORACION DE MANTEQUILLA)',
  },
  { id: 315, name: 'EXPLOTACION DE TERMINALES TERRESTRES DE PASAJEROS' },
  { id: 316, name: 'VENTA AL POR MAYOR DE DIARIOS Y REVISTAS' },
  { id: 317, name: 'LEASING FINANCIERO' },
  {
    id: 318,
    name: 'ELABORACION DE CACAO, CHOCOLATE Y DE PRODUCTOS DE CONFITERIA',
  },
  { id: 319, name: 'FABRICACION DE OTROS PRODUCTOS TEXTILES N.C.P.' },
  {
    id: 320,
    name: 'ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE ACUATICO',
  },
  {
    id: 321,
    name: 'FABRICACION DE PRODUCTOS FARMACEUTICOS, SUSTANCIAS QUIMICAS MEDICINALES Y PRODUCTOS BOTANICOS',
  },
  { id: 322, name: 'CULTIVO Y CRIANZA DE PECES MARINOS' },
  { id: 323, name: 'TELEVISION DE PAGO SATELITAL' },
  {
    id: 324,
    name: 'ELABORACION Y CONSERVACION DE FRUTAS, LEGUMBRES Y HORTALIZAS',
  },
  {
    id: 325,
    name: 'OTRAS ACTIVIDADES DE LIMPIEZA DE EDIFICIOS E INSTALACIONES INDUSTRIALES N.C.P.',
  },
  {
    id: 326,
    name: 'FABRICACION DE OTROS PRODUCTOS MINERALES NO METALICOS N.C.P.',
  },
  {
    id: 327,
    name: 'VENTA AL POR MENOR DE MASCOTAS EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 328, name: 'ELABORACION DE BEBIDAS NO ALCOHOLICAS' },
  { id: 329, name: 'ACTIVIDADES DE APOYO A LA ENSEÑANZA' },
  {
    id: 330,
    name: 'ACTIVIDADES ARTISTICAS REALIZADAS POR BANDAS DE MUSICA, COMPAÑIAS DE TEATRO, CIRCENSES Y SIMILARES',
  },
  { id: 331, name: 'SERVICIOS RELACIONADOS CON LA ACUICULTURA MARINA' },
  { id: 332, name: 'CULTIVO, REPRODUCCION Y MANEJO DE ALGAS MARINAS' },
  { id: 333, name: 'ORGANIZACION DE CONVENCIONES Y EXPOSICIONES COMERCIALES' },
  { id: 334, name: 'ACTIVIDADES DE RESIDENCIALES PARA TURISTAS' },
  {
    id: 335,
    name: 'ASESORIA Y GESTION EN LA COMPRA O VENTA DE PEQUEÑAS Y MEDIANAS EMPRESAS',
  },
  {
    id: 336,
    name: 'VENTA AL POR MAYOR DE PRODUCTOS FARMACEUTICOS Y MEDICINALES',
  },
  { id: 337, name: 'PROMOCION Y ORGANIZACION DE COMPETENCIAS DEPORTIVAS' },
  { id: 338, name: 'CONSTRUCCION DE EDIFICIOS PARA USO NO RESIDENCIAL' },
  { id: 339, name: 'FABRICACION DE OTROS TIPOS DE MAQUINARIA DE USO ESPECIAL' },
  {
    id: 340,
    name: 'OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SEGUROS Y FONDOS DE PENSIONES',
  },
  { id: 341, name: 'TRANSPORTE DE PASAJEROS POR VIA AEREA' },
  {
    id: 342,
    name: 'RECUPERACION Y RECICLAMIENTO DE OTROS DESPERDICIOS Y DESECHOS N.C.P.',
  },
  { id: 343, name: 'FABRICACION DE ARTICULOS DE HORMIGON, CEMENTO Y YESO' },
  {
    id: 344,
    name: 'FABRICACION DE CARROCERIAS PARA VEHICULOS AUTOMOTORES, FABRICACION DE REMOLQUES Y SEMIRREMOLQUES',
  },
  {
    id: 345,
    name: 'INVESTIGACIONES Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS NATURALES Y LA INGENIERIA',
  },
  { id: 346, name: 'SERVICIOS DE TRANSPORTE DE TRABAJADORES' },
  { id: 347, name: 'FABRICACION DE EQUIPO ELECTRICO DE ILUMINACION' },
  {
    id: 348,
    name: 'FABRICACION DE PINTURAS, BARNICES Y PRODUCTOS DE REVESTIMIENTO, TINTAS DE IMPRENTA Y MASILLAS',
  },
  {
    id: 349,
    name: 'VENTA AL POR MAYOR DE COMPUTADORES, EQUIPO PERIFERICO Y PROGRAMAS INFORMATICOS',
  },
  {
    id: 350,
    name: 'CULTIVO DE PLANTAS AROMATICAS, MEDICINALES Y FARMACEUTICAS',
  },
  { id: 351, name: 'FABRICACION DE CEMENTO, CAL Y YESO' },
  {
    id: 352,
    name: 'OTROS SERVICIOS DE RESERVAS Y ACTIVIDADES CONEXAS (INCLUYE VENTA DE ENTRADAS PARA TEATRO, Y OTROS)',
  },
  { id: 353, name: 'FABRICACION DE MAQUINARIA AGROPECUARIA Y FORESTAL' },
  {
    id: 354,
    name: 'ACTIVIDADES DE VETERINARIOS, TECNICOS Y OTRO PERSONAL AUXILIAR, PRESTADOS DE FORMA INDEPENDIENTE',
  },
  { id: 355, name: 'OTRAS ACTIVIDADES DE SERVICIO DE BEBIDAS N.C.P.' },
  {
    id: 356,
    name: 'VENTA AL POR MAYOR DE INSTRUMENTOS CIENTIFICOS Y QUIRURGICOS',
  },
  { id: 357, name: 'FABRICACION DE ROPA DE TRABAJO' },
  { id: 358, name: 'SERVICIOS DE CEMENTERIOS' },
  {
    id: 359,
    name: 'EXPLOTACION DE MATADEROS DE BOVINOS, OVINOS, EQUINOS, CAPRINOS, PORCINOS Y CAMELIDOS',
  },
  {
    id: 360,
    name: 'ACTIVIDADES DE DESCONTAMINACION Y OTROS SERVICIOS DE GESTION DE DESECHOS',
  },
  { id: 361, name: 'ACTIVIDADES DE AGENTES Y CORREDORES DE SEGUROS' },
  { id: 362, name: 'TRANSPORTE DE PASAJEROS MARITIMO Y DE CABOTAJE' },
  {
    id: 363,
    name: 'OTROS SERVICIOS DE CORRETAJE DE VALORES Y COMMODITIES N.C.P.',
  },
  { id: 364, name: 'EVACUACION Y TRATAMIENTO DE AGUAS SERVIDAS' },
  { id: 365, name: 'VENTA AL POR MAYOR DE PRODUCTOS DE CONFITERIA' },
  {
    id: 366,
    name: 'ACTIVIDADES DE AGENCIAS DE EMPLEO TEMPORAL (INCLUYE EMPRESAS DE SERVICIOS TRANSITORIOS)',
  },
  { id: 367, name: 'RECOGIDA DE DESECHOS NO PELIGROSOS' },
  {
    id: 368,
    name: 'CULTIVO DE OTROS CEREALES (EXCEPTO TRIGO, MAIZ, AVENA Y CEBADA)',
  },
  { id: 369, name: 'PORTALES WEB' },
  { id: 370, name: 'OTRAS ACTIVIDADES DE SERVICIOS DE INFORMACION N.C.P.' },
  {
    id: 371,
    name: 'VENTA AL POR MENOR DE DIARIOS Y REVISTAS EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 372, name: 'TRANSPORTE DE CARGA POR VIAS DE NAVEGACION INTERIORES' },
  { id: 373, name: 'TRANSPORTE DE PASAJEROS VIA TAXI COLECTIVO' },
  {
    id: 374,
    name: 'TRANSPORTE DE PASAJEROS POR VIAS DE NAVEGACION INTERIORES',
  },
  {
    id: 375,
    name: 'ELABORACION Y CONSERVACION DE OTROS PESCADOS, EN PLANTAS EN TIERRA (EXCEPTO BARCOS FACTORIA)',
  },
  {
    id: 376,
    name: 'REPARACION DE MAQUINARIA METALURGICA, PARA LA MINERIA, EXTRACCION DE PETROLEO Y PARA LA CONSTRUCCION',
  },
  { id: 377, name: 'INSTALACION DE MAQUINARIA Y EQUIPOS INDUSTRIALES' },
  {
    id: 378,
    name: 'REPARACION DE EQUIPO DE MEDICION, PRUEBA, NAVEGACION Y CONTROL',
  },
  {
    id: 379,
    name: 'REPARACION DE MAQUINARIA PARA PRODUCIR TEXTILES, PRENDAS DE VESTIR, ARTICULOS DE CUERO Y CALZADO',
  },
  {
    id: 380,
    name: 'VENTA AL POR MENOR DE PRODUCTOS DE VIDRIO EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 381,
    name: 'VENTA AL POR MAYOR DE MUEBLES, EXCEPTO MUEBLES DE OFICINA',
  },
  {
    id: 382,
    name: 'FORJA, PRENSADO, ESTAMPADO Y LAMINADO DE METALES, PULVIMETALURGIA',
  },
  {
    id: 383,
    name: 'SERVICIOS DE TRADUCCION E INTERPRETACION PRESTADOS POR EMPRESAS',
  },
  { id: 384, name: 'OTRAS ACTIVIDADES DE ALOJAMIENTO PARA TURISTAS N.C.P.' },
  {
    id: 385,
    name: 'ACTIVIDADES DE POSTPRODUCCION DE PELICULAS CINEMATOGRAFICAS, VIDEOS Y PROGRAMAS DE TELEVISION',
  },
  {
    id: 386,
    name: 'FABRICACION DE INSTRUMENTOS Y MATERIALES MEDICOS, OFTALMOLOGICOS Y ODONTOLOGICOS N.C.P.',
  },
  { id: 387, name: 'VENTA DE PARTES, PIEZAS Y ACCESORIOS DE MOTOCICLETAS' },
  { id: 388, name: 'MANTENIMIENTO Y REPARACION DE MOTOCICLETAS' },
  {
    id: 389,
    name: 'SILVICULTURA Y OTRAS ACTIVIDADES FORESTALES (EXCEPTO EXPLOTACION DE VIVEROS FORESTALES)',
  },
  {
    id: 390,
    name: 'FABRICACION DE COJINETES, ENGRANAJES, TRENES DE ENGRANAJES Y PIEZAS DE TRANSMISION',
  },
  { id: 391, name: 'ACTIVIDADES DE ORGANIZACIONES RELIGIOSAS' },
  {
    id: 392,
    name: 'ACTIVIDADES DE ORGANIZACIONES Y ORGANOS EXTRATERRITORIALES',
  },
  { id: 393, name: 'SUMINISTRO DE VAPOR Y DE AIRE ACONDICIONADO' },
  {
    id: 394,
    name: 'ACTIVIDADES DE JARDINES BOTANICOS, ZOOLOGICOS Y RESERVAS NATURALES',
  },
  {
    id: 395,
    name: 'RECOLECCION DE PRODUCTOS FORESTALES DISTINTOS DE LA MADERA',
  },
  { id: 396, name: 'FONDOS DE PENSIONES' },
  { id: 397, name: 'CULTIVO DE MAIZ' },
  { id: 398, name: 'ACTIVIDADES DE CAMPING Y DE PARQUES PARA CASAS RODANTES' },
  { id: 399, name: 'CULTIVO DE TABACO' },
  {
    id: 400,
    name: 'CULTIVO DE SEMILLAS DE CEREALES, LEGUMBRES Y OLEAGINOSAS (EXCEPTO SEMILLAS DE RAPS Y MARAVILLA)',
  },
  { id: 401, name: 'CULTIVO DE CITRICOS' },
  { id: 402, name: 'TELEFONIA LARGA DISTANCIA' },
  {
    id: 403,
    name: 'ACTIVIDADES DE ATENCION EN INSTITUCIONES PARA PERSONAS CON DISCAPACIDAD MENTAL Y TOXICOMANOS',
  },
  { id: 404, name: 'CULTIVO DE CEBADA' },
  { id: 405, name: 'SERVICIOS IMPUTADOS DE ALQUILER DE VIVIENDAS' },
  { id: 406, name: 'CULTIVO DE OTROS TUBERCULOS (EXCEPTO PAPAS Y CAMOTES)' },
  { id: 407, name: 'FABRICACION DE ACCESORIOS DE VESTIR' },
  { id: 408, name: 'SERVICIOS PERSONALES DE EDUCACION' },
  { id: 409, name: 'ACTIVIDADES DE CENTROS DE MADRES' },
  { id: 410, name: 'REPARACION DE PRODUCTOS ELABORADOS DE METAL' },
  { id: 411, name: 'ACTIVIDADES DE CLUBES SOCIALES' },
  { id: 412, name: 'ELABORACION DE HIELO (EXCEPTO FABRICACION DE HIELO SECO)' },
  { id: 413, name: 'CULTIVO DE OTRAS LEGUMBRES (EXCEPTO POROTOS Y LUPINO)' },
  { id: 414, name: 'RECOLECCION Y EXTRACCION DE PRODUCTOS MARINOS' },
  { id: 415, name: 'FABRICACION DE OTROS ARTICULOS DE PAPEL Y CARTON' },
  {
    id: 416,
    name: 'VENTA AL POR MAYOR DE OTRAS MATERIAS PRIMAS AGROPECUARIAS N.C.P.',
  },
  {
    id: 417,
    name: 'EDICION DE DIARIOS, REVISTAS Y OTRAS PUBLICACIONES PERIODICAS',
  },
  {
    id: 418,
    name: 'REPARACION DE EQUIPO DE COMUNICACIONES (INCLUYE LA REPARACION TELEFONOS CELULARES)',
  },
  { id: 419, name: 'FUNDICION DE METALES NO FERROSOS' },
  { id: 420, name: 'CORTE, TALLA Y ACABADO DE LA PIEDRA' },
  {
    id: 421,
    name: 'ACTIVIDADES DE ASOCIACIONES EMPRESARIALES Y DE EMPLEADORES',
  },
  { id: 422, name: 'OTRAS ACTIVIDADES DE CONCESION DE CREDITO N.C.P.' },
  { id: 423, name: 'ACTIVIDADES DE SINDICATOS' },
  { id: 424, name: 'ADMINISTRADORAS DE FONDOS DE INVERSION' },
  { id: 425, name: 'TELEFONIA MOVIL CELULAR' },
  { id: 426, name: 'ACTIVIDADES DE LA ADMINISTRACION PUBLICA EN GENERAL' },
  { id: 427, name: 'SERVICIO DE TRANSPORTE DE VALORES EN VEHICULOS BLINDADOS' },
  {
    id: 428,
    name: 'REPARACION DE MAQUINARIA PARA LA ELABORACION DE ALIMENTOS, BEBIDAS Y TABACO',
  },
  { id: 429, name: 'CULTIVO DE LUPINO' },
  { id: 430, name: 'ELABORACION DE BEBIDAS MALTEADAS Y DE MALTA' },
  {
    id: 431,
    name: 'ELABORACION DE COMIDAS Y PLATOS PREPARADOS ENVASADOS, ROTULADOS Y CON INFORMACION NUTRICIONAL',
  },
  {
    id: 432,
    name: 'VENTA AL POR MENOR POR COMISIONISTAS (NO DEPENDIENTES DE COMERCIOS)',
  },
  { id: 433, name: 'SERVICIOS DE TRANSPORTE DE ESCOLARES' },
  { id: 434, name: 'ACTIVIDADES DE DEFENSA' },
  {
    id: 435,
    name: 'ACTIVIDADES DE SERVICIOS DE SISTEMAS DE SEGURIDAD (INCLUYE SERVICIOS DE CERRAJERIA)',
  },
  { id: 436, name: 'OTRAS ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO' },
  {
    id: 437,
    name: 'FABRICACION DE MAQUINARIA PARA LA CONFORMACION DE METALES Y DE MAQUINAS HERRAMIENTA',
  },
  {
    id: 438,
    name: 'VENTA AL POR MAYOR DE MAQUINARIA, EQUIPO Y MATERIALES AGROPECUARIOS',
  },
  { id: 439, name: 'ACTIVIDADES DE AGENCIAS DE COBRO' },
  { id: 440, name: 'GESTION DE OTRAS INSTALACIONES DEPORTIVAS N.C.P.' },
  {
    id: 441,
    name: 'ACTIVIDADES DE ARTISTAS REALIZADAS DE FORMA INDEPENDIENTE: ACTORES, MUSICOS, ESCRITORES, ENTRE OTROS',
  },
  {
    id: 442,
    name: 'REPRODUCCION Y CRIA DE MOLUSCOS, CRUSTACEOS Y GUSANOS MARINOS',
  },
  { id: 443, name: 'ACTIVIDADES DE SOCIEDADES DE CARTERA' },
  { id: 444, name: 'ACABADO DE PRODUCTOS TEXTILES' },
  {
    id: 445,
    name: 'CULTIVO DE FRUTAS TROPICALES Y SUBTROPICALES (INCLUYE EL CULTIVO DE PALTAS)',
  },
  {
    id: 446,
    name: 'FABRICACION DE PRENDAS DE VESTIR DE CUERO NATURAL O ARTIFICIAL',
  },
  { id: 447, name: 'ACTIVIDADES DE ORGANIZACIONES POLITICAS' },
  {
    id: 448,
    name: 'ACTIVIDADES DE MANTENIMIENTO DEL ORDEN PUBLICO Y DE SEGURIDAD',
  },
  { id: 449, name: 'ACTIVIDADES DE CLUBES DE FUTBOL AMATEUR Y PROFESIONAL' },
  { id: 450, name: 'ACTIVIDADES DE BIBLIOTECAS Y ARCHIVOS' },
  { id: 451, name: 'ENSEÑANZA PREESCOLAR PUBLICA' },
  { id: 452, name: 'ACTIVIDADES DE OFICINAS PRINCIPALES' },
  { id: 453, name: 'ACTIVIDADES DE APOYO A LA GANADERIA' },
  { id: 454, name: 'DISTRIBUCION DE ENERGIA ELECTRICA' },
  { id: 455, name: 'ACTIVIDADES DE OPERADORES TURISTICOS' },
  {
    id: 456,
    name: 'ACTIVIDADES COMBINADAS DE SERVICIOS ADMINISTRATIVOS DE OFICINA',
  },
  { id: 457, name: 'CULTIVO DE SEMILLAS DE FRUTAS' },
  {
    id: 458,
    name: 'INVESTIGACIONES Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS SOCIALES Y LAS HUMANIDADES',
  },
  {
    id: 459,
    name: 'ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO PARA PERSONAS DE EDAD Y PERSONAS CON DISCAPACIDAD',
  },
  {
    id: 460,
    name: 'ACTIVIDADES DE PAISAJISMO, SERVICIOS DE JARDINERIA Y SERVICIOS CONEXOS',
  },
  { id: 461, name: 'ENSEÑANZA CULTURAL' },
  {
    id: 462,
    name: 'ACTIVIDADES DE CENTROS DE SALUD MUNICIPALIZADOS (SERVICIOS DE SALUD PUBLICA)',
  },
  {
    id: 463,
    name: 'ACTIVIDADES DE MUSEOS, GESTION DE LUGARES Y EDIFICIOS HISTORICOS',
  },
  { id: 464, name: 'CULTIVO DE ESPECIAS' },
  { id: 465, name: 'ENSEÑANZA DEPORTIVA Y RECREATIVA' },
  {
    id: 466,
    name: 'ARRENDAMIENTO DE PROPIEDAD INTELECTUAL Y SIMILARES, EXCEPTO OBRAS PROTEGIDAS POR DERECHOS DE AUTOR',
  },
  { id: 467, name: 'ADMINISTRADORAS DE FONDOS PARA OTROS FINES N.C.P.' },
  {
    id: 468,
    name: 'ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES DE PERSONAL DOMESTICO',
  },
  { id: 469, name: 'ACTIVIDADES BANCARIAS' },
  { id: 470, name: 'TRANSPORTE DE CARGA MARITIMO Y DE CABOTAJE' },
  {
    id: 471,
    name: 'OTRAS ACTIVIDADES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES N.C.P.',
  },
  { id: 472, name: 'TRANSPORTE DE CARGA POR VIA AEREA' },
  { id: 473, name: 'ENSEÑANZA SUPERIOR EN UNIVERSIDADES PRIVADAS' },
  { id: 474, name: 'OTRAS ACTIVIDADES DE TRANSPORTE POR TUBERIAS N.C.P.' },
  { id: 475, name: 'OTROS SERVICIOS DE TELECOMUNICACIONES ALAMBRICAS N.C.P.' },
  { id: 476, name: 'OTROS TIPOS DE INTERMEDIACION MONETARIA N.C.P.' },
  {
    id: 477,
    name: 'GENERACION DE ENERGIA ELECTRICA EN OTRAS CENTRALES N.C.P.',
  },
  {
    id: 478,
    name: 'ACTIVIDADES DE APOYO PARA LA EXTRACCION DE PETROLEO Y GAS NATURAL PRESTADOS POR EMPRESAS',
  },
  {
    id: 479,
    name: 'ADMINISTRADORAS DE FICES (FONDOS DE INVERSION DE CAPITAL EXTRANJERO)',
  },
  {
    id: 480,
    name: 'ACTIVIDADES DE APOYO PARA LA EXPLOTACION DE OTRAS MINAS Y CANTERAS PRESTADOS POR PROFESIONALES',
  },
  {
    id: 481,
    name: 'VENTA AL POR MAYOR DE MAQUINARIA METALURGICA, PARA LA MINERIA, EXTRACCION DE PETROLEO Y CONSTRUCCION',
  },
  { id: 482, name: 'EXTRACCION DE MINERALES DE HIERRO' },
  {
    id: 483,
    name: 'ACTIVIDADES DE DISTRIBUCION DE PELICULAS CINEMATOGRAFICAS, VIDEOS Y PROGRAMAS DE TELEVISION',
  },
  { id: 484, name: 'REPARACION DE AERONAVES Y NAVES ESPACIALES' },
  {
    id: 485,
    name: 'SERVICIOS DE EXTINCION Y PREVENCION DE INCENDIOS FORESTALES',
  },
  { id: 486, name: 'ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE AEREO' },
  {
    id: 487,
    name: 'VENTA AL POR MAYOR DE EQUIPO, PARTES Y PIEZAS ELECTRONICOS Y DE TELECOMUNICACIONES',
  },
  {
    id: 488,
    name: 'FABRICACION DE EQUIPO DE MEDICION, PRUEBA, NAVEGACION Y CONTROL',
  },
  { id: 489, name: 'FINANCIERAS' },
  { id: 490, name: 'ACTIVIDADES COMBINADAS DE APOYO A INSTALACIONES' },
  { id: 491, name: 'FABRICACION DE FIBRAS DE VIDRIO' },
  {
    id: 492,
    name: 'VENTA AL POR MAYOR DE EQUIPO DE TRANSPORTE(EXCEPTO VEHICULOS AUTOMOTORES, MOTOCICLETAS Y BICICLETAS)',
  },
  {
    id: 493,
    name: 'OTROS SERVICIOS DE TELECOMUNICACIONES INALAMBRICAS N.C.P.',
  },
  { id: 494, name: 'CONSERVADOR DE BIENES RAICES' },
  { id: 495, name: 'ADMINISTRADORAS DE FONDOS PARA LA VIVIENDA' },
  { id: 496, name: 'ENSEÑANZA SUPERIOR EN UNIVERSIDADES PUBLICAS' },
  { id: 497, name: 'ACTIVIDADES DE HOSPITALES Y CLINICAS PUBLICAS' },
  {
    id: 498,
    name: 'TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS VIA METRO Y METROTREN',
  },
  { id: 499, name: 'CRIA DE CERDOS' },
  {
    id: 500,
    name: 'ACTIVIDADES DE EXHIBICION DE PELICULAS CINEMATOGRAFICAS Y CINTAS DE VIDEO',
  },
  { id: 501, name: 'EXTRACCION DE SAL' },
  { id: 502, name: 'ELABORACION Y PROCESAMIENTO DE ALGAS' },
  { id: 503, name: 'FABRICACION DE ARTICULOS DE PUNTO Y GANCHILLO' },
  {
    id: 504,
    name: 'REGULACION DE LAS ACTIVIDADES DE ORGANISMOS QUE PRESTAN SERVICIOS SANITARIOS, EDUCATIVOS, CULTURALES',
  },
  { id: 505, name: 'ELABORACION Y CONSERVACION DE SALMONIDOS' },
  {
    id: 506,
    name: 'REPARACION DE OTROS EQUIPOS DE TRANSPORTE N.C.P., EXCEPTO VEHICULOS AUTOMOTORES',
  },
  { id: 507, name: 'ENSEÑANZA PREUNIVERSITARIA' },
  { id: 508, name: 'ACTIVIDADES DE CALL-CENTER' },
  { id: 509, name: 'ACUICULTURA DE AGUA DULCE' },
  { id: 510, name: 'FABRICACION DE ARTICULOS DE PIEL' },
  { id: 511, name: 'FABRICACION DE JUEGOS Y JUGUETES' },
  { id: 512, name: 'FABRICACION DE TEJIDOS DE PUNTO Y GANCHILLO' },
  { id: 513, name: 'ACTIVIDADES DE GRABACION DE SONIDO Y EDICION DE MUSICA' },
  { id: 514, name: 'RECUPERACION Y RECICLAMIENTO DE PAPEL' },
  { id: 515, name: 'SERVICIOS DE PUBLICIDAD PRESTADOS POR PROFESIONALES' },
  {
    id: 516,
    name: 'FABRICACION DE EQUIPO DE IRRADIACION Y EQUIPO ELECTRONICO DE USO MEDICO Y TERAPEUTICO',
  },
  { id: 517, name: 'SERVICIOS Y ACTIVIDADES DE FOTOGRAFIA' },
  { id: 518, name: 'TRANSMISION DE ENERGIA ELECTRICA' },
  {
    id: 519,
    name: 'FABRICACION DE CARBON VEGETAL (EXCEPTO ACTIVADO), FABRICACION DE BRIQUETAS DE CARBON VEGETAL',
  },
  { id: 520, name: 'PREPARACION E HILATURA DE FIBRAS TEXTILES' },
  {
    id: 521,
    name: 'VENTA AL POR MENOR DE ARTICULOS DE CAZA Y PESCA EN COMERCIOS ESPECIALIZADOS',
  },
  {
    id: 522,
    name: 'SERVICIOS DE ADIESTRAMIENTO, GUARDERIA, PELUQUERIA, PASEO DE MASCOTAS (EXCEPTO ACT. VETERINARIAS)',
  },
  {
    id: 523,
    name: 'CULTIVOS DE SEMILLAS DE FLORES, CULTIVO DE SEMILLAS DE PLANTAS FORRAJERAS',
  },
  { id: 524, name: 'CURTIDO Y ADOBO DE CUEROS, ADOBO Y TEÑIDO DE PIELES' },
  {
    id: 525,
    name: 'OTROS PLANES DE SEGURIDAD SOCIAL DE AFILIACION OBLIGATORIA N.C.P.',
  },
  { id: 526, name: 'TRATAMIENTO Y ELIMINACION DE DESECHOS NO PELIGROSOS' },
  { id: 527, name: 'ELABORACION DE PISCO (INDUSTRIAS PISQUERAS)' },
  { id: 528, name: 'RADIOCOMUNICACIONES MOVILES' },
  { id: 529, name: 'RECUPERACION Y RECICLAMIENTO DE VIDRIO' },
  {
    id: 530,
    name: 'FABRICACION DE OTROS PRODUCTOS DE PORCELANA Y DE CERAMICA',
  },
  { id: 531, name: 'FABRICACION DE INSTRUMENTOS OPTICOS Y EQUIPO FOTOGRAFICO' },
  { id: 532, name: 'ACTIVIDADES DE MENSAJERIA' },
  { id: 533, name: 'RELACIONES EXTERIORES' },
  {
    id: 534,
    name: 'ELABORACION Y CONSERVACION DE CRUSTACEOS, MOLUSCOS Y OTROS PRODUCTOS ACUATICOS, EN PLANTAS EN TIERRA',
  },
  { id: 535, name: 'ACTIVIDADES DE ATENCION DE ENFERMERIA EN INSTITUCIONES' },
  { id: 536, name: 'FABRICACION DE PRODUCTOS PRIMARIOS DE COBRE' },
  { id: 537, name: 'EDICION DE PROGRAMAS INFORMATICOS' },
  {
    id: 538,
    name: 'FABRICACION DE MAQUINARIA PARA LA ELABORACION DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CUEROS',
  },
  { id: 539, name: 'ADMINISTRACION DE MERCADOS FINANCIEROS' },
  {
    id: 540,
    name: 'GENERACION DE ENERGIA ELECTRICA EN CENTRALES HIDROELECTRICAS',
  },
  { id: 541, name: 'SEGUROS DE VIDA' },
  { id: 542, name: 'CAJAS DE COMPENSACION' },
  { id: 543, name: 'PESCA DE AGUA DULCE' },
  { id: 544, name: 'ACTIVIDADES DE ISAPRES' },
  { id: 545, name: 'ACTIVIDADES DE CLASIFICADORAS DE RIESGO' },
  { id: 546, name: 'VENTA AL POR MENOR MEDIANTE MAQUINAS EXPENDEDORAS' },
  { id: 547, name: 'FABRICACION DE PRODUCTOS PRIMARIOS DE ALUMINIO' },
  {
    id: 548,
    name: 'REPARACION DE BUQUES, EMBARCACIONES MENORES Y ESTRUCTURAS FLOTANTES',
  },
  {
    id: 549,
    name: 'FABRICACION DE TANQUES, DEPOSITOS Y RECIPIENTES DE METAL N.C.P.',
  },
  {
    id: 550,
    name: 'EXTRACCION DE MINERALES PARA LA FABRICACION DE ABONOS Y PRODUCTOS QUIMICOS N.C.P.',
  },
  {
    id: 551,
    name: 'ALQUILER DE MAQUINARIA Y EQUIPO DE OFICINA, SIN OPERARIOS (SIN SERVICIO ADMINISTRATIVO)',
  },
  { id: 552, name: 'REPARACION DE OTROS TIPOS DE EQUIPO' },
  { id: 553, name: 'REGASIFICACION DE GAS NATURAL LICUADO (GNL)' },
  { id: 554, name: 'FABRICACION DE EQUIPO DE ELEVACION Y MANIPULACION' },
  {
    id: 555,
    name: 'SERVICIOS DE REVELADO, IMPRESION Y AMPLIACION DE FOTOGRAFIAS',
  },
  { id: 556, name: 'CULTIVO DE CAMOTES' },
  { id: 557, name: 'INDUSTRIAS BASICAS DE HIERRO Y ACERO' },
  { id: 558, name: 'FABRICACION DE MAQUINARIA METALURGICA' },
  { id: 559, name: 'AGENTES DE VALORES' },
  { id: 560, name: 'FABRICACION DE LOCOMOTORAS Y MATERIAL RODANTE' },
  {
    id: 561,
    name: 'GENERACION DE ENERGIA ELECTRICA EN CENTRALES TERMOELECTRICAS',
  },
  { id: 562, name: 'ADMINISTRACION DE TARJETAS DE CREDITO' },
  { id: 563, name: 'CULTIVO DE AVENA' },
  {
    id: 564,
    name: 'FABRICACION DE MOTORES Y TURBINAS, EXCEPTO PARA AERONAVES, VEHICULOS AUTOMOTORES Y MOTOCICLETAS',
  },
  {
    id: 565,
    name: 'EVALUACION DE RIESGOS Y DAÑOS (INCLUYE ACTIVIDADES DE LIQUIDADORES DE SEGUROS)',
  },
  { id: 566, name: 'FABRICACION DE TAPICES Y ALFOMBRAS' },
  {
    id: 567,
    name: 'FABRICACION DE PAPEL Y CARTON PARA SU POSTERIOR USO INDUSTRIAL N.C.P.',
  },
  {
    id: 568,
    name: 'FABRICACION DE HOJAS DE MADERA PARA ENCHAPADO Y TABLEROS A BASE DE MADERA',
  },
  {
    id: 569,
    name: 'REPARACION DE APARATOS ELECTRONICOS DE CONSUMO (INCLUYE APARATOS DE TELEVISION Y RADIO)',
  },
  {
    id: 570,
    name: 'VENTA AL POR MAYOR DE MAQUINARIA Y EQUIPO DE OFICINA, VENTA AL POR MAYOR DE MUEBLES DE OFICINA',
  },
  { id: 571, name: 'FABRICACION DE PRODUCTOS DE HORNOS DE COQUE' },
  {
    id: 572,
    name: 'CAZA ORDINARIA Y MEDIANTE TRAMPAS Y ACTIVIDADES DE SERVICIOS CONEXAS',
  },
  { id: 573, name: 'CORREDORES DE BOLSA' },
  { id: 574, name: 'ACTIVIDADES DE CASINOS DE JUEGOS' },
  { id: 575, name: 'SEGUROS GENERALES, EXCEPTO ACTIVIDADES DE ISAPRES' },
  { id: 576, name: 'SERVICIOS PERSONALES DE TRADUCCION E INTERPRETACION' },
  { id: 577, name: 'EXTRACCION DE MANGANESO' },
  { id: 578, name: 'FABRICACION DE OTRAS SUSTANCIAS QUIMICAS BASICAS N.C.P.' },
  { id: 579, name: 'CULTIVO DE REMOLACHA AZUCARERA' },
  {
    id: 580,
    name: 'VENTA AL POR MENOR DE EQUIPO DE SONIDO Y DE VIDEO EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 581, name: 'REASEGUROS' },
  {
    id: 582,
    name: 'VENTA AL POR MENOR REALIZADA POR INDEPENDIENTES EN LA LOCOMOCION COLECTIVA (LEY 20.388)',
  },
  { id: 583, name: 'AGENCIAS DE NAVES' },
  { id: 584, name: 'ACTIVIDADES DE CREDITO PRENDARIO' },
  { id: 585, name: 'ACTIVIDADES DE AGENCIAS DE CALIFICACION CREDITICIA' },
  {
    id: 586,
    name: 'OTROS SERVICIOS DE TELECOMUNICACIONES POR SATELITE N.C.P.',
  },
  { id: 587, name: 'FABRICACION DE HORNOS, CALDERAS Y QUEMADORES' },
  { id: 588, name: 'SERVICIOS PRESTADOS POR PERIODISTAS INDEPENDIENTES' },
  { id: 589, name: 'PRODUCCION DE HARINA DE PESCADO' },
  { id: 590, name: 'FABRICACION DE PRODUCTOS REFRACTARIOS' },
  { id: 591, name: 'EXTRACCION DE PETROLEO CRUDO' },
  { id: 592, name: 'RECOGIDA DE DESECHOS PELIGROSOS' },
  { id: 593, name: 'EDICION DE DIRECTORIOS Y LISTAS DE CORREO' },
  { id: 594, name: 'FUNDICION DE HIERRO Y ACERO' },
  { id: 595, name: 'TRATAMIENTO Y ELIMINACION DE DESECHOS PELIGROSOS' },
  { id: 596, name: 'ADMINISTRADORAS DE FONDOS MUTUOS' },
  { id: 597, name: 'SERVICIOS PERSONALES DE FOTOGRAFIA' },
  { id: 598, name: 'SERVICIOS PRESTADOS POR CONCESIONARIOS DE CARRETERAS' },
  { id: 599, name: 'FABRICACION DE EXPLOSIVOS Y PRODUCTOS PIROTECNICOS' },
  { id: 600, name: 'CRIA DE CABRAS (CAPRINOS)' },
  {
    id: 601,
    name: 'SERVICIOS DE ARBITRAJE, SINDICOS DE QUIEBRA Y PERITOS JUDICIALES, OTRAS ACTIVIDADES JURIDICAS N.C.P.',
  },
  { id: 602, name: 'MOLIENDA DE ARROZ, PRODUCCION DE HARINA DE ARROZ' },
  {
    id: 603,
    name: 'VENTA AL POR MENOR DE TAPICES, ALFOMBRAS Y CUBRIMIENTOS PARA PAREDES Y PISOS',
  },
  {
    id: 604,
    name: 'ACTIVIDADES DE ELABORACION Y CONSERVACION DE PESCADO, REALIZADAS EN BARCOS FACTORIA',
  },
  { id: 605, name: 'FABRICACION DE FIBRAS ARTIFICIALES' },
  { id: 606, name: 'RECEPTORES JUDICIALES' },
  { id: 607, name: 'FABRICACION DE ABONOS Y COMPUESTOS DE NITROGENO' },
  {
    id: 608,
    name: 'FABRICACION DE PLAGUICIDAS Y OTROS PRODUCTOS QUIMICOS DE USO AGROPECUARIO',
  },
  {
    id: 609,
    name: 'ACTIVIDADES DE PLANTAS DE REVISION TECNICA PARA VEHICULOS AUTOMOTORES',
  },
  { id: 610, name: 'FABRICACION DE APARATOS ELECTRONICOS DE CONSUMO' },
  {
    id: 611,
    name: 'VENTA AL POR MENOR DE ARMAS Y MUNICIONES EN COMERCIOS ESPECIALIZADOS',
  },
  { id: 612, name: 'EXTRACCION Y PROCESAMIENTO DE LITIO' },
  { id: 613, name: 'FABRICACION DE CELULOSA Y OTRAS PASTAS DE MADERA' },
  {
    id: 614,
    name: 'FABRICACION DE AERONAVES, NAVES ESPACIALES Y MAQUINARIA CONEXA',
  },
  { id: 615, name: 'ELABORACION DE LEVADURAS NATURALES O ARTIFICIALES' },
  { id: 616, name: 'CULTIVO DE SEMILLAS DE MARAVILLA (GIRASOL)' },
  { id: 617, name: 'FABRICACION DE VIDRIO HUECO' },
  {
    id: 618,
    name: 'FABRICACION DE GENERADORES DE VAPOR, EXCEPTO CALDERAS DE AGUA CALIENTE PARA CALEFACCION CENTRAL',
  },
  { id: 619, name: 'CONSTRUCCION DE EMBARCACIONES DE RECREO Y DE DEPORTE' },
  { id: 620, name: 'TRATAMIENTO DE SEMILLAS PARA PROPAGACION' },
  { id: 621, name: 'FABRICACION DE EQUIPO DE COMUNICACIONES' },
  { id: 622, name: 'FABRICACION DE PRODUCTOS DE LA REFINACION DEL PETROLEO' },
  {
    id: 623,
    name: 'FABRICACION DE RECIPIENTES DE METAL PARA GASES COMPRIMIDOS O LICUADOS',
  },
  { id: 624, name: 'FABRICACION DE PILAS, BATERIAS Y ACUMULADORES' },
  { id: 625, name: 'CULTIVO DE SEMILLAS DE RAPS' },
  { id: 626, name: 'REPARACION DE MUEBLES Y ACCESORIOS DOMESTICOS' },
  {
    id: 627,
    name: 'FABRICACION DE MAQUINARIA Y EQUIPO DE OFICINA (EXCEPTO COMPUTADORES Y EQUIPO PERIFERICO)',
  },
  { id: 628, name: 'TELEVISION DE PAGO INALAMBRICA' },
  { id: 629, name: 'ACTIVIDADES DE SECURITIZADORAS' },
  { id: 630, name: 'FABRICACION DE BICICLETAS Y DE SILLAS DE RUEDAS' },
  { id: 631, name: 'FABRICACION DE BISUTERIA Y ARTICULOS CONEXOS' },
  { id: 632, name: 'TRANSPORTE DE CARGA POR FERROCARRIL' },
  { id: 633, name: 'SERVICIO NOTARIAL' },
  {
    id: 634,
    name: 'ACTIVIDADES DE AGENCIAS Y AGENTES DE REPRESENTACION DE ACTORES, DEPORTISTAS Y OTRAS FIGURAS PUBLICAS',
  },
  {
    id: 635,
    name: 'EXPLOTACION DE MATADEROS DE AVES Y DE OTROS TIPOS DE ANIMALES N.C.P.',
  },
  { id: 636, name: 'FABRICACION DE INSTRUMENTOS MUSICALES' },
  { id: 637, name: 'FABRICACION DE OTROS HILOS Y CABLES ELECTRICOS' },
  { id: 638, name: 'HIPODROMOS' },
  { id: 639, name: 'ADMINISTRADORAS DE FONDOS DE PENSIONES (AFP)' },
  { id: 640, name: 'CULTIVO DE PLANTAS DE FIBRA' },
  { id: 641, name: 'ELABORACION DE CIGARROS Y CIGARRILLOS' },
  { id: 642, name: 'TELEVISION DE PAGO POR CABLE' },
  { id: 643, name: 'FABRICACION DE VEHICULOS AUTOMOTORES' },
  { id: 644, name: 'FABRICACION DE EQUIPO DE PROPULSION DE FLUIDOS' },
  { id: 645, name: 'TRANSPORTE INTERURBANO DE PASAJEROS POR FERROCARRIL' },
  { id: 646, name: 'FABRICACION DE DISPOSITIVOS DE CABLEADO' },
  { id: 647, name: 'TELEFONIA MOVIL SATELITAL' },
  {
    id: 648,
    name: 'ELABORACION DE ALMIDONES Y PRODUCTOS DERIVADOS DEL ALMIDON',
  },
  { id: 649, name: 'FABRICACION DE RELOJES' },
  { id: 650, name: 'FABRICACION DE MOTOCICLETAS' },
  { id: 651, name: 'REGULACION Y FACILITACION DE LA ACTIVIDAD ECONOMICA' },
  { id: 652, name: 'ELABORACION DE AZUCAR' },
  {
    id: 653,
    name: 'CRIA DE LLAMAS, ALPACAS, VICUÑAS, GUANACOS Y OTROS CAMELIDOS',
  },
  { id: 654, name: 'ELABORACION DE OTROS PRODUCTOS DE TABACO N.C.P.' },
  { id: 655, name: 'EXTRACCION DE TURBA' },
  { id: 656, name: 'EXTRACCION DE MINERALES DE URANIO Y TORIO' },
  { id: 657, name: 'FABRICACION DE HERRAMIENTAS DE MANO MOTORIZADAS' },
  {
    id: 658,
    name: 'GESTION DE SALAS DE BILLAR, GESTION DE SALAS DE BOLOS (BOWLING)',
  },
  { id: 659, name: 'ALQUILER DE CINTAS DE VIDEO Y DISCOS' },
  { id: 660, name: 'EXTRACCION DE GAS NATURAL' },
  { id: 661, name: 'FABRICACION DE CABLES DE FIBRA OPTICA' },
  { id: 662, name: 'FABRICACION DE SOPORTES MAGNETICOS Y OPTICOS' },
  {
    id: 663,
    name: 'ACTIVIDADES DE APOYO PARA LA EXTRACCION DE PETROLEO Y GAS NATURAL PRESTADOS POR PROFESIONALES',
  },
  { id: 664, name: 'TRANSPORTE POR OLEODUCTOS' },
  { id: 665, name: 'TRANSPORTE POR GASODUCTOS' },
];
