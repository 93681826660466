import React, { useState } from 'react';
import EnhancedTable from '../../../../../../../../../../lib/designSystem/Table';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import CustomBarTooltip from '../CustomBarTooltip';
import CustomSwitch from '../../../../../../../../../../lib/designSystem/CustomSwitch';
import EnhacedBox from '../../../../../../../../../../lib/designSystem/Box';

const countTableHeaders = [
  {
    label: 'Ejecutivo',
    key: 'collectorName',
  },
  {
    label: 'Total',
    key: 'totalCount',
  },
  {
    label: 'Vence en 15d+',
    key: 'dueInMore15DaysCount',
  },
  {
    label: 'Vence en 15d-',
    key: 'dueIn15DaysCount',
  },
  {
    label: 'Vencido 0d a 30d',
    key: 'dued0to30DaysCount',
  },
  {
    label: 'Vencido 31d a 60d',
    key: 'dued31to60DaysCount',
  },
  {
    label: 'Vencido 61d a 90d',
    key: 'dued61to90DaysCount',
  },
  {
    label: 'Vencido 90d+',
    key: 'duedMore90DaysCount',
  },
];

const amountTableHeaders = [
  {
    label: 'Ejecutivo',
    key: 'collectorName',
    type: 'money',
  },
  {
    label: 'Total',
    key: 'totalAmount',
    type: 'money',
  },
  {
    label: 'Vence en 15d+',
    key: 'dueInMore15DaysAmount',
    type: 'money',
  },
  {
    label: 'Vence en 15d-',
    key: 'dueIn15DaysAmount',
    type: 'money',
  },
  {
    label: 'Vencido 0d a 30d',
    key: 'dued0to30DaysAmount',
    type: 'money',
  },
  {
    label: 'Vencido 31d a 60d',
    key: 'dued31to60DaysAmount',
    type: 'money',
  },
  {
    label: 'Vencido 61d a 90d',
    key: 'dued61to90DaysAmount',
    type: 'money',
  },
  {
    label: 'Vencido 90d+',
    key: 'duedMore90DaysAmount',
    type: 'money',
  },
];

const barConfig = {
  stackId: 'a',
  // isAnimationActive: false,
  // animationDuration: 200,
  // animationEasing: 'ease-out' as const,
};

const ByExecutiveCharts = ({ tableData }: { tableData: any }) => {
  const [show, setShow] = useState<'bars' | 'table'>('bars');
  const [headersType, setHeadersType] = useState<'Amount' | 'Count'>('Amount');
  const [barsType, setBarsType] = useState<'Amount' | 'Count'>('Amount');
  const [tableHeaders, setTableHeaders] = useState<any[]>(amountTableHeaders);

  const changeTableHeaders = () => {
    if (headersType === 'Count') {
      setHeadersType('Amount');
      setTableHeaders(amountTableHeaders);
    } else {
      setHeadersType('Count');
      setTableHeaders(countTableHeaders);
    }
  };

  const changeBarsType = () => {
    if (barsType === 'Count') {
      setBarsType('Amount');
    } else {
      setBarsType('Count');
    }
  };

  const formatYAxis = (tickItem: any) => {
    if (barsType === 'Count') {
      return tickItem;
    }
    if (tickItem >= 1000000) {
      return `$${tickItem / 1000000} M`;
    } else if (tickItem >= 1000) {
      return `$${tickItem / 1000} K`;
    }
    return `$${tickItem}`;
  };

  const formatXAxis = (tickItem: any) => {
    if (tickItem.length > 20) {
      return `${tickItem.slice(0, 20)}...`;
    }
    return tickItem;
  };

  const header = () => {
    return (
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          width: '90%',
          justifyContent: 'center',
          alignSelf: 'center',
          paddingLeft: '5%',
        }}
      >
        <CustomSwitch
          leftText="Grafico"
          rightText="Tabla"
          onChange={() => {
            setShow(show === 'bars' ? 'table' : 'bars');
            setTableHeaders(amountTableHeaders);
            setHeadersType('Amount');
            setBarsType('Amount');
          }}
        />
        {show === 'bars' && (
          <CustomSwitch
            sx={{ marginLeft: 'auto' }}
            leftText="Monto"
            rightText="Documentos"
            onChange={() => {
              changeBarsType();
            }}
          />
        )}
        {show === 'table' && (
          <CustomSwitch
            sx={{ marginLeft: 'auto' }}
            leftText="Monto"
            rightText="Documentos"
            onChange={() => {
              changeTableHeaders();
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <EnhacedBox>
        {header()}
        {show === 'bars' && (
          <div>
            <ResponsiveContainer
              width="98%"
              height={window.innerHeight * 0.24}
              // height="25%"
              style={{ padding: '5px' }}
            >
              <BarChart
                key={barsType}
                data={tableData}
                margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
                maxBarSize={60}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="collectorName"
                  // angle={-45}
                  textAnchor="middle"
                  tickFormatter={formatXAxis}
                />
                <YAxis tickFormatter={formatYAxis} />
                <Tooltip
                  content={(props) => (
                    <CustomBarTooltip
                      {...props}
                      money={barsType === 'Amount'}
                    />
                  )}
                  cursor={{ fill: 'transparent' }}
                  position={{ y: -120 }}
                />
                <Legend />
                <Bar
                  dataKey={`dueInMore15Days${barsType}`}
                  fill="#14BA25"
                  name="Vence en 15d+"
                  {...barConfig}
                />
                <Bar
                  dataKey={`dueIn15Days${barsType}`}
                  fill="#E7EB2A"
                  name="Vence en 15d-"
                  {...barConfig}
                />
                <Bar
                  dataKey={`dued0to30Days${barsType}`}
                  fill="#DB6723"
                  name="Vencido 0d a 30d"
                  {...barConfig}
                />
                <Bar
                  dataKey={`dued31to60Days${barsType}`}
                  fill="#E14F2C"
                  name="Vencido 31d a 60d"
                  {...barConfig}
                />
                <Bar
                  dataKey={`dued61to90Days${barsType}`}
                  fill="#E83832"
                  name="Vencido 61d a 90d"
                  {...barConfig}
                />
                <Bar
                  dataKey={`duedMore90Days${barsType}`}
                  fill="#D62839"
                  name="Vencido 90d+"
                  {...barConfig}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
        {show === 'table' && (
          <div>
            <EnhancedTable data={tableData} dataHeaders={tableHeaders} />
          </div>
        )}
      </EnhacedBox>
    </div>
  );
};

export default ByExecutiveCharts;
