import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EnhancedTab from '../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';
import Companies from './components/Businesses';
import PausedAccounts from './components/Paused';
import { AuthState } from '../../services/redux/authSlice';
import { useSelector } from 'react-redux';
import { validatePermissions } from '../../lib/functions/utils';

const companyTabsAvailable = {
  added: {
    label: 'Agregadas',
    component: <Companies />,
    path: 'added',
    index: 0,
  },
  paused: {
    label: 'Eliminadas',
    component: <PausedAccounts />,
    path: 'deleted',
    index: 1,
  },
  // temporary: {
  //   label: 'Temporales',
  //   component: <TemporaryBusinesses />,
  //   path: 'shared',
  //   index: 2,
  // },
};

const MyAccounts: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const { page } = useParams<{ page: string }>();
  const navigate = useNavigate();
  const [compnayTabs, setCompanyTabs] = useState<any>([
    companyTabsAvailable.added,
  ]);

  const handleNavigation = (index: number) => {
    navigate(`/companies/${compnayTabs[index].path}`);
  };
  let startValue = compnayTabs.findIndex((x: any) => x.path === page);
  if (startValue === -1) startValue = 0;

  const setUp = async () => {
    const canManagePaused = validatePermissions(
      user,
      'manage_paused_businesses',
    );
    const newCompanyTabs: any = [];
    newCompanyTabs.push(companyTabsAvailable.added);
    if (canManagePaused) {
      newCompanyTabs.push(companyTabsAvailable.paused);
    }
    // if (validateCompanyTypes(user, 'Factoring')) {
    //   if (validatePermissions(user, 'financial_states_update_configuration'))  {
    //     newCompanyTabs.push({ label: 'Balances ', component: <FinancialStatementsSettings />, path: 'financialStatements', index: 0})
    //   }
    // }
    setCompanyTabs(newCompanyTabs);
  };

  useEffect(() => {
    setUp();
  }, [user]);

  return (
    <div>
      <EnhancedTab
        title="Mis cuentas"
        startValue={startValue}
        tabs={compnayTabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default MyAccounts;
