import { requestHandler } from '../../api';

export const getAutomaticCollectionNotificationsForMonth = async (
  dispatch: any,
  month: string,
  queryParams: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/notifications/automatic/${month}?${queryParams}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getManualCollectionNotificationsForMonth = async (
  dispatch: any,
  month: string,
  queryParams: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/notifications/manual/${month}?${queryParams}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentById = async (documentId: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/document/${documentId}`,
  });
  if (response) return response.data;
};

export const getAvailableUsersForSearch = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/available/collectors/search`,
    dispatch,
  });
  if (response) return response.data;
};
