import { MenuItem, Select, TextField, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import EnhacedBox from '../../../../../../../lib/designSystem/Box';
import HeaderWithActions from '../../../../../../../lib/designSystem/HeaderWithActions';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import { validatePermissions } from '../../../../../../../lib/functions/utils';
import {
  regions,
  provinces,
  communes,
  companySizes,
  economicSectors,
  economicSubSectors,
  economicActivities,
} from '../constants';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../../../../../../lib/functions/utils';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import { updateBusinessSummary } from '../../../../../../../lib/api';
const GridRowText = ({
  label,
  value,
  setValue,
  sizes,
  disabled,
}: {
  label: string;
  value: string;
  setValue: (value: string) => void;
  sizes?: number[];
  disabled?: boolean;
}) => {
  return (
    <>
      <Grid
        item
        xs={sizes?.[0] || 4}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography fontWeight={600}>{label}:</Typography>
      </Grid>
      <Grid item xs={sizes?.[1] || 8}>
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

const GridRowSelect = ({
  label,
  value,
  setValue,
  options,
  sizes,
}: {
  label: string;
  value: string;
  setValue: (value: string) => void;
  options: any[];
  sizes?: number[];
}) => {
  return (
    <>
      <Grid
        item
        xs={sizes?.[0] || 4}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography fontWeight={600}>{label}:</Typography>
      </Grid>
      <Grid item xs={sizes?.[1] || 8}>
        <Select
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          displayEmpty
        >
          {options.map((option) => (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </>
  );
};

const Editor = (props: any) => {
  const {
    setEditing,
    user,
    setUp,
    businessIdentifier,
    email,
    region,
    province,
    commune,
    address,
    economicSector,
    economicSubSector,
    economicActivity,
    salesSegment,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [emailInEdition, setEmailInEdition] = useState(email);
  const [regionInEdition, setRegionInEdition] = useState(region);
  const [provinceInEdition, setProvinceInEdition] = useState(province);
  const [communeInEdition, setCommuneInEdition] = useState(commune);
  const [addressInEdition, setAddressInEdition] = useState(address);
  const [economicSectorInEdition, setEconomicSectorInEdition] =
    useState(economicSector);
  const [economicSubSectorInEdition, setEconomicSubSectorInEdition] =
    useState(economicSubSector);
  const [economicActivityInEdition, setEconomicActivityInEdition] =
    useState(economicActivity);
  const [salesSegmentInEdition, setSalesSegmentInEdition] =
    useState(salesSegment);

  const handleSave = async () => {
    setLoading(true);
    try {
      const data = {
        email: emailInEdition,
        region: regionInEdition,
        province: provinceInEdition,
        commune: communeInEdition,
        address: addressInEdition,
        economicSector: economicSectorInEdition,
        economicSubSector: economicSubSectorInEdition,
        economicActivity: economicActivityInEdition,
        salesSegment: salesSegmentInEdition,
      };
      await updateBusinessSummary(dispatch, businessIdentifier, data);
      showAlert(dispatch, 'success', 'Información actualizada correctamente');
      await setUp();
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al actualizar la información');
    }
    setLoading(false);
  };

  if (loading) {
    return <CenteredCircularProgress />;
  }

  return (
    <>
      <HeaderWithActions
        variant="body1"
        subHeader
        buttons={[
          {
            action: () => setEditing(false),
            color: 'secondary' as const,
            customButton: (
              <CustomButton color="secondary" onClick={() => setEditing(false)}>
                Cancelar
              </CustomButton>
            ),
          },
          {
            action: () => handleSave(),
            color: 'warning' as const,
            disabled: !validatePermissions(user, 'businesses_create_new'),
            customButton: (
              <CustomButton color="warning" onClick={() => handleSave()}>
                Guardar
              </CustomButton>
            ),
          },
        ]}
        title="Aquí encontrarás un resumen de la información de tu cliente."
      />
      <EnhacedBox>
        <div style={{ padding: '10px 0px' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <GridRowText
                  label="Rut"
                  value={businessIdentifier}
                  setValue={() => {}}
                  disabled
                />
                <GridRowSelect
                  label="Región"
                  value={regionInEdition}
                  setValue={setRegionInEdition}
                  options={regions}
                />
                <GridRowSelect
                  label="Provincia"
                  value={provinceInEdition}
                  setValue={setProvinceInEdition}
                  options={provinces}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <GridRowText
                  label="Email"
                  value={emailInEdition}
                  setValue={setEmailInEdition}
                  sizes={[2, 10]}
                />
                <GridRowSelect
                  label="Comuna"
                  value={communeInEdition}
                  setValue={setCommuneInEdition}
                  options={communes}
                  sizes={[2, 10]}
                />
                <GridRowText
                  label="Dirección"
                  value={addressInEdition}
                  setValue={setAddressInEdition}
                  sizes={[2, 10]}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <GridRowSelect
                  label="Sector económico"
                  value={economicSectorInEdition}
                  setValue={setEconomicSectorInEdition}
                  options={economicSectors}
                  sizes={[2, 10]}
                />
                <GridRowSelect
                  label="Subsector económico"
                  value={economicSubSectorInEdition}
                  setValue={setEconomicSubSectorInEdition}
                  options={economicSubSectors}
                  sizes={[2, 10]}
                />
                <GridRowSelect
                  label="Actividad económica"
                  value={economicActivityInEdition}
                  setValue={setEconomicActivityInEdition}
                  options={economicActivities}
                  sizes={[2, 10]}
                />
                <GridRowSelect
                  label="Segmento de ventas"
                  value={salesSegmentInEdition}
                  setValue={setSalesSegmentInEdition}
                  options={companySizes}
                  sizes={[2, 10]}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </EnhacedBox>
    </>
  );
};

export default Editor;
