import React, { useState } from 'react';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { formatMoney } from '../../../../../../../../../../../../lib/functions/utils';
import CustomButton from '../../../../../../../../../../../../lib/designSystem/Button';

const KnowPayerAssign = (props: any) => {
  const {
    index,
    concentration,
    deleteConcentration,
    handleSelectPayer,
    avaliablePayers,
    handleChangeAmount,
  } = props;

  const handleChange = (event: any, newValue: any) => {
    console.log('handleChange', event, newValue);
    if (newValue) {
      handleSelectPayer(newValue.identifier, index);
    }
  };

  return (
    <div key={index} style={{ display: 'flex' }}>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={9}>
          {concentration && concentration.stakeholderName === 'Otros' ? (
            <TextField
              disabled
              autoFocus
              margin="dense"
              label="Nombre del deudor"
              type="text"
              fullWidth
              value={'Otros'}
            />
          ) : (
            <Autocomplete
              options={avaliablePayers(index)}
              value={
                avaliablePayers(index).find(
                  (option: any) =>
                    option.identifier === concentration.identifier,
                ) || null
              }
              getOptionLabel={(option) =>
                `${option.name} - ${option.identifier}`
              }
              renderInput={(params) => (
                <TextField {...params} label="Seleccionar una empresa" />
              )}
              onChange={handleChange}
              fullWidth
            />
          )}
        </Grid>
        <Grid item xs={2}>
          <TextField
            autoFocus
            margin="dense"
            label="Monto"
            value={formatMoney(concentration.amount)}
            onChange={(e) => handleChangeAmount(e, index)}
          />
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'right' }}>
          <IconButton onClick={() => deleteConcentration(index)}>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

const UnknowPayerAssign = (props: any) => {
  const {
    index,
    concentration,
    deleteConcentration,
    handleNewPayer,
    handleChangeAmount,
  } = props;
  return (
    <div key={index} style={{ display: 'flex' }}>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={4}>
          <TextField
            autoFocus
            margin="dense"
            label="Rut del pagador"
            type="text"
            fullWidth
            value={concentration.identfier}
            onChange={(e) => handleNewPayer(e, index, 'identifier')}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            label="Nombre del pagador"
            type="text"
            value={concentration.stakeholderName}
            onChange={(e) => handleNewPayer(e, index, 'stakeholderName')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            autoFocus
            margin="dense"
            label="Monto"
            value={formatMoney(concentration.amount)}
            onChange={(e) => handleChangeAmount(e, index)}
          />
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'right' }}>
          <IconButton onClick={() => deleteConcentration(index)}>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

const PayersAssignation = (props: any) => {
  const { concentrations, setConcentrations, amount, payers, setAmount } =
    props;
  console.log('AssignationModule rendered', concentrations);
  const [openAutoAssign, setOpenAutoAssign] = useState(false);
  const [autoAssignPayers, setAutoAssignPayers] = useState(0);
  const [autoAssignMonth, setAutoAssignMonth] = useState(0);
  const addEmptyConcentration = () => {
    setConcentrations([...concentrations, { stakeholderName: '', amount: 0 }]);
  };

  const addNewEmptyConcentration = () => {
    setConcentrations([
      ...concentrations,
      { stakeholderName: '', amount: 0, new: true },
    ]);
  };

  const addNewOtherConcentration = () => {
    const newArray = [...concentrations];
    const index = newArray.length;
    newArray[index] = {};
    newArray[index].stakeholderName = 'Otros';
    newArray[index].identifier = 'Otros';
    return setConcentrations(newArray);
  };

  const deleteConcentration = (index: number) => {
    const newArray = [...concentrations];
    newArray.splice(index, 1);
    setConcentrations(newArray);
  };

  const handleNewPayer = (e: any, index: number, id: string) => {
    const newArray = [...concentrations];
    newArray[index][id] = e.target.value;
    setConcentrations(newArray);
  };

  const handleAutoAssign = () => {
    const concentrations = payers.slice(0, autoAssignPayers).map((x: any) => {
      return {
        identifier: x.identifier,
        stakeholderName: x.name,
        amount: x.average * autoAssignMonth,
      };
    });

    if (payers.length > autoAssignPayers) {
      const otherAmountAverage = payers
        .slice(autoAssignPayers)
        .reduce((acc: any, curr: any) => acc + curr.average, 0);
      const otherAmount = otherAmountAverage * autoAssignMonth;
      concentrations.push({
        identifier: 'Otros',
        stakeholderName: 'Otros',
        amount: otherAmount,
      });
    }
    setAmount(
      Math.ceil(
        concentrations.reduce((acc: any, curr: any) => acc + curr.amount, 0),
      ),
    );
    setConcentrations(concentrations);
  };

  const handleSelectPayer = (value: any, index: number) => {
    const newArray = [...concentrations];
    const payer = payers.find((x: any) => x.identifier === value);
    console.log(payer);
    if (!payer) return;
    newArray[index].stakeholderName = payer.name;
    newArray[index].identifier = payer.identifier;
    setConcentrations(newArray);
  };

  const handleChangeAmountConcentration = (e: any, index: number) => {
    const value = e.target.value.split('.').join('').split('$').join('');
    if (isNaN(value)) return;
    const newArray = [...concentrations];
    newArray[index].amount = Number(value) || 0;
    setConcentrations(newArray);
  };

  const handleAddClick = (known: boolean) => {
    if (known) {
      addEmptyConcentration();
    } else {
      addNewEmptyConcentration();
    }
  };

  const avaliablePayers = (index: number) =>
    payers.filter(
      (x: any) =>
        !concentrations.map((x: any) => x.identifier).includes(x.identifier) ||
        concentrations[index].identifier === x.identifier,
    );

  // if (loading) {
  //   return <CenteredCircularProgress type="dialog" />;
  // }
  return (
    <div>
      <div style={{ padding: '10px 0px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            textAlign: 'right',
          }}
        >
          <Typography>
            Acá podrás asignar los detalles de tus deudores
          </Typography>
          <div style={{ display: 'flex' }}>
            <CustomButton
              color="secondary"
              style={{ marginRight: 10 }}
              onClick={() => handleAddClick(false)}
            >
              <Add /> Deudor nuevo
            </CustomButton>
            {payers.length > 0 && (
              <CustomButton
                color="secondary"
                style={{ marginRight: 10 }}
                onClick={() => handleAddClick(true)}
              >
                <Add /> Deudor SII
              </CustomButton>
            )}
            <CustomButton
              color="secondary"
              style={{ marginRight: 10 }}
              disabled={concentrations.find(
                (x: any) => x.stakeholderName === 'Otros',
              )}
              onClick={() => addNewOtherConcentration()}
            >
              <Add /> Otros
            </CustomButton>
            {payers.length > 0 && (
              <CustomButton
                onClick={() => setOpenAutoAssign(true)}
                color="primary"
              >
                AutoAsignar
              </CustomButton>
            )}
          </div>
        </div>
      </div>
      <div style={{ padding: '10px 0px' }}>
        {concentrations.map((concentration: any, index: any) => (
          <div key={index}>
            {concentration.new ? (
              <UnknowPayerAssign
                key={index}
                index={index}
                concentration={concentration}
                concentrations={concentrations}
                deleteConcentration={deleteConcentration}
                handleNewPayer={handleNewPayer}
                handleChangeAmount={handleChangeAmountConcentration}
                amount={amount}
              />
            ) : (
              <KnowPayerAssign
                key={index}
                index={index}
                concentration={concentration}
                concentrations={concentrations}
                deleteConcentration={deleteConcentration}
                handleSelectPayer={handleSelectPayer}
                avaliablePayers={avaliablePayers}
                handleChangeAmount={handleChangeAmountConcentration}
                amount={amount}
              />
            )}
          </div>
        ))}
      </div>
      <Dialog open={openAutoAssign} onClose={() => setOpenAutoAssign(false)}>
        <DialogTitle>AutoAsignar</DialogTitle>
        <DialogContent>
          <Typography>
            Selecciona cuantos deudores quieres autoasignar y cuantos meses
            venta quieres asignar
          </Typography>
          <Grid container spacing={2} alignItems={'center'} paddingTop={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Deudores"
                type="number"
                value={autoAssignPayers}
                onChange={(e) => setAutoAssignPayers(Number(e.target.value))}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Meses de venta"
                type="number"
                value={autoAssignMonth}
                onChange={(e) => setAutoAssignMonth(Number(e.target.value))}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <CustomButton
              color="secondary"
              onClick={() => setOpenAutoAssign(false)}
              style={{ marginRight: 10 }}
            >
              Salir
            </CustomButton>
            <CustomButton onClick={() => handleAutoAssign()}>
              AutoAsignar
            </CustomButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PayersAssignation;
