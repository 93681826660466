import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Autocomplete,
  FormControl,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  createCreditLineEvaluation,
  fetchDecisionEnginesAvailables,
} from '../../../../../../lib/api/decisionEngine';
import {
  getBusinessRelationsForUser,
  getOwnCompanyProducts,
} from '../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';

const NewEvaluation = (props: any) => {
  const { type, setOpen, open, setSuccessOpen, setFailureOpen, setUp } = props;
  const [loading, setLoading] = useState(false);
  const [businesses, setBusinesses] = useState<any>([]);
  const [business, setBusiness] = useState<any>(null);

  const [products, setProducts] = useState<any>([]);
  const [product, setProduct] = useState<any>(null);

  const [enginesAvailables, setEnginesAvailables] = useState<any>([]);
  const [engine, setEngine] = useState<any>(null);

  const validateAttributes = () => {
    return true;
  };

  const handleSubmit = async () => {
    if (!validateAttributes()) return;
    setLoading(true);
    try {
      const payload = {
        businessIdentifier: business.id,
        productId: product,
        decisionEngineId: engine,
      };
      const response = await createCreditLineEvaluation(dispatch, payload);
      if (!response.status) {
        setFailureOpen(response.message);
        setLoading(false);
        return;
      }
      await setUp();
      setSuccessOpen(
        'Evaluación creada correctamente. Vuelve en unos segundos para ver los resultados.',
      );
      setOpen(false);
    } catch (error) {
      setFailureOpen('Ha ocurrido un error');
    }
    setLoading(false);
  };
  const dispatch = useDispatch();

  const fetchAvailableBusinesses = async () => {
    setLoading(true);
    try {
      const businessesResponse =
        await getBusinessRelationsForUser(dispatch);
      setBusinesses(businessesResponse);
      const productsResponse = await getOwnCompanyProducts(dispatch);
      const enginesAvailables = await fetchDecisionEnginesAvailables(
        dispatch,
        'creditLine',
      );
      setEnginesAvailables(enginesAvailables);
      setProducts(productsResponse);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAvailableBusinesses();
  }, []);

  const handleChange = (event: any, newValue: any) => {
    setBusiness(newValue);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        Nueva evaluación de {type === 'creditLine' ? 'cliente' : 'documento'}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <div>
            <Typography variant="body1" style={{ margin: '8px 0px' }}>
              A continuación selecciona los parámetros para comenzar la
              evaluación automática.
            </Typography>
            <Autocomplete
              options={businesses.relations}
              value={business}
              getOptionLabel={(option) =>
                `${option.businessName} - ${option.identifier}`
              }
              renderInput={(params) => (
                <TextField
                  style={{ minWidth: 250 }}
                  {...params}
                  label="Selecciona el cliente a evaluar"
                />
              )}
              onChange={handleChange}
              fullWidth
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="filter-label">Tipo de revisión</InputLabel>
              <Select
                labelId="filter-label"
                label="Tipo de revisión"
                value={product}
                onChange={(e) => setProduct(e.target.value)}
              >
                {products.map((product: any) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.productType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="filter-label">
                Que revisión quieres realizar?
              </InputLabel>
              <Select
                labelId="filter-label"
                label="Que revisión quieres realizar?"
                value={engine}
                onChange={(e) => setEngine(e.target.value)}
              >
                {enginesAvailables.map((engine: any) => (
                  <MenuItem key={engine.id} value={engine.id}>
                    {engine.name} (
                    {engine.engineType === 'tree'
                      ? 'Árbol de decisión'
                      : 'Matriz de decisión'}
                    )
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body1" style={{ margin: '8px 0px' }}>
              La evaluación correrá en segundo plano. Vuelve en unos minutos
              para revisar los resultados.
            </Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          {' '}
          Cancelar{' '}
        </CustomButton>
        <CustomButton
          disabled={business === null || product === null || engine === null}
          color="primary"
          onClick={() => handleSubmit()}
        >
          {' '}
          Evaluar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewEvaluation;
