import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import { getPortfolio } from '../../../../../../lib/api/portfolio';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { deleteUser } from '../../../../../../lib/api';
import { showAlert } from '../../../../../../lib/functions/utils';

interface DeleteUserProps {
  userId: number;
  userEmail: string;
  open: boolean;
  handleClose: () => void;
  reload: number;
  setReload: (value: number) => void;
  setUserToTransfer: (value: any) => void;
  setOpenPortfolioTransfer: (value: boolean) => void;
}

const DeleteUser: React.FC<DeleteUserProps> = (props) => {
  const {
    open,
    handleClose,
    userEmail,
    userId,
    reload,
    setReload,
    setUserToTransfer,
    setOpenPortfolioTransfer,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [portfolio, setPortfolio] = React.useState<any>(null);
  const [confirmationWord, setConfirmationWord] = React.useState('');

  const handleDeleteUser = async () => {
    try {
      await deleteUser(dispatch, { id: userId });
      showAlert(dispatch, 'success', 'Usuario eliminado correctamente');
      setReload(reload + 1);
      handleClose();
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al eliminar el usuario');
    }
  };

  const fetchPortfolio = async () => {
    setLoading(true);
    try {
      const portfolio = await getPortfolio(dispatch, userId);
      setPortfolio(portfolio);
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al obtener la cartera del usuario');
    }
    setLoading(false);
  };

  const handleTransferPortfolio = () => {
    setUserToTransfer(userId);
    handleClose();
    setOpenPortfolioTransfer(true);
  };

  useEffect(() => {
    if (open) {
      setConfirmationWord('');
      fetchPortfolio();
    }
  }, [open]);

  if (loading) {
    return <CenteredCircularProgress />;
  }

  if (portfolio?.length > 0) {
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <Typography variant="h6">Eliminar usuario: {userEmail}</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              ¿Estás seguro que deseas eliminar este usuario?
            </Typography>
            <br />
            <Alert severity="warning">
              <Typography variant="body2">
                Este usuario posee una cartera de {portfolio?.length} clientes
                activos. Debes{' '}
                <a href="#" onClick={() => handleTransferPortfolio()}>
                  traspasar esta cartera
                </a>{' '}
                a otro usuario antes de eliminarlo.
              </Typography>
            </Alert>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleClose} color="secondary">
              Cancelar
            </CustomButton>
            <CustomButton
              disabled={portfolio?.length > 0}
              onClick={handleDeleteUser}
              color="primary"
            >
              Desactivar
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <Typography variant="h6">Eliminar usuario: {userEmail}</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              ¿Estás seguro que deseas eliminar este usuario?
            </Typography>
            <br />
            <Alert severity="error">
              <Typography variant="body2">
                Al eliminar un usuario, este no podrá acceder a Pruff. Esta es
                una acción que no se puede deshacer.
              </Typography>
              <div style={{ padding: '5% 0' }}>
                <TextField
                  error
                  fullWidth
                  label="Escribe 'eliminar' para confirmar"
                  variant="outlined"
                  value={confirmationWord}
                  onChange={(e) => setConfirmationWord(e.target.value)}
                />
              </div>
            </Alert>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleClose} color="secondary">
              Cancelar
            </CustomButton>
            <CustomButton
              disabled={confirmationWord !== 'eliminar'}
              onClick={handleDeleteUser}
              color="primary"
            >
              Eliminar
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
};

export default DeleteUser;
