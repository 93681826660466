import React, { useState } from 'react';
import { Stack, Switch, Typography, SxProps } from '@mui/material';

interface CustomSwitchProps {
  leftText?: string;
  rightText?: string;
  onChange?: () => void;
  highlightColor?: string;
  disableHighlight?: boolean;
  align?: 'center' | 'left' | 'right';
  sx?: SxProps;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({
  leftText,
  rightText,
  onChange,
  highlightColor = '#0F23DF',
  disableHighlight = false,
  align = 'center',
  sx,
}) => {
  const [isHighlighted, setIsHighlighted] = useState(true);
  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: align,
        ...sx,
      }}
      direction="row"
      spacing={1}
    >
      <Typography
        variant="body2"
        sx={{
          color:
            isHighlighted && !disableHighlight ? highlightColor : 'inherit', // Change text color
          transition: '0.3s ease-in-out',
          fontWeight: isHighlighted ? 'bold' : 'normal',
        }}
      >
        {leftText}
      </Typography>
      <Switch
        color="default"
        onChange={() => {
          onChange?.();
          setIsHighlighted(!isHighlighted);
        }}
      />
      <Typography
        variant="body2"
        sx={{
          color:
            !isHighlighted && !disableHighlight ? highlightColor : 'inherit', // Change text color
          transition: '0.3s ease-in-out',
          fontWeight: !isHighlighted ? 'bold' : 'normal',
        }}
      >
        {rightText}
      </Typography>
    </Stack>
  );
};

export default CustomSwitch;
