import React, { useState } from 'react';
import { DialogActions, TextField, Typography } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import {
  requestCreditLineValidated,
  requestMoreInformationCreditLine,
} from '../../../../../../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CreditLineStatus from '../../../../../../../../../common/creditLineStatus';
import { PriorityHigh } from '@mui/icons-material';

const ShoppingCartFactoringValidating = (props: any) => {
  const {
    setOpenShoppingCart,
    openRequest,
    setSuccessOpen,
    setLoading,
    setUp,
  } = props;

  const [missingInfo, setMissingInfo] = useState(false);
  const [moreInfo, setMoreInfo] = useState('');
  const dispatch = useDispatch();

  const handleSendInfo = async () => {
    setLoading(true);
    try {
      await requestMoreInformationCreditLine(dispatch, {
        id: openRequest.id,
        message: moreInfo,
      });
      setOpenShoppingCart(false);
      setSuccessOpen(
        'Hemos notificado al cliente para que corrija la información',
      );
      await setUp(openRequest.id);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleValidate = async () => {
    setLoading(true);
    try {
      await requestCreditLineValidated(dispatch, {
        id: openRequest.id,
        async: true,
      });
      setOpenShoppingCart(false);
      setSuccessOpen(
        'Hemos marcado como validada la solicitud, ahora pasará a revisión de riesgos',
      );
      await setUp(openRequest.id);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div>
      {missingInfo ? (
        <div>
          <Typography variant="body1">
            Acá podrás indicarle al cliente que archivos necesitas que suba o
            corrija, esto quedará guardado en la pestaña de chat para próximas
            referencias.
          </Typography>
          <TextField
            label="¿Qué más necesitas saber?"
            variant="outlined"
            margin="dense"
            multiline
            fullWidth
            value={moreInfo}
            onChange={(e) => setMoreInfo(e.target.value)}
          />
          <DialogActions>
            <CustomButton
              onClick={() => setMissingInfo(false)}
              color="secondary"
            >
              Cancelar
            </CustomButton>
            <CustomButton onClick={() => handleSendInfo()} color="primary">
              Enviar
            </CustomButton>
          </DialogActions>
        </div>
      ) : (
        <div>
          <CreditLineStatus openRequest={openRequest} audienceType="received" />
          <div
            style={{
              padding: '10px 0px',
              display: 'flex',
              alignItems: 'top',
              color: 'red',
            }}
          >
            <PriorityHigh />
            <Typography variant="body1">
              <b>Importante:</b>
            </Typography>
          </div>
          <Typography variant="body1">
            Completa el procesamiento de información (vaciar balances, asignar
            matriz de riesgo) antes de validar la solicitud. Esto permite que
            nuestros motores de decisión operen con todos los datos necesarios.
          </Typography>
          <DialogActions>
            <CustomButton
              onClick={() => setOpenShoppingCart(false)}
              color="secondary"
            >
              Salir
            </CustomButton>
            {/* <CustomButton onClick={() => setMissingInfo(true)} color="warning">
              Falta información
            </CustomButton> */}
            <CustomButton onClick={() => handleValidate()} color="primary">
              Validar
            </CustomButton>
          </DialogActions>
        </div>
      )}
    </div>
  );
};

export default ShoppingCartFactoringValidating;
