import React, { useState, useEffect } from 'react';
import EnhancedTable from '../../../../lib/designSystem/Table';
import { Typography } from '@mui/material';
import EnhancedTab from '../../../../lib/designSystem/TabSimulation';
import CustomAlert from '../../../../lib/designSystem/Alert';

const JudiciaryResponse = (props: any) => {
  const { response, searched } = props;
  const [failed, setFailed] = useState('');
  const [validResponse, setValidResponse] = useState(true);
  const [checkedResponse, setCheckedResponse] = useState(false);

  const verifyResponse = () => {
    if (
      !response ||
      !Object.keys(response).length ||
      response.statusCode !== 200
    ) {
      setFailed('No se pudieron obtener los datos de la consulta');
      setValidResponse(false);
      setCheckedResponse(true);
      return false;
    }
    if (response.payload.definitiveFailures > 0) {
      setFailed(
        'El servicio está experimentando una alta demanda en este momento. Por favor, inténtelo nuevamente en unos minutos.',
      );
      setValidResponse(false);
      setCheckedResponse(true);
      return false;
    }
    setFailed('');
    setValidResponse(true);
    setCheckedResponse(true);
    return true;
  };

  const tabs = [];
  let index = 0;
  for (const competence of response.payload.results) {
    const { name, count, properties, propertiesESP, data } = competence;
    const headers = [];
    for (let i = 0; i < properties.length; i++) {
      headers.push({ key: properties[i], label: propertiesESP[i] });
    }
    tabs.push({
      index,
      label: `${name} (${count})`,
      component: (
        <EnhancedTable
          exportToFile="xlsx"
          dataHeaders={headers}
          data={data}
          rowsPerPageDefault={10}
        />
      ),
    });
    index++;
  }

  useEffect(() => {
    verifyResponse();
  }, []);

  const totalCount = response.payload.totalCauses;
  if ((totalCount || 0) < 1 && validResponse && checkedResponse) {
    return (
      <div style={{ padding: '0px' }}>
        <Typography variant="body1">
          {searched ? 'No se encontraron resultados' : ''}{' '}
        </Typography>
      </div>
    );
  }

  return (
    <div>
      {validResponse && <EnhancedTab startValue={0} tabs={tabs} />}
      <CustomAlert
        open={failed !== ''}
        title="Error"
        onClose={() => {
          setFailed('');
        }}
        setOpen={setFailed}
        text={failed}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default JudiciaryResponse;
