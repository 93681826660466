import React, { useState, useEffect } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../../lib/designSystem/HeaderWithActions';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  getDecisionEngineAssignations,
  saveDecisionTree,
  updateDecisionEngine,
} from '../../../../../../../../lib/api';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import { useParams } from 'react-router-dom';
import TreeEngine from './components/TreeEngine';
import MatrixEngine from './components/MatrixEngine';
import { showAlert } from '../../../../../../../../lib/functions/utils';

const enginesDescription: any = {
  tree: 'Este motor te permite crear un árbol de decisiones el cual te ayudará a tomar decisiones de manera automática.',
  matrix:
    'Este motor te permite crear una matriz de decisiones el cual te ayudará a tomar decisiones de manera automática.',
};

const revisionObjectiveDescription: any = {
  invoice: 'Revisión de facturas cuando se solicita factoring de facturas',
  creditLine: 'Revisión de líneas de crédito',
  'factoring-request': 'Revisión de solicitudes de factoring',
};

const DecisionTreeCreate = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [decisionNodesAssignation, setDecisionNodesAssignation] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [name, setName] = useState('');
  const [engineType, setEngineType] = useState(null);
  const [revisionObjective, setRevisionObjective] = useState(null);

  const handleUpdate = async () => {
    console.log(decisionNodesAssignation);
    setLoading(true);
    try {
      const response = await updateDecisionEngine(dispatch, {
        nodes: decisionNodesAssignation,
        id,
      });
      console.log(response);
      setLoading(false);
      window.location.reload();
      showAlert(dispatch, 'success', 'Motor editado correctamente');
    } catch (error) {
      console.log(error);
      setLoading(false);
      showAlert(dispatch, 'error', 'Hubo un error al editar el motor');
    }
  };

  const handleSubmit = async () => {
    console.log(decisionNodesAssignation);
    setLoading(true);
    try {
      const response = await saveDecisionTree(dispatch, {
        nodes: decisionNodesAssignation,
        name,
        engineType,
        revisionObjective,
      });
      console.log(response);
      setLoading(false);
      showAlert(dispatch, 'success', 'Motor creado correctamente');
      window.location.href = '/settings/risk/engines';
    } catch (error) {
      console.log(error);
      setLoading(false);
      showAlert(dispatch, 'error', 'Hubo un error al crear el motor');
    }
  };

  const startButtons: CustomButtonProps[] = [
    {
      action: () => goBackToShow(),
      name: 'Volver',
      color: 'secondary',
    },
    {
      action: () => openConfirmationDialog(),
      name: 'Guardar motor',
      color: 'primary',
    },
  ];

  const goBackToShow = () => {
    window.location.href = '/settings/risk/engines';
  };

  const openConfirmationDialog = () => {
    if (engineType === 'tree') {
      if (!checkAllFatherHaveAnEmptyRange()) {
        alert(
          'Todo nodo padre debe tener un hijo con caso base. (Es la opción cuando no se cumplen las otras condiciones)',
        );
      } else if (!checkAllLeafsAreNegative()) {
        alert(
          'Debes cerrar las ramas con nodos de decisión (Aceptado, rechazado o advertencia)',
        );
      } else if (!checkAllFatherHave2Children()) {
        alert('Debes tener al menos dos hijos por cada nodo de decisión');
      } else {
        setOpenDialog(true);
      }
    } else if (engineType === 'matrix') {
      if (checkMinimumNodesForMatrix()) {
        console.log(decisionNodesAssignation);
        setOpenDialog(true);
      } else {
        alert('Debes tener al menos un nodo de decisión');
      }
    }
  };

  // const checkMinimuNodesForTree = () => {
  //   return decisionNodesAssignation.length >= 3;
  // };

  const checkMinimumNodesForMatrix = () => {
    return decisionNodesAssignation.length >= 1;
  };

  const checkAllFatherHaveAnEmptyRange = () => {
    const allFatherIds = decisionNodesAssignation.map(
      (node: any) => node.fatherNodeId,
    );
    const allFatherIdsUnique = Array.from(new Set(allFatherIds));
    const oks: boolean[] = [];
    allFatherIdsUnique.forEach((fatherNodeId) => {
      console.log(fatherNodeId);
      if (fatherNodeId === 0) {
        return;
      }
      let isOk = false;
      const father: any = decisionNodesAssignation.find(
        (node: any) => node.id === fatherNodeId,
      );
      if (!father) {
        return (isOk = true);
      }
      console.log(father);
      father.ranges.forEach((range: any) => {
        if (range.value === '') {
          isOk = true;
        }
      });
      console.log(isOk);
      oks.push(isOk);
    });
    console.log(oks);
    return oks.every((ok) => ok);
  };

  const checkAllLeafsAreNegative = () => {
    let isOk = true;
    const leafs = decisionNodesAssignation.filter(
      (node: any) => node.ranges.length === 0,
    );
    leafs.forEach((leaf: any) => {
      if (leaf.type !== 'final') {
        isOk = false;
      }
      console.log(leaf, isOk);
    });
    return isOk;
  };

  const checkAllFatherHave2Children = () => {
    let isOk = true;
    const nonFinalNdoes = decisionNodesAssignation.filter(
      (node: any) => node.type !== 'final',
    );
    nonFinalNdoes.forEach((node: any) => {
      if (node.ranges.length < 2) {
        isOk = false;
      }
    });

    return isOk;
  };

  const fetchDecisionEngineNodes = async () => {
    setLoading(true);
    if (!id) {
      setLoading(false);
      return;
    }
    try {
      const response = await getDecisionEngineAssignations(dispatch, { id });
      console.log;
      setDecisionNodesAssignation(response.nodes);
      setEngineType(response.decisionEngine.engineType);
      setRevisionObjective(response.decisionEngine.revisionObjectiveId);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDecisionEngineNodes();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title={id ? 'Editar motor de decisiones' : 'Crear motor de decisiones'}
        type="pageHeader"
        buttons={startButtons}
      />
      <div
        style={{
          display: 'flex',
          padding: '10px 0px 20px',
          alignItems: 'center',
        }}
      >
        <div style={{ minWidth: 250 }}>
          <FormControl
            disabled={id ? true : false}
            fullWidth
            variant="outlined"
          >
            <InputLabel id="filter-label">
              Tipo de revisión de decisiones
            </InputLabel>
            <Select
              labelId="filter-label"
              id="Filtrar por estado"
              name="engineType"
              value={revisionObjective}
              onChange={(e: any) => {
                setDecisionNodesAssignation([]);
                setRevisionObjective(e.target.value);
                setEngineType(null);
              }}
              label="Tipo de revisión de decisiones"
            >
              <MenuItem value="invoice">Facturas</MenuItem>
              <MenuItem value="creditLine">Línea de crédito</MenuItem>
              <MenuItem value="factoring-request">Solicitud factoring</MenuItem>
              <MenuItem value="credit-request">Solicitud de crédito</MenuItem>
              <MenuItem value="creditLine-payers">
                Línea de crédito - Deudores
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <Typography variant="body1" style={{ marginLeft: '20px' }}>
          {revisionObjective
            ? revisionObjectiveDescription[revisionObjective]
            : 'Selecciona una revisión'}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          padding: '10px 0px 20px',
          alignItems: 'center',
        }}
      >
        <div style={{ minWidth: 250 }}>
          <FormControl
            disabled={id ? true : false}
            fullWidth
            variant="outlined"
          >
            <InputLabel id="filter-label">
              Tipo de motor de decisiones
            </InputLabel>
            <Select
              labelId="filter-label"
              id="Filtrar por estado"
              name="engineType"
              value={engineType}
              onChange={(e: any) => {
                setDecisionNodesAssignation([]);
                setEngineType(e.target.value);
              }}
              label="Tipo de motor de decisiones"
            >
              <MenuItem value="tree">Árbol de decisiones</MenuItem>
              <MenuItem value="matrix">Matriz de decisiones</MenuItem>
              <MenuItem disabled value="scoring">
                Scoring (Próximamente)
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <Typography variant="body1" style={{ marginLeft: '20px' }}>
          {engineType ? enginesDescription[engineType] : 'Selecciona un motor'}
        </Typography>
      </div>
      {!engineType || !revisionObjective ? (
        <Typography variant="body1" color="red">
          Selecciona un motor y un tipo de revisión para continuar
        </Typography>
      ) : null}
      <Divider />
      {
        // <DecisionTreeVisualization />
        revisionObjective && engineType === 'tree' && (
          <TreeEngine
            decisionNodesAssignation={decisionNodesAssignation}
            setDecisionNodesAssignation={setDecisionNodesAssignation}
            revisionObjective={revisionObjective}
          />
        )
      }
      {revisionObjective && engineType === 'matrix' && (
        <MatrixEngine
          decisionNodesAssignation={decisionNodesAssignation}
          setDecisionNodesAssignation={setDecisionNodesAssignation}
          revisionObjective={revisionObjective}
        />
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {id ? 'Guardar Motor' : 'Crear motor de decisiones'}
        </DialogTitle>
        <DialogContent>
          {id ? (
            <DialogContentText>
              ¿Estás seguro que quieres guardar los cambios en este motor?
            </DialogContentText>
          ) : (
            <div>
              <DialogContentText>
                ¿Cómo le vas a llamar a este motor?
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nombre del árbol"
                type="text"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setOpenDialog(false)} color="secondary">
            Cancelar
          </CustomButton>
          <CustomButton
            onClick={() => (id ? handleUpdate() : handleSubmit())}
            color="primary"
          >
            {id ? 'Guardar' : 'Crear'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DecisionTreeCreate;
