import React, { useState, useEffect } from 'react';
import EnhancedTable from '../../../../lib/designSystem/Table';
import { showAlert } from '../../../../lib/functions/utils';
import { useDispatch } from 'react-redux';

const NdfResponse = (props: any) => {
  const dispatch = useDispatch();
  const { response } = props;
  const [validResponse, setValidResponse] = useState(true);

  const verifyResponse = () => {
    if (!response || response.length === 0) {
      showAlert(
        dispatch,
        'error',
        'No se pudieron obtener los datos de la consulta. Intenta mas tarde.',
      );
      setValidResponse(false);
      return false;
    }
    setValidResponse(true);
    return true;
  };

  useEffect(() => {
    verifyResponse();
  }, []);

  console.log('RESPONSE', response);

  return (
    <div>
      {validResponse && (
        <EnhancedTable
          data={response}
          dataHeaders={[
            {
              key: 'rut',
              label: 'Rut',
            },
            {
              key: 'name',
              label: 'Nombre o Razón Social',
            },
            {
              key: 'date',
              label: 'Fecha Consulta',
            },
            {
              key: 'status',
              label: 'Situación Actual',
            },
          ]}
        />
      )}
    </div>
  );
};

export default NdfResponse;
