import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import React from 'react';
import { LightTooltip } from '../../../../../../../lib/designSystem/WhiteTooltip';
import EnhacedBox from '../../../../../../../lib/designSystem/Box';
import HeaderWithActions from '../../../../../../../lib/designSystem/HeaderWithActions';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import { validatePermissions } from '../../../../../../../lib/functions/utils';

const GridRow = ({
  label,
  value,
  sizes,
  maxLength,
}: {
  label: string;
  value: string;
  sizes?: number[];
  maxLength?: number;
}) => {
  const textToShow = maxLength
    ? value.length > maxLength
      ? value.slice(0, maxLength) + '...'
      : value
    : value;
  return (
    <>
      <Grid item xs={sizes?.[0] || 4}>
        <Typography fontWeight={600}>{label}:</Typography>
      </Grid>
      <Grid item xs={sizes?.[1] || 8}>
        <LightTooltip title={value}>
          <Typography>{textToShow}</Typography>
        </LightTooltip>
      </Grid>
    </>
  );
};

const Summary = (props: any) => {
  const {
    setEditing,
    user,
    businessIdentifier,
    email,
    region,
    province,
    commune,
    address,
    economicSector,
    economicSubSector,
    economicActivity,
    salesSegment,
  } = props;
  return (
    <>
      <HeaderWithActions
        variant="body1"
        subHeader
        buttons={[
          {
            action: () => setEditing(true),
            color: 'secondary' as const,
            disabled: !validatePermissions(user, 'businesses_create_new'),
            customButton: (
              <CustomButton color="secondary" onClick={() => setEditing(true)}>
                Editar
              </CustomButton>
            ),
          },
        ]}
        title="Aquí encontrarás un resumen de la información de tu cliente."
      />
      <EnhacedBox>
        <div style={{ padding: '20px 0px' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <GridRow
                  label="Rut"
                  value={businessIdentifier}
                  maxLength={40}
                />
                <GridRow label="Región" value={region} maxLength={40} />
                <GridRow label="Provincia" value={province} maxLength={40} />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <GridRow
                  label="Email"
                  value={email}
                  sizes={[2, 10]}
                  maxLength={40}
                />
                <GridRow
                  label="Comuna"
                  value={commune}
                  sizes={[2, 10]}
                  maxLength={40}
                />
                <GridRow
                  label="Dirección"
                  value={address}
                  sizes={[2, 10]}
                  maxLength={40}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <GridRow
                  label="Sector económico"
                  value={economicSector}
                  sizes={[2, 10]}
                  maxLength={90}
                />
                <GridRow
                  label="Subsector económico"
                  value={economicSubSector}
                  sizes={[2, 10]}
                  maxLength={90}
                />
                <GridRow
                  label="Actividad económica"
                  value={economicActivity}
                  sizes={[2, 10]}
                  maxLength={90}
                />
                <GridRow
                  label="Segmento de ventas"
                  value={salesSegment}
                  sizes={[2, 10]}
                  maxLength={90}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </EnhacedBox>
    </>
  );
};

export default Summary;
