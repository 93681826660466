import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Info from '@mui/icons-material/Info';
import EnhacedBox from '../../../../../../../lib/designSystem/Box';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../lib/functions/utils';
import { LightTooltip } from '../../../../../../../lib/designSystem/WhiteTooltip';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import { Visibility } from '@mui/icons-material';

const TableTooltip = (
  type: string,
  data: any,
  handleOpenDocumentsDialog: any,
) => {
  const handleSpecificSegment = (id: number) => {
    handleOpenDocumentsDialog(type, id);
  };

  return (
    <div style={{ padding: '10px' }}>
      <EnhancedTable
        dataHeaders={[
          { label: 'Nombre', key: 'name' },
          { label: 'Monto', key: 'value' },
          { label: 'Documentos', key: 'count' },
          {
            key: 'uploadDocumentPDF',
            label: '',
            handleAction: handleSpecificSegment,
            icon: <Visibility />,
            keyDisabled: 'count',
          },
        ]}
        data={data}
        rowsPerPageDefault={25}
      />
    </div>
  );
};

interface SummaryProps {
  creditLineData: {
    total: number;
    activeAmount: number;
    dueAmount: number;
    status: string;
    validUntil: Date;
    segmentedActive: {
      id: number;
      name: string;
      documentsInRange: {
        [key: string]: number;
      }[];
    }[];
    segmentedArrear: {
      id: number;
      name: string;
      documentsInRange: {
        [key: string]: number;
      }[];
    }[];
    conciliatedByBusiness: number;
    conciliatedByStakeholderAmount: number;
  };
}

const Summary = (props: SummaryProps) => {
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [documents, setDocuments] = useState<any[]>([]);
  const [type, setType] = useState<'segmentedActive' | 'segmentedArrear'>(
    'segmentedActive',
  );
  const [selectedDate, setSelectedDate] = useState<string>('');
  const creditLineData = props.creditLineData;

  const handleOpenDocumentsDialog = (
    type: 'segmentedActive' | 'segmentedArrear',
    id: number,
  ) => {
    const segment = creditLineData[type];
    if (!segment) return;

    setType(type);
    const segmentData = segment.find((x: any) => x.id === id);
    if (!segmentData) return;

    setSelectedDate(segmentData.name);

    setDocuments(segmentData.documentsInRange);
    setOpenDocumentsDialog(true);
  };

  return (
    <div style={{ padding: '20px 0px' }}>
      <EnhacedBox>
        <Typography variant="body1" fontWeight={600}>
          Resumen | Factoring
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}></div>
        <div style={{ padding: '20px 0px' }}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Total Línea de crédito
              </Typography>
              {formatMoney(creditLineData.total)}
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Monto disponible
              </Typography>
              {formatMoney(
                creditLineData.total -
                  creditLineData.activeAmount -
                  creditLineData.dueAmount,
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Monto Vigente
                {creditLineData.segmentedActive && (
                  <LightTooltip
                    title={TableTooltip(
                      'segmentedActive',
                      creditLineData.segmentedActive,
                      handleOpenDocumentsDialog,
                    )}
                  >
                    <Info
                      style={{
                        marginLeft: 6,
                        fontSize: 16,
                        cursor: 'pointer',
                      }}
                    />
                  </LightTooltip>
                )}
              </Typography>
              <Typography variant="body1">
                {formatMoney(creditLineData.activeAmount)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Monto Vencido
                {creditLineData.segmentedArrear && (
                  <LightTooltip
                    title={TableTooltip(
                      'segmentedArrear',
                      creditLineData.segmentedArrear,
                      handleOpenDocumentsDialog,
                    )}
                  >
                    <Info
                      style={{
                        marginLeft: 6,
                        fontSize: 16,
                        cursor: 'pointer',
                      }}
                    />
                  </LightTooltip>
                )}
              </Typography>
              <Typography variant="body1">
                {formatMoney(creditLineData.dueAmount)}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Estado Cliente
              </Typography>
              <Typography variant="body1">{creditLineData.status}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Vencimiento
              </Typography>
              <Typography variant="body1">
                {creditLineData.validUntil
                  ? formatDate(creditLineData.validUntil)
                  : 'Sin fecha'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Recaudado por cliente
              </Typography>
              <Typography variant="body1">
                {formatMoney(creditLineData.conciliatedByBusiness)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Recaudado por deudor
              </Typography>
              <Typography variant="body1">
                {formatMoney(creditLineData.conciliatedByStakeholderAmount)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </EnhacedBox>
      <Dialog
        open={openDocumentsDialog}
        onClose={() => setOpenDocumentsDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          Documentos | {type === 'segmentedActive' ? 'Vigente' : 'Vencido'}{' '}
          {selectedDate}
        </DialogTitle>
        <DialogContent>
          <EnhancedTable
            dataHeaders={[
              { label: 'Folio', key: 'folio' },
              { label: 'Deudor', key: 'stakeholderName' },
              { label: 'Producto', key: 'subProductName' },
              { label: 'Monto', key: 'amount', type: 'money' },
              { label: 'Pago', key: 'paymentDate', type: 'date' },
              { label: 'Mora', key: 'arrearAmount', type: 'money' },
            ]}
            actions={[]}
            data={documents}
            rowsPerPageDefault={25}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton
            color="secondary"
            onClick={() => setOpenDocumentsDialog(false)}
          >
            Cerrar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Summary;
