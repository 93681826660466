import React, { useState, useEffect } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../lib/designSystem/HeaderWithActions';
import { getFinancialStatementAssignationsSummary } from '../../../../../lib/api/finnacialStatements';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import { useParams } from 'react-router-dom';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { Collapse, Typography } from '@mui/material';

import ExportFinancialStatementDialog from './components/ExportDialog';

const Summary = (props: any) => {
  const { emptyer } = props;
  const { id } = useParams<{ id: string; financialStatementId: string }>();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState({
    years: [],
    categories: [],
    data: [[]],
  });
  const [expanded, setExpanded] = useState<string | null>(null);
  const [business, setBusiness] = useState<any>({});
  const [open, setOpen] = useState(false);

  const buttons = summaryData.categories; // Assuming the categories are your button labels

  const fetchSetupData = async () => {
    if (!id) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    setLoading(true);
    const financialStatementsSummary =
      await getFinancialStatementAssignationsSummary(dispatch, id, { emptyer });
    setBusiness(financialStatementsSummary.business);
    console.log(financialStatementsSummary);
    setSummaryData(financialStatementsSummary.processedFiles);
    setExpanded(financialStatementsSummary.processedFiles.categories[0]);
    setLoading(false);
  };

  const openDownloadDialog = () => {
    setOpen(true);
  };

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => openDownloadDialog(),
      name: 'Exportar PDF',
      color: 'primary',
    },
  ];

  useEffect(() => {
    fetchSetupData();
  }, []);

  const titleButtons = buttons.map((category) => ({
    action: () => setExpanded(category),
    name: category,
    color: 'secondary',
    disabled: expanded === category,
  }));

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      {!summaryData.years.length && !emptyer ? (
        <Typography variant="body1">
          No hay balances en estado ASIGNADO para mostrar. Anda a la pestaña
          "Asignaciones" y agregar tu balance para comenzar
        </Typography>
      ) : (
        <div>
          {emptyer && (
            <div>
              <HeaderWithActions
                title={`${business.name} - Balances asignados`}
                type="pageHeader"
                buttons={[
                  {
                    action: () =>
                      (window.location.href = `/financialStatements/done`),
                    name: 'Volver',
                    color: 'secondary',
                  },
                ]}
              />
              {!summaryData.years.length && (
                <Typography variant="body1">
                  No hay balances en estado ASIGNADO para mostrar de esta
                  empresa.
                </Typography>
              )}
            </div>
          )}
          <HeaderWithActions
            type="subHeader"
            buttons={buttonsData}
            titleButtons={titleButtons}
          />
          {buttons.map((category) => (
            <div key={category} style={{ width: '100%' }}>
              <Collapse in={expanded === category}>
                <EnhancedTable
                  dataHeaders={[
                    { key: 'key', label: 'Cuenta' },
                    ...summaryData.years.map((x: any) => ({
                      key: x,
                      label: x,
                      type: 'money',
                    })),
                  ]}
                  data={summaryData.data[category]}
                  rowsPerPageDefault={50}
                />
              </Collapse>
            </div>
          ))}
        </div>
      )}
      <ExportFinancialStatementDialog
        summaryData={summaryData}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};

export default Summary;
