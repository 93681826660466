import { requestHandler } from '../../api';

export const createPaymentRequest = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/paymentRequest`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const createPaymentRequestPassive = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/paymentRequestPassive`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getCompanyPayoutGroup = async (
  dispatch: any,
  source: string,
  sourceId: number,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/payout/group/${source}/${sourceId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const executePaymentRequestPayouts = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/paymentRequest/${id}/payouts/execute`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const executePaymentRequestPassivePayouts = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/paymentRequestPassive/${id}/payouts/execute`,
    data,
    dispatch,
  });
  if (response) return response.data;
};
export const markDocumentsAsPaid = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/paymentRequest/markDocumentsAsPaid`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getExpensesFinancialNodes = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/paymentRequest/financialNodes/expenses`,
    dispatch,
  });
  if (response) return response.data;
};
