// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getConsultorResponse,
  getIntegrationStatusByInternalName,
} from '../../lib/api';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import HeaderWithActions from '../../lib/designSystem/HeaderWithActions';
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import EnhancedBox from '../../lib/designSystem/BigBox';
import CustomButton from '../../lib/designSystem/Button';
import { validaRut, rutChanger } from '../../lib/functions/utils';
import BankruptcyResponse from './ResponseTypes/Bankruptcy';
import SiiComercialYears from './ResponseTypes/SiiCommercialYears';
import CobranzaOnlineCerificateResponse from './ResponseTypes/CobranzaOnlineCertificateResponse';
import SuperDebtResponse from './ResponseTypes/SuperDebt';
import JudiciaryResponse from './ResponseTypes/Judiciary';
import CustomAlert from '../../lib/designSystem/Alert';
import NdfResponse from './ResponseTypes/Ndf';

const DocumentConsultor = (props: any) => {
  const {
    selectedConsult,
    handleSelectChange,
    identifier,
    setIdentifier,
    setDocumentType,
    documentType,
    availableConsults,
    setFolio,
    folio,
    mainType,
  } = props;
  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="filter-label">Selecciona una consulta</InputLabel>
          <Select
            labelId="filter-label"
            id="Filtrar por estado"
            fullWidth
            value={selectedConsult}
            onChange={(e) => handleSelectChange(e)}
            label="Selecciona una consulta"
          >
            {availableConsults[mainType].map((x: any, index: any) => (
              <MenuItem key={index} value={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="filter-label">Tipo de documento</InputLabel>
          <Select
            labelId="filter-label"
            id="Filtrar por estado"
            fullWidth
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
            label="Tipo de documento"
          >
            <MenuItem value="33">Factura electrónica</MenuItem>
            <MenuItem value="34">Factura electrónica exenta</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            id="identifier"
            label="Rut emisor"
            fullWidth
            value={identifier}
            onChange={(e) => rutChanger(e.target.value, setIdentifier)}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            id="identifier"
            label="Folio"
            fullWidth
            type="number"
            value={folio}
            onChange={(e) => setFolio(e.target.value.toUpperCase())}
            variant="outlined"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

const IdentifierConsultor = (props: any) => {
  const {
    selectedConsult,
    handleSelectChange,
    identifier,
    setIdentifier,
    availableConsults,
    mainType,
    year,
    setYear,
  } = props;
  const handleYearChange = (e: any) => {
    const input = e.target.value;
    const validInput = input.replace(/\D/g, '').slice(0, 4);
    setYear(validInput);
  };
  const judiciary: boolean = selectedConsult === 'judiciary';
  return (
    <Grid container spacing={4}>
      <Grid item xs={judiciary ? 4 : 6}>
        <FormControl fullWidth>
          <InputLabel id="filter-label">Selecciona una consulta</InputLabel>
          <Select
            labelId="filter-label"
            id="Filtrar por estado"
            fullWidth
            value={selectedConsult}
            onChange={(e) => handleSelectChange(e)}
            label="Selecciona una consulta"
          >
            {availableConsults[mainType].map((x: any, index: any) => (
              <MenuItem key={index} value={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={judiciary ? 4 : 6}>
        <FormControl fullWidth>
          <TextField
            id="identifier"
            label="Rut"
            fullWidth
            value={identifier}
            onChange={(e) => rutChanger(e.target.value, setIdentifier)}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      {judiciary && (
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              id="year"
              label="Año"
              fullWidth
              value={year}
              onChange={handleYearChange}
              variant="outlined"
              type="number"
            />
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

const Consultor = () => {
  const dispatch = useDispatch();
  const [selectedConsult, setSelectedConsult] = useState<any>(null);
  const [identifier, setIdentifier] = useState('');
  const [year, setYear] = useState('');
  const [folio, setFolio] = useState('');
  const [documentType, setDocumentType] = useState('33');
  const [response, setResponse] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [searched, setSearched] = useState(false);
  const [mainType, setMainType] = useState('rut');
  const [failed, setFailed] = useState('');
  const [availableConsults, setAvailableConsults] = useState<any>({
    document: [
      {
        id: 'valid',
        name: 'Documento válido',
      },
      {
        id: 'ceded',
        name: 'Documento cedido',
      },
    ],
    rut: [
      {
        id: 'bankruptcy',
        name: 'Boletín concursal (Quiebras)',
      },
      {
        id: 'siiCommercialYears',
        name: 'SII - Años comerciales (2023)',
      },
      {
        id: 'judiciary',
        name: 'Poder Judicial (Causas)',
      },
      {
        id: 'ndf',
        name: 'Nómina de Difícil Fiscalización',
      },
    ],
  });

  const handleSelectChange = (event: any) => {
    if (event.target.value === 'judiciary') {
      setLoadingText('Esto puede tardar unos minutos');
    } else {
      setLoadingText('');
    }
    setSearched(false);
    setSelectedConsult(event.target.value as string);
  };

  const validateAllIntegrations = async () => {
    try {
      setLoading(true);
      let integrationLists: any = [];
      integrationLists =
        await validateCobranzaOnlineActivation(integrationLists);
      integrationLists = await validateSuperDebtActivation(integrationLists);
      const mergedRut = availableConsults.rut.concat(integrationLists);
      setAvailableConsults({
        ...availableConsults,
        rut: mergedRut,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const validateSuperDebtActivation = async (integrationLists: any) => {
    try {
      const validationResponse = await getIntegrationStatusByInternalName(
        dispatch,
        'super-debt',
      );
      if (validationResponse.status) {
        integrationLists = integrationLists.concat([
          {
            id: 'superDebt',
            name: 'Deuda',
          },
        ]);
      }
      return integrationLists;
    } catch (error) {
      console.log(error);
      return integrationLists;
    }
  };

  const validateCobranzaOnlineActivation = async (integrationLists: any) => {
    try {
      const validationResponse = await getIntegrationStatusByInternalName(
        dispatch,
        'cobranza-online',
      );
      if (validationResponse.status) {
        integrationLists = integrationLists.concat([
          {
            id: 'cobranzaOnline',
            name: 'Cobranza Online (Certificado de morosidad)',
          },
        ]);
      }
      return integrationLists;
    } catch (error) {
      console.log(error);
      return integrationLists;
    }
  };

  const validateRutConsult = () => {
    if (!identifier) {
      setFailed('Debes ingresar un rut');
      return true;
    }
    if (!selectedConsult) {
      setFailed('Debes seleccionar un tipo de consulta');
      return true;
    }
    const validRut = validaRut(identifier);
    if (!validRut) {
      setFailed('Rut inválido');
      return true;
    }
    return false;
  };

  const validateDocumentConsult = () => {
    if (!identifier) {
      setFailed('Debes ingresar un rut');
      return true;
    }
    const validRut = validaRut(identifier);
    if (!validRut) {
      setFailed('Rut inválido');
      return true;
    }
    if (!documentType) {
      setFailed('Debes seleccionar un tipo de documento');
      return true;
    }
    if (!selectedConsult) {
      setFailed('Debes seleccionar un tipo de consulta');
      return true;
    }
    if (!folio) {
      setFailed('Debes ingresar un folio');
      return true;
    }
    // Folio is number
    if (isNaN(Number(folio))) {
      setFailed('Folio debe ser un número');
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    try {
      if (mainType === 'rut') {
        if (validateRutConsult()) return;
      } else if (mainType === 'document') {
        if (validateDocumentConsult()) return;
      }
      setLoading(true);
      const responseConsult = await getConsultorResponse(dispatch, {
        type: mainType,
        consult: selectedConsult,
        payload: {
          identifier,
          folio: String(folio),
          documentType,
          years: [year],
          competences: ['1', '2', '3', '4', '5', '6'],
        },
      });
      console.log(selectedConsult !== 'judiciary' ? responseConsult : '');
      setResponse(responseConsult);
      setSearched(true);
      setLoading(false);
    } catch (error: any) {
      if (error.message === 'TIMEOUT' && selectedConsult === 'judiciary') {
        setFailed(
          'El poder judicial presenta intermitencias, intenta nuevamente',
        );
      } else {
        console.log(error);
      }
      setLoading(false);
    }
  };

  const disabled = () => {
    if (loading) return true;
    if (!identifier) return true;
    if (!selectedConsult) return true;
    if (
      availableConsults[mainType]
        .map((x: any) => x.id)
        .indexOf(selectedConsult) === -1
    ) {
      return true;
    }
    return false;
  };

  const ResponseComponent = () => {
    if (loading) return <div></div>;
    if (selectedConsult === 'bankruptcy') {
      return (
        <BankruptcyResponse
          searched={searched}
          response={response}
          identifier={identifier}
        />
      );
    } else if (selectedConsult === 'siiCommercialYears') {
      return <SiiComercialYears searched={searched} response={response} />;
    } else if (selectedConsult === 'cobranzaOnline') {
      return (
        <CobranzaOnlineCerificateResponse
          response={response}
          searched={searched}
        />
      );
    } else if (selectedConsult === 'superDebt') {
      return <SuperDebtResponse response={response} searched={searched} />;
    } else if (selectedConsult === 'valid') {
      return <Typography>{response.results[0].message}</Typography>;
    } else if (selectedConsult === 'ceded') {
      return <Typography>{response.results[0].message}</Typography>;
    } else if (selectedConsult === 'judiciary') {
      return <JudiciaryResponse response={response} searched={searched} />;
    } else if (selectedConsult === 'ndf') {
      return <NdfResponse response={response} />;
    }
    return <div></div>;
  };

  const presetQuery = () => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query');
    if (query) {
      console.log(query);
      setSelectedConsult(query);
    }
  };

  useEffect(() => {
    validateAllIntegrations();
    presetQuery();
  }, []);

  return (
    <div>
      <HeaderWithActions buttons={[]} title="Consultas" />
      <EnhancedBox>
        <Typography component="h1" variant="body1">
          En esta sección podrás hacer consultas para rut específicos
        </Typography>
        <Grid
          container
          style={{ alignItems: 'center', padding: '10px 0px' }}
          spacing={4}
        >
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel id="filter-label">Tipo de consulta</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                fullWidth
                value={mainType}
                onChange={(e) => setMainType(e.target.value)}
                label="Tipo de consulta"
              >
                <MenuItem value="rut">Rut</MenuItem>
                <MenuItem value="document">Documento</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            {mainType === 'rut' && (
              <IdentifierConsultor
                selectedConsult={selectedConsult}
                handleSelectChange={handleSelectChange}
                identifier={identifier}
                setIdentifier={setIdentifier}
                availableConsults={availableConsults}
                mainType={mainType}
                year={year}
                setYear={setYear}
              />
            )}
            {mainType === 'document' && (
              <DocumentConsultor
                selectedConsult={selectedConsult}
                handleSelectChange={handleSelectChange}
                identifier={identifier}
                setIdentifier={setIdentifier}
                availableConsults={availableConsults}
                setFolio={setFolio}
                folio={folio}
                mainType={mainType}
                documentType={documentType}
                setDocumentType={setDocumentType}
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <CustomButton
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              fullWidth
              disabled={disabled()}
            >
              Buscar
            </CustomButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          style={{ alignContent: 'center', padding: '10px 0px' }}
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Resultado</Typography>
          </Grid>
          <Grid item xs={12}>
            {searched && <ResponseComponent />}
            {loading && (
              <CenteredCircularProgress relative={true} text={loadingText} />
            )}
          </Grid>
        </Grid>
      </EnhancedBox>
      <CustomAlert
        open={failed !== ''}
        title="Error"
        onClose={() => {
          setFailed('');
        }}
        setOpen={setFailed}
        text={failed}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default Consultor;
