import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { formatMoney } from '../../../../../../../../lib/functions/utils';
import { getBusinessContacts } from '../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
const SimulationForm = (props: any) => {
  const { formData, setFormData, handleChange, selectedRequest } = props;
  const [contacts, setContacts] = useState<any[]>([]);
  const [addingContact, setAddingContact] = useState(false);
  const gridStyle = {
    alignContent: 'center',
    alignItems: 'center',
    padding: '4px',
    paddingRight: 20,
  };

  const dispatch = useDispatch();

  const setUpExistingContacts = async () => {
    if (!selectedRequest.businessIdentifier)
      return alert('No tenemos el rut de la empresa');
    const response = await getBusinessContacts(
      dispatch,
      selectedRequest.businessIdentifier,
    );
    console.log('response', response);
    if (response) {
      setContacts(response);
    }
  };

  const handleChangeContact = (id: string, value: string) => {
    if (id === 'email') {
      const existingEmail = contacts.find((x) => x.email === value);
      if (existingEmail) return alert('Ya existe un contacto con este email');
    }

    setFormData({
      ...formData,
      contactData: { ...formData.contactData, [id]: value },
    });
  };
  useEffect(() => {
    setUpExistingContacts();
  }, []);

  return (
    <div
      style={{
        padding: '0px 0px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <Typography variant="h6">
        <b>Producto:</b> {'Simulación facturas'}
      </Typography>
      <Typography variant="body1" fontWeight={600}>
        Detalles operación
      </Typography>
      <Grid container style={gridStyle} spacing={3}>
        <Grid item xs={6}>
          <Typography variant="body1">Anticipo (%) </Typography>

          <TextField
            value={formData.advance}
            placeholder="100"
            type="number"
            fullWidth
            style={{ padding: '0px !important' }}
            onChange={(e) => handleChange('advance', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Interés (%)</Typography>
          <TextField
            value={formData.rate}
            type="number"
            fullWidth
            placeholder="2.2"
            onChange={(e) => handleChange('rate', e.target.value)}
          />
        </Grid>
      </Grid>
      <Typography variant="body1" marginTop={1} fontWeight={600}>
        Costo de operación
      </Typography>
      <Grid container style={gridStyle} spacing={3}>
        <Grid item xs={6}>
          <Typography variant="body1">Fijo ($)</Typography>
          <TextField
            value={formatMoney(formData.operationCostComissionFixed)}
            type="text"
            fullWidth
            onChange={(e) =>
              handleChange('operationCostComissionFixed', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Variable (%)</Typography>
          <TextField
            value={formData.operationCostComissionVariablePercentage}
            type="number"
            fullWidth
            onChange={(e) =>
              handleChange('operationCostComissionVariable', e.target.value)
            }
          />
        </Grid>
      </Grid>
      {selectedRequest.userOwner ? (
        <div>
          {' '}
          <Typography variant="body1" marginTop={1} fontWeight={600}>
            Creador de la solicitud
          </Typography>
          <Typography variant="body1" marginTop={1}>
            {selectedRequest.userOwner.name} ({selectedRequest.userOwner.email})
          </Typography>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              ...gridStyle,
            }}
          >
            <Typography variant="body1" marginTop={1} fontWeight={600}>
              A quíen se lo envías?
            </Typography>
            {addingContact ? (
              <div>
                <Typography
                  variant="caption"
                  color="primary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setAddingContact(false)}
                >
                  Cancelar
                </Typography>
              </div>
            ) : (
              <Typography
                variant="caption"
                color="primary"
                style={{ cursor: 'pointer' }}
                onClick={() => setAddingContact(true)}
              >
                Nuevo
              </Typography>
            )}
          </div>
          {addingContact ? (
            <Grid container style={gridStyle} spacing={3}>
              <Grid item xs={6}>
                <Typography variant="body1">Nombre</Typography>
                <TextField
                  value={formData.contactData.name}
                  type="text"
                  fullWidth
                  onChange={(e) => handleChangeContact('name', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Email </Typography>
                <TextField
                  value={formData.contactData.email}
                  fullWidth
                  onChange={(e) => handleChangeContact('email', e.target.value)}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container style={gridStyle} spacing={3}>
              <Grid item xs={12}>
                {' '}
                <FormControl margin="dense" fullWidth>
                  <InputLabel id="filter-label">
                    Seleccionar contacto existente
                  </InputLabel>
                  <Select
                    labelId="filter-label"
                    id="Filtrar por estado"
                    fullWidth
                    value={formData.contactData.id}
                    onChange={(e) => handleChangeContact('id', e.target.value)}
                    label="Seleccionar contacto existente"
                  >
                    {contacts.map((x: any, index: any) => (
                      <MenuItem key={index} value={x.id}>
                        {x.name} ({x.email})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </div>
  );
};

export default SimulationForm;
