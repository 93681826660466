import React, { useEffect, useState } from 'react';
import { DialogActions, Grid, Typography } from '@mui/material';

import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import { getCreditLineAssignations } from '../../../../../../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import { formatMoney } from '../../../../../../../../../../../../../lib/functions/utils';
import ShoppingCartFactoringReviewed from '../2_ShoppingCartReviewed';
import CreditLineConcentrations from './components/Concentrations';
import ExportDialog from '../../../../../../../../../common/creditLineStatus/ExportDialog';

const ShoppingCartFactoringAssigned = (props: any) => {
  const {
    setOpenShoppingCart,
    openRequest,
    setSuccessOpen,
    openShoppingCart,
    setUp,
    handleDetails,
  } = props;
  const [stakeholdersConcentrations, setStakeholdersConcentrations] = useState(
    [],
  );
  const [subProductsConcentrations, setSubProductsConcentrations] = useState(
    [],
  );
  const [openExportDialog, setOpenExportDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [editing, setEditing] = React.useState(false);

  const setUpConcentrations = async () => {
    setLoading(true);
    try {
      const response = await getCreditLineAssignations(dispatch, {
        id: openRequest.id,
      });
      console.log('response', response);
      setStakeholdersConcentrations(response.stakeholderAssignations);
      setSubProductsConcentrations(response.subProductAssignations);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpConcentrations();
  }, []);

  if (editing) {
    console.log('ShoppingCartFactoringAssigned');
    return (
      <ShoppingCartFactoringReviewed
        openShoppingCart={openShoppingCart}
        setOpenShoppingCart={setOpenShoppingCart}
        openRequest={openRequest}
        setSuccessOpen={setSuccessOpen}
        inAssignationDefault
        setUp={setUp}
        setLoading={setLoading}
        handleDetails={handleDetails}
      />
    );
  }

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      {openExportDialog && (
        <ExportDialog
          openExportDialog={openExportDialog}
          setOpenExportDialog={setOpenExportDialog}
          openRequest={openRequest}
          dispatch={dispatch}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography fontWeight={600} variant="h6">
          Línea de crédito por aprobar
        </Typography>
        <Typography
          variant="body1"
          onClick={() => setOpenExportDialog(true)}
          style={{ cursor: 'pointer', color: '#0F23DF', fontWeight: 'bold' }}
        >
          Exportar
        </Typography>
      </div>
      <Typography variant="body1">
        A continuación, se presenta un resumen de la Línea de crédito. Una vez
        tenga las aprobaciones necesarias, pasará a estar en estado activa.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ padding: '20px 0px' }}>
            <Grid item xs={3}>
              <div
                style={{
                  padding: '0px 0px',
                }}
              >
                <div>
                  <Typography variant="body1">
                    <b>Nombre cliente</b>
                  </Typography>

                  <Typography variant="body1">
                    {openRequest.businessName}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  padding: '0px 0px',
                }}
              >
                <div>
                  <Typography variant="body1">
                    <b>Rut</b>
                  </Typography>

                  <Typography variant="body1">
                    {openRequest.businessIdentifier}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  padding: '0px 0px',
                }}
              >
                <div
                  style={{
                    paddingBottom: 10,
                  }}
                >
                  <Typography variant="body1">
                    <b>Producto</b>
                  </Typography>
                  <Typography variant="body1">
                    {openRequest.productName}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  padding: '0px 0px',
                }}
              >
                <Typography variant="body1">
                  <b>Monto línea</b>
                </Typography>

                <Typography variant="body1">
                  {formatMoney(openRequest.amount)}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {openRequest.productTypeId === 'factoring' && (
            <div style={{ padding: '20px 0px 0px' }}>
              <CreditLineConcentrations
                stakeholdersConcentrations={stakeholdersConcentrations}
                subProductsConcentrations={subProductsConcentrations}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <DialogActions>
        <CustomButton
          onClick={() => setOpenShoppingCart(false)}
          color="secondary"
        >
          Cerrar
        </CustomButton>
        <CustomButton onClick={() => setEditing(true)} color="primary">
          Editar
        </CustomButton>
      </DialogActions>
    </div>
  );
};

export default ShoppingCartFactoringAssigned;
