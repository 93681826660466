import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  IconButton,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import DatesSelector from './components/DatesSelector';
import { AuthState } from '../../../../../../../../services/redux/authSlice';
import {
  formatDate,
  loggerDev,
  showAlert,
  validateCompanyTypes,
  validatePermissions,
} from '../../../../../../../../lib/functions/utils';
import { getCompanyConfig } from '../../../../../../../../lib/api/company';
import { expressGetCompanyConfig } from '../../../../../../../../lib/api/express/company';
import { Edit } from '@mui/icons-material';

const EditorShoppingCart = (props: any) => {
  const {
    openShoppingCart,
    setOpenShoppingcart,
    selectedDocuments,
    setSelectedDocuments,
    subProductName,
    setOpenDocumentsSelector,
    selectedRequest,
    setSelectedRequest,
    setSelected,
  } = props;
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const [requestPayers, setRequestPayers] = useState<any>([]);
  const [canForcePaymentDate, setCanForcePaymentDate] = useState(false);
  const [fileStatusMandatory, setFileStatusMandatory] = useState(false);
  const [minimumDays, setMinimumDays] = useState(15);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const newOperationDate = new Date(selectedRequest.requestDate);
  newOperationDate.setUTCHours(12);
  const [operationDate, setOperationDate] = useState(newOperationDate);
  const [exchangeDay, setExchangeDay] = useState(false);

  const updateSelectedRequest = () => {
    const documentsForRequest = selectedDocuments.map((document: any) => {
      const documents: any = [];
      if (document.subDocuments && document.subDocuments.length) {
        document.subDocuments.forEach((subDocument: any) => {
          documents.push({
            id: subDocument.id,
            amount: subDocument.amount,
            amountDocument: 0,
            amountFinanced: 0,
            amountRetention: 0,
            businessIdentifier: subDocument.businessIdentifier,
            issuedDate: subDocument.issuedDate,
            confirmDate: document.confirmDate,
            cedded: 'No cedido',
            daysToCount: subDocument.daysToCount,
            folio: `${document.folio}-${subDocument.folio}`,
            subProductName: subProductName,
            paymentDate: subDocument.paymentDate,
            cancelDate: subDocument.cancelDate,
            stakeholderName: subDocument.stakeholderName,
            stakeholderIdentifier: subDocument.stakeholderIdentifier,
            fatherDocumentId: document.id,
            active: true,
          });
        });
        return documents;
      } else {
        return [
          {
            id: document.id,
            amount: document.amount,
            amountDocument: 0,
            amountFinanced: 0,
            amountRetention: 0,
            businessIdentifier: document.businessIdentifier,
            issuedDate: document.issuedDate,
            daysToCount: document.daysToCount,
            confirmDate: document.confirmDate,
            cedded: 'No cedido',
            folio: document.folio,
            paymentDate: document.paymentDate,
            subProductName: subProductName,
            cancelDate: document.cancelDate,
            stakeholderName: document.stakeholderName,
            stakeholderIdentifier: document.stakeholderIdentifier,
            active: true,
          },
        ];
      }
    });

    const documents = documentsForRequest.flat();

    const documentsIds = documents.map((document: any) => document.id);
    setSelected(documentsIds);

    const request = {
      ...selectedRequest,
      amount: documents.reduce((acc: any, curr: any) => acc + curr.amount, 0),
      documents: documents,
      requestDate: operationDate,
    };
    setSelectedRequest(request);
  };

  const validateDocumentsAndSubDocuments = () => {
    const duplicatedFolios: string[] = [];
    selectedDocuments.forEach((document: any) => {
      if (document.isOld) return;
      if (!document.oldSubDocuments) return;
      if (document.subDocuments && document.subDocuments.length) {
        const subFolios = document.oldSubDocuments.map(
          (subDocument: any) => subDocument.folio,
        );
        document.subDocuments.forEach((subDocument: any) => {
          const key = `${document.folio}-${subDocument.folio}`;
          if (subFolios.includes(key) && !subDocument.isOld) {
            duplicatedFolios.push(key);
          }
        });
      }
    });
    if (duplicatedFolios.length) {
      showAlert(
        dispatch,
        'warning',
        `Ya existen algunos sub folios. ${duplicatedFolios.join(', ')}`,
      );
      return false;
    }

    const paymentDate = selectedDocuments.every((document: any) => {
      if (document.subDocuments && document.subDocuments.length) {
        return document.subDocuments.every((subDocument: any) => {
          return subDocument.paymentDate;
        });
      } else {
        return document.paymentDate;
      }
    });
    if (!paymentDate) {
      showAlert(dispatch, 'warning', 'Por favor, complete las fechas de pago');
      return false;
    }
    const amount = selectedDocuments.every((document: any) => {
      if (document.subDocuments && document.subDocuments.length) {
        return document.subDocuments.every((subDocument: any) => {
          return subDocument.amount;
        });
      } else {
        return document.amount;
      }
    });
    if (!amount) {
      showAlert(dispatch, 'warning', 'Por favor, complete los montos');
      return false;
    }
    const amountSubDocumentsLessThanFather = selectedDocuments.every(
      (document: any) => {
        if (document.subDocuments && document.subDocuments.length) {
          return (
            document.subDocuments.reduce(
              (acc: any, curr: any) => acc + curr.amount,
              0,
            ) <= document.amount
          );
        } else {
          return true;
        }
      },
    );
    if (!amountSubDocumentsLessThanFather) {
      showAlert(
        dispatch,
        'warning',
        'El monto de los documentos no puede ser mayor al del padre',
      );
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    if (!validateDocumentsAndSubDocuments()) {
      return;
    }
    updateSelectedRequest();
    setOpenShoppingcart(false);
    return;
  };

  const handleBackwards = () => {
    setOpenDocumentsSelector(true);
    setOpenShoppingcart(false);
  };

  const validatePaymentDays = () => {
    console.log(
      'WATCH MAP',
      selectedDocuments.map((document: any) => {
        return {
          id: document.id,
          folio: document.folio,
          paymentDate: document.paymentDate,
          subDocuments: document.subDocuments
            ? document.subDocuments.map((subDocument: any) => {
                return {
                  id: subDocument.id,
                  folio: subDocument.folio,
                  paymentDate: subDocument.paymentDate,
                };
              })
            : [],
        };
      }),
    );
    return selectedDocuments.every((document: any) => {
      if (document.subDocuments && document.subDocuments.length) {
        return document.subDocuments.every((subDocument: any) => {
          return subDocument.paymentDate;
        });
      } else {
        return document.paymentDate;
      }
    });
  };

  const setUpRequestsInvoices = () => {
    const requestInvoicesArray = [];
    const payersObject: any = {};
    for (const document of selectedDocuments) {
      const paymentDate = document.paymentDate;
      const requestInvoice = { ...document, paymentDate };
      requestInvoicesArray.push(requestInvoice);
      if (!payersObject[requestInvoice.stakeholderIdentifier]) {
        payersObject[requestInvoice.stakeholderIdentifier] = {
          stakeholderName: requestInvoice.stakeholderName,
          paymentDays: null,
        };
      }
    }
    setSelectedDocuments(requestInvoicesArray);
    setRequestPayers(payersObject);
  };

  const setUp = async () => {
    setLoading(true);
    await fetchDateRestrictionsConfig();
    setUpRequestsInvoices();
    setLoading(false);
  };
  const getRestrictions = async () => {
    let system_operations_forcePaymentDate,
      system_operations_minimunDays,
      pruffConnect_operations_uploadPdfMandatory;
    if (user?.express) {
      if (!user.clientId) {
        return {
          system_operations_forcePaymentDate,
          system_operations_minimunDays,
          pruffConnect_operations_uploadPdfMandatory,
        };
      }
      ({
        system_operations_forcePaymentDate,
        system_operations_minimunDays,
        pruffConnect_operations_uploadPdfMandatory,
      } = await expressGetCompanyConfig(dispatch, user.clientId, [
        'system_operations_forcePaymentDate',
        'system_operations_minimunDays',
        'pruffConnect_operations_uploadPdfMandatory',
      ]));
    } else {
      system_operations_forcePaymentDate = await getCompanyConfig(
        dispatch,
        'system_operations_forcePaymentDate',
      );
      system_operations_minimunDays = await getCompanyConfig(
        dispatch,
        'system_operations_minimunDays',
      );
      pruffConnect_operations_uploadPdfMandatory = await getCompanyConfig(
        dispatch,
        'pruffConnect_operations_uploadPdfMandatory',
      );
    }
    return {
      system_operations_forcePaymentDate,
      system_operations_minimunDays,
      pruffConnect_operations_uploadPdfMandatory,
    };
  };

  const fetchDateRestrictionsConfig = async () => {
    try {
      const {
        system_operations_forcePaymentDate,
        system_operations_minimunDays,
        pruffConnect_operations_uploadPdfMandatory,
      } = await getRestrictions();

      setCanForcePaymentDate(Number(system_operations_forcePaymentDate) === 1);
      setFileStatusMandatory(
        Number(pruffConnect_operations_uploadPdfMandatory) === 1,
      );
      if (system_operations_minimunDays)
        setMinimumDays(Number(system_operations_minimunDays));
    } catch (error) {
      loggerDev('Error fetching date restrictions config', error);
    }
  };

  useEffect(() => {
    setUp();
  }, []);

  const resetPaymentDates = () => {
    const requestsInvoicesWithDates = [];
    for (const requestInvoice of selectedDocuments) {
      const newRI = {
        ...requestInvoice,
        paymentDate: '',
        dueDate: '',
        tempDueDate: '',
        daysToCount: null,
        subDocuments: requestInvoice.subDocuments.map((subDocument: any) => ({
          ...subDocument,
          paymentDate: '',
          dueDate: '',
          tempDueDate: '',
          daysToCount: null,
        })),
      };
      requestsInvoicesWithDates.push(newRI);
    }
    setSelectedDocuments(requestsInvoicesWithDates);
  };

  const handleExchangeDay = () => {
    resetPaymentDates();
    setExchangeDay(!exchangeDay);
  };

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const handleEditOperationDate = (event: any) => {
    try {
      const inputDate = new Date(event.target.value);

      for (const document of selectedDocuments) {
        if (inputDate < new Date(document.issuedDate)) {
          showAlert(
            dispatch,
            'warning',
            'La fecha de operación no puede ser menor a la fecha de emisión',
          );
          return;
        }
      }
      resetPaymentDates();
      inputDate.setUTCHours(12);
      setOperationDate(inputDate);
      setOpenEditDialog(false);
    } catch (error) {
      loggerDev('Error editing operation date', error);
    }
  };

  const disabledEditOperationDate =
    validateCompanyTypes(user, 'Accountant') ||
    !validatePermissions(user, 'financing_factoring_edit_operation_date');

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={openShoppingCart}
        onClose={() => {
          setOpenShoppingcart(false);
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>Editar documentos</span>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '10px',
              }}
            >
              Fecha de operación: {operationDate?.toISOString().split('T')[0]}
              <IconButton
                onClick={() => setOpenEditDialog(true)}
                disabled={disabledEditOperationDate}
                style={{ marginLeft: '5px' }}
              >
                <Edit />
              </IconButton>
            </span>
          </div>
        </DialogTitle>

        <div style={{ minHeight: 100 }}>
          {loading ? (
            <CenteredCircularProgress type="dialog" />
          ) : (
            <div>
              <DatesSelector
                selectedDocuments={selectedDocuments}
                requestPayers={requestPayers}
                setRequestPayers={setRequestPayers}
                setSelectedDocuments={setSelectedDocuments}
                exchangeDay={exchangeDay}
                canForcePaymentDate={canForcePaymentDate}
                minimumDays={minimumDays}
                fileStatusMandatory={fileStatusMandatory}
                operationDate={operationDate}
                setOperationDate={setOperationDate}
              />
              <DialogActions>
                {!user?.express && (
                  <CustomButton
                    onClick={() => handleExchangeDay()}
                    color="warning"
                  >
                    {exchangeDay ? 'Deshabilitar' : 'Habilitar'} canje
                  </CustomButton>
                )}
                <CustomButton
                  onClick={() => handleBackwards()}
                  color="secondary"
                >
                  Atrás
                </CustomButton>
                <CustomButton
                  color="primary"
                  disabled={!validatePaymentDays()}
                  onClick={() => handleSave()}
                >
                  Guardar
                </CustomButton>
              </DialogActions>
            </div>
          )}
        </div>
        <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
          <DialogTitle>Editar fecha de operación</DialogTitle>
          <DialogContent style={{ paddingTop: 12 }}>
            <TextField
              fullWidth
              label="Fecha de operación"
              type="date"
              value={operationDate ? formatDate(operationDate) : null}
              onChange={handleEditOperationDate}
            />
          </DialogContent>
          <DialogActions>
            <CustomButton
              color="secondary"
              onClick={() => setOpenEditDialog(false)}
            >
              Cerrar
            </CustomButton>
          </DialogActions>
        </Dialog>
      </Dialog>
    </div>
  );
};

export default EditorShoppingCart;
