import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import LineNodesConfiguration from './Configuration';
import MatrixVisualization from './Visualization';
import { getNodesForDecisionAvailables } from '../../../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';

const MatrixEngine = (props: any) => {
  const {
    revisionObjective,
    decisionNodesAssignation,
    setDecisionNodesAssignation,
  } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [decisionNodes, setDecisionNodes] = useState<any>([]);
  const [categorizedNodes, setCategorizedNodes] = useState<any>([]);
  const [comiteesAvailables, setComiteesAvailables] = useState<any>([]);

  const getNodes = async () => {
    setLoading(true);
    const nodes = await getNodesForDecisionAvailables(dispatch, {
      revisionObjective,
    });
    console.log('nodesAll', nodes);
    if (nodes) {
      setCategorizedNodes(nodes.categorizedNodes);
      setDecisionNodes(nodes.decisionNodes);
      setComiteesAvailables(nodes.comiteesAvailables);
    }
    setLoading(false);
  };

  useEffect(() => {
    getNodes();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <Grid container style={{ paddingTop: '20px' }}>
      <Grid item xs={4}>
        <LineNodesConfiguration
          revisionObjective={revisionObjective}
          decisionNodesAssignation={decisionNodesAssignation}
          setDecisionNodesAssignation={setDecisionNodesAssignation}
          decisionNodes={decisionNodes}
          categorizedNodes={categorizedNodes}
          setDecisionNodes={setDecisionNodes}
          comiteesAvailables={comiteesAvailables}
        />
      </Grid>
      <Grid item xs={8} style={{ overflowX: 'auto' }}>
        <MatrixVisualization
          nodes={decisionNodesAssignation}
          decisionNodes={decisionNodes}
          setDecisionNodes={setDecisionNodes}
          comiteesAvailables={comiteesAvailables}
        />
      </Grid>
    </Grid>
  );
};

export default MatrixEngine;
