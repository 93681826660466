import React, { useEffect, useState } from 'react';
import { getFutureCollectionManagementsByCompanyId } from '../../../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  flattenArrayObject,
  validatePermissions,
} from '../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../services/redux/authSlice';
import CustomAlert from '../../../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import NewManagement from '../../../Documents/components/NewManagement';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import TableWithPagination from '../../../../../../../../lib/designSystem/TableWithPagination';
import DocumentSearcher from '../../../../../../../../lib/common/DocumentSearcher';

const adminHeaders: { key: string; label: string; type: any }[] = [
  { key: 'name', label: 'Nombre', type: '' },
  { key: 'issuedDate', label: 'Fecha emisión', type: 'date' },
  { key: 'paymentDate', label: 'Fecha pago', type: 'date' },
  { key: 'stakeholderName', label: 'Deudor', type: '' },
  { key: 'folio', label: 'Folio documento', type: '' },
  { key: 'amountRemaining', label: 'Monto adeudado', type: 'money' },
];

const regularHeaders: { key: string; label: string; type: any }[] = [
  { key: 'name', label: 'Nombre', type: '' },
  { key: 'issuedDate', label: 'Fecha vencimiento', type: 'date' },
  { key: 'paymentDate', label: 'Fecha pago', type: 'date' },
  { key: 'stakeholderName', label: 'Deudor', type: '' },
  { key: 'folio', label: 'Folio factura', type: '' },
  { key: 'amountRemaining', label: 'Monto adeudado', type: 'money' },
];

const CollectionsManagementsSuggested: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [collectionsData, setCollectionsData] = useState<any[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [businessIdentifier, setBusinessIdentifier] = useState('');
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const [documentType, setDocumentType] = useState('factoring');
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (businessIdentifier)
      query += `businessIdentifier=${businessIdentifier}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (documentType) query += `documentType=${documentType}&`;
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const handleManagement = async (id: string) => {
    const document = collectionsData.find((document) => document.id === id);
    if (document) {
      setSelectedDocument(document);
      setOpenModal(true);
    }
  };

  const actions = [
    {
      action: handleManagement,
      actionLabel: 'Completar',
    },
  ];

  const fetchCollections = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    try {
      const query = generateQuery({ page, pageSize });
      const response = await getFutureCollectionManagementsByCompanyId(
        dispatch,
        query,
      );
      const results: any[] = flattenArrayObject(response.data) || [];
      setCollectionsData(results);
      setPagination(response.pagination);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCollections({ page: 0, pageSize: PAGE_SIZE });
  }, []);

  return (
    <div>
      <HeaderWithActions
        title={'Sugeridas'}
        variant={'body1'}
        fontWeight="bold"
        buttons={[
          {
            name: 'Filtro',
            action: () => {},
            color: 'primary',
            hidden: loading,
            customButton: (
              <DocumentSearcher
                searchData={fetchCollections}
                setData={setCollectionsData}
                setLoading={setLoading}
                businessIdentifier={businessIdentifier}
                setBusinessIdentifier={setBusinessIdentifier}
                stakeholderIdentifier={stakeholderIdentifier}
                setStakeholderIdentifier={setStakeholderIdentifier}
                documentType={documentType}
                setDocumentType={setDocumentType}
                pagination={pagination}
              />
            ),
          },
        ]}
      />
      {loading ? (
        <CenteredCircularProgress relative />
      ) : (
        <TableWithPagination
          dataHeaders={
            validatePermissions(user, 'collection_admin')
              ? adminHeaders
              : regularHeaders
          }
          data={collectionsData}
          rowsPerPageDefault={PAGE_SIZE}
          actions={actions}
          showActions
          pagination={pagination}
          setPagination={setPagination}
          setData={setCollectionsData}
          searchData={fetchCollections}
        />
      )}
      <CustomAlert
        open={openAlert}
        title="Gestión completada"
        onClose={() => {
          setOpenAlert(false);
        }}
        setOpen={setOpenAlert}
        text=""
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={openErrorAlert}
        title="Error al completar la gestión"
        onClose={() => {
          setOpenErrorAlert(false);
        }}
        setOpen={setOpenErrorAlert}
        text=""
        severity="error"
        size="small"
        variant="outlined"
      />
      {openModal && (
        <NewManagement
          openModal={openModal}
          setOpenModal={setOpenModal}
          document={selectedDocument}
          setUp={fetchCollections}
        />
      )}
    </div>
  );
};

export default CollectionsManagementsSuggested;
