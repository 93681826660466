import React, { useEffect, useState } from 'react';
import LineChart, { colorsData, dataFilters } from './LineChart';
import { makeStyles, createStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { getPlacementsChartReport } from '../../../../../../lib/api/reports';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { loggerDev } from '../../../../../../lib/functions/utils';

interface SummaryBoxProps {
  title?: string;
  content?: React.JSX.Element;
  children?: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      border: '1px solid #e0e0e0',
      padding: '30px 0px',
      background: '#ffffff',
      borderRadius: '10px',
      width: '100%', // ensure the table fits its container
    },
    numberBox: {
      border: '1px solid #e0e0e0',
      padding: '0px',
      background: '#ffffff',
      borderRadius: '10px',
      width: '100%', // ensure the table fits its container
      tableLayout: 'fixed', // this can help with column overflow issues
      textAlign: 'center',
    },
  }),
);

const SummaryBox: React.FC<SummaryBoxProps> = ({ content, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.box}>
      {/* <Typography variant="h4">{title}</Typography> */}
      {content}
      {children}
    </div>
  );
};

const Placements: React.FC = () => {
  const [selectedChartDataFilter, setSelectedChartDataFilter] =
    useState<string[]>(dataFilters);
  const [chartData, setChartData] = useState<any[]>([]);
  const [chartDataFiltered, setChartDataFiltered] = useState<any[]>([]);
  const [months, setMonths] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleSelectChartDataFilter = (selectedFilters: any) => {
    setSelectedChartDataFilter(selectedFilters);
    const filters = [...selectedFilters, 'name'];
    setChartDataFiltered(
      chartData.map((obj) => {
        return Object.fromEntries(
          Object.entries(obj).filter(([key]) => filters.includes(key)),
        );
      }),
    );
  };

  const buildQuery = () => {
    let query = '';
    query += `months=${months}`;
    return query;
  };

  const handleChangeMonths = (value: number) => {
    setMonths(value);
    setSelectedChartDataFilter(dataFilters);
  };

  const fetchPlacementsChartReport = async () => {
    try {
      setLoading(true);
      const query = buildQuery();
      const response = await getPlacementsChartReport(dispatch, query);
      setChartData(response.data);
      setChartDataFiltered(response.data);
    } catch (error) {
      loggerDev(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlacementsChartReport();
  }, [months]);

  const buttons: CustomButtonProps[] = [
    {
      name: 'Obtener datos',
      color: 'primary',
      action: () => fetchPlacementsChartReport(),
      customButton: (
        <div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simplex-select"
            value={months}
            onChange={(event) =>
              handleChangeMonths(event.target.value as number)
            }
          >
            <MenuItem value={1}>1 mes</MenuItem>
            <MenuItem value={3}>3 meses</MenuItem>
            <MenuItem value={6}>6 meses</MenuItem>
          </Select>
        </div>
      ),
    },
  ];

  if (loading) {
    return <CenteredCircularProgress />;
  }

  return (
    <>
      <HeaderWithActions
        title="Acá podrás ver el detalle del monto colocado por fecha."
        variant="body1"
        buttons={buttons}
      />
      <SummaryBox
        title={`Historico de colocación`}
        content={
          <>
            <div
              style={{
                padding: '0px 8px 10px 8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <Typography variant="body2">
                  Selecciona los gráficos que deseas ver
                </Typography>
              </div>
              <div>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simplex-select"
                  multiple
                  value={selectedChartDataFilter}
                  onChange={(event) =>
                    handleSelectChartDataFilter(event.target.value)
                  }
                  renderValue={(selectedChartDataFilter) =>
                    selectedChartDataFilter.join(', ')
                  }
                  fullWidth
                  style={{ width: '300px', height: '30px' }}
                >
                  {dataFilters.map((data) => (
                    <MenuItem key={data} value={data}>
                      <Checkbox
                        checked={selectedChartDataFilter.indexOf(data) > -1}
                      />
                      <ListItemText
                        primary={data}
                        style={{
                          color: colorsData[data as keyof typeof colorsData],
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <LineChart aspect={4.0 / 1.08} data={chartDataFiltered} />
          </>
        }
      />
    </>
  );
};

export default Placements;
