import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  Typography,
} from '@mui/material';

export interface CollectionState {
  collectionManagementOptions: any[];
  companyId: number;
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
}

export const OptionsGrid = (props: any) => {
  const {
    executiveId,
    documentHasExecutives,
    byStakeholder,
    document,
    checkFields,
    option,
    setOption,
    setExecutiveId,
    loading,
    executivesOptions,
    portfolioExecutiveId,
    setPortfolioExecutiveId,
    stateId,
    handleSelectState,
    stateOptions,
    isManager,
    completedCheckbox,
    setCompletedCheckbox,
    completedDate,
    dueDate,
    description,
    setDescription,
    contactMethod,
    disabled,
    completed,
    setDueDate,
    setCompletedDate,
    setContactMethod,
    options,
  } = props;
  return (
    <div>
      {executiveId == 0 ? (
        documentHasExecutives ? (
          <div>
            <Typography variant="body1">
              Asigna la gestión a un ejecutivo
            </Typography>
            <Grid container spacing={2} style={{ paddingTop: '12px' }}>
              <Grid item xs={12}>
                <TextField
                  error={checkFields && option === ''}
                  select
                  value={executiveId}
                  label="Ejecutivo gestión"
                  variant="outlined"
                  fullWidth
                  onChange={(e) =>
                    setExecutiveId(e.target.value as unknown as number)
                  }
                  disabled={loading}
                >
                  {executivesOptions.map((element: any) => {
                    return (
                      <MenuItem key={element.id} value={element.id}>
                        {`${element.name} (${element.email})`}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
          </div>
        ) : isManager ? (
          <div>
            <Typography variant="body1">
              {`No hay ejecutivos con este ${
                byStakeholder ? 'deudor' : 'cliente'
              } en su cartera. Puedes asignar un ejecutivo a este documento asociando ${
                byStakeholder
                  ? `${document.stakeholderName}`
                  : `${document.businessName}`
              } a su cartera.`}
            </Typography>
            <Grid container spacing={2} style={{ paddingTop: '12px' }}>
              <Grid item xs={12}>
                <TextField
                  error={checkFields && option === ''}
                  select
                  value={portfolioExecutiveId}
                  label="Ejecutivo a asignar"
                  variant="outlined"
                  fullWidth
                  onChange={(e) =>
                    setPortfolioExecutiveId(e.target.value as unknown as number)
                  }
                  disabled={loading}
                >
                  {executivesOptions.map((element: any) => {
                    return (
                      <MenuItem key={element.id} value={element.id}>
                        {`${element.name} (${element.email})`}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Typography variant="body1">
            {`No hay ejecutivos con este documento en su cartera. Solicita a un
      gerente que asigne un ejecutivo a ${
        byStakeholder
          ? `${document.stakeholderName}`
          : `${document.businessName}`
      }.`}
          </Typography>
        )
      ) : (
        <Grid container spacing={2} style={{ paddingTop: '12px' }}>
          <Grid item xs={12}>
            <TextField
              error={checkFields && option === ''}
              select
              value={executiveId}
              label="Ejecutivo gestión"
              variant="outlined"
              fullWidth
              onChange={(e) =>
                setExecutiveId(e.target.value as unknown as number)
              }
              disabled={loading}
            >
              {executivesOptions.map((element: any) => {
                return (
                  <MenuItem key={element.id} value={element.id}>
                    {`${element.name} (${element.email})`}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={checkFields && option === ''}
              select
              value={stateId}
              label="Estado gestión"
              variant="outlined"
              fullWidth
              onChange={(e) =>
                handleSelectState(e.target.value as unknown as number)
              }
              disabled={loading}
            >
              {stateOptions.map((element: CollectionState) => {
                return (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={checkFields && option === ''}
              select
              value={option}
              label="Subestado de gestión"
              variant="outlined"
              fullWidth
              onChange={(e) => setOption(e.target.value)}
              disabled={loading || !stateId}
            >
              {options.map((element: any) => {
                return (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Método de contacto
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={contactMethod}
                onChange={(e) => setContactMethod(e.target.value)}
                disabled={disabled || !stateId || !option}
                label="Método de contacto"
              >
                {['Llamada', 'Correo', 'Whatsapp'].map((element) => {
                  return (
                    <MenuItem key={element} value={element}>
                      {element}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {!completed && (
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Fecha de vencimiento"
                variant="outlined"
                type="date"
                value={dueDate}
                fullWidth
                onChange={(e) => {
                  setDueDate(e.target.value);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              error={checkFields && description === ''}
              id="outlined-basic"
              label="Observación"
              variant="outlined"
              fullWidth
              multiline
              disabled={disabled || !stateId || !option}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={completedCheckbox}
                  onChange={(e) => setCompletedCheckbox(e.target.checked)}
                  name="completed"
                  color="primary"
                />
              }
              label="Esta tarea ya fue completada?"
            />
          </Grid>
          {completedCheckbox && (
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Fecha de completado"
                variant="outlined"
                type="date"
                value={completedDate}
                fullWidth
                onChange={(e) => {
                  setCompletedDate(e.target.value);
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default OptionsGrid;
