import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TransferList from './Components/TransferList';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import {
  showAlert,
  validatePermissions,
} from '../../../../../../lib/functions/utils';
import { getUserCollectionPortfolio } from '../../../../../../lib/api';

interface PortfolioTransferProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  users: any[];
  selectedUser: any;
}

const CollectionPortfolioTransfer: React.FC<PortfolioTransferProps> = (
  props,
) => {
  const { open, setOpen, selectedUser, users } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(0);
  const [userPortfolio, setUserPortfolio] = useState<any[]>([]);
  const validUsers = users.filter(
    (user) =>
      validatePermissions(user, 'have_collection_portfolio') &&
      user.id !== selectedUser.id,
  );

  const fetchUserPortfolio = async () => {
    setLoading(true);
    try {
      const response = await getUserCollectionPortfolio(
        dispatch,
        selectedUser.id,
      );
      if (response.length === 0) {
        showAlert(dispatch, 'error', 'El usuario no tiene ruts en su cartera');
        return setOpen(false);
      }
      if (response) {
        setUserPortfolio(response);
      }
      showAlert(
        dispatch,
        'warning',
        'La cartera se transferirá de forma permanente',
      );
    } catch (error) {
      showAlert(dispatch, 'error', 'Error al obtener la cartera');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserPortfolio();
  }, []);

  if (loading) {
    return <CenteredCircularProgress />;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="lg"
      >
        <DialogTitle>
          <Typography variant="h6">Transferir cartera cobranza</Typography>
        </DialogTitle>
        <DialogContent>
          <TransferList
            setOpen={setOpen}
            reload={reload}
            setReload={setReload}
            selectedUser={selectedUser}
            userPortfolio={userPortfolio}
            validUsers={validUsers}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CollectionPortfolioTransfer;
