import React, { useState } from 'react';
import {
  Collapse,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import {
  cellFormat,
  formatDateToLocal,
} from '../../../../../../../../../../../lib/designSystem/Table/cellFormat';
import { formatMoney } from '../../../../../../../../../../../lib/functions/utils';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';
const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '100%', // ensure the table fits its container
    },
    tableCell: {
      padding: '2px 12px !important',
      maxWidth: '200px',
      maxHeight: '10px',
      overflow: 'hidden', // Ensure the content doesn't overflow.
      whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
    selectFullWidth: {
      width: '100%',
    },
    selectSmall: {
      width: '200px', // or any other small width
    },
    tooltip: {
      '&:hover': {
        background: '#f00',
      },
    },
  }),
);

const Documents = (props: any) => {
  const classes = useStyles();
  const { documents, setOpenShoppingCart } = props;
  const [expandedDays, setExpandedDays] = useState<Record<number, boolean>>({});

  const toggleDay = (dayIndex: number) => {
    setExpandedDays((prev) => ({ ...prev, [dayIndex]: !prev[dayIndex] }));
  };

  return (
    <div>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead style={{ background: '#efefef' }}>
            <TableCell>Folio</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Fecha de pago</TableCell>

            <TableCell style={{ textAlign: 'right' }}>Saldo</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Cuota</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Interés</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Capital</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Amortización</TableCell>

            <TableCell style={{ textAlign: 'right' }}>Mora</TableCell>
            {/* <TableCell style={{ textAlign: 'right' }}>Interés prórroga</TableCell> */}

            <TableCell style={{ textAlign: 'right' }}>Pagado</TableCell>
            <TableCell style={{ textAlign: 'right' }}></TableCell>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {documents.map((row: any, rowIndex: any) => (
              <React.Fragment key={rowIndex}>
                <TableRow key={rowIndex}>
                  <TableCell className={classes.tableCell}>
                    {row.folio}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {formatDateToLocal(row.paymentDate)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {formatMoney(row.balance)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {formatMoney(row.amount)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {formatMoney(row.interest)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {formatMoney(row.principal)}
                  </TableCell>

                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {formatMoney(row.totalAmortization)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {formatMoney(row.arrearAmount)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {cellFormat(row.paidAt, 'date-colored-ok')}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    <IconButton onClick={() => toggleDay(rowIndex)}>
                      {expandedDays[rowIndex] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </TableCell>
                </TableRow>
                {expandedDays[rowIndex] && (
                  <TableRow>
                    <TableCell colSpan={10} style={{ padding: 0 }}>
                      <Collapse
                        in={expandedDays[rowIndex]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Fecha</TableCell>
                              <TableCell>Interés Diario</TableCell>
                              <TableCell>Interés Acumulado</TableCell>
                              <TableCell>Colocación</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.dailyInterests.map(
                              (
                                dailyInterest: any,
                                dailyInterestIndex: number,
                              ) => (
                                <React.Fragment
                                  key={`${rowIndex}-${dailyInterestIndex}`}
                                >
                                  <TableRow>
                                    <TableCell className={classes.tableCell}>
                                      {formatDateToLocal(dailyInterest.date)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {formatMoney(dailyInterest.amount)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {formatMoney(
                                        dailyInterest.totalInterestDaily,
                                      )}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {formatMoney(dailyInterest.placed)}
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ),
                            )}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions>
        <CustomButton
          color="secondary"
          onClick={() => setOpenShoppingCart(false)}
        >
          Cerrar
        </CustomButton>
      </DialogActions>
    </div>
  );
};

export default Documents;
