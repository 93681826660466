import { Typography } from '@mui/material';
import React from 'react';
import LongTextWithTooltip from '../LongTextWithTooltip';
import { formatMoney } from '../../functions/utils';

interface TwoColumnDocumentResumeProps {
  document: any;
  maxBusinessLength: number;
}

const TwoColumnDocumentResume = ({
  document,
  maxBusinessLength,
}: TwoColumnDocumentResumeProps) => {
  return (
    <div>
      <Typography variant="body2" fontWeight="bold">
        Documento
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="body2">Folio</Typography>
        <Typography variant="body2">{document.folio}</Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="body2">Cliente</Typography>
        <LongTextWithTooltip
          text={document.businessName}
          maxLength={maxBusinessLength}
          variant="body2"
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="body2">Deudor</Typography>
        <LongTextWithTooltip
          text={document.stakeholderName}
          maxLength={maxBusinessLength}
          variant="body2"
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="body2">Monto</Typography>
        <Typography variant="body2">{formatMoney(document.amount)}</Typography>
      </div>
    </div>
  );
};

export default TwoColumnDocumentResume;
