import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomAlert from '../../../../designSystem/Alert';
import { hideAlert } from '../../../../../services/redux/alertSlice';
import { loggerDev } from '../../../../../lib/functions/utils';

interface AlertState {
  open: string;
  message: string;
  severity: 'success' | 'error' | 'warning' | 'info';
}

interface RootState {
  alert: AlertState;
}

const GeneralCustomAlert = () => {
  const dispatch = useDispatch();
  const { open, message, severity } = useSelector(
    (state: RootState) => state.alert,
  );

  loggerDev('openStatus', open);

  const handleClose = () => {
    dispatch(hideAlert());
  };

  if (!open) return null;

  return (
    <div>
      <CustomAlert
        open={open}
        severity={severity}
        text={message}
        onClose={handleClose}
        sx={{
          position: 'fixed',
          top: 24,
          right: 24,
          zIndex: 9999,
        }}
        t
      />
    </div>
  );
};

export default GeneralCustomAlert;
