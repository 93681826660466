export const CHILEAN_HOLIDAYS = [
  // 2024
  new Date('2024-01-01'),
  new Date('2024-03-29'),
  new Date('2024-03-30'),
  new Date('2024-05-01'),
  new Date('2024-05-21'),
  new Date('2024-06-20'),
  new Date('2024-06-29'),
  new Date('2024-07-16'),
  new Date('2024-08-15'),
  new Date('2024-09-18'),
  new Date('2024-09-19'),
  new Date('2024-09-20'),
  new Date('2024-10-12'),
  new Date('2024-10-31'),
  new Date('2024-11-01'),
  new Date('2024-12-08'),
  new Date('2024-12-25'),
  // 2025
  new Date('2025-01-01'),
  new Date('2025-04-18'),
  new Date('2025-04-19'),
  new Date('2025-05-01'),
  new Date('2025-05-21'),
  new Date('2025-06-20'),
  new Date('2025-06-29'),
  new Date('2025-07-16'),
  new Date('2025-08-15'),
  new Date('2025-09-18'),
  new Date('2025-09-19'),
  new Date('2025-10-12'),
  new Date('2025-10-31'),
  new Date('2025-11-01'),
  new Date('2025-11-16'),
  new Date('2025-12-08'),
  new Date('2025-12-14'),
  new Date('2025-12-25'),
  // 2026
  new Date('2026-01-01'),
  new Date('2026-04-03'),
  new Date('2026-04-04'),
  new Date('2026-05-01'),
  new Date('2026-05-21'),
  new Date('2026-06-21'),
  new Date('2026-06-29'),
  new Date('2026-07-16'),
  new Date('2026-08-15'),
  new Date('2026-09-18'),
  new Date('2026-09-19'),
  new Date('2026-10-12'),
  new Date('2026-10-31'),
  new Date('2026-11-01'),
  new Date('2026-12-08'),
  new Date('2026-12-25'),
];
